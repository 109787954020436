export class MailConfiguracion {
  constructor(
    public readonly Id: number,
    public readonly Codigo: string,
    public readonly ProveedorEnvio: number,
    public readonly ProveedorEnvioNombre: string,
    public readonly MailSaliente: string,
    public readonly MailSalienteDisplayName: string,
    public readonly MailResponderA: string,
    public readonly ServidorMail: string,
    public readonly PuertoServidorMail: number,
    public readonly HabilitarSsl: boolean,
    public readonly Usuario: string,
    public readonly Clave: string,
    public CertificadoExternoUrl: string,
    public ClaveCertificadoExterno: string,
    public readonly Activo: boolean
  ) {}
}

export class SearchMailConfiguracionRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Codigo: string,
    public readonly MailSaliente: string,
    public readonly MailSalienteDisplayName: string,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveMailConfiguracionDto {
  constructor(
    public Codigo: string,
    public readonly ProveedorEnvio: number,
    public readonly MailSaliente: string,
    public readonly MailSalienteDisplayName: string,
    public readonly MailResponderA: string,
    public readonly ServidorMail: string,
    public readonly PuertoServidorMail: number,
    public readonly HabilitarSsl: boolean,
    public readonly Usuario: string,
    public readonly Clave: string,
    public readonly CertificadoExternoUrl: string,
    public readonly ClaveCertificadoExterno: string,
    public Activo: boolean
  ) {}
}

export class SearchMailConfiguracionResponse {
  constructor(
    public readonly count: number,
    public readonly result: MailConfiguracion[]
  ) {}
}

export class MailProveedorEnvio {
  constructor(
    public readonly Id: number,
    public readonly Nombre: string
  ) {}
}

export class SendTestEmailDto {
  constructor(
    public readonly MailConfiguracion: SaveMailConfiguracionDto,
    public readonly MailDestinatario: string
  ) {}
}

export class UploadMailCertificadoDto{
  constructor(
    public NombreCertificado: string,
    public CertificadoExternoUrl: string,
  ){}
}