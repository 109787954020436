<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Rol</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
 </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && item != null">

      <ng-container *ngIf="canEdit">

        <form [formGroup]="formGroup">

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Código*</mat-label>
                <input matInput formControlName="Codigo" (keyup.enter)="onGuardar()" maxlength="100">
                <mat-error *ngIf="!formGroup.controls['Codigo'].valid && formGroup.controls['Codigo'].touched">
                  {{ getErrorCodigo() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre*</mat-label>
                <input matInput formControlName="Nombre" (keyup.enter)="onGuardar()" maxlength="150">
                <mat-error *ngIf="!formGroup.controls['Nombre'].valid && formGroup.controls['Nombre'].touched">
                  {{ getErrorNombre() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isRolTipoSearching">Tipo de Rol</mat-label>
                <mat-label *ngIf="isRolTipoSearching">Cargando...</mat-label>
                <mat-select formControlName="RolTipoId">
                  <mat-option *ngFor="let s of rolTipoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.controls['RolTipoId'].valid && formGroup.controls['RolTipoId'].touched">
                  {{ getErrorRolTipoId() }}
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
              <mat-checkbox [(ngModel)]="item.Activo" (keyup.enter)="onGuardar()" [ngModelOptions]="{standalone: true}">Activo</mat-checkbox> 
            </div>
            <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
              <mat-checkbox [(ngModel)]="item.EsDefaultParaAltas" (keyup.enter)="onGuardar()" [ngModelOptions]="{standalone: true}">Es Default Para Altas de Usuarios</mat-checkbox> 
            </div>
          </div>

          <hr style="margin:20px 0px; width: 100%;">

          <mat-expansion-panel [expanded]="true">

            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="fa fa-key" style="margin-right: 5px;"></i> Configuraciones de Accesos
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div fxLayout="row stretch" fxLayout.xs="column">
              <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                <mat-label *ngIf="!menuLoaded || !rolMenuLoaded"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
                <mat-label *ngIf="menuLoaded && rolMenuLoaded"><b>Menues Seleccionados*: {{getTotalSelectedMenues()}}</b></mat-label>
              </div>
              <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin-top: 5px;">
                <ejs-treeview #treeViewMenu id="checkbox" [fields]='treeViewField' [showCheckBox]='canEdit'></ejs-treeview>
              </div>
            </div>

          </mat-expansion-panel>

          <ng-container *ngIf="puedeVerMensajeOpcionEnvio || puedeVerMensajeTipo">
            
            <hr style="margin:20px 0px; width: 100%;">

            <mat-expansion-panel [expanded]="true">

              <mat-expansion-panel-header>
                <mat-panel-title>
                  <i class="fa fa-envelope fa-1x" style="margin-right: 5px;"></i> Configuraciones de Envio de Mensajes
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div fxLayout="row stretch" fxLayout.xs="column">
                <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="puedeVerMensajeOpcionEnvio">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label *ngIf="!isMensajeOpcionEnvioSearching">Opciones de Envio</mat-label>
                    <mat-label *ngIf="isMensajeOpcionEnvioSearching">Cargando...</mat-label>
                    <mat-select formControlName="OpcionesEnvioMensaje" multiple [compareWith]="compareOpcionesEnvioMensaje">
                      <mat-option *ngFor="let s of mensajeUsuarioOpcionEnvioList" [value]="s.Id">{{s.Nombre}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="!formGroup.controls['OpcionesEnvioMensaje'].valid && formGroup.controls['OpcionesEnvioMensaje'].touched">
                      {{ getErrorOpcionesEnvioMensaje() }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="puedeVerMensajeTipo">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label *ngIf="!isMensajeTipoSearching">Tipos de Mensaje</mat-label>
                    <mat-label *ngIf="isMensajeTipoSearching">Cargando...</mat-label>
                    <mat-select formControlName="TiposMensaje" multiple [compareWith]="compareTiposMensaje">
                      <mat-option *ngFor="let s of mensajeUsuarioTipoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="!formGroup.controls['TiposMensaje'].valid && formGroup.controls['TiposMensaje'].touched">
                      {{ getErrorTiposMensaje() }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

            </mat-expansion-panel>

          </ng-container>

        </form>

      </ng-container>

      <ng-container *ngIf="!canEdit">

        <div fxLayout="row stretch" class="zb-readonly">
    
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Código</b>: {{item.Codigo}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Nombre</b>: {{item.Nombre}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Tipo Rol</b>: {{item.RolTipo?.Nombre}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Es Default Para Altas de Usuarios?</b>: {{item.EsDefaultParaAltas ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Activo</b>: {{item.Activo ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <hr style="margin:20px 0px; width: 100%;">

          <div fxFlex=100 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">

              <div fxLayout="row stretch" fxLayout.xs="column" style="margin-bottom: 10px;">
                <mat-label><b><i class="fa fa-key" style="margin-right: 3px;"></i> Configuraciones de Accesos</b></mat-label>
              </div>
              <mat-label fxLayout="row stretch" *ngIf="isMenuRolSearching"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>

              <ejs-grid #grid [dataSource]='rolMenuList' [allowPaging]="true" [allowSorting]="true" [toolbar]='toolbarOptions' [selectionSettings]='selectionOptions' 
                [pageSettings]='pageSettings' [allowResizing]='true' *ngIf="!isMenuRolSearching">
                <e-columns>
                  <e-column field='Codigo' headerText='Código' width=90></e-column>
                  <e-column field='Nombre' headerText='Nombre' width=90></e-column>
                  <e-column field='Visible' headerText='Visible' width=90>
                    <ng-template #template let-data>
                      {{data.Visible ? 'Sí' : 'No'}}
                    </ng-template>
                  </e-column>
                </e-columns>
              </ejs-grid>
            </mat-expansion-panel>
          </div>

          <hr style="margin:20px 0px; width: 100%;">

          <div fxFlex=100 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b><i class="fa fa-envelope" style="margin-right: 5px;"></i> Configuraciones de Envio de Mensajes:</b></mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=100 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Opciones de Envio</b>: {{concatMensajeOpcionesEnvio()}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=100 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Tipos de Mensaje</b>: {{concatMensajeTipos()}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </ng-container>

    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onGuardar()" *ngIf="canEdit" [disabled]="isProcessing()" color="primary" class="zb-button">{{isSaving ? 'Guardando...' : 'Guardar'}}</button>
      <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canEdit ? 'Cancelar' : 'Cerrar'}}</button>
      <button mat-raised-button (click)="onDelete()" [disabled]="isProcessing()" *ngIf="canDelete" color="accent" class="zb-button">{{isDeleting ? 'Eliminando...' : 'Eliminar'}}</button>
    </div>
  </mat-card-actions>

</mat-card>