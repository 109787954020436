<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Licitación</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

    <ng-container *ngIf="!isSearching && licitacion != null">

      <mat-expansion-panel [expanded]="true">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">receipt</mat-icon> Datos de Licitación
          </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="licitacionFormGroup" *ngIf="canAdd || canEdit">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Licitación*</mat-label>
                <input matInput formControlName="Numero">
                <mat-error *ngIf="!licitacionFormGroup.controls['Numero'].valid && licitacionFormGroup.controls['Numero'].touched">
                  {{ getErrorNumero() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Empresa Código*</mat-label>
                <input matInput formControlName="EmpresaCodigo">
                <mat-error *ngIf="!licitacionFormGroup.controls['EmpresaCodigo'].valid && licitacionFormGroup.controls['EmpresaCodigo'].touched">
                  {{ getErrorEmpresaCodigo() }}
                </mat-error>
              </mat-form-field>
            </div>
      
            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Descripción*</mat-label>
                <input matInput formControlName="Descripcion">
                <mat-error *ngIf="!licitacionFormGroup.controls['Descripcion'].valid && licitacionFormGroup.controls['Descripcion'].touched">
                  {{ getErrorDescripcion() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Unidad de Compra*</mat-label>
                  <input matInput formControlName="UnidadDeCompra">
                    <mat-error *ngIf="!licitacionFormGroup.controls['UnidadDeCompra'].valid && licitacionFormGroup.controls['UnidadDeCompra'].touched">
                  {{ getErrorUnidadDeCompra() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Dependencia*</mat-label>
                  <input matInput formControlName="Dependencia">
                <mat-error *ngIf="!licitacionFormGroup.controls['Dependencia'].valid && licitacionFormGroup.controls['Dependencia'].touched">
                  {{ getErrorDependencia() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Modalidad</mat-label>
                <input matInput formControlName="Modalidad" maxlength="255">
                  <mat-error *ngIf="!licitacionFormGroup.controls['Modalidad'].valid && licitacionFormGroup.controls['Modalidad'].touched">
                  {{ getErrorModalidad() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isEstadoSearching">Estado*</mat-label>
                <mat-label *ngIf="isEstadoSearching">Cargando...</mat-label>
                <mat-select formControlName="EstadoId">
                  <mat-option *ngFor="let s of estadoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!licitacionFormGroup.controls['EstadoId'].valid && licitacionFormGroup.controls['EstadoId'].touched">
                  {{ getErrorEstadoId() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Moneda</mat-label>
                  <input matInput formControlName="Moneda">
                <mat-error *ngIf="!licitacionFormGroup.controls['Moneda'].valid && licitacionFormGroup.controls['Moneda'].touched">
                  {{ getErrorMoneda() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Monto de referencia*</mat-label>
                  <input matInput formControlName="MontoReferencia">
                <mat-error *ngIf="!licitacionFormGroup.controls['MontoReferencia'].valid && licitacionFormGroup.controls['MontoReferencia'].touched">
                  {{ getErrorMontoReferencia() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" >
                <mat-label>Fecha Publicación*</mat-label>
                <input matInput [matDatepicker]="fechaPublicacionPicker" formControlName="FechaPublicacion" readonly>
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaPublicacionPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaPublicacion')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaPublicacionPicker></mat-datepicker>
              </mat-form-field>
            </div> 
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" >
                <mat-label>Fecha Ult. Publicación*</mat-label>
                <input matInput [matDatepicker]="fechaUltimaPublicacionPicker" formControlName="FechaUltimaPublicacion" readonly>
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaUltimaPublicacionPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaUltimaPublicacion')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaUltimaPublicacionPicker></mat-datepicker>
              </mat-form-field>
            </div>

          </div>

        </form>

        <ng-container *ngIf="!canAdd && !canEdit">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nro. Licitación</b>: {{licitacion?.Numero}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Empresa Código</b>: {{licitacion?.EmpresaCodigo}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            
            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Descripción</b>: {{licitacion?.Descripcion}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Unidad de Compra</b>: {{licitacion?.UnidadDeCompra}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Dependencia</b>: {{licitacion?.Dependencia}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Modalidad</b>: {{licitacion?.Modalidad}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Estado</b>: {{licitacion?.EstadoNombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
      
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Moneda</b>: {{licitacion?.Moneda}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Monto Referencia</b>: {{licitacion?.MontoReferencia | number:'1.2-2'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Fecha de Publicación</b>: {{licitacion?.FechaPublicacion | date:'dd/MM/yyyy'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Fecha de Ult. Publicación</b>: {{licitacion?.FechaUltimaPublicacion | date:'dd/MM/yyyy'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

          </div>

        </ng-container>

      </mat-expansion-panel>

      <hr style="margin:20px 0px; width: 100%;">

      <mat-expansion-panel [expanded]="true">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">list</mat-icon> Especificaciones Técnicas
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div fxLayout="row stretch" fxLayout.xs="column">

          <mat-expansion-panel [expanded]="expandEspecificacionesTecnicas">

            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon style="margin-top: -3px;">receipt</mat-icon> Datos de Especificación Técnica
              </mat-panel-title>
            </mat-expansion-panel-header>
    
            <form [formGroup]="especificacionTecnicaFormGroup" *ngIf="canAdd || canEdit">
    
              <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">
    
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Nro. Línea*</mat-label>
                    <input type="number" matInput formControlName="NumeroLinea">
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['NumeroLinea'].valid && especificacionTecnicaFormGroup.controls['NumeroLinea'].touched">
                      {{ getErrorEspecificacionTecnicaNumeroLinea() }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label *ngIf="!isCategoriaSearching">Cód. Categoria*</mat-label>
                    <mat-label *ngIf="isCategoriaSearching">Cargando...</mat-label>
                    <input type="text" placeholder="Buscar una categoria" matInput
                      formControlName="LicitacionCategoria" [matAutocomplete]="categoriaAuto">
                    <mat-autocomplete autoActiveFirstOption #categoriaAuto="matAutocomplete" [displayWith]="displayCategoria">
                      <mat-option *ngFor="let option of filteredCategoriaList" [value]="option">
                        {{option.Codigo}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['LicitacionCategoria'].valid && especificacionTecnicaFormGroup.controls['LicitacionCategoria'].touched">
                      {{ getErrorEspecificacionTecnicaCodigoCategoria() }}
                    </mat-error>
                  </mat-form-field>
                </div>
          
                <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Descripción*</mat-label>
                    <input matInput formControlName="Descripcion">
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['Descripcion'].valid && especificacionTecnicaFormGroup.controls['Descripcion'].touched">
                      {{ getErrorEspecificacionTecnicaDescripcion() }}
                    </mat-error>
                  </mat-form-field>
                </div>
    
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                      <mat-label>Clasificación*</mat-label>
                      <input matInput formControlName="Clasificacion">
                        <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['Clasificacion'].valid && especificacionTecnicaFormGroup.controls['Clasificacion'].touched">
                      {{ getErrorEspecificacionTecnicaClasificacion() }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Unidad de Medida*</mat-label>
                    <input matInput formControlName="UnidadMedida" maxlength="255">
                      <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['UnidadMedida'].valid && especificacionTecnicaFormGroup.controls['UnidadMedida'].touched">
                      {{ getErrorEspecificacionTecnicaUnidadMedida() }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Cantidad*</mat-label>
                    <input type="number" matInput formControlName="Cantidad" maxlength="255">
                      <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['Cantidad'].valid && especificacionTecnicaFormGroup.controls['Cantidad'].touched">
                      {{ getErrorEspecificacionTecnicaCantidad() }}
                    </mat-error>
                  </mat-form-field>
                </div>
    
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Moneda</mat-label>
                    <input matInput formControlName="Moneda">
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['Moneda'].valid && especificacionTecnicaFormGroup.controls['Moneda'].touched">
                      {{ getErrorEspecificacionTecnicaMoneda() }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Monto Unitario*</mat-label>
                    <input type="number" matInput formControlName="MontoUnitario">
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['MontoUnitario'].valid && especificacionTecnicaFormGroup.controls['MontoUnitario'].touched">
                      {{ getErrorEspecificacionTecnicaMontoUnitario() }}
                    </mat-error>
                  </mat-form-field>
                </div>
    
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%" >
                    <mat-label>Monto Total*</mat-label>
                    <input type="number" matInput formControlName="MontoTotal">
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['MontoTotal'].valid && especificacionTecnicaFormGroup.controls['MontoTotal'].touched">
                      {{ getErrorEspecificacionTecnicaMontoTotal() }}
                    </mat-error>
                  </mat-form-field>
                </div> 
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%" >
                    <mat-label>SES*</mat-label>
                    <input matInput formControlName="SES">
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['SES'].valid && especificacionTecnicaFormGroup.controls['SES'].touched">
                      {{ getErrorEspecificacionTecnicaSES() }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                      <mat-label>Cód. Producto</mat-label>
                      <input matInput formControlName="CodigoProducto">
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['CodigoProducto'].valid && especificacionTecnicaFormGroup.controls['CodigoProducto'].touched">
                      {{ getErrorEspecificacionTecnicaCodigoProducto() }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label *ngIf="!isLicitacionProductoSearching">Producto Asociado</mat-label>
                    <mat-label *ngIf="isLicitacionProductoSearching">Cargando...</mat-label>
                    <input type="text" placeholder="Buscar un producto para asociar" matInput
                      formControlName="Producto" [matAutocomplete]="productoAuto">
                    <mat-autocomplete autoActiveFirstOption #productoAuto="matAutocomplete" [displayWith]="displayProducto">
                      <mat-option *ngFor="let option of filteredProductoList" [value]="option">
                        {{option.Codigo}} - {{option.Nombre}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-error *ngIf="!especificacionTecnicaFormGroup.controls['Producto'].valid && especificacionTecnicaFormGroup.controls['Producto'].touched">
                      {{ getErrorEspecificacionTecnicaProducto() }}
                    </mat-error>
                  </mat-form-field>
                </div>
    
              </div>

              <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">
                <button mat-raised-button (click)="saveEspecificacionTecnica()" *ngIf="canAdd || canEdit" [disabled]="isEspecificacionesTecnicasSearching" color="primary" class="zb-button">Guardar</button>
                <button mat-raised-button (click)="cleanEspecificacionTecnica()" *ngIf="canAdd || canEdit" [disabled]="isEspecificacionesTecnicasSearching" color="warn" class="zb-button">Limpiar</button>
              </div>
    
            </form>
    
            <ng-container *ngIf="!canAdd && !canEdit">
    
              <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">
    
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Nro. Línea</b>: {{itemEspecificacionTecnica?.NumeroLinea}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Cód. Categoria</b>: {{itemEspecificacionTecnica?.LicitacionCategoria}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>

                <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Descripción</b>: {{itemEspecificacionTecnica?.Descripcion}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>

                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Clasificación</b>: {{itemEspecificacionTecnica?.Clasificacion}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Cód. Producto</b>: {{itemEspecificacionTecnica?.CodigoProducto}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>

                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Unidad de Medida</b>: {{itemEspecificacionTecnica?.UnidadMedida}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Cantidad</b>: {{itemEspecificacionTecnica?.Cantidad | number:'1.2-2'}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>

                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Moneda</b>: {{itemEspecificacionTecnica?.Moneda}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Monto Unitario</b>: {{itemEspecificacionTecnica?.MontoUnitario | number:'1.2-2'}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>

                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>Monto Total</b>: {{itemEspecificacionTecnica?.MontoTotal | number:'1.2-2'}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>
                <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                  <mat-expansion-panel [expanded]="true" hideToggle="true">
                    <div fxLayout="row stretch" fxLayout.xs="column">
                      <mat-label><b>SES</b>: {{itemEspecificacionTecnica?.SES}}</mat-label>
                    </div>
                  </mat-expansion-panel>
                </div>
    
              </div>
    
            </ng-container>
    
          </mat-expansion-panel>

        </div>

        <hr style="margin:10px 0px; width: 100%;">

        <div fxLayout="row stretch" fxLayout.xs="column">

          <mat-label *ngIf="isEspecificacionesTecnicasSearching">Buscando...</mat-label>
          <mat-label *ngIf="!isEspecificacionesTecnicasSearching && especificacionesTecnicasMsgError.length > 0">{{especificacionesTecnicasMsgError}}</mat-label>

          <ejs-grid #especificacionTecnicaGrid [dataSource]='especificacionTecnicaList' [allowPaging]="true" [allowSorting]="true" [pageSettings]='pageSettings'
            [allowResizing]='true'>
            <e-columns>
              <e-column field='NumeroLinea' headerText='Nro. Línea' width=40></e-column>
              <e-column field='LicitacionCategoria' headerText='Cód. Categ.' width=70></e-column>
              <e-column field='Descripcion' headerText='Descripción' width=70></e-column>
              <e-column field='Clasificacion' headerText='Clasificación' width=70></e-column>
              <e-column field='CodigoProducto' headerText='Cód. Producto' width=70></e-column>
              <e-column field='Producto' headerText='Producto' width=70></e-column>
              <e-column field='UnidadMedida' headerText='Unid. Med.' width=70></e-column>
              <e-column field='Cantidad' headerText='Cantidad' width=70 format="N2"></e-column>
              <e-column field='Moneda' headerText='Moneda' width=70></e-column>
              <e-column field='MontoUnitario' headerText='MontoUnitario' width=70 format="N2"></e-column>
              <e-column field='MontoTotal' headerText='MontoTotal' width=70 format="N2"></e-column>
              <e-column field='SES' headerText='SES' width=70></e-column>
              <e-column headerText='Acciones' width=60>
                <ng-template #template let-data>
                  <a style="cursor: pointer; margin-right: 5px" (click)="editEspecificacionTecnica(data)" title="Editar"><i class="fa fa-eye fa-1x"></i></a>
                  <a style="cursor: pointer; margin-right: 5px" (click)="deleteEspecificacionTecnica(data)" *ngIf="canAdd || canEdit" title="Eliminar"><i class="fa fa-trash fa-1x"></i></a>
                </ng-template>
              </e-column>
            </e-columns>
          </ejs-grid>

        </div>

      </mat-expansion-panel>

    </ng-container>
    
  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onGuardar()" *ngIf="licitacion != null && (canAdd || canEdit)" [disabled]="isImporting || isSaving || isDeleting" color="primary" class="zb-button">
        {{isSaving ? 'Guardando...' : 'Guardar'}}
      </button>
      <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canAdd || canEdit ? 'Cancelar' : 'Cerrar'}}</button>
      <button mat-raised-button (click)="onDelete()" *ngIf="canDelete && licitacion != null && licitacion.Id > 0" [disabled]="isImporting || isSaving || isDeleting" color="warn" class="zb-button">
        {{isDeleting ? 'Eliminando...' : 'Eliminar' }}
      </button>
      <button mat-raised-button (click)="onImportar()" *ngIf="canImport && licitacion != null && licitacion.Id > 0" [disabled]="isImporting || isSaving || isDeleting" class="zb-button">
        {{isImporting ? 'Importando...' : 'Importar'}}
      </button>
    </div>
  </mat-card-actions>

</mat-card>
