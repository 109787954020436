import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { SharedFunctions } from 'src/app/shared/shared.functions';

import { SeguridadService } from '../../shared/services';
import { ResetPasswordDto } from '../../shared/model';

@Component({
  selector: 'app-dialogOlvidoClave',
  templateUrl: 'dialogOlvidoClave.component.html'
})

export class DialogOlvidoClaveComponent implements OnInit {

  formGroup: FormGroup;
  private entity: ResetPasswordDto;
  buttonEnabled: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DialogOlvidoClaveComponent>,
    private service: SeguridadService, 
    private sharedFunctions: SharedFunctions,
    private formBuilder: FormBuilder) { }

  ngOnInit() { 
    this.buildForm();
  }

  buildForm(){
    this.formGroup = this.formBuilder.group({
     'Login': ["", Validators.required]
    });
  }

  onGuardar(){
    if (this.formGroup.valid) {
        var value = { ... this.entity, ... this.formGroup.getRawValue() };
        this.buttonEnabled = false;
        
        this.service.forgotPassword(value).subscribe(data => {
          this.buttonEnabled = true;
          if(data.Success) {
            let result = data.Result;

            if (result)
              this.sharedFunctions.showMessage("Se envió un correo electrónico a la casilla de mail asociada a su usuario con las instrucciones para el reinicio de clave", 'Reinicio de Clave');

            this.dialogRef.close(result);
          }
          else{
            this.sharedFunctions.showMessage("Ocurrió un error recuperando la clave del usuario", 'Error');
          }
        },
        (err: HttpErrorResponse) => {
          this.buttonEnabled = true;
          this.sharedFunctions.handleError(err, true);
        })
    }
    else
      this.sharedFunctions.showMessage("Debe completar todos los campos requeridos", 'Error');
  }
  
  onCancelar(){
    this.dialogRef.close(null);
  }

  getErrorLogin() {
    return this.formGroup.get('Login').hasError('required') ? 'Debe ingresar su usuario para recuperar la clave' : '';
  }
}