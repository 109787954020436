<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Usuario</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && usuario != null">

      <mat-expansion-panel [expanded]="true">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">account_circle</mat-icon> Datos de Usuario
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="usuarioFormGroup" *ngIf="canAdd || canEdit">

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="!userImageChanged()">
              <a target="_blank" href="{{usuario?.FotoPerfilUrl}}">
                <img src="{{usuario?.FotoPerfilUrl}}" style="height: 100px; width: 100px; border-radius: 100%;">
              </a>
            </div>
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="userImageChanged()">
              <img src="{{userImageBase64}}"
                style="height: 100px; width: 100px; border-radius: 100%; margin-bottom: 10px;">
            </div>
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center">
              <button type="button" mat-raised-button [disabled]="isProcessing()" (click)="fileInput.click()">Cambiar Imagen</button>
              <input hidden type="file" (change)="convertFileToBase64($event)" accept="image/*" #fileInput color="primary">
            </div>
          </div>

          <hr style="margin:20px 0px; width: 100%;">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" *ngIf="!(usuario?.Id > 0)">
                <mat-label>Nombre de Usuario*</mat-label>
                <input matInput formControlName="Login" (keyup.enter)="onGuardarUsuario()" maxlength="100">
                <mat-error *ngIf="!usuarioFormGroup.controls['Login'].valid && usuarioFormGroup.controls['Login'].touched">
                  {{ getErrorLogin() }}
                </mat-error>
              </mat-form-field>
              <mat-expansion-panel [expanded]="true" hideToggle="true" *ngIf="usuario?.Id > 0">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nombre de Usuario:</b> {{usuario?.Login}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isRolSearching">Roles*</mat-label>
                <mat-label *ngIf="isRolSearching">Cargando...</mat-label>
                <mat-select formControlName="Roles" multiple [compareWith]="compareRoles">
                  <mat-option *ngFor="let s of rolList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!usuarioFormGroup.controls['Roles'].valid && usuarioFormGroup.controls['Roles'].touched">
                  {{ getErrorRoles() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="!(usuario?.Id > 0)">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Clave*</mat-label>
                <input matInput [type]="hideClave ? 'password' : 'text'" formControlName="Clave"
                  (keypress)="claveChanged=true" (keyup.enter)="onGuardarUsuario()" maxlength="100">
                <button mat-icon-button matSuffix *ngIf="claveChanged" (click)="hideClave = !hideClave" type="button">
                  <mat-icon>{{hideClave ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="!usuarioFormGroup.controls['Clave'].valid && usuarioFormGroup.controls['Clave'].touched">
                  {{ getErrorClave() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="!(usuario?.Id > 0)">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Confirmación de Clave*</mat-label>
                <input matInput [type]="hideConfirmClave ? 'password' : 'text'" formControlName="ConfirmClave"
                  (keypress)="confirmClaveChanged=true" (keyup.enter)="onGuardarUsuario()" maxlength="100">
                <button mat-icon-button matSuffix *ngIf="confirmClaveChanged"
                  (click)="hideConfirmClave = !hideConfirmClave" type="button">
                  <mat-icon>{{hideConfirmClave ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error
                  *ngIf="!usuarioFormGroup.controls['ConfirmClave'].valid && usuarioFormGroup.controls['ConfirmClave'].touched">
                  {{ getErrorConfirmClave() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Email*</mat-label>
                <input matInput formControlName="Mail" type="email" (keyup.enter)="onGuardarUsuario()" maxlength="255">
                <mat-error *ngIf="!usuarioFormGroup.controls['Mail'].valid && usuarioFormGroup.controls['Mail'].touched">
                  {{ getErrorMail() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Confirmación de Email*</mat-label>
                <input matInput formControlName="ConfirmMail" type="email" (keyup.enter)="onGuardarUsuario()" maxlength="255">
                <mat-error
                  *ngIf="!usuarioFormGroup.controls['ConfirmMail'].valid && usuarioFormGroup.controls['ConfirmMail'].touched">
                  {{ getErrorConfirmMail() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Cel.</mat-label>
                <input matInput formControlName="Movil" (keyup.enter)="onGuardarUsuario()" maxlength="100">
                <mat-error *ngIf="!usuarioFormGroup.controls['Movil'].valid && usuarioFormGroup.controls['Movil'].touched">
                  {{ getErrorMovil() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="usuario?.Id > 0">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Estado</b>: {{usuario?.UsuarioEstadoNombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="usuario?.Id > 0">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Últ. Inicio Sesión</b>: {{usuario?.FechaUltimoInicioSesion | date:'dd/MM/yyyy HH:mm:ss'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="!usuario?.Activo">
              <mat-expansion-panel [expanded]="true" hideToggle="true" style="background-color: rgb(255, 157, 157);">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label>
                    <i class="fa fa-lock" style="margin-right: 5px;"></i> El usuario se encuentra dado de baja
                  </mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="usuario?.Bloqueado">
              <mat-expansion-panel [expanded]="true" hideToggle="true" style="background-color: rgb(255, 157, 157);">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label>
                    <i class="fa fa-ban" style="margin-right: 5px;"></i> El usuario se encuentra bloqueado
                  </mat-label>
                </div>
              </mat-expansion-panel>
            </div>

          </div>

          <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center">
            <button mat-raised-button (click)="onGuardarUsuario()" *ngIf="!isNewUser && canEdit" [disabled]="isProcessing()"
              color="primary" class="zb-button">{{isUserSaving ? 'Guardando...' : 'Guardar Usuario'}}</button>
          </div>
      
        </form>

        <ng-container *ngIf="!canAdd && !canEdit">

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="!userImageChanged()">
              <a target="_blank" href="{{usuario?.FotoPerfilUrl}}">
                <img src="{{usuario?.FotoPerfilUrl}}" style="height: 100px; width: 100px; border-radius: 100%;">
              </a>
            </div>
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="userImageChanged()">
              <img src="{{userImageBase64}}"
                style="height: 100px; width: 100px; border-radius: 100%; margin-bottom: 10px;">
            </div>
          </div>
  
          <hr style="margin:20px 0px; width: 100%;">
  
          <div fxLayout="row stretch" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nombre de Usuario</b>: {{usuario.Login}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Email</b>: {{usuario.Mail}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nro. Cel.</b>: {{usuario.Movil}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Estado</b>: {{usuario?.UsuarioEstadoNombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Roles Asociados</b>: {{rolesString}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Administraciones Asociadas</b>: {{tenantsString}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="!usuario?.Activo">
              <mat-expansion-panel [expanded]="true" hideToggle="true" style="background-color: rgb(255, 157, 157);">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label style="text-align: center; font-size: 16px;">
                    <i class="fa fa-lock" style="margin-right: 5px;"></i> El usuario se encuentra dado de baja
                  </mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="usuario?.Bloqueado">
              <mat-expansion-panel [expanded]="true" hideToggle="true" style="background-color: rgb(255, 157, 157);">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label style="text-align: center; font-size: 16px;">
                    <i class="fa fa-ban" style="margin-right: 5px;"></i> El usuario se encuentra bloqueado
                  </mat-label>
                </div>
              </mat-expansion-panel>
            </div>

          </div>
  
        </ng-container>

      </mat-expansion-panel>

      <hr style="margin:20px 0px; width: 100%;">

      <mat-expansion-panel [expanded]="true">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">person</mat-icon> Datos de Persona
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="personaFormGroup" *ngIf="canPersonAdd || canPersonEdit">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=100 fxFlex.xs=100 fxLayoutAlign="center center" class="flex-item" width="100%">
              <mat-expansion-panel [expanded]="true" hideToggle="true" style="background-color: #add5ff;">
                <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center center">
                  <mat-label>
                    ¿Ya tenes cargados los datos de la persona para asociarlos a este usuario?
                  </mat-label>
                </div>
                <br/>
                <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center center">
                  <button mat-raised-button (click)="onPersonSearchClick()" [disabled]="isProcessing()" color="accent" class="zb-button">Buscar Datos de Persona Existente</button>
                </div>
              </mat-expansion-panel>
            </div>

          </div>

          <hr style="margin:20px 0px; width: 100%;">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b># Persona</b>: {{(persona?.Id > 0 ? persona.Id : 'NUEVO')}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 fxLayoutAlign="center center" class="flex-item" style="margin-top: 5px;">
              <button mat-raised-button (click)="onPersonDeassociateClick()" [disabled]="isProcessing()" *ngIf="canDeassociatePerson()" color="warn" class="zb-button">
                Cargar como Nueva Persona
              </button>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre*</mat-label>
                <input matInput formControlName="Nombre" maxlength="150" (keyup.enter)="onGuardarPersona()">
                <mat-error *ngIf="!personaFormGroup.controls['Nombre'].valid && personaFormGroup.controls['Nombre'].touched">
                  {{ getErrorNombre() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Apellido*</mat-label>
                <input matInput formControlName="Apellido" maxlength="150" (keyup.enter)="onGuardarPersona()">
                <mat-error *ngIf="!personaFormGroup.controls['Apellido'].valid && personaFormGroup.controls['Apellido'].touched">
                  {{ getErrorApellido() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isTipoDocumentoSearching">Tipo Documento</mat-label>
                <mat-label *ngIf="isTipoDocumentoSearching">Cargando...</mat-label>
                <mat-select formControlName="TipoDocumentoId" (selectionChange)="onTipoDocumentoSelection()">
                  <mat-option>Ninguno</mat-option>
                  <mat-option *ngFor="let s of tipoDocumentoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Documento</mat-label>
                <input matInput formControlName="NroDocumento" type="text" (change)="onNroDocumentoChange()" maxlength="100" (keyup.enter)="onGuardarPersona()">
                <mat-error *ngIf="!personaFormGroup.controls['NroDocumento'].valid && personaFormGroup.controls['NroDocumento'].touched">
                  {{ getErrorNroDocumento() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" >
                <mat-label>Fecha Nacimiento</mat-label>
                <input matInput [matDatepicker]="fechaNacimientoPicker" formControlName="FechaNacimiento" readonly (click)="fechaNacimientoPicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaNacimientoPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaNacimiento')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaNacimientoPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Mail de Contacto</mat-label>
                <input matInput formControlName="MailContacto" (keyup.enter)="onGuardarPersona()" maxlength="255">
                 <mat-error *ngIf="!personaFormGroup.controls['MailContacto'].valid && personaFormGroup.controls['MailContacto'].touched">
                  {{ getErrorMailContacto() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" style="margin-top: 20px;">
              <mat-checkbox [(ngModel)]="persona.Activo" [ngModelOptions]="{standalone: true}" (keyup.enter)="onGuardarPersona()" color="primary">Activo</mat-checkbox> 
            </div>

          </div>

          <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center">
            <button mat-raised-button (click)="onGuardarPersona()" *ngIf="!isNewUser" [disabled]="isProcessing()"
              color="primary" class="zb-button">{{isPersonSaving ? 'Guardando...' : 'Guardar Persona'}}</button>

            <button mat-raised-button (click)="onPersonSearchClick()" [disabled]="isProcessing()" color="accent" class="zb-button">Buscar Persona Existente</button>
          </div>

        </form>

        <ng-container *ngIf="!canPersonAdd && !canPersonEdit">

          <div fxLayout="row stretch" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b># Persona</b>: {{(persona?.Id > 0 ? persona.Id : '-')}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nombre</b>: {{persona.Nombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Apellido</b>: {{persona.Apellido}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Tipo Doc.</b>: {{persona.TipoDocumentoNombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nro. Doc.</b>: {{persona.NroDocumento}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Fecha Nacimiento</b>: {{persona.FechaNacimiento | date:'dd/MM/yyyy'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Mail de Contacto</b>: {{persona.MailContacto}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Activo</b>: {{persona.Activo ? 'Sí' : 'No'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

          </div>

          <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center" *ngIf="canEdit">
            <button mat-raised-button (click)="onPersonSearchClick()" [disabled]="isProcessing()" color="accent" class="zb-button">Buscar Persona Existente</button>
          </div>

        </ng-container>

        <hr style="margin:20px 0px; width: 100%;">

        <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

          <div fxFlex=100 fxFlex.xs=100 class="flex-item" fxLayoutAlign="center" style="margin-bottom: 15px;">
            <mat-label><u><b>Datos Biométricos</b></u></mat-label>
          </div>

          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column" style="margin-top: 12px;">
                <div fxFlex=90 fxFlex.xs=100 class="flex-item">
                  <a href="{{persona?.FotoDniFrenteUrl}}" target="_blank" *ngIf="hasPersonaFotoDniFrente()">Ver Foto DNI Frente</a>
                  <mat-label class="text-label" *ngIf="!hasPersonaFotoDniFrente()">
                    <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de DNI frente</b>
                  </mat-label>
                </div>
                <div fxFlex=10 fxFlex.xs=100 class="flex-item" *ngIf="!isImageLoading && (canPersonAdd || canPersonEdit)">
                  <a style="cursor: pointer;" (click)="fileInputDniFrente.click()" title="Cargar nueva imagen"><i class="fa fa-upload fa-1x"></i></a>
                  <input class="text-label" hidden type="file" (change)="convertFileToBase64($event, 'DniFrente')" accept="image/*" #fileInputDniFrente>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <br />
          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column" style="margin-top: 12px;">
                <div fxFlex=90 fxFlex.xs=100 class="flex-item">
                  <a href="{{persona?.FotoDniDorsoUrl}}" target="_blank" *ngIf="hasPersonaFotoDniDorso()">Ver Foto DNI Dorso</a>
                  <mat-label class="text-label" *ngIf="!hasPersonaFotoDniDorso()">
                    <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de DNI dorso</b>
                  </mat-label>
                </div>
                <div fxFlex=10 fxFlex.xs=100 class="flex-item" *ngIf="!isImageLoading && (canPersonAdd || canPersonEdit)">
                  <a style="cursor: pointer;" (click)="fileInputDniDorso.click()" title="Cargar nueva imagen"><i class="fa fa-upload fa-1x"></i></a>
                  <input class="text-label" hidden type="file" (change)="convertFileToBase64($event, 'DniDorso')" accept="image/*" #fileInputDniDorso>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <br />
          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column" style="margin-top: 12px;">
                <div fxFlex=90 fxFlex.xs=100 class="flex-item">
                  <a href="{{persona?.FotoSelfieUrl}}" target="_blank" *ngIf="hasPersonaFotoSelfie()">Ver Foto Selfie</a>
                  <mat-label class="text-label" *ngIf="!hasPersonaFotoSelfie()">
                    <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de selfie</b>
                  </mat-label>
                </div>
                <div fxFlex=10 fxFlex.xs=100 class="flex-item" *ngIf="!isImageLoading && (canPersonAdd || canPersonEdit)">
                  <a style="cursor: pointer;" (click)="fileInputSelfie.click()" title="Cargar nueva imagen"><i class="fa fa-upload fa-1x"></i></a>
                  <input class="text-label" hidden type="file" (change)="convertFileToBase64($event, 'Selfie')" accept="image/*" #fileInputSelfie>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </mat-expansion-panel>

      <hr style="margin:20px 0px; width: 100%;">

      <mat-expansion-panel *ngIf="usuario?.Id > 0" [expanded]="false">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">history</mat-icon> Historial del Usuario
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div fxLayout="row stretch" fxLayout.xs="column">

          <mat-label *ngIf="isHistorialSearching">Buscando...</mat-label>
          <mat-label *ngIf="!isHistorialSearching && historialMsgError.length > 0">{{historialMsgError}}</mat-label>

          <ejs-grid #grid [dataSource]='historialList' [allowPaging]="true" [allowSorting]="true"
            *ngIf="!isHistorialSearching" [selectionSettings]='selectionOptions' [pageSettings]='pageSettings'
            [allowResizing]='true'>
            <e-columns>
              <e-column field='FechaCreacion' headerText='Fecha' width=70>
                <ng-template #template let-data>
                  <mat-label>{{data.FechaCreacion | date:'dd/MM/yyyy HH:mm:ss'}}</mat-label>
                </ng-template>
              </e-column>
              <e-column field='Descripcion' headerText='Descripción' width=120></e-column>
              <e-column field='UsuarioCreacion' headerText='Usuario' width=70></e-column>
              <e-column field='Observaciones' headerText='Observaciones' width=200></e-column>
            </e-columns>
          </ejs-grid>

        </div>

      </mat-expansion-panel>

    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onGuardarUsuario()" *ngIf="isNewUser && canEdit" [disabled]="isProcessing()"
        color="primary" class="zb-button">{{isUserSaving ? 'Creando...' : 'Crear Usuario'}}</button>
      <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">Cerrar</button>
      <ng-container *ngIf="usuario?.Id > 0 && canUserActions">
        <button mat-raised-button (click)="onActivar()" [disabled]="isProcessing()" *ngIf="showActionButtons()"
          color="accent" class="zb-button">{{isActivating ? 'Procesando...' : (usuario?.Activo ? 'Desactivar' :
          'Activar')}}</button>
        <button mat-raised-button (click)="onAprobar()" [disabled]="isProcessing()" *ngIf="showStateFlowButtons()"
          color="primary" class="zb-button">{{isApproving ? 'Procesando...' : 'Aprobar'}}</button>
        <button mat-raised-button (click)="onRechazar()" [disabled]="isProcessing()" *ngIf="showStateFlowButtons()"
          color="accent" class="zb-button">{{isRejecting ? 'Procesando...' : 'Rechazar'}}</button>
        <button mat-raised-button (click)="onBloquear()" [disabled]="isProcessing()" *ngIf="showActionButtons()"
          color="accent" class="zb-button">{{isBlocking ? 'Procesando...' : (usuario?.Bloqueado ? 'Desbloquear' :
          'Bloquear')}}</button>
        <button mat-raised-button (click)="onReinicioClave()" [disabled]="isProcessing()" *ngIf="showActionButtons()"
          class="zb-button">{{isResetting ? 'Procesando...' : 'Reiniciar Clave'}}</button>
      </ng-container>
    </div>
  </mat-card-actions>

</mat-card>