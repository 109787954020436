import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query} from '@syncfusion/ej2-data';
import { GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { DatePipe } from '@angular/common';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { LicitacionCategoriaService, MenuFuncionService } from 'src/app/shared/services/index';
import { GridPageSettings, MenuFuncionCodes, LicitacionCategoria, SearchLicitacionCategoriaRequest } from 'src/app/shared/model';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-licitacionCategoriaLista',
  templateUrl: './licitacionCategoriaLista.component.html',
})

export class LicitacionCategoriaListaComponent implements OnInit {
  @ViewChild('grid')
  public grid: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;

  filteredCategoriaPadreList: LicitacionCategoria[];

  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isLicitacionCategoriaPadreSearching: boolean = false;

  canEdit: boolean = false;
  canView: boolean = false;
  canAdd: boolean = false;
  canExport: boolean = false;

  verSoloActivos: boolean = false;
  public query: Query;

  public selectionOptions: SelectionSettingsModel;
  public pageSizes: number[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private router: Router,
    private route: ActivatedRoute,
    private service: LicitacionCategoriaService,
    private menuService: MenuFuncionService,
    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenu(MenuFuncionCodes.LICITACION_CATEGORIA_EXPORT).subscribe(data =>{
      console.log("lic categoria export data ", data)
      this.canExport = data?.Result;
    })
    this.menuService.checkMenuPadre(MenuFuncionCodes.LICITACION_CATEGORIA).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result){
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.LICITACION_CATEGORIA_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.LICITACION_CATEGORIA_LIST);

          if (this.canView){
            this.loadView();
          } else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.router.navigate(['/']);
          }
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.buildForm();

    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
    this.toolbarOptions = null; //['ExcelExport'];

    this.setQueryParams();

    this.data = new DataManager({
      url: this.service.getSearchUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.service.getAuthorizationHeader()]
    });

    this.getLicitacionCategoriaPadres();
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'Codigo': [null],
      'Nombre': [null],
      'LicitacionCategoriaPadre': [null]
    });
  }

  getLicitacionCategoriaPadres() {
    this.searchForm.controls.LicitacionCategoriaPadre.valueChanges
      .pipe(
        filter(res => {
          return res && res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCategoriaPadreList = [];
          this.isLicitacionCategoriaPadreSearching = true;
        }),
        switchMap(value => this.service.search(new SearchLicitacionCategoriaRequest(20, 0, true, "Codigo", value?.toString(), null, null, true))
          .pipe(
            finalize(() => {
              this.isLicitacionCategoriaPadreSearching = false;
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data != null && data.result != null)
          this.filteredCategoriaPadreList = data.result;
        else
          this.filteredCategoriaPadreList = [];
      });
  }

  displayCategoriaPadre(item: LicitacionCategoria) {
    return item ? item?.Codigo : "";
  }

  onGridActionFailure(event: any): void {
    this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {
    this.setQueryParams();
    this.grid.refresh();
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "Codigo");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Codigo;
    else
      this.query.addParams("Codigo",this.searchForm.value.Codigo);
    
    index = this.query.params.findIndex(obj => obj.key === "Nombre");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Nombre;
    else
      this.query.addParams("Nombre",this.searchForm.value.Nombre);

    index = this.query.params.findIndex(obj => obj.key === "LicitacionCategoriaPadreId");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.LicitacionCategoriaPadre?.Id;
    else
      this.query.addParams("LicitacionCategoriaPadreId",this.searchForm.value.LicitacionCategoriaPadre?.Id);

    index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
    if (index >= 0)
      this.query.params[index].value = this.verSoloActivos.toString();
    else
      this.query.addParams("VerSoloActivos",this.verSoloActivos.toString());
  }

  add() {
    if (this.canAdd)
      this.router.navigate(['licitacion-categoria-edit', 0]);
    else
      this.sharedFunctions.showMessage("No puede ejecutar esta operación", "Error");
  }

  edit(item: LicitacionCategoria) {
    if (!item || item.Id == null || item.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }    
    this.router.navigate(['licitacion-categoria-edit', item.Id]);
  }

  onExport() {
    var search = new SearchLicitacionCategoriaRequest(null, null, null, "", null, null, this.searchForm.value.LicitacionCategoriaPadre?.Id, this.verSoloActivos);
    search = Object.assign(search, this.searchForm.value);

    this.isExporting = true;
    this.service.exportSearch(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "LicitacionCategorias_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
      anchor.href = fileUrl;
      anchor.click();
    },
    (err: HttpErrorResponse) => {
      this.isExporting = false;
      this.sharedFunctions.handleError(err, true);
    })
  }
}
