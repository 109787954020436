import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { LicitacionService } from 'src/app/shared/services';
import { ImportArchivoEspecificacionesTecnicasRequestDto } from 'src/app/shared/model';

@Component({
    selector: 'app-dialogImportacionLicitacionArchivo',
    templateUrl: 'dialogImportacionLicitacionArchivo.component.html',
    styleUrls: ['./dialogImportacionLicitacionArchivo.component.css']
})

export class DialogImportacionLicitacionArchivoComponent implements OnInit {

  isImporting: boolean = false;

  fileBase64: string | ArrayBuffer = null;
  fileName: string = "";
  caracterSeparador: string = ",";
  actualizarAsignados: boolean = false;
  saltearPrimeraLinea: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<DialogImportacionLicitacionArchivoComponent>,
    private sharedFunctions: SharedFunctions,
    private licitacionService: LicitacionService
  ) {

  }

  ngOnInit(): void {
  }

  convertFileToBase64(event) {
    this.fileBase64 = null;
    this.fileName = null;
    
    const file = event.target.files[0];

    if (file != null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.backofficeImageBase64 = reader.result;
      };
      reader.onloadend = () => {
        var fileExtension = (file?.name?.split('.')?.pop() ?? "");
        if (fileExtension == null || fileExtension?.toLowerCase() != "csv") {
          this.sharedFunctions.showMessage("Solo puede adjuntar archivos CSV", "Error");
        }

        this.fileBase64 = reader.result;
        this.fileName = file?.name;
        // console.log(reader.result);
      };
    }
  }

  onImportar() {
    var r = new ImportArchivoEspecificacionesTecnicasRequestDto(this.fileName, this.fileBase64, this.actualizarAsignados, this.saltearPrimeraLinea, this.caracterSeparador);

    this.isImporting = true;
    this.licitacionService.importarArchivoLicitaciones(r).subscribe(data => {
      this.isImporting = false;
      if (data != null && data.Success && data.Result != null) {
        var msg = "Finalizó el proceso de importación con " + data.Result.TotalLicitacionesActualizadas + " licitaciones actualizadas y " + data.Result.TotalProductosAgregados + " productos nuevos dados de alta";
        msg += ", sobre un total de " + data.Result.TotalLineasLeidas + " líneas leídas, de las cuales " + data.Result.TotalLineasConProducto + " tenían un producto asociado.";

        this.sharedFunctions.showMessage(msg, "Importación de Archivo de Licitaciones");
      }
      else
        this.sharedFunctions.showMessage("Ocurrió un error realizando la importación", "Importación de Archivo de Licitaciones");
    },
    (err: HttpErrorResponse) => {
      this.isImporting = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}