export class MensajeUsuarioTipo {
  constructor(
    public readonly Id: number,
    public readonly Codigo: string,
    public readonly Nombre: string,
    public readonly Activo: boolean
  ) {}
}

export class SearchMensajeUsuarioTipoRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Nombre: string,
    public readonly Codigo: string,
    public readonly RolId: number | null,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveMensajeUsuarioTipoDto {
  constructor(
    public Nombre: string,
    public Codigo: string,
    public Activo: boolean
  ) {}
}

export class SearchMensajeUsuarioTipoResponse {
  constructor(
    public readonly count: number,
    public readonly result: MensajeUsuarioTipo[]
  ) {}
}
