import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { EstadoService } from 'src/app/shared/services';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

import { MenuFuncionCodes, Estado, SaveEstadoDto, TipoEstado, DialogConfirmRequest, DialogConfirmResponse } from 'src/app/shared/model';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';

@Component({
  selector: 'app-estadoEdit',
  templateUrl: './estadoEdit.component.html',
  styles: [
  ]
})

export class EstadoEditComponent implements OnInit {
  formGroup: FormGroup;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isSaving: boolean = false;
  isDeleting: boolean = false;
  isTipoSearching: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canAdd: boolean = false;
  canView: boolean = false;

  msgError: string = "";
  item: Estado;
  tipoList: TipoEstado[];

  constructor (
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private estadoService: EstadoService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.ESTADO).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.ESTADO_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.ESTADO_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.ESTADO_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.ESTADO_DELETE);
        this.loadView();
      } else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();        
      }      
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.buildForm();
    this.getTipos();
    this.loadEntity();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Nombre': [null, [Validators.required, Validators.maxLength(100)]],
      'Tipo': [null, Validators.required],
      'Activo': [true, Validators.required]
    });
  }

  isProcessing() {
    return (this.isSearching || this.isSaving || this.isDeleting);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        var id = Number(params['id']);

        if (id > 0) {
          if(this.canView || this.canEdit ){
            this.isSearching = true;
            this.estadoService.get(id).subscribe(data => {
              this.isSearching = false;
              if (data != null && data.Success) {
                this.item = data.Result;
                
                if (this.item != null && this.item.Id > 0) {
                  this.formGroup.patchValue(this.item);
                }
                else
                this.msgError = "No se encontró el estado seleccionado.";
              }
              else
              this.msgError = "Ocurrió un error al intentar obtener los datos del estado.";
            },
            (err: HttpErrorResponse) => {
              this.isSearching = false;
              this.sharedFunctions.handleError(err, true);
            })
          } 
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();
          }
        }
        else
          this.msgError = "Item no válido.";
      } 
      else {
        if (this.canAdd) {
          this.item = new Estado(0, null, null, null, true);
          this.formGroup.patchValue(this.item);
        } 
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.onCancel(); 
        }
      }
    });
  }

  getErrorNombre() {
    var v = this.formGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorTipo() {
    return this.formGroup.get('TipoNombre').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getTipos() {
    this.isTipoSearching = true;
    this.estadoService.getTipos().subscribe(data => {
      this.isTipoSearching = false;
      if (data != null && data.Success)
        this.tipoList = data.Result;
    },
    (err: HttpErrorResponse) => {
      this.isTipoSearching = false;
      this.sharedFunctions.handleError(err);
    })
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new SaveEstadoDto(null, null, true);

    r = 
    {
      ...this.formGroup.value,
      ...
      {
        Id: (this.item?.Id ?? 0),
        Activo: this.item.Activo
      }
    };

    this.isSaving = true;
    this.estadoService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onDelete() {
    if (!this.canDelete) 
      return false;

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar el estado?", null, null)
    });
    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
        if (result != null && result.Accepted) {
            this.isDeleting = true;
            this.estadoService.delete(this.item.Id).subscribe(data => {
                this.isDeleting = false;
                if (data != null && data.Success && data.Result) {
                    this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
                    this.onCancel();
                }
                else
                    this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
            },
            (err: HttpErrorResponse) => {
                this.isDeleting = false;
                this.sharedFunctions.handleError(err, true);
            })
        }
    });
  }

  onCancel() {
    this.location.back();
  }
}
