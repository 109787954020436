import { Rol, Persona } from 'src/app/shared/model';

export class Usuario {
    constructor(
        public readonly Id: number,
        public PersonaId: number,
        public readonly Persona: Persona,
        public readonly Movil: string,
        public readonly Login: string,
        public readonly Clave: string,
        public readonly Pin: string,
        public readonly Mail: string,
        public readonly FotoPerfilUrl: string,
        public readonly FechaUltimoInicioSesion: Date | null,
        public readonly UsuarioEstadoId: number,
        public readonly IntentosFallidosClave: number,
        public readonly MotivoBloqueo: string,
        public readonly AceptaCondiciones: boolean,
        public readonly DebeCambiarClave: boolean,
        public Bloqueado: boolean,
        public Activo: boolean,
        public readonly Roles: Rol[]
    ) { }

    static getNewInstance() {
        return new Usuario(null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, true, null);
    }
}

export class RegisterUsuarioDto {
    constructor(
        public readonly Nombre: string,
        public readonly Apellido: string,
        public readonly PaisId: number,
        public readonly TipoDocumentoId: number,
        public readonly NroDocumento: string,
        public readonly FechaNacimiento: Date,
        public readonly Mail: string,
        public readonly Movil: string,
        public readonly Login: string,
        public readonly Password: string,
        public readonly Pin: string,
        public readonly AceptaCondiciones: boolean,
        public readonly FotoDniFrenteBase64: string,
        public readonly FotoDniFrenteExtension: string,
        public readonly FotoDniDorsoBase64: string,
        public readonly FotoDniDorsoExtension: string,
        public readonly FotoSelfieBase64: string,
        public readonly FotoSelfieExtension: string
    ) { }
}

export class ResetPasswordDto {
    constructor(
        public readonly Login: string
    ) { }
}

export class ForgotUserDto {
    constructor(
        public readonly UserMail: string
    ) { }
}

export class SaveUsuarioDto {
    constructor(
        public readonly Id: number,
        public readonly PersonaId: number,
        public readonly Nombre: string,
        public readonly Apellido: string,
        public readonly NroDocumento: string,
        public readonly Movil: string,
        public readonly Login: string,
        public readonly Clave: string,
        public readonly Mail: string,
        public readonly FotoPerfilBase64: string,
        public readonly FotoPerfilExtension: string,
        public UsuarioEstadoId: number,
        public readonly MotivoBloqueo: string,
        public DebeCambiarClave: boolean,
        public Bloqueado: boolean,
        public Activo: boolean,
        public Roles: number[]
    ) { }
}

export class SearchUsuariosRequestDto {
    constructor(
        public readonly PageSize: number | null,
        public readonly PageNumber: number | null,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly Login: string,
        public readonly Nombre: string,
        public readonly Apellido: string,
        public readonly NroDocumento: string,
        public readonly Mail: string,
        public readonly Movil: string,
        public readonly PersonaId: number | null,
        public readonly RolId: number | null,
        public readonly EstadoId: number | null,
        public readonly VerSoloActivos: boolean | null,
        public readonly VerSoloBloqueados: boolean | null
    ) { }
}

export class SearchUsuariosResponseDto {
    constructor(
        public readonly count: number,
        public readonly result: SearchUsuariosItemDto[]
    ) { }
}

export class SearchUsuariosItemDto {
    constructor(
        public readonly Id: number,
        public readonly PersonaId: number,
        public readonly Login: string,
        public readonly Nombre: string,
        public readonly Apellido: string,
        public readonly NroDocumento: string,
        public readonly Movil: string,
        public readonly Estado: string,
        public readonly RolNombre: string,
        public readonly Activo: boolean,
        public readonly Bloqueado: boolean,
        public readonly FotoPerfilUrl: string,
        public readonly Mail: string,
        public readonly FechaUltimoInicioSesion: Date | null,
    ) { }
}

export class SearchUsuarioHistorialRequestDto {
    constructor(
        public readonly PageSize: number,
        public readonly PageNumber: number,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly FechaDesde: Date | null,
        public readonly FechaHasta: Date | null,
        public readonly UsuarioId: number | null,
        public readonly Descripcion: string
    ) { }
}

export class SearchUsuarioHistorialResponseDto {
    constructor(
        public readonly count: number,
        public readonly result: SearchUsuarioHistorialItemDto[]
    ) { }
}

export class SearchUsuarioHistorialItemDto {
    constructor(
        public readonly Id: number,
        public readonly UsuarioId: number,
        public readonly Descripcion: string,
        public readonly Observaciones: string,
        public readonly UsuarioCreacion: string,
        public readonly FechaCreacion: Date
    ) { }
}

export class UsuarioRol {
    constructor(
        public readonly UsuarioId: number,
        public readonly RolId: number,
        public readonly Id: number
    ) { }
}

export class ActivateUserRequestDto {
    constructor(
        public readonly UsuarioId: number,
        public readonly Activo: boolean,
        public readonly Observaciones: string
    ) { }
}

export class BlockUserRequestDto {
    constructor(
        public readonly UsuarioId: number,
        public readonly Bloqueado: boolean,
        public readonly MotivoBloqueo: string
    ) { }
}

export class ChangeUserStateRequestDto {
    constructor(
        public readonly UsuarioId: number,
        public readonly EstadoId: number,
        public readonly Observaciones: string
    ) { }
}

export class DialogUsuarioSearchComponentRequestDto {
    constructor(
        public readonly AllowMultipleSelection: boolean,
        public readonly MaxItemSelection: number
    ) { }
}

export class DialogUsuarioSearchComponentResponseDto {
    constructor(
        public readonly Cancelled: boolean,
        public readonly Usuarios: SearchUsuariosItemDto[]
    ) { }
}