<h1 mat-dialog-title>Recordatorio de Usuario</h1>

<div mat-dialog-content>
  <form [formGroup]="formGroup">

    <div fxLayout="row stretch" fxLayout.xs="column">
      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-label>Ingresa el correo electrónico que usaste para darte de alta y te enviaremos la información para el inicio de sesión:</mat-label>
      </div>
    </div>
    <br/>
    <div fxLayout="row stretch" fxLayout.xs="column">
      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Mail registrado en el sistema</mat-label>
          <input matInput formControlName="UserMail" (keyup.enter)="onGuardar()">
          <mat-error *ngIf="!formGroup.controls['UserMail'].valid && formGroup.controls['UserMail'].touched">
            {{ getErrorUserMail() }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </form>
</div>

<div mat-dialog-actions align="end">
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="primary" (click)="onGuardar()" [disabled]="!buttonEnabled" class="zb-button">{{buttonEnabled ? 'Recordar' : 'Procesando...'}}</button>
    <button mat-raised-button  color="warn" (click)="onCancelar()" [disabled]="!buttonEnabled" class="zb-button">Cancelar</button>
  </div>
</div>