export class MenuFuncion {
    constructor( 
        public readonly Codigo: string, 
        public readonly Nombre: string, 
        public readonly Orden: string, 
        public readonly Visible: boolean, 
        public readonly MenuPadreId: number | null,
        public readonly MenuPadre: MenuFuncion, 
        public readonly Link: string, 
        public readonly Icono: string, 
        public readonly MaxRolTipoId: number,
        public readonly Activo: boolean, 
        public readonly Id: number,
        public readonly TieneHijos: boolean,
        public readonly MenuHijos: MenuFuncion[]
    ){}
}

export class SearchMenuFuncionRequestDto {
    constructor( 
        public readonly PageSize: number | null,
        public readonly PageNumber: number | null,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly Codigo: string, 
        public readonly Nombre: string, 
        public readonly RolId: number | null,
        public readonly MenuPadreId: number | null,
        public readonly VerSoloActivos: boolean | null
    ){}
}

export class SearchMenuFuncionResponseDto {
    constructor( 
        public readonly count: number,
        public readonly result: SearchMenuFuncionItemDto[]
    ){}
}

export class SearchMenuFuncionItemDto {
    constructor( 
        public readonly Codigo: string, 
        public readonly Nombre: string, 
        public readonly Orden: string, 
        public readonly Visible: boolean, 
        public readonly MenuPadreId: number | null,
        public readonly MenuPadreCodigo: string, 
        public readonly MenuPadreNombre: string, 
        public readonly Link: string, 
        public readonly Icono: string, 
        public readonly Activo: boolean, 
        public readonly Id: number
    ){}
}

export class SaveMenuFuncionDto {
    constructor( 
        public readonly Codigo: string, 
        public readonly Nombre: string, 
        public readonly Orden: string, 
        public readonly Visible: boolean, 
        public MenuPadreId: number | null,
        public readonly Link: string, 
        public readonly Icono: string, 
        public readonly Activo: boolean, 
        public readonly Id: number,
        public readonly MaxRolTipoId: number,
    ){}
}