import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService, ActionCompleteEventArgs, ActionBeginEventArgs } from '@syncfusion/ej2-angular-richtexteditor';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';
import { DialogUsuarioSearchComponent } from '../usuarios/components/dialogUsuarioSearch.component';

import { MensajeUsuarioService } from 'src/app/shared/services/mensajeUsuario.service';
import { MensajeUsuarioOpcionEnvioService } from 'src/app/shared/services/mensajeUsuarioOpcionEnvio.service';
import { MensajeUsuarioTipoService } from 'src/app/shared/services/mensajeUsuarioTipo.service';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';
import { RolService } from 'src/app/shared/services/rol.service';

import { MenuFuncionCodes, MensajeUsuarioOpcionEnvioConstants, MensajeUsuario, MensajeUsuarioOpcionEnvio, MensajeUsuarioTipo, SearchMensajeUsuarioTipoRequest, SaveMensajeUsuario, MensajeUsuarioAdjunto } from 'src/app/shared/model';
import { Persona, DialogConfirmRequest, DialogConfirmResponse, SearchRolRequest, Rol, Usuario, DialogUsuarioSearchComponentRequestDto, DialogUsuarioSearchComponentResponseDto } from 'src/app/shared/model';

@Component({
    selector: 'app-mensajeUsuarioEdit',
    templateUrl: './mensajeUsuarioEdit.component.html',
    styleUrls: ['./mensajeUsuarioLista.component.scss'],
    providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService]
}) 

export class MensajeUsuarioEditComponent {
    public tools: object = this.sharedFunctions.getRichTextToolbarItems();
    public insertImageSettings: object = this.sharedFunctions.getRichTextImageSettings
    (
        this.mensajeUsuarioService.getResourceName(),
        this.mensajeUsuarioService.uploadAttachTempUrl()
    );

    formGroup: FormGroup;

    isValidating: boolean = false;
    isSearching: boolean = false;
    isTipoSearching: boolean = false;
    isRolSearching: boolean = false;
    isAttachmentSearching: boolean = false;
    isOpcionEnvioSearching: boolean = false;
    isSaving: boolean = false;
    isDeleting: boolean = false;
    programarEnvio: boolean = false;
    canEdit: boolean = false;
    canDelete: boolean = false;
    isFileAttaching: boolean = false;
    isMobile: boolean = false;

    msgError: string = "";
    opcionEnvioList: MensajeUsuarioOpcionEnvio[];
    tipoList: MensajeUsuarioTipo[];
    rolList: Rol[];
    usuarioOrigen: Usuario;
    usuarioDestino: Usuario;
    rolDestino: Rol;
    textoDestino: string = "";
    item: MensajeUsuario;
    fechaEnvioHora: number;
    fechaEnvioMinuto: number;

    constructor (
        private formBuilder: FormBuilder,
        private location: Location,
        public dialog: MatDialog,
        private sharedFunctions: SharedFunctions,
        private route: ActivatedRoute,
        private menuFuncionService: MenuFuncionService,
        private rolService: RolService,
        private mensajeUsuarioService: MensajeUsuarioService,
        private mensajeUsuarioOpcionEnvioService: MensajeUsuarioOpcionEnvioService,
        private mensajeUsuarioTipoService: MensajeUsuarioTipoService
    ) {
    }

    ngOnInit(): void {
        this.isValidating = true;
        this.menuFuncionService.checkMenu(MenuFuncionCodes.MENSAJE_USUARIO_EDIT).subscribe(data => {
            if (data != null && data.Success && data?.Result) {
                this.isValidating = false;
                this.loadView();
            }
            else {
                this.menuFuncionService.checkMenu(MenuFuncionCodes.MENSAJE_USUARIO_LIST).subscribe(data => {
                    this.isValidating = false;
                    if (data != null && data.Success && data?.Result)
                        this.loadView();
                    else {
                        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                        this.onCancel();
                    }
                },
                (err: HttpErrorResponse) => {
                    this.isValidating = false;
                    this.sharedFunctions.handleError(err, true);
                });
            }
        },
        (err: HttpErrorResponse) => {
            this.isValidating = false;
            this.sharedFunctions.handleError(err, true);
        });
    }

    @HostListener('window:resize', ['$event'])
    sizeChange(event) {
        this.isMobile = (window.screen.width < 500);
    }

    trigger() {
        window.dispatchEvent(new Event('resize'));
    }

    loadView() {
        this.buildForm();
        this.getTipos();
        this.getOpcionesEnvio();
        this.getRoles();

        this.usuarioOrigen = this.sharedFunctions.getLoggedUser();
        this.loadEntity();
    }

    getDefaultDateTime() {
        var date = new Date();

        this.formGroup?.get("FechaEnvio")?.setValue(date);
        this.fechaEnvioHora = date.getHours();
        this.fechaEnvioMinuto = date.getMinutes();
    }

    onProgramarEnvioChange() {
        this.getDefaultDateTime();
    }

    onHourChange() {
        if (this.fechaEnvioHora < 0) this.fechaEnvioHora = 0;
        if (this.fechaEnvioHora > 23) this.fechaEnvioHora = 23;
    }

    onMinuteChange() {
        if (this.fechaEnvioMinuto < 0) this.fechaEnvioMinuto = 0;
        if (this.fechaEnvioMinuto > 59) this.fechaEnvioMinuto = 59;
    }

    buildForm() {
        this.formGroup = this.formBuilder.group({
            'Mensaje': [""],
            'Asunto': ["", Validators.maxLength(150)],
            'FechaEnvio': [null],
            'HoraEnvioString': [null],
            'RolDestinoId': [null],
            'UsuarioDestinoId': [null],
            'MensajeUsuarioTipoId': [null, Validators.required],
            'OpcionEnvioId': [null, Validators.required],
        });
    }

    clearDate(formFieldName: string) {
        if (formFieldName != null && formFieldName.length > 0) {
            if (this.formGroup.get(formFieldName) != null && this.formGroup.get(formFieldName)?.value)
                this.formGroup.get(formFieldName).setValue(null);
        }
    }

    isProcessing() {
        return (this.isSearching || this.isSaving || this.isDeleting);
    }

    getTipos() {
        this.isTipoSearching = true;
        this.mensajeUsuarioTipoService.getAll().subscribe(data => {
            this.isTipoSearching = false;
            if (data != null && data.Success)
                this.tipoList = data.Result;
        },
        (err: HttpErrorResponse) => {
            this.isTipoSearching = false;
            this.sharedFunctions.handleError(err);
        })
    }

    getOpcionesEnvio() {
        this.isOpcionEnvioSearching = true;
        this.mensajeUsuarioOpcionEnvioService.getAll().subscribe(data => {
            this.isOpcionEnvioSearching = false;
            if (data != null && data.Success)
                this.opcionEnvioList = data.Result;
        },
        (err: HttpErrorResponse) => {
            this.isOpcionEnvioSearching = false;
            this.sharedFunctions.handleError(err);
        })
    }

    getRoles() {
        var r = new SearchRolRequest(null, null, true, "Nombre", null, null, true);
        this.isRolSearching = true;
    
        this.rolService.search(r).subscribe(data => {
          this.isRolSearching = false;
          if (data != null)
            this.rolList = data?.result;
        },
        (err: HttpErrorResponse) => {
          this.isRolSearching = false;
          this.sharedFunctions.handleError(err);
        });
    }

    onOpcionEnvioChange() {
        this.setTextoDestino();
    }

    isOpcionEnvioUsuario() {
        return this.formGroup.get("OpcionEnvioId")?.value == MensajeUsuarioOpcionEnvioConstants.USUARIO;
    }

    isOpcionEnvioRol() {
        return this.formGroup.get("OpcionEnvioId")?.value == MensajeUsuarioOpcionEnvioConstants.ROL;
    }

    setTextoDestino() {
        this.textoDestino = "";

        switch (this.formGroup.get("OpcionEnvioId")?.value) {
            case MensajeUsuarioOpcionEnvioConstants.TODOS:
                this.textoDestino = "Todos los usuarios";
                break;

            case MensajeUsuarioOpcionEnvioConstants.USUARIO:
                if (this.usuarioDestino != null && this.usuarioDestino.Id)
                    this.textoDestino = (this.usuarioDestino.Persona?.Nombre  ?? "") + " " + (this.usuarioDestino.Persona?.Apellido ?? "") + " - " + (this.usuarioDestino.Login  ?? "");
                break;

            case MensajeUsuarioOpcionEnvioConstants.ROL:
                if (this.rolDestino != null && this.rolDestino.Id > 0)
                    this.textoDestino = "Todos los usuarios del rol " + this.rolDestino.Nombre;
                break;
        }
    }

    loadEntity() {
        this.route.params.subscribe(params => {
            if (params['id'] != null && params['id'] != '0') {
                var id = Number(params['id']);

                if (id > 0) {
                    this.isSearching = true;
                    this.mensajeUsuarioService.get(id).subscribe(data => {
                        this.isSearching = false;
                        if (data != null && data.Success) {
                            this.item = data.Result;

                            if (this.item != null && this.item.Id > 0) {
                                this.canEdit = this.item.Editable;
                                this.canDelete = this.item.Editable;
                                this.loadAttachments(this.item.Id);

                                if (this.canEdit) {
                                    this.formGroup.patchValue(this.item);

                                    this.programarEnvio = true;
                                    this.fechaEnvioHora = this.item.FechaEnvioHora;
                                    this.fechaEnvioMinuto = this.item.FechaEnvioMinuto;
                                    this.formGroup.get("OpcionEnvioId").setValue(MensajeUsuarioOpcionEnvioConstants.USUARIO);

                                    this.usuarioOrigen = new Usuario
                                    (
                                        this.item.UsuarioCreadorId , null,
                                        new Persona(null, this.item.UsuarioCreadorNombre, this.item.UsuarioCreadorApellido, null, null, null, null, null, null, null, null),
                                        null, this.item.UsuarioCreadorLogin, null, null, null, null, null, null, null, null, null, null, null, null, null
                                    );

                                    this.usuarioDestino = new Usuario
                                    (
                                        this.item.UsuarioDestinoId , null,
                                        new Persona(null, this.item.UsuarioDestinoNombre, this.item.UsuarioDestinoApellido, null, null, null, null, null, null, null, null),
                                        null, this.item.UsuarioDestinoLogin, null, null, null, null, null, null, null, null, null, null, null, null, null
                                    );

                                    this.setTextoDestino();
                                }
                            }
                            else
                                this.msgError = "No se encontró el mensaje seleccionado.";
                        }
                        else
                            this.msgError = "Ocurrió un error al intentar obtener los datos del mensaje.";
                    },
                    (err: HttpErrorResponse) => {
                        this.isSearching = false;
                        this.sharedFunctions.handleError(err, true);
                    })
                }
                else
                    this.msgError = "Item no válido.";
            }
            else
            {
                this.canEdit = true;
                this.canDelete = false;
                this.item = new MensajeUsuario(0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, true, null, null, null, null, 0, [], null);
                this.formGroup.patchValue(this.item);
            }
        });
    }

    loadAttachments(id) {
        this.isAttachmentSearching = true;
        this.mensajeUsuarioService.getAttachments(id).subscribe(data => {
            this.isAttachmentSearching = false;
            if (data != null && data.Success)
                this.item.Adjuntos = data.Result?.filter(f => !f.IncluidoEnCuerpoMensaje);

            if (this.item.Adjuntos == null)
                this.item.Adjuntos = []
        },
        (err: HttpErrorResponse) => {
            this.isAttachmentSearching = false;
            this.sharedFunctions.handleError(err);
        })
    }

    getErrorAsunto() {
        var v = this.formGroup.get('Asunto');
        if (v.hasError('required'))
        return 'El campo es obligatorio';
        if (v.hasError('maxlength'))
        return 'Límite de 150 caracteres alcanzado';
        return '';
    }

    getErrorMensaje() {
        return this.formGroup.get('Mensaje').hasError('required') ? 'El campo es obligatorio' : '';
    }
    
    getErrorMensajeUsuarioTipoId() {
        return this.formGroup.get('MensajeUsuarioTipoId').hasError('required') ? 'El campo es obligatorio' : '';
    }
    
    getErrorOpcionEnvioId() {
        return this.formGroup.get('OpcionEnvioId').hasError('required') ? 'El campo es obligatorio' : '';
    }

    onGuardar() {

        if (!this.formGroup.valid) {
            this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
            return false;
        }

        var fechaEnvio = this.formGroup.get("FechaEnvio")?.value;
        if (this.programarEnvio) {
            if (fechaEnvio == null || fechaEnvio.toString().length == 0) {
                this.sharedFunctions.showMessage("Por favor seleccione una fecha de envio para el mensaje", "Error");
                return false;
            }

            if (this.fechaEnvioHora == null || this.fechaEnvioHora.toString().length == 0 ||
                this.fechaEnvioMinuto == null || this.fechaEnvioMinuto.toString().length == 0) {
                this.sharedFunctions.showMessage("Por favor seleccione una hora de envio para el mensaje", "Error");
                return false;
            }
        }

        if (this.programarEnvio) {
            var horaEnvio = (this.fechaEnvioHora?.toString()?.padStart(2, '0') ?? "00") + ":" + (this.fechaEnvioMinuto.toString()?.padStart(2, '0') ?? "00") + ":00:0000";
            this.formGroup.get("HoraEnvioString").setValue(horaEnvio);
        }
        else {
            this.formGroup.get("FechaEnvio").setValue(null);
            this.formGroup.get("HoraEnvioString").setValue(null);
        }

        this.formGroup.get("RolDestinoId").setValue(null);
        if (this.rolDestino != null && this.rolDestino.Id > 0)
            this.formGroup.get("RolDestinoId").setValue(this.rolDestino.Id);

        this.formGroup.get("UsuarioDestinoId").setValue(null);
        if (this.usuarioDestino != null && this.usuarioDestino.Id > 0)
            this.formGroup.get("UsuarioDestinoId").setValue(this.usuarioDestino.Id);

        var asunto = this.formGroup.get("Asunto").value;
        if (asunto != null && asunto.length > 0) 
            this.doSave();
        else {
            const dialogRef = this.dialog.open(DialogConfirmComponent, {
                data: new DialogConfirmRequest
                (
                    null, 
                    "No ingresó ningún asunto. ¿Está seguro que desea realizar el envio de todas formas?", 
                    "Enviar sin asunto", 
                    "No enviar"
                )
            });
    
            dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
    
                if (result.Accepted)
                    this.doSave();
            });
        }
    }

    doSave() {
        var r = new SaveMensajeUsuario(null, null, null, null, null, null, null, null, null, null, null);

        if (this.item.Adjuntos == null)
            this.item.Adjuntos = [];

        r = 
        {
            ...this.formGroup.value,
            ...
            {
                Id: (this.item?.Id ?? 0),
                Adjuntos: this.item?.Adjuntos
            } 
        };

        this.isSaving = true;
        this.mensajeUsuarioService.save2(r).subscribe(data => {
            this.isSaving = false;
            if (data != null && data.Success && data.Result != null && data.Result.length > 0) {
                this.sharedFunctions.showMessage("Se " + 
                    (this.programarEnvio ? 
                        (data.Result.length == 1 ? "programó" : "programaron") : 
                        (data.Result.length == 1 ? "envió" : "enviaron")) + 
                    " exitosamente " + data.Result.length + " mensaje" + (data.Result.length==1 ? "" : "s") + "!", "Operación Exitosa!");
                this.onCancel();
            }
            else
                this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
            this.isSaving = false;
            this.sharedFunctions.handleError(err, true);
        })
    }

    onDelete() {

        if (!this.canEdit) 
            return false;

        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            data: new DialogConfirmRequest(null, "¿Está seguro que desea cancelar el envio del mensaje?", null, null)
        });

        dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {

            if (result.Accepted) {
                if (this.item.TotalEnviados > 1) {
                    const dialogRef = this.dialog.open(DialogConfirmComponent, {
                        data: new DialogConfirmRequest
                        (
                            null, 
                            "El mensaje se envió a un total de " + this.item.TotalEnviados + " usuarios. ¿Desea cancelar todos los envios o solo este?",
                            "Cancelar todos",
                            "Cancelar solo este"
                        )
                    });
            
                    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
                        if (result.Accepted)
                            this.doDeleteByEnvio();
                        else
                            this.doDelete();
                    });
                }
                else
                    this.doDelete();
            }
        });
    }

    doDelete() {
        this.isDeleting = true;
        this.mensajeUsuarioService.delete(this.item.Id).subscribe(data => {
            this.isDeleting = false;
            if (data != null && data.Success && data.Result) {
                this.sharedFunctions.showMessage("Se canceló correctamente en envio del mensaje actual!", "Operación Exitosa!");
                this.onCancel();
            }
            else
                this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
            this.isDeleting = false;
            this.sharedFunctions.handleError(err, true);
        })
    }

    doDeleteByEnvio() {
        this.isDeleting = true;
        this.mensajeUsuarioService.deleteByEnvio(this.item.EnvioId).subscribe(data => {
            this.isDeleting = false;
            if (data != null && data.Success && data.Result) {
                this.sharedFunctions.showMessage("Se cancelaron correctamente todos los envios!", "Operación Exitosa!");
                this.onCancel();
            }
            else
                this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
            this.isDeleting = false;
            this.sharedFunctions.handleError(err, true);
        })
    }

    onCancel() {
        this.location.back();
    }

    onRolSelectChange() {
        this.setTextoDestino();
    }

    onUserSearchClick() {
        if (this.isProcessing())
            return;
    
        const dialogRef = this.dialog.open(DialogUsuarioSearchComponent, {
            width: '90%',
            data: new DialogUsuarioSearchComponentRequestDto(false, 0)
        });
    
        dialogRef.afterClosed().subscribe((result: DialogUsuarioSearchComponentResponseDto) => {
            if (result != null && !result.Cancelled) {
                var u = (result.Usuarios != null && result.Usuarios.length > 0 ? result.Usuarios[0] : null);

                this.usuarioDestino = null;
                if (u != null && u.Id > 0)
                    this.usuarioDestino = new Usuario(u.Id, u.PersonaId,
                        new Persona(u.PersonaId, u.Nombre, u.Apellido, u.NroDocumento, null, null, null, null, null, null, null),
                        u.Movil, u.Login, null, null, u.Mail, u.FotoPerfilUrl, null, null, null, null, null, null, null, null, null
                    );

                this.setTextoDestino();
            }
        });
    }

    onFileAttachedDelete(item: any) {
        if (item.Id > 0)
            this.onExistingFileAttachedDelete(item);
        else
            this.onNewFileAttachedDelete(item);
    }

    onNewFileAttach(event: any) {
        if (event?.target?.files != null && event.target.files.length > 0) {
            var fileList = event.target.files;

            if (this.item.Adjuntos == null)
                this.item.Adjuntos = [];

            for (var i=0; i<fileList.length; i++) {
                if (this.item.Adjuntos.find(f => f.NombreDocumento == fileList[i].name)) {
                    this.sharedFunctions.showMessage("Ya existe un archivo con ese nombre. Por favor modifique el nombre del archivo o elimine el ya adjuntado", "Error");
                    return false;
                }
            }

            if (fileList != null && fileList.length > 0) {
                this.isFileAttaching = true;
                this.mensajeUsuarioService.uploadAttachTemp(fileList).subscribe(data => {
                    this.isFileAttaching = false;
                    if (data != null && data.Success && data.Result != null && data.Result.Archivos != null && data.Result.Archivos.length > 0) {
                        for (let i=0; i<data.Result.Archivos.length; i++) {
                            if (data.Result.Archivos[i] != null) {
                                this.item.Adjuntos.push(data.Result.Archivos[i]);
                            }
                        }
                    }
                    else
                        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error intentando subir los archivos", "Error");
                },
                (err: HttpErrorResponse) => {
                    this.isFileAttaching = false;
                    this.sharedFunctions.handleError(err, true);
                })
            }
        }
    }

    onExistingFileAttachedDelete(item: any) {
        if (this.item.Adjuntos != null && this.item.Adjuntos.length > 0) {
            var index = this.item.Adjuntos.findIndex(f => f.Id == item.Id);

            if (index >= 0)
                this.item.Adjuntos.splice(index, 1);
        }
    }

    onNewFileAttachedDelete(item: any) {
        if (this.item.Adjuntos != null && this.item.Adjuntos.length > 0) {
            var index = this.item.Adjuntos.findIndex(f => f.Id == item.Id);

            if (index >= 0) {
                this.item.Adjuntos.splice(index, 1);
            }
        }
    }

    public imageUploading(args: any): void {
        if (args != null) {
            args.currentRequest.setRequestHeader(
                this.mensajeUsuarioService.getAuthorizationHeaderParameterName(),
                this.mensajeUsuarioService.getAuthorizationHeaderParameterValue()
            );
        }
    }

    public imageUploadSuccess(args: any): void {
        if (args != null && args.file != null) {
            let filename: any = document.querySelectorAll(".e-file-name")[0];
            
            if (filename != null) {
                filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, '');
                filename.title = args.file.name;
            }
        }
    }
}