<mat-card>
  <mat-card-header>
    <mat-card-title>Búsqueda de Licitaciones</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
      <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    </div>

    <ng-container *ngIf="canView">

      <mat-expansion-panel *ngIf="!isValidating">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">search</mat-icon> Filtros de Búsqueda
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="searchForm">
          <div fxLayout="row stretch" fxLayout.xs="column">
            
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" >
                <mat-label>Fecha Publicación Desde</mat-label>
                <input matInput [matDatepicker]="fechaDesdePicker" formControlName="FechaDesde" readonly (click)="fechaDesdePicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaDesdePicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaDesde')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaDesdePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Fecha Publicación Hasta</mat-label>
                <input matInput [matDatepicker]="fechaHastaPicker" formControlName="FechaHasta" readonly (click)="fechaHastaPicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaHastaPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaHasta')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaHastaPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isEstadoSearching">Estado</mat-label>
                <mat-label *ngIf="isEstadoSearching">Cargando...</mat-label>
                <mat-select formControlName="Estado">
                  <mat-option>Todos</mat-option>
                  <mat-option *ngFor="let s of estadoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Licitación</mat-label>
                <input matInput formControlName="NumeroLicitacion" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </form>

        <!-- <div fxLayout="row stretch" fxLayout.xs="column" align="end">
          <mat-checkbox [(ngModel)]="verSoloActivos" (keyup.enter)="onSearch()" color="primary">Ver Solo Activos</mat-checkbox> 
        </div> -->

      </mat-expansion-panel>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px">
        <button mat-raised-button (click)="add()" *ngIf="canAdd" [disabled]="isSearching" color="primary" class="zb-button">Agregar</button>
        <button mat-raised-button (click)="onSearch()" [disabled]="isSearching" color="primary" class="zb-button">{{isSearching ? 'Buscando...' : 'Buscar'}}</button>
        <button mat-raised-button (click)="onExport()" [disabled]="isExporting" *ngIf="canExport" color="primary" class="zb-button">{{isExporting ? 'Exportando...' : 'Exportar'}}</button>
        <button mat-raised-button (click)="onImportLicitacion()" *ngIf="canImport" [disabled]="isSearching || isExporting" class="zb-button">Importar Licitación</button>
        <button mat-raised-button (click)="onImportArchivoLicitacion()" *ngIf="canImportArchivo" [disabled]="isSearching || isExporting" class="zb-button">Importar Archivo</button>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" *ngIf="!isSearching">
        <ejs-grid #grid [dataSource]='data' [query]='query' [allowPaging]="true" [allowSorting]="true" [toolbar]='toolbarOptions' [selectionSettings]='selectionOptions' 
          [pageSettings]='pageSettings' [allowResizing]='true' (actionFailure)="onGridActionFailure($event)">
          <e-columns>
            <e-column field='Numero' headerText='Nro. Licitación' width=90></e-column>
            <e-column field='Estado' headerText='Estado' width=90></e-column>
            <e-column field='Moneda' headerText='Moneda' width=90></e-column>
            <e-column field='MontoReferencia' headerText='Monto Ref.' width=90 format="N2"></e-column>
            <e-column field='FechaPublicacion' headerText='Fecha Publicación' width=90>
              <ng-template #template let-data>
                {{data?.FechaPublicacion | date:'dd/MM/yyyy'}}
              </ng-template>
            </e-column>
            <e-column field='FechaUltimaPublicacion' headerText='Ult. Publicación' width=90>
              <ng-template #template let-data>
                {{data?.FechaUltimaPublicacion | date:'dd/MM/yyyy'}}
              </ng-template>
            </e-column>
            <e-column headerText='Acciones' width=90>
              <ng-template #template let-data>
                <a style="cursor: pointer; margin-right: 5px" (click)="edit(data)"><i class="fa fa-eye fa-1x"></i></a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>

    </ng-container>

  </mat-card-content>
</mat-card>

