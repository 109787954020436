<div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px">
    <ng-container *ngIf="canImport">

        <mat-expansion-panel [expanded]="true" [hideToggle]="true">

            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon style="margin-top: -3px; margin-right: 5px;">cloud_download</mat-icon> Importación de Licitaciones
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form [formGroup]="importWebForm">
                <div fxLayout="row stretch" fxLayout.xs="column">

                    <div fxFlex=65 fxFlex.xs=100 class="flex-item">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>Fecha a Importar</mat-label>
                            <input matInput [matDatepicker]="fechaInicioPicker" formControlName="FechaInicio" readonly
                            (click)="fechaInicioPicker.open()">
                            <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaInicioPicker">
                            </mat-datepicker-toggle>
                            <!-- <mat-datepicker-toggle matSuffix (click)="clearDate('FechaInicio')">
                                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                            </mat-datepicker-toggle> -->
                            <mat-datepicker #fechaInicioPicker></mat-datepicker>
                        </mat-form-field>
                        <mat-error *ngIf="!importWebForm?.controls['FechaInicio'].valid && importWebForm?.controls['FechaInicio'].touched">
                            {{ getErrorFechaInicio() }}
                        </mat-error>
                    </div>

                    <div fxFlex=35 fxFlex.xs=100 fxLayoutAlign="end center" class="flex-item">
                        <button mat-raised-button (click)="importLicitaciones()" [disabled]="isImporting" color="primary"
                            class="zb-button">{{isImporting ? 'Importando...' : 'Importar'}}</button>
                    </div>
                </div>
            </form>

        </mat-expansion-panel>

    </ng-container>
</div>