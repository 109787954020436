<mat-card>
    <mat-card-header>
      <mat-card-title>Envio de Mensaje de Usuario</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
   </mat-card-header>
  
    <mat-card-content>
  
        <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
        <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

        <div *ngIf="!isSearching && item != null">

            <ng-container *ngIf="canEdit">

                <form [formGroup]="formGroup">

                    <mat-expansion-panel [expanded]="true">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon style="margin-top: -3px;">settings</mat-icon> Configuraciones del Mensaje
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row stretch" fxLayout.xs="column">

                            <div fxFlex=30 fxFlex.xs=100 class="flex-item">
                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label *ngIf="!isTipoSearching">Tipo de Mensaje*</mat-label>
                                    <mat-label *ngIf="isTipoSearching">Cargando...</mat-label>
                                    <mat-select formControlName="MensajeUsuarioTipoId">
                                        <mat-option *ngFor="let s of tipoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="!formGroup.controls['MensajeUsuarioTipoId'].valid && formGroup.controls['MensajeUsuarioTipoId'].touched">
                                        {{ getErrorMensajeUsuarioTipoId() }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex=70 fxFlex.xs=100 class="flex-item">
                                <div fxLayout="row stretch" fxLayout.xs="column">
                                    <div fxFlex=25 fxFlex.xs=100 class="flex-item" style="margin: 20px 0px;">
                                        <mat-checkbox (change)="onProgramarEnvioChange()" [(ngModel)]="programarEnvio" [ngModelOptions]="{standalone: true}" color="primary">¿Programar envio?</mat-checkbox> 
                                    </div>
                                    <div fxFlex=40 fxFlex.xs=100 class="flex-item" *ngIf="programarEnvio">
                                        <mat-form-field appearance="outline" style="width: 100%">
                                            <mat-label>Fecha Envio</mat-label>
                                            <input matInput [matDatepicker]="fechaEnvioPicker" formControlName="FechaEnvio" readonly (click)="fechaEnvioPicker.open()">
                                            <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaEnvioPicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker-toggle matSuffix (click)="clearDate('FechaEnvio')">
                                                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #fechaEnvioPicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex=35 fxFlex.xs=100 class="flex-item" *ngIf="programarEnvio">
                                        <div fxLayout="row stretch" fxLayout.xs="column">
                                            <div fxFlex=30 fxFlex.xs=100 class="flex-item">
                                                <mat-form-field appearance="outline" style="width: 100%" >
                                                    <mat-label>Hora</mat-label>
                                                    <input matInput type="number" min="0" max="23" (change)="onHourChange()" [(ngModel)]="fechaEnvioHora" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex=3 fxFlex.xs=0 class="flex-item" *ngIf="!isMobile" style="margin-top: 20px; font-size: 16px; font-weight: bold; text-align: center;">
                                                <mat-label>:</mat-label>
                                            </div>
                                            <div fxFlex=30 fxFlex.xs=100 class="flex-item">
                                                <mat-form-field appearance="outline" style="width: 100%">
                                                    <mat-label>Minuto</mat-label>
                                                    <input matInput type="number" min="0" max="59" (change)="onMinuteChange()" [(ngModel)]="fechaEnvioMinuto" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex=15 fxFlex.xs=0 fxFlexLayoutAlign="start center" fxLayoutAlign.xs="center center" *ngIf="!isMobile" class="flex-item" style="margin-top: 20px;">
                                                <mat-icon matDatepickerToggleIcon>access_time</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div fxFlex=30 fxFlex.xs=100 class="flex-item" *ngIf="item.Id==0">
                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label *ngIf="!isOpcionEnvioSearching">Opciones de Envio*</mat-label>
                                    <mat-label *ngIf="isOpcionEnvioSearching">Cargando...</mat-label>
                                    <mat-select formControlName="OpcionEnvioId" (selectionChange)="onOpcionEnvioChange()">
                                        <mat-option *ngFor="let s of opcionEnvioList" [value]="s.Id">{{s.Nombre}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="!formGroup.controls['OpcionEnvioId'].valid && formGroup.controls['OpcionEnvioId'].touched">
                                        {{ getErrorOpcionEnvioId() }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex=70 fxFlex.xs=100 class="flex-item" *ngIf="item.Id==0">
                                <div fxFlex=100 fxFlex.xs=100 fxLayoutAlign="start center" fxLayoutAlign.xs="center center" class="flex-item" *ngIf="isOpcionEnvioUsuario()">
                                    <button mat-raised-button (click)="onUserSearchClick()" [disabled]="isProcessing()" style="margin-top: -15px;" color="primary">
                                        <mat-icon style="margin-right: 5px;">search</mat-icon> Buscar Usuario
                                    </button>
                                </div>

                                <div fxFlex=100 fxFlex.xs=100 class="flex-item" *ngIf="isOpcionEnvioRol()">
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label *ngIf="!isRolSearching">Seleccione un rol a enviar el mensaje*</mat-label>
                                        <mat-label *ngIf="isRolSearching">Cargando...</mat-label>
                                        <mat-select (selectionChange)="onRolSelectChange()" [(ngModel)]="rolDestino" [ngModelOptions]="{standalone: true}">
                                            <mat-option>Ninguno</mat-option>
                                            <mat-option *ngFor="let s of rolList" [value]="s">{{s.Nombre}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        
                        </div>

                    </mat-expansion-panel>

                    <br/>

                    <mat-expansion-panel [expanded]="true" hideToggle="true">

                        <br/>

                        <div fxLayout="row stretch" fxLayout.xs="column">

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;">
                                <mat-label style="font-weight: bold;">De: {{usuarioOrigen?.Persona?.Nombre}} {{usuarioOrigen?.Persona?.Apellido}} - {{usuarioOrigen?.Login}}</mat-label>
                            </div>

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item"  style="margin:10px;">
                                <mat-label style="font-weight: bold;">Para: {{textoDestino}}</mat-label>
                            </div>

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label>Asunto*</mat-label>
                                    <input matInput formControlName="Asunto" (keyup.enter)="onGuardar()" maxlength="150">
                                    <mat-error *ngIf="!formGroup.controls['Asunto'].valid && formGroup.controls['Asunto'].touched">
                                        {{ getErrorAsunto() }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                                <ejs-richtexteditor #mensajeRte formControlName="Mensaje" [toolbarSettings]='tools' [insertImageSettings]='insertImageSettings' 
                                    (imageUploading)="imageUploading($event)" (imageUploadSuccess)="imageUploadSuccess($event)">
                                </ejs-richtexteditor>
                            </div>

                        </div>

                    </mat-expansion-panel>

                    <br/>

                    <mat-expansion-panel [expanded]="true" hideToggle="true">

                        <br/>

                        <div fxLayout="row stretch" fxLayout.xs="column">

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;">
                                <mat-nav-list>
                                    <mat-list-item *ngFor="let file of item.Adjuntos" style="background-color: lightgray; margin: 5px 0px;border-radius: 10px;">
                                        <a matLine href="{{file.UrlDocumento}}" target="_blank">{{file.NombreDocumento}}</a>
                                        <button mat-icon-button (click)="onFileAttachedDelete(file)" title="Eliminar adjunto">
                                            <i class="fa fa-trash fa-1x"></i>
                                        </button>
                                    </mat-list-item>
                                    </mat-nav-list>
                            </div>

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;">
                                <button mat-icon-button matSuffix (click)="fileInput.click()" [disabled]="isFileAttaching"><mat-icon style="margin-right: 5px;">attach_file</mat-icon> Agregar adjunto</button>
                                <input hidden (change)="onNewFileAttach($event)" #fileInput type="file" multiple>
                                <mat-label *ngIf="isFileAttaching"><br/><i class="fa fa-spinner fa-spin"></i> Subiendo archivo...</mat-label>
                            </div>
                        </div>

                    </mat-expansion-panel>

                </form>

            </ng-container>

        </div>

    </mat-card-content>

    <mat-card-actions style="margin-bottom: 20px;">
        <div fxLayout="row stretch" fxLayout.xs="column">
            <button mat-raised-button (click)="onGuardar()" *ngIf="canEdit" [disabled]="isProcessing()" color="primary" class="zb-button">{{isSaving ? 'Enviando...' : 'Enviar'}}</button>
            <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canEdit ? 'Cancelar' : 'Cerrar'}}</button>
            <button mat-raised-button (click)="onDelete()" [disabled]="isProcessing()" *ngIf="canDelete" color="accent" class="zb-button">{{isDeleting ? 'Cancelando...' : 'Cancelar Envio'}}</button>
        </div>
    </mat-card-actions>

</mat-card>