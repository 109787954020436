import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { UsuarioService, PersonaService } from 'src/app/shared/services';
import { SaveUsuarioDto, Usuario, Persona, SavePersonaDto, DialogConfirmRequest, DialogConfirmResponse } from 'src/app/shared/model';

@Component({
  selector: 'app-usuarioSelfEdit',
  templateUrl: './usuarioSelfEdit.component.html'
})

export class UsuarioSelfEditComponent implements OnInit {

  usuarioFormGroup: FormGroup;
  personaFormGroup: FormGroup;
  hideClave: boolean = true;
  hideConfirmClave: boolean = true;
  claveChanged: boolean = false;
  confirmClaveChanged: boolean = false;

  isSearching: boolean = false;
  isUserSaving: boolean = false;
  isPersonSaving: boolean = false;
  isPaisSearching: boolean = false;
  isPersonaSearching: boolean = false;

  msgError: string = "";
  usuario: Usuario;
  persona: Persona = new Persona(null, null, null, null, null, null, null, true, null, null, null);
  userImageBase64: string | ArrayBuffer = null;
  userImageExtension: string = "";
  personaMsgError: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private usuarioService: UsuarioService,
    private personaService: PersonaService) {
  }

  ngOnInit(): void {
    this.loadView();
  }

  loadView() {

    this.buildUserForm();
    this.buildPersonForm();

    this.loadEntity();
  }

  buildUserForm() {
    this.usuarioFormGroup = this.formBuilder.group({
      'Movil': [null, Validators.maxLength(100)],
      'Clave': [null, [Validators.required, Validators.maxLength(100)]],
      'ConfirmClave': [null, [Validators.required, Validators.maxLength(100)]]
    });
  }

  buildPersonForm() {
    this.personaFormGroup = this.formBuilder.group({
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'Apellido': [null, [Validators.required, Validators.maxLength(150)]],
      'FechaNacimiento': [null, Validators.required]
    });
  }

  convertFileToBase64(event) {
    this.userImageBase64 = null;
    const file = event.target.files[0];

    if (file != null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.backofficeImageBase64 = reader.result;
      };
      reader.onloadend = () => {
        this.userImageBase64 = reader.result;
        this.userImageExtension = (file?.name?.split('.')?.pop() ?? "");
        // console.log(reader.result);
      };
    }
  }

  isProcessing() {
    return (this.isSearching || this.isPersonSaving || this.isUserSaving);
  }

  loadEntity() {
    this.isSearching = true;
    this.usuarioService.getSelf().subscribe(data => {
      this.isSearching = false;
      if (data != null && data.Success) {
        this.usuario = data.Result;

        if (this.usuario != null && this.usuario.Id > 0) {
          this.usuarioFormGroup.patchValue(this.usuario);

          this.usuarioFormGroup.get('Clave').setValue(this.usuario.Clave);
          this.usuarioFormGroup.get('ConfirmClave').setValue(this.usuario.Clave);

          this.getPersona();
        }
        else
          this.msgError = "No se encontró el usuario seleccionado.";
      }
      else
        this.msgError = "Ocurrió un error al intentar obtener los datos del usuario.";
    },
      (err: HttpErrorResponse) => {
        this.isSearching = false;
        this.sharedFunctions.handleError(err, true);
    })
  }

  getPersona() {
    if (this.usuario != null && this.usuario.Id > 0) {
      this.isPersonaSearching = true;
      this.personaMsgError = "";

      this.personaService.getSelf().subscribe(data => {
        this.isPersonaSearching = false;
        if (data != null) {
          this.persona = data.Result;
          this.loadPersonData();
        }
        else
          this.personaMsgError = "Ocurrió un error al intentar obtener los datos de la persona.";
      },
        (err: HttpErrorResponse) => {
          this.isPersonaSearching = false;
          this.sharedFunctions.handleError(err);
        })
    }
  }

  loadPersonData() {
    if (this.persona != null && this.persona.Id > 0) {
      this.personaFormGroup.patchValue(this.persona);

      if (this.persona.FechaNacimiento != null)
        this.personaFormGroup.get("FechaNacimiento").setValue(this.datePipe.transform(this.persona.FechaNacimiento, "yyyy-MM-dd"));
    }
  }

  getErrorNombre() {
    var v = this.personaFormGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorApellido() {
    var v = this.personaFormGroup.get('Apellido');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorFechaNacimiento() {
    return this.personaFormGroup.get('FechaNacimiento').hasError('required') ? 'El campo es obligatorio' : '';
  }

  getErrorMovil() {
    var v = this.usuarioFormGroup.get('Movil');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorClave() {
    var v = this.usuarioFormGroup.get('Clave');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorConfirmClave() {
    var v = this.usuarioFormGroup.get('ConfirmClave');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  hasPersonaFotoDniFrente() {
    return (this.persona?.FotoDniFrenteUrl != null && this.persona?.FotoDniFrenteUrl.length > 0);
  }

  hasPersonaFotoDniDorso() {
    return (this.persona?.FotoDniDorsoUrl != null && this.persona?.FotoDniDorsoUrl.length > 0);
  }

  hasPersonaFotoSelfie() {
    return (this.persona?.FotoSelfieUrl != null && this.persona?.FotoSelfieUrl.length > 0);
  }

  userImageChanged() {
    return (this.userImageBase64 != null);
  }

  onGuardarPersona() {

    if (!this.personaFormGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new SavePersonaDto(null, null, null, null, null, null, true, null, null, null, null, null, null);

    r =
    {
      ...this.persona,
      ...this.personaFormGroup.value,
      ...
      {
        Id: (this.persona?.Id ?? 0)
      }
    };

    this.isPersonSaving = true;
    this.personaService.saveSelf(r).subscribe(data => {
      this.isPersonSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
      (err: HttpErrorResponse) => {
        this.isPersonSaving = false;
        this.sharedFunctions.handleError(err, true);
      })
  }

  onGuardarUsuario() {

    if (!this.usuarioFormGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var p = this.usuarioFormGroup.get('Clave')?.value?.toString();
    if (p != this.usuarioFormGroup.get('ConfirmClave')?.value?.toString()) {
      this.sharedFunctions.showMessage("Revise la clave seteada y su confirmación ya que son diferentes", "Error");
      return false;
    }

    var m = this.usuarioFormGroup.get('Movil')?.value;
    if (m != null && m.length > 0 && !m.startsWith("+"))
      this.usuarioFormGroup.get('Movil').setValue("+" + m);

    var index = this.userImageBase64?.toString()?.indexOf(",");
    var r = new SaveUsuarioDto(null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, true, null);

    r =
    {
      ...this.usuario,
      ...this.usuarioFormGroup.value,
      ...this.persona,
      ...
      {
        Id: (this.usuario?.Id ?? 0),
        PersonaId: (this.usuario?.PersonaId ?? 0),
        FotoPerfilBase64: (index != null && index >= 0 ? this.userImageBase64?.toString()?.substring(index + 1) : null),
        FotoPerfilExtension: this.userImageExtension,
        Roles: this.usuario.Roles?.map(item => item?.Id),
        // DebeCambiarClave: this.usuario?.DebeCambiarClave,
        // Bloqueado: this.usuario?.Bloqueado,
        // Activo: this.usuario?.Activo
      }
    };

    this.isUserSaving = true;
    this.usuarioService.saveSelf(r).subscribe(data => {
      this.isUserSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
      (err: HttpErrorResponse) => {
        this.isUserSaving = false;
        this.sharedFunctions.handleError(err, true);
      })
  }

  logout() {
    this.sharedFunctions.clearLocalStorage();
    this.router.navigate(['auth/login']);
  }

  onCancel() {
    this.router.navigate(['/']);
  }
}