import { Component, OnInit, ViewChild, Inject, ViewContainerRef, TemplateRef, ElementRef } from '@angular/core';
import { DataManager, WebApiAdaptor, Query} from '@syncfusion/ej2-data';
import { GridComponent, GridModel, PageSettingsModel, RowDataBoundEventArgs, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { isNumber } from 'highcharts';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { MenuFuncionService, LicitacionService, EstadoService, ProductoService } from 'src/app/shared/services/index';
import { GridPageSettings, MenuFuncionCodes, SearchLicitacionForecastRequest, SearchEstadoRequest, Estado, Producto, SearchProductoRequestDto, SearchLicitacionForecastItemDto, SearchLicitacionForecastAnioItemDto } from 'src/app/shared/model/index';
import { DialogForecastDetalleAnioComponent } from './components/dialogForecastDetalleAnio.component';

@Component({
  selector: 'app-forecastLista',
  templateUrl: './forecastLista.component.html',
  styleUrls: ['./forecastLista.component.scss']
})

export class ForecastListaComponent implements OnInit {

  @ViewChild('anioMesGrid')
  public anioMesGrid: GridComponent;
  public anioMesChildGrid: GridModel;

  @ViewChild('mesChildTemplate') 
  public mesChildTemplate: any;

  public data: DataManager;
  public anioMesDataManager: DataManager;

  total: number = 0;
  searchForm: FormGroup;

  lastSearchAnioList: number[];

  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;

  filteredProductoList: Producto[];
  isProductoSearching: boolean = false;

  estadoList: Estado[];
  isEstadoSearching: boolean = false;

  canView: boolean = false;
  canExport: boolean = false;

  public query: Query;
  public subQuery: Query;

  public selectionOptions: SelectionSettingsModel;
  public pageSizes: number[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private estadoService: EstadoService,
    private productoService: ProductoService,
    private service: LicitacionService,
    private datePipe: DatePipe,
    @Inject(ViewContainerRef) private viewContainerRef?: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenu(MenuFuncionCodes.REPORTE_FORECAST).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result)
        this.loadView();
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.canView = true;
    this.buildForm();
    
    this.getEstados();
    this.getProductos();

    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
    this.toolbarOptions = null; //['ExcelExport'];

    this.query = this.setQueryParams();
    this.subQuery = this.setQueryParams();
    
    // this.data = new DataManager({
    //   url: this.service.getReporteForecastFarmaciaUrl(),
    //   adaptor: new WebApiAdaptor(),
    //   headers: [this.service.getAuthorizationHeader()]
    // });

    setTimeout(() => {
      this.setGroupedGrid();
    }, 1000);

    this.menuService.checkMenu(MenuFuncionCodes.REPORTE_FORECAST_EXPORT).subscribe(data => {
      if (data != null && data.Success && data?.Result != null && data.Result == true) {
        this.canExport = true;
      }
    },
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'EstadoLicitacionId': [null],
      'Producto': [null],
      'Descripcion': [null],
      'AnioDesde': [ (new Date()).getFullYear(), Validators.required ],
      'AnioHasta': [(new Date()).getFullYear(), Validators.required]
    });
  }

  getProductos() {
    this.searchForm.controls.Producto.valueChanges
      .pipe(
        filter(res => {
          return res && res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredProductoList = [];
          this.isProductoSearching = true;
        }),
        switchMap(value => this.productoService.search(new SearchProductoRequestDto(20, 0, true, "Codigo", isNumber(parseInt(value)) ? value : null, (!isNumber(parseInt(value)) ? value : null), true))
          .pipe(
            finalize(() => {
              this.isProductoSearching = false;
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data != null && data.result != null)
          this.filteredProductoList = data.result;
        else
          this.filteredProductoList = [];
      });
  }

  getEstados() {
    var r = new SearchEstadoRequest(null, null, true, "Nombre", null, null, true);
    this.isEstadoSearching = true;

    this.estadoService.searchLicitacionEstados(r).subscribe(data => {
      this.isEstadoSearching = false;
      if (data != null)
        this.estadoList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isEstadoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  onGridActionFailure(event: any): void {
    console.log("onGridActionFailure >> ", event);
    // this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {

    if (!this.validateSearch())
      return;

    this.query = this.setQueryParams();

    if (this.data == null) {
      this.data = new DataManager({
        url: this.service.getReporteForecastFarmaciaUrl(),
        adaptor: new WebApiAdaptor(),
        headers: [this.service.getAuthorizationHeader()]
      });

      this.anioMesDataManager = new DataManager({
        url: this.service.getReporteForecastFarmaciaItemUrl(),
        adaptor: new WebApiAdaptor(),
        headers: [this.service.getAuthorizationHeader()]
      });
      this.anioMesChildGrid.dataSource = this.anioMesDataManager;
    }

    if (this.anioMesChildGrid != null)
      this.anioMesChildGrid.query = this.setQueryParams();
    if (this.anioMesGrid != null)
      this.anioMesGrid.refresh();
  }

  setGroupedGrid() {

    // this.anioMesDataManager = new DataManager({
    //   url: this.service.getReporteForecastFarmaciaItemUrl(),
    //   adaptor: new WebApiAdaptor(),
    //   headers: [this.service.getAuthorizationHeader()]
    // });

    this.anioMesChildGrid = {
      // dataSource: this.anioMesDataManager,
      query: this.subQuery,
      queryString: 'CodigoProducto',
      load() {
        this.registeredTemplate = {};   // set registertemplate value as empty in load event
      },
      // rowDataBound: this.onRowDataBound.bind(this),
      columns: [
        { headerText: '', template: this.mesChildTemplate },
        { field: 'Anio', headerText: 'Año' },
        { field: 'TotalCantidad', headerText: 'Cantidad Total', textAlign: 'Right', format: "N2" },
        { field: 'MinimoPrecioUnitario', headerText: 'Min. Precio Unitario', textAlign: 'Right', format: "N2" },
        { field: 'TotalMonto', headerText: 'Total Monto', textAlign: 'Right', format: "N2" }
      ]
    };

    if (this.mesChildTemplate?.elementRef?.nativeElement != null) {
      this.mesChildTemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
      this.mesChildTemplate.elementRef.nativeElement.propName = 'template';
    }

  }

  onRowDataBound(args: RowDataBoundEventArgs): void {
    //(click)="mostrarDetalles(data, $row)"
    console.log("onRowDataBound",args);
    var data = args.data as any;

    const tr = args.row as HTMLTableRowElement;
    console.log("onRowDataBound2",tr.innerHTML);

    tr.classList.add('empty-row');
    tr.innerHTML += `
        <td colspan="5">test</td>
    `;

    console.log("onRowDataBound3",tr.innerHTML);
    // tr.innerHTML = `
    //   <tr class="e-row">
    //     <td class="e-rowcell">
    //       <a style="cursor: pointer; margin-right: 18px">
    //         <mat-icon style="width: 5px; font-size: 18px;" title="Ver detalles por mes">
    //           ${data.VerDetalles ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
    //         </mat-icon>
    //       </a>
    //     </td>
    //     <td class="e-rowcell">${data.Anio}</td>
    //     <td class="e-rowcell">${data.TotalCantidad}</td>
    //     <td class="e-rowcell">${data.MinimoPrecioUnitario}</td>
    //     <td class="e-rowcell">${data.TotalMonto}</td>
    //   </tr>
    //   <tr class="e-row">
    //     <td class="e-rowcell" colspan="5">
    //       <ejs-grid #childGrid2 [dataSource]='${data.Meses}' [allowSorting]="true" [allowResizing]='true'>
    //         <e-columns>
    //           <e-column field='MesNombre' headerText='Mes' width=90></e-column>
    //           <e-column field='TotalCantidad' headerText='Total Cantidad' textAlign='Right' format="N2" width=90></e-column>
    //           <e-column field='MinimoPrecioUnitario' headerText='Min. Precio Unitario' textAlign='Right' format="N2" width=90></e-column>
    //           <e-column field='TotalMonto' headerText='Total Monto' textAlign='Right' format="N2" width=90></e-column>
    //         </e-columns>
    //       </ejs-grid>
    //     </td>
    //   </tr>
    // `;
  }

  mostrarDetalles(item: any) {
    // console.log("mostrarDetalles", item);
    const dialogRef = this.dialog.open(DialogForecastDetalleAnioComponent, {
      width: '80%',
      data: item
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  public onActionBegin(args: any): void {
    if (args.requestType === 'searching') {
      console.log('Inicio de búsqueda');
      this.isSearching = true;
    }
  }

  public onActionComplete(args: any): void {
    if (args.requestType === 'search') {
      console.log('Búsqueda finalizada');
      this.isSearching = false;
    }
  }

  displayProducto(producto: Producto) {
    return producto ? producto?.Codigo +  " - " + producto?.Nombre : "";
  }
  
  getAnioDesdeError() {
    return this.searchForm.get('AnioDesde').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getAnioHastaError() {
    return this.searchForm.get('AnioHasta').hasError('required') ? 'El campo es obligatorio' :'';
  }

  validateSearch() {
    if (!this.searchForm.valid) {
      this.sharedFunctions.showMessage("Por favor revise los filtros de búsqueda", "Reporte Forecast");
      return false;
    }

    if (parseInt(this.searchForm.value.AnioHasta) < parseInt(this.searchForm.value.AnioDesde)) {
      this.sharedFunctions.showMessage("Por favor revise los filtros de búsqueda. La fecha hasta es mayor que la desde", "Reporte Forecast");
      return false;
    }

    // if ((parseInt(this.searchForm.value.AnioHasta) - parseInt(this.searchForm.value.AnioDesde)) != 2) {
    //   this.sharedFunctions.showMessage("Por favor revise los filtros de búsqueda. La diferencia entre los años debe ser de 2 años", "Reporte Forecast");
    //   return false;
    // }

    return true;
  }

  setQueryParams(): Query {
    var q = new Query();

    var index = q.params.findIndex(obj => obj.key === "EstadoLicitacionId");
    if (index >= 0)
      q.params[index].value = this.searchForm.value.EstadoLicitacionId;
    else
      q.addParams("EstadoLicitacionId",this.searchForm.value.EstadoLicitacionId);

    index = q.params.findIndex(obj => obj.key === "ProductoId");
    if (index >= 0)
      q.params[index].value = this.searchForm.value.Producto?.Id;
    else
      q.addParams("ProductoId",this.searchForm.value.Producto?.Id);

    index = q.params.findIndex(obj => obj.key === "Descripcion");
    if (index >= 0)
      q.params[index].value = this.searchForm.value.Descripcion;
    else
      q.addParams("Descripcion",this.searchForm.value.Descripcion);

    index = q.params.findIndex(obj => obj.key === "AnioDesde");
    if (index >= 0)
      q.params[index].value = this.searchForm.value.AnioDesde;
    else
      q.addParams("AnioDesde",this.searchForm.value.AnioDesde);

    index = q.params.findIndex(obj => obj.key === "AnioHasta");
    if (index >= 0)
      q.params[index].value = this.searchForm.value.AnioHasta;
    else
      q.addParams("AnioHasta",this.searchForm.value.AnioHasta);

    return q;
  }

  onExport() {

    if (!this.validateSearch() || !this.canExport)
      return false;
    
    var search = new SearchLicitacionForecastRequest(null, null, null, "", this.searchForm.value.Producto?.Id, null, null, null, null);
    search = Object.assign(search, this.searchForm.value);

    this.isExporting = true;
    this.service.exportReporteForecastFarmacia(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "ForecastFarmacia_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
      anchor.href = fileUrl;
      anchor.click();
    },
    (err: HttpErrorResponse) => {
      this.isExporting = false;
      this.sharedFunctions.handleError(err, true);
    })
  }
}
