<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSideBar()">
            <mat-icon>menu</mat-icon>
        </button>

        <ejs-toast #notificationtoaster showCloseButton=true showProgressBar=true timeOut="10000" [position]="notificationPosition" [animation]="notificationShowAnimation">
        </ejs-toast>

        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <ul fxLayout="row">
                <li>
                    <button mat-icon-button (click)="openUserHelp()">
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </li>
                <li *ngIf="showUserMessageIcon">
                    <button mat-button [matMenuTriggerFor]="messagemenu">
                        <mat-icon>mail_outline</mat-icon>
                        <span *ngIf="totalUnreadMessages > 0" style="margin-left: 5px;">{{totalUnreadMessages}}</span>
                    </button>
                    <mat-menu #messagemenu="matMenu" class="menu-mensajes">
                        <div fxLayout="row stretch" fxLayout.xs="column" fxFlexAlign="center" class="menu-title">
                            <div fxFlex=100 fxFlex.xs=100>
                                <mat-label>Mensajes</mat-label>
                            </div>
                        </div>

                        <mat-divider></mat-divider>

                        <div fxLayout="row stretch" fxLayout.xs="column" class="msg-list">
                            <span *ngIf="isMensajesLoading"><i class="fa fa-spinner fa-spin"></i> Cargando...</span>
                            <ng-container *ngIf="!isMensajesLoading">
                                <a *ngFor="let msg of messageList" (click)="openMessage(msg)" class="{{msg?.Leido ? 'message-read' : 'message-unread'}}">
                                    <div fxFlex=20 class="img-area menu-image-container">
                                        <mat-icon *ngIf="!showUserImage(msg?.UsuarioCreadorImagenUrl)">person_outline</mat-icon>
                                        <img class="photo-msg-item" *ngIf="showUserImage(msg?.UsuarioCreadorImagenUrl)" src="{{msg?.UsuarioCreadorImagenUrl}}">
                                    </div>
                                    <div fxFlex=80 class="msg-area">
                                        <mat-label style="font-weight: bold;">De: {{msg?.UsuarioCreadorNombre}}</mat-label> <br/>
                                        <mat-label>{{msg?.Asunto != null && msg?.Asunto.length > 0 ? msg?.Asunto : '(Sin asunto)'}}</mat-label> <br/>
                                        <span>{{msg?.FechaEnvio | date:'dd/MM/yyyy HH:mm'}}</span>
                                    </div>
                                </a>
                            </ng-container>
                        </div>

                        <mat-divider></mat-divider>

                        <div (click)="openUserMessages()" fxLayout="row stretch" fxLayout.xs="column" fxFlexAlign="center" class="menu-footer">
                            <div fxFlex=100 fxFlex.xs=100>
                                <mat-label>Ver todos</mat-label>
                            </div>
                        </div>
                    </mat-menu>
                </li>
                <li>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon *ngIf="!showUserImage(usuario?.FotoPerfilUrl)">person_outline</mat-icon>
                        <img src="{{usuario?.FotoPerfilUrl}}" *ngIf="showUserImage(usuario?.FotoPerfilUrl)" style="height: 25px; width: 25px; border-radius: 100%; margin-top: 4px;">
                    </button>
                    <mat-menu #menu="matMenu">
                        <div fxLayout="row stretch" fxLayout.xs="column" fxFlexAlign="center" class="menu-title" *ngIf="usuario?.Nombre">
                            <div fxFlex=100 fxFlex.xs=100>
                                <mat-label>{{usuario?.Login}}</mat-label>
                            </div>
                        </div>

                        <mat-divider></mat-divider>

                        <button mat-menu-item (click)="selfEdition()">
                            <mat-icon>person_outline</mat-icon>
                            <span>Ver Mis Datos</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Cerrar Sesión</span>
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
