import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';

import { ParametroSistemaService } from 'src/app/shared/services/parametroSistema.service';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

import { MenuFuncionCodes, ParametroSistema, MenuFuncion, DialogConfirmRequest, DialogConfirmResponse } from 'src/app/shared/model';

@Component({
    selector: 'app-parametrosistemaEdit',
    templateUrl: './parametrosistemaEdit.component.html',
  })

export class ParametroSistemaEditComponent implements OnInit {
  formGroup: FormGroup;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isMenuSearching: boolean = false;
  isSaving: boolean = false;
  isDeleting: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  msgError: string = "";
  menuList: MenuFuncion[];
  parametrosistemaMenuList: MenuFuncion[];
  item: ParametroSistema;

  constructor (
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private menuFuncionService: MenuFuncionService,
    private parametrosistemaService: ParametroSistemaService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuFuncionService.checkMenu(MenuFuncionCodes.PARAM_SISTEMA_EDIT).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = true;

        this.loadView();
      }
      else {
        this.menuFuncionService.checkMenu(MenuFuncionCodes.PARAM_SISTEMA_LIST).subscribe(data => {
          this.isValidating = false;
          if (data != null && data.Success && data?.Result)
            this.loadView();
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();
          }
        },
        (err: HttpErrorResponse) => {
          this.isValidating = false;
          this.sharedFunctions.handleError(err, true);
        });
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    if (this.canEdit) {
      this.buildForm();
    }

    this.loadEntity();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Codigo': [null, [Validators.required, Validators.maxLength(100)]],
      'Valor': [null, [Validators.required, Validators.maxLength(1500)]],
      'PermitirAccederAnonimamente': [false, Validators.required],
      'Comentarios': [null]
    });
  }

  isProcessing() {
    return (this.isSearching || this.isSaving || this.isDeleting);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        var id = Number(params['id']);

        if (id > 0) {
          this.isSearching = true;
          this.parametrosistemaService.get(id).subscribe(data => {
            this.isSearching = false;
            if (data != null && data.Success) {
              this.item = data.Result;

              if (this.item != null && this.item.Id > 0) {
                if (this.canEdit) {
                  this.canDelete = true;
                  this.formGroup.patchValue(this.item);
                }
              }
              else
                this.msgError = "No se encontró el parámetro seleccionado.";
            }
            else
              this.msgError = "Ocurrió un error al intentar obtener los datos del parámetro.";
          },
          (err: HttpErrorResponse) => {
            this.isSearching = false;
            this.sharedFunctions.handleError(err, true);
          })
        }
        else
          this.msgError = "Item no válido.";
      }
      else
      {
        this.item = new ParametroSistema(null, null, null, false, 0);
        this.formGroup.patchValue(this.item);
      }
    });
  }

  getErrorCodigo() {
    var v = this.formGroup.get('Codigo');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorValor() {
    var v = this.formGroup.get('Valor');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 1500 caracteres alcanzado';
    return '';
  }

  getErrorPermitirAccederAnonimamente() {
    return this.formGroup.get('PermitirAccederAnonimamente').hasError('required') ? 'El campo es obligatorio' :'';
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new ParametroSistema(null, null, null, null, null);

    r = 
    {
      ...this.formGroup.value,
      ...
      {
        PermitirAccederAnonimamente: (this.item?.PermitirAccederAnonimamente ?? false),
        Id: (this.item?.Id ?? 0)
      } 
    };

    this.isSaving = true;
    this.parametrosistemaService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onDelete() {

    if (!this.canDelete) 
      return false;

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar el item?", null, null)
    });

    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {

      if (result.Accepted) {
        this.isDeleting = true;
        this.parametrosistemaService.delete(this.item.Id).subscribe(data => {
          this.isDeleting = false;
          if (data != null && data.Success && data.Result) {
            this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
            this.onCancel();
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
          this.isDeleting = false;
          this.sharedFunctions.handleError(err, true);
        })
      }
    });
  }

  onCancel() {
    this.location.back();
  }
}
