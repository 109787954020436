export class ParametroSistema {
    constructor ( 
        public readonly Codigo: string, 
        public readonly Valor: string, 
        public readonly Comentarios: string, 
        public readonly PermitirAccederAnonimamente: boolean, 
        public readonly Id: number  
    ) {}
}

export class SearchParametroSistemaRequestDto {
    constructor( 
        public readonly PageSize: number | null,
        public readonly PageNumber: number | null,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly Codigo: string, 
        public readonly Nombre: string, 
        public readonly VerSoloPermitirAccederAnonimamente: boolean | null
    ){}
}

export class SearchParametroSistemaResponseDto {
    constructor( 
        public readonly Total: number, 
        public readonly Items: ParametroSistema[]
    ){}
}