<mat-card>
  <mat-card-header>
    <mat-card-title>Reporte Forecast Farmaceúticas</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
      <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    </div>

    <ng-container *ngIf="canView">

      <mat-expansion-panel *ngIf="!isValidating">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">search</mat-icon> Filtros de Búsqueda
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="searchForm">
          <div fxLayout="row stretch" fxLayout.xs="column">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Año Desde</mat-label>
                <input matInput type="number" formControlName="AnioDesde" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
                <mat-error *ngIf="!searchForm.controls['AnioDesde'].valid && searchForm.controls['AnioDesde'].touched">
                  {{ getAnioDesdeError() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Año Hasta</mat-label>
                <input matInput type="number" formControlName="AnioHasta" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
                <mat-error *ngIf="!searchForm.controls['AnioHasta'].valid && searchForm.controls['AnioHasta'].touched">
                  {{ getAnioHastaError() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isProductoSearching">Producto</mat-label>
                <mat-label *ngIf="isProductoSearching">Cargando...</mat-label>
                <input type="text" placeholder="Ingrese el código o nombre de producto a buscar" aria-label="Number" matInput
                  formControlName="Producto" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayProducto">
                  <mat-option *ngFor="let option of filteredProductoList" [value]="option">
                    {{option.Codigo}} - {{option.Nombre}}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Descripción</mat-label>
                <input matInput formControlName="Descripcion" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isEstadoSearching">Estados</mat-label>
                <mat-label *ngIf="isEstadoSearching">Cargando...</mat-label>
                <mat-select formControlName="EstadoLicitacionId">
                  <mat-option>Todos</mat-option>
                  <mat-option *ngFor="let s of estadoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </form>

      </mat-expansion-panel>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px">
        <button mat-raised-button (click)="onSearch()" [disabled]="isSearching" color="primary" class="zb-button">{{isSearching ? 'Buscando...' : 'Buscar'}}</button>
        <button mat-raised-button (click)="onExport()" [disabled]="isExporting" *ngIf="canExport" color="primary" class="zb-button">{{isExporting ? 'Exportando...' : 'Exportar'}}</button>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
      </div>

      <ng-container *ngIf="!isSearching">

        <div fxLayout="row stretch" fxLayout.xs="column" >
          <ejs-grid #anioMesGrid [dataSource]='data' [query]='query' [allowPaging]="true" [allowSorting]="true" 
            [toolbar]='toolbarOptions' [selectionSettings]='selectionOptions' [childGrid]='anioMesChildGrid'
            [pageSettings]='pageSettings' [allowResizing]='true' (actionFailure)="onGridActionFailure($event)">
            <e-columns>
              <e-column field='CodigoProducto' headerText='Cód. Prod' width=90></e-column>
              <e-column field='Descripcion' headerText='Descripción' width=90></e-column>
              <e-column field='UnidadMedida' headerText='Medida' width=90></e-column>
              <e-column field='TotalCantidad' headerText='Total Cantidad' textAlign='Right' format="N2" width=90></e-column>
              <e-column field='MinimoPrecioUnitario' headerText='Min. Precio Unitario' textAlign='Right' format="N2" width=90></e-column>
              <e-column field='TotalMonto' headerText='Total Monto' textAlign='Right' format="N2" width=90></e-column>
            </e-columns>
          </ejs-grid>
          <ng-template #mesChildTemplate let-data>
            <a style="cursor: pointer; margin-right: 18px">
              <mat-icon style="width: 5px; font-size: 18px;" (click)="mostrarDetalles(data)" title="Ver detalles por mes">
                remove_red_eye
              </mat-icon>
            </a>
            <!-- {{data.VerDetalles ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}} -->
          </ng-template>

        </div>

      </ng-container>

    </ng-container>

  </mat-card-content>
</mat-card>