import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { MensajeUsuarioService } from 'src/app/shared/services/mensajeUsuario.service';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

import { MenuFuncionCodes, MensajeUsuario, MensajeUsuarioOpcionEnvio, MensajeUsuarioTipo, Rol, Usuario } from 'src/app/shared/model';

@Component({
    selector: 'app-mensajeUsuarioView',
    templateUrl: './mensajeUsuarioView.component.html', 
}) 

export class MensajeUsuarioViewComponent {
    formGroup: FormGroup;

    isValidating: boolean = false;
    isSearching: boolean = false;
    isTipoSearching: boolean = false;
    isRolSearching: boolean = false;
    isAttachmentSearching: boolean = false;
    isOpcionEnvioSearching: boolean = false;
    isSaving: boolean = false;
    isDeleting: boolean = false;
    programarEnvio: boolean = false;
    canEdit: boolean = false;
    canDelete: boolean = false;

    msgError: string = "";
    opcionEnvioList: MensajeUsuarioOpcionEnvio[];
    tipoList: MensajeUsuarioTipo[];
    rolList: Rol[];
    usuarioOrigen: Usuario;
    usuarioDestino: Usuario;
    rolDestino: Rol;
    textoDestino: string = "";
    item: MensajeUsuario;
    fechaEnvioHora: number;
    fechaEnvioMinuto: number;

    constructor (
        private location: Location,
        public dialog: MatDialog,
        private sharedFunctions: SharedFunctions,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private menuFuncionService: MenuFuncionService,
        private mensajeUsuarioService: MensajeUsuarioService
    ) {
    }

    ngOnInit(): void {
        this.isValidating = true;
        this.menuFuncionService.checkMenu(MenuFuncionCodes.MENSAJE_USUARIO_LIST).subscribe(data => {
            this.isValidating = false;
            if (data != null && data.Success && data?.Result)
                this.loadView();
            else {
                this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                this.onCancel();
            }
        },
        (err: HttpErrorResponse) => {
            this.isValidating = false;
            this.sharedFunctions.handleError(err, true);
        });
    }

    loadView() {
        this.loadEntity();
    }

    loadEntity() {
        this.route.params.subscribe(params => {
            if (params['id'] != null && params['id'] != '0') {
                var id = Number(params['id']);

                if (id > 0) {
                    this.isSearching = true;
                    this.mensajeUsuarioService.get(id).subscribe(data => {
                        this.isSearching = false;
                        if (data != null && data.Success) {
                            this.item = data.Result;

                            if (this.item != null && this.item.Id > 0) {
                                var loggedUser = this.sharedFunctions.getLoggedUser();

                                if (this.item.UsuarioDestinoId != loggedUser.Id) {
                                    this.item = null;
                                    this.msgError = "No se encontró el mensaje seleccionado";
                                    return false;
                                }
                                
                                this.item.MensajeHtml = this.sanitizer.bypassSecurityTrustHtml(this.item.Mensaje);
                                this.markMessageAsRead(this.item.Id);
                                this.loadAttachments(this.item.Id);
                            }
                            else
                                this.msgError = "No se encontró el mensaje seleccionado.";
                        }
                        else
                            this.msgError = "Ocurrió un error al intentar obtener los datos del mensaje.";
                    },
                    (err: HttpErrorResponse) => {
                        this.isSearching = false;
                        this.sharedFunctions.handleError(err, true);
                    })
                }
                else
                    this.msgError = "Item no válido.";
            }
            else
                this.msgError = "Debe seleccionar un mensaje para visualizarlo.";
        });
    }

    markMessageAsRead(id) {
        this.mensajeUsuarioService.markAsRead(id).subscribe(data => {
            if (data != null && data.Success)
                this.mensajeUsuarioService.informMessageRead(id);
        },
        (err: HttpErrorResponse) => {
            this.sharedFunctions.handleError(err);
        })
    }

    loadAttachments(id) {
        this.isAttachmentSearching = true;
        this.mensajeUsuarioService.getAttachments(id).subscribe(data => {
            this.isAttachmentSearching = false;
            if (data != null && data.Success)
                this.item.Adjuntos = data.Result?.filter(f => !f.IncluidoEnCuerpoMensaje);
        },
        (err: HttpErrorResponse) => {
            this.isAttachmentSearching = false;
            this.sharedFunctions.handleError(err);
        })
    }

    onCancel() {
        this.location.back();
    }
}