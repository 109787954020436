<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Configuración de Mail</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && item != null">

      <ng-container *ngIf="canEdit">

        <form [formGroup]="formGroup">

          <div fxLayout="row stretch" fxLayout.xs="column">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Código*</mat-label>
                <input matInput formControlName="Codigo" (keyup.enter)="onGuardar()" maxlength="100">
                <mat-error *ngIf="!formGroup.controls['Codigo'].valid && formGroup.controls['Codigo'].touched">
                  {{ getErrorCodigo() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isProveedorEnvioSearching">Forma Envio*</mat-label>
                <mat-label *ngIf="isProveedorEnvioSearching">Cargando...</mat-label>
                <mat-select formControlName="ProveedorEnvio">
                  <mat-option *ngFor="let s of proveedorEnvioList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.controls['ProveedorEnvio'].valid && formGroup.controls['ProveedorEnvio'].touched">
                  {{ getErrorProveedorEnvio() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Mail Saliente*</mat-label>
                <input matInput formControlName="MailSaliente" (keyup.enter)="onGuardar()" maxlength="255">
                <mat-error *ngIf="!formGroup.controls['MailSaliente'].valid && formGroup.controls['MailSaliente'].touched">
                  {{ getErrorMailSaliente() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre de Mail Saliente</mat-label>
                <input matInput formControlName="MailSalienteDisplayName" (keyup.enter)="onGuardar()" maxlength="255">
                <mat-error *ngIf="!formGroup.controls['MailSalienteDisplayName'].valid && formGroup.controls['MailSalienteDisplayName'].touched">
                  {{ getErrorMailSalienteDisplayName() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Mail al cual responder</mat-label>
                <input matInput formControlName="MailResponderA" (keyup.enter)="onGuardar()" maxlength="255">
                <mat-error *ngIf="!formGroup.controls['MailResponderA'].valid && formGroup.controls['MailResponderA'].touched">
                  {{ getErrorMailResponderA() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            </div>

            <hr style="margin: 10px 0px; width: 100%;">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="checkIfGoogleWorkSpace()">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Servidor Mail<span *ngIf="formGroup.controls['ProveedorEnvio'].value!=3">*</span></mat-label>
                <input matInput formControlName="ServidorMail" (keyup.enter)="onGuardar()" maxlength="300" maxlength="300">
                <mat-error *ngIf="!formGroup.controls['ServidorMail'].valid && formGroup.controls['ServidorMail'].touched">
                  {{ getErrorServidorMail() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="checkIfGoogleWorkSpace()">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Puerto Servidor Mail<span *ngIf="formGroup.controls['ProveedorEnvio'].value!=3">*</span></mat-label>
                <input matInput type="number" formControlName="PuertoServidorMail" (keyup.enter)="onGuardar()">
                <mat-error *ngIf="!formGroup.controls['PuertoServidorMail'].valid && formGroup.controls['PuertoServidorMail'].touched">
                  {{ getErrorPuertoServidorMail() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Usuario*</mat-label>
                <input matInput formControlName="Usuario" (keyup.enter)="onGuardar()" maxlength="255">
                <mat-error *ngIf="!formGroup.controls['Usuario'].valid && formGroup.controls['Usuario'].touched">
                  {{ getErrorUsuario() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="checkIfGoogleWorkSpace()">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Clave<span *ngIf="formGroup.controls['ProveedorEnvio'].value!=3">*</span></mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="Clave" (keyup.enter)="onGuardar()" maxlength="255">
                <button mat-icon-button matSuffix (click)="hide = !hide" type="button"><mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon></button>
                <mat-error *ngIf="!formGroup.controls['Clave'].valid && formGroup.controls['Clave'].touched">
                  {{ getErrorClave() }}
                </mat-error>
              </mat-form-field>
              <p style="font-size: 12px; margin-top: -15px; margin-left: 5px;">Si está configurando una cuenta de Gmail personal, tenga en cuenta este <a href="https://support.google.com/mail/answer/185833?hl=es-419" target="_blank">artículo</a>.</p>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item" style="margin-top: 10px" *ngIf="!checkIfGoogleWorkSpace()">
              <div fxFlex=40 fxFlex.xs=100 class="flex-item" style="margin-left:-10px">
                <button type="button" mat-raised-button [disabled]="isProcessing()" (click)="fileInput.click()">Subir Certificado Externo</button>
                <input hidden type="file" (change)="onNewFileAttach($event)" accept="application/json,.p12" #fileInput color="primary">
              </div>
              <div fxFlex=55 fxFlex.xs=100 class="flex-item" *ngIf="item.CertificadoExternoUrl?.length > 0" style="margin-top: 10px;">
                <mat-label>
                  <a href="{{item.CertificadoExternoUrl}}" target="_blank">{{certificadoExternoFileName}}</a>
                </mat-label>
              </div>
              <div fxFlex=5 fxFlex.xs=100 class="flex-item" *ngIf="item.CertificadoExternoUrl?.length > 0">
                <button mat-icon-button matSuffix (click)="deleteCertificado()" type="button" title="Eliminar certificado"><mat-icon>delete</mat-icon></button>
              </div>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="!checkIfGoogleWorkSpace()">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Clave de Certificado Externo<span *ngIf="formGroup.controls['ProveedorEnvio'].value==3">*</span></mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="ClaveCertificadoExterno" (keyup.enter)="onGuardar()" maxlength="255">
                <button mat-icon-button matSuffix (click)="hide = !hide" type="button"><mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon></button>
                <mat-error *ngIf="!formGroup.controls['ClaveCertificadoExterno'].valid && formGroup.controls['ClaveCertificadoExterno'].touched">
                  {{ getErrorClaveCertificadoExterno() }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

        </form>

        <div fxLayout="row stretch" fxLayout.xs="column">
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" style="margin-top: 0px; margin-bottom: 20px;">
              <mat-checkbox [(ngModel)]="item.Activo" (keyup.enter)="onGuardar()" color="primary">Activo</mat-checkbox> 
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item" style="margin-top: 0px; margin-bottom: 20px;" *ngIf="checkIfGoogleWorkSpace()">
              <mat-checkbox [(ngModel)]="item.HabilitarSsl" [ngModelOptions]="{standalone: true}" (keyup.enter)="onGuardar()" color="primary">Habilitar SSL</mat-checkbox> 
            </div>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="!canEdit">

        <div fxLayout="row stretch" class="zb-readonly">

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Código</b>: {{item.Codigo}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Forma Envio</b>: {{item.ProveedorEnvioNombre}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Mail Saliente</b>: {{item.MailSaliente}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Nombre de Mail Saliente</b>: {{item.MailSalienteDisplayName}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Mail al cual responder</b>: {{item.MailResponderA}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
          </div>

          <hr style="margin: 10px 0px; width: 100%;">

          <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="checkIfGoogleWorkSpace()">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Servidor Mail</b>: {{item.ServidorMail}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="checkIfGoogleWorkSpace()">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Puerto Servidor Mail</b>: {{item.PuertoServidorMail}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Usuario Servidor Mail</b>: {{item.Usuario}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="checkIfGoogleWorkSpace()">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Clave Servidor Mail</b>: {{item.Clave}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>URL de Certificado Externo</b>: <a href="{{item.CertificadoExternoUrl}}" target="_blank">{{item.CertificadoExternoUrl}}</a></mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Clave de Certificado Externo</b>: {{item.ClaveCertificadoExterno}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="checkIfGoogleWorkSpace()">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Habilitar SSL</b>: {{item.HabilitarSsl ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Activo</b>: {{item.Activo ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </ng-container>

    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row" fxLayoutGap="10px" style="margin: 10px 0px;">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="onGuardar()" *ngIf="canEdit" [disabled]="isProcessing()" color="primary" class="zb-button">{{isSaving ? 'Guardando...' : 'Guardar'}}</button>
        <button mat-raised-button (click)="onEmailTest()" *ngIf="canEdit" [disabled]="isProcessing()" color="accent" class="zb-button">{{isSendingTestMail ? 'Enviando...' : 'Enviar Mail de Prueba'}}</button>
        <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canEdit ? 'Cancelar' : 'Cerrar'}}</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" style="flex-grow: 1;">
        <button mat-raised-button (click)="onDelete()" [disabled]="isProcessing()" *ngIf="canDelete" color="warn" class="zb-button">{{isDeleting ? 'Eliminando...' : 'Eliminar'}}</button>
      </div>
    </div>
  </mat-card-actions>

</mat-card>