import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { SharedFunctions } from 'src/app/shared/shared.functions';

import { MensajeUsuarioService } from 'src/app/shared/services/mensajeUsuario.service';
import { MensajeUsuario } from 'src/app/shared/model';

@Component({
    selector: 'app-dialogMensajePopUp',
    templateUrl: 'dialogMensajePopUp.component.html',
    styleUrls: ['./dialogMensajePopUp.component.css']
})

export class DialogMensajePopUpComponent implements OnInit {

  public item: MensajeUsuario;
  isSearching: boolean = false;
  isAttachmentSearching: boolean = false;
  msgError: string;

  constructor(
    private dialogRef: MatDialogRef<DialogMensajePopUpComponent>,
    private sharedFunctions: SharedFunctions,
    private mensajeUsuarioService: MensajeUsuarioService,
    @Inject(MAT_DIALOG_DATA) public data: number) { 

  }

  ngOnInit() {
    this.loadEntity(this.data);
  }

  loadEntity(id: number) {
    if (id > 0) {
        this.isSearching = true;
        this.mensajeUsuarioService.get(id).subscribe(data => {
            this.isSearching = false;
            if (data != null && data.Success) {
                this.item = data.Result;

                if (this.item != null && this.item.Id > 0) {
                    var loggedUser = this.sharedFunctions.getLoggedUser();

                    if (this.item.UsuarioDestinoId != loggedUser.Id) {
                        this.item = null;
                        this.msgError = "No se encontró el mensaje seleccionado";
                        return false;
                    }
                    
                    this.markMessageAsRead(this.item.Id);
                    this.loadAttachments(this.item.Id);
                }
                else
                    this.msgError = "No se encontró el mensaje seleccionado.";
            }
            else
                this.msgError = "Ocurrió un error al intentar obtener los datos del mensaje.";
        },
        (err: HttpErrorResponse) => {
            this.isSearching = false;
            this.sharedFunctions.handleError(err, true);
        })
    }
    else
      this.msgError = "Item no válido.";
  }

  loadAttachments(id) {
    this.isAttachmentSearching = true;
    this.mensajeUsuarioService.getAttachments(id).subscribe(data => {
        this.isAttachmentSearching = false;
        if (data != null && data.Success)
            this.item.Adjuntos = data.Result?.filter(f => !f.IncluidoEnCuerpoMensaje);
    },
    (err: HttpErrorResponse) => {
        this.isAttachmentSearching = false;
        this.sharedFunctions.handleError(err);
    })
  }

  markMessageAsRead(id) {
    this.mensajeUsuarioService.markAsRead(id).subscribe(data => {
      if (data != null && data.Success)
        this.mensajeUsuarioService.informMessageRead(id);
    },
    (err: HttpErrorResponse) => {
        this.sharedFunctions.handleError(err);
    })
  }

  onClose() {
    this.dialogRef.close(null);
  }
}