export class Tenant {
  constructor(
    public readonly Id: number,
    public readonly Nombre: string,
    public readonly NombreCorto: string,
    public readonly NroDocumento: string,
    public readonly Direccion: string,
    public readonly Telefono: string,
    public readonly Email: string,
    public readonly SitioWeb: string,
    public readonly LogoUrl: string,
    public readonly Activo: boolean
  ) {}
}

export class SearchTenantRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Nombre: string,
    public readonly NroDocumento: string,
    public readonly VerSoloActivos: boolean | null
  ) {}
}

export class SaveTenantDto {
  constructor(
    public Id: number,
    public Nombre: string,
    public NombreCorto: string,
    public NroDocumento: string,
    public Direccion: string,
    public Telefono: string,
    public Email: string,
    public SitioWeb: string,
    public FotoLogoBase64: string,
    public FotoLogoExtension: string,
    public Activo: boolean
  ) {}
}

export class SearchTenantResponse {
  constructor(
    public readonly count: number,
    public readonly result: Tenant[]
  ) {}
}
