<mat-card>
  <mat-card-header>
    <mat-card-title>Bandeja de {{verEnviados ? 'Salida' : 'Entrada'}} de Mensajes</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
      <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    </div>

    <ng-container *ngIf="canView">

      <mat-expansion-panel>

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">search</mat-icon> Filtros de Búsqueda
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="searchForm">
          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" *ngIf="!verEnviados">
                <mat-label>Nombre/Apellido/Usuario del Remitente</mat-label>
                <input matInput formControlName="UsuarioCreador" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 100%" *ngIf="verEnviados">
                <mat-label>Nombre/Apellido/Usuario del Destinatario</mat-label>
                <input matInput formControlName="UsuarioDestino" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Asunto del Mensaje</mat-label>
                <input matInput formControlName="Asunto" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%" >
                <mat-label>Fecha {{verEnviados ? 'Envio' : 'Recepción'}} Desde</mat-label>
                <input matInput [matDatepicker]="fechaEnvioDesdePicker" formControlName="FechaEnvioDesde" readonly (click)="fechaEnvioDesdePicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaEnvioDesdePicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaEnvioDesde')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaEnvioDesdePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Fecha {{verEnviados ? 'Envio' : 'Recepción'}} Hasta</mat-label>
                <input matInput [matDatepicker]="fechaEnvioHastaPicker" formControlName="FechaEnvioHasta" readonly (click)="fechaEnvioHastaPicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaEnvioHastaPicker">
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate('FechaEnvioHasta')">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fechaEnvioHastaPicker></mat-datepicker>
              </mat-form-field>
            </div>
            
          </div>
        </form>

        <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px" *ngIf="!verEnviados">
          <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="end">
            <mat-checkbox [(ngModel)]="verSoloNoLeidos" (keyup.enter)="onSearch()" color="primary">Ver solo no leídos</mat-checkbox> 
          </div>
        </div>

      </mat-expansion-panel>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px">
        <button mat-raised-button (click)="add()" *ngIf="canAdd" [disabled]="isSearching" class="zb-button">Nuevo Mensaje</button>
        <button mat-raised-button (click)="onSearch()" [disabled]="isSearching" color="primary" class="zb-button">{{isSearching ? 'Buscando...' : 'Buscar'}}</button>
        <!-- <button mat-raised-button (click)="onExport()" [disabled]="isExporting" color="primary" class="zb-button">{{isExporting ? 'Exportando...' : 'Exportar'}}</button> -->
        <button mat-raised-button (click)="onSentSearch()" [disabled]="isSearching" color="accent" class="zb-button">{{verEnviados ? 'Ver Recibidos' : 'Ver Enviados'}}</button>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" *ngIf="!isSearching">
        <ejs-grid #grid [dataSource]='data' [query]='query' [allowPaging]="true" [allowSorting]="true" [toolbar]='toolbarOptions' [selectionSettings]='selectionOptions' 
          [pageSettings]='pageSettings' [allowResizing]='true' (actionFailure)="onGridActionFailure($event)">
          <e-columns>
            <e-column field='FechaEnvio' headerText='Fecha' width=60></e-column>
            <e-column field='UsuarioNombre' headerText='Nombre' width=90></e-column>
            <e-column field='UsuarioLogin' headerText='Usuario' width=90></e-column>
            <e-column field='Asunto' headerText='Asunto' width=120></e-column>
            <e-column headerText='Acciones' width=40>
              <!-- <ng-template #template let-data>
                <a style="cursor: pointer; margin-right: 5px" (click)="view(data)"><i class="fa fa-eye fa-1x"></i></a>
              </ng-template> -->
            </e-column>
          </e-columns>
          <ng-template #rowTemplate let-data>
            <tr class="e-row {{verEnviados ? (data.Enviado ? 'message-sent' : 'message-pending-sent') : (data.Leido ? 'message-read' : 'message-unread')}}">
              <td class="e-rowcell">{{data.FechaEnvio | date:'dd/MM/yyyy HH:mm:ss'}}</td>
              <td class="e-rowcell">{{data.UsuarioNombre}}</td>
              <td class="e-rowcell">{{data.UsuarioLogin}}</td>
              <td class="e-rowcell">{{data.Asunto}}</td>
              <td class="e-rowcell">
                <a style="cursor: pointer; margin-right: 5px" (click)="view(data)"><i class="fa fa-eye fa-1x"></i></a>
              </td>
            </tr>
          </ng-template>
        </ejs-grid>
      </div>

    </ng-container>

  </mat-card-content>
</mat-card>

