import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, MensajeUsuarioTipo, SearchMensajeUsuarioTipoRequest, SearchMensajeUsuarioTipoResponse, SaveMensajeUsuarioTipoDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class MensajeUsuarioTipoService extends CrudService<MensajeUsuarioTipo, number, SaveMensajeUsuarioTipoDto, SearchMensajeUsuarioTipoRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'mensajeusuariotipo', sharedFunctions);

  }

  getAll(): Observable<GenericResponse<MensajeUsuarioTipo[]>> {
    return this._http.get<GenericResponse<MensajeUsuarioTipo[]>>(this._serviceUrl + '', this.httpOptions);
  }

  // search(p: SearchMensajeUsuarioTipoRequest): Observable<SearchMensajeUsuarioTipoResponse> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.Codigo != null && p.Codigo.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Codigo=" + p.Codigo;

  //     if (p.VerSoloActivos != null && p.VerSoloActivos.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;

  //     if (p.RolId != null && p.RolId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "RolId=" + p.RolId;
  //   }

  //   return this._http.get<SearchMensajeUsuarioTipoResponse>(this.getSearchUrl()+parameters, this.httpOptions);
  // }

  // exportSearch(p: SearchMensajeUsuarioTipoRequest): Observable<any> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.Codigo != null && p.Codigo.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Codigo=" + p.Codigo;

  //     if (p.VerSoloActivos != null && p.VerSoloActivos.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;

  //     if (p.RolId != null && p.RolId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "RolId=" + p.RolId;
  //   }
    
  //   return this._http.get<any>(this._serviceUrl + '/export-search'+parameters, this.httpCsvOptions);
  // }
}