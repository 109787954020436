<h2 mat-dialog-title>Importar Archivo de Licitaciones</h2>

<div mat-dialog-content>

  <div fxLayout="row stretch" fxLayout.xs="column">
    <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="center">
      <button type="button" mat-raised-button [disabled]="isImporting" (click)="fileInput.click()">Adjuntar archivo</button>
      <input hidden type="file" (change)="convertFileToBase64($event)" accept=".csv" #fileInput color="primary">
    </div>
    
    <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="center">
      {{fileName}}
    </div>

    <hr width="100%" style="margin: 10px 0px;">

    <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="center">
      <mat-form-field appearance="outline" style="width: 30%">
        <mat-label>Caracter Separador</mat-label>
        <input matInput [(ngModel)]="caracterSeparador" maxlength="2">
      </mat-form-field>
    </div>
    <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="center">
      <mat-checkbox [(ngModel)]="saltearPrimeraLinea" color="primary">La primera línea del archivo contiene los nombres de las columnas</mat-checkbox> 
    </div>
    <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="center">
      <mat-checkbox [(ngModel)]="actualizarAsignados" color="primary">Actualizar los productos ya asignados con los que figuren en el archivo adjuntado</mat-checkbox> 
    </div>

  </div>

</div>

<br/>

<div mat-dialog-actions>
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="primary" (click)="onImportar()" class="zb-button" [disabled]="isImporting">
      {{isImporting ? 'Importando...' : 'Importar Archivo'}}
    </button>
    <button mat-raised-button color="warn" (click)="onCancel()" class="zb-button">Cerrar</button>
  </div>
</div>