import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { CrudService } from "./crud.service";
import { LicitacionDto, SearchLicitacionRequest, SaveLicitacionDto, SearchLicitacionDetalleLicitacionesRequest, SearchLicitacionDetalleLicitacionesResponse, SaveEspecificacionTecnicaProductoDto, GenericResponse, ProcessLicitacionesFromWebDto, ProcessLicitacionesFromWebResultDto, LicitacionEspecificacionTecnicaDto, LicitacionEspecificacionTecnicaResponse, ImportArchivoEspecificacionesTecnicasRequestDto, ImportArchivoEspecificacionesTecnicasResponseDto } from "../model/index";
import { SearchLicitacionForecastRequest, SearchLicitacionForecastResponse } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class LicitacionService extends CrudService<LicitacionDto, number, SaveLicitacionDto, SearchLicitacionRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'licitacion', sharedFunctions);

  }

  getReporteDetalleLicitacionesUrl() {
    return this._serviceUrl + '/report-detalle-licitaciones';
  }

  getReporteDetalleLicitaciones(p: SearchLicitacionDetalleLicitacionesRequest): Observable<SearchLicitacionDetalleLicitacionesResponse> {
    var parameters = this.sharedFunctions.convertToQueryString(p);
    return this._http.get<SearchLicitacionDetalleLicitacionesResponse>(this.getReporteDetalleLicitacionesUrl()+parameters, this.httpOptions);
  }

  exportReporteDetalleLicitaciones(value: SearchLicitacionDetalleLicitacionesRequest): Observable<any> {
    var queryString = this.sharedFunctions.convertToQueryString(value);
    return this._http.get<any>(this._serviceUrl + '/export-report-detalle-licitaciones' + queryString, this.httpCsvOptions);
  }
  
  getEspecificacionesTecnicasByLicitacionId(id: number): Observable<GenericResponse<LicitacionEspecificacionTecnicaResponse>> {
    return this._http.get<GenericResponse<LicitacionEspecificacionTecnicaResponse>>(this._serviceUrl + '/' + id + '/especificaciones-tecnicas');
  }
  
  getLicitacionById(id: number): Observable<GenericResponse<LicitacionDto>>{
    return this._http.get<GenericResponse<LicitacionDto>>(this._serviceUrl + '/' + id);
  }

  getReporteDetalleLicitacionesFarmaciaUrl() {
    return this._serviceUrl + '/report-detalle-licitaciones-farmacia';
  }

  getReporteDetalleLicitacionesFarmacia(p: SearchLicitacionDetalleLicitacionesRequest): Observable<SearchLicitacionDetalleLicitacionesResponse> {
    var parameters = this.sharedFunctions.convertToQueryString(p);
    return this._http.get<SearchLicitacionDetalleLicitacionesResponse>(this.getReporteDetalleLicitacionesFarmaciaUrl()+parameters, this.httpOptions);
  }

  exportReporteDetalleLicitacionesFarmacia(value: SearchLicitacionDetalleLicitacionesRequest): Observable<any> {
    var queryString = this.sharedFunctions.convertToQueryString(value);
    return this._http.get<any>(this._serviceUrl + '/export-report-detalle-licitaciones-farmacia' + queryString, this.httpCsvOptions);
  }

  getReporteForecastFarmaciaUrl() {
    return this._serviceUrl + '/report-forecast-farmacia';
  }

  getReporteForecastFarmaciaItemUrl() {
    return this._serviceUrl + '/report-forecast-farmacia-item';
  }

  getReporteForecastFarmacia(p: SearchLicitacionForecastRequest): Observable<SearchLicitacionForecastResponse> {
    var parameters = this.sharedFunctions.convertToQueryString(p);
    return this._http.get<SearchLicitacionForecastResponse>(this.getReporteForecastFarmaciaUrl()+parameters, this.httpOptions);
  }

  exportReporteForecastFarmacia(value: SearchLicitacionForecastRequest): Observable<any> {
    var queryString = this.sharedFunctions.convertToQueryString(value);
    return this._http.get<any>(this._serviceUrl + '/export-report-forecast-farmacia' + queryString, this.httpCsvOptions);
  }

  asignarProducto(value: SaveEspecificacionTecnicaProductoDto): Observable<any> {
    return this._http.post<SaveEspecificacionTecnicaProductoDto>(this._serviceUrl + '/asignar-producto', value, this.httpOptions);
  }

  importarLicitaciones(value: ProcessLicitacionesFromWebDto): Observable<GenericResponse<ProcessLicitacionesFromWebResultDto>> {
    return this._http.post<GenericResponse<ProcessLicitacionesFromWebResultDto>>(this._serviceUrl + '/import-from-web', value, this.httpOptions);
  }

  importarArchivoLicitaciones(value: ImportArchivoEspecificacionesTecnicasRequestDto): Observable<GenericResponse<ImportArchivoEspecificacionesTecnicasResponseDto>> {
    return this._http.post<GenericResponse<ImportArchivoEspecificacionesTecnicasResponseDto>>(this._serviceUrl + '/import-archivo-licitaciones', value, this.httpOptions);
  }
}