import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { LicitacionCategoriaService, MenuFuncionService } from 'src/app/shared/services/index';
import { MenuFuncionCodes, RolTipo, SaveLicitacionCategoriaDto, LicitacionCategoria, DialogConfirmRequest, DialogConfirmResponse, SearchLicitacionCategoriaRequest } from 'src/app/shared/model';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { isNumber } from 'highcharts';

@Component({
  selector: 'app-licitacionCategoriaEdit',
  templateUrl: './licitacionCategoriaEdit.component.html',
})

export class LicitacionCategoriaEditComponent implements OnInit {
  formGroup: FormGroup;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isSaving: boolean = false;
  isDeleting: boolean = false;
  isLicitacionCategoriaPadreSearching: boolean = false;

  canView: boolean = false;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  msgError: string = "";
  filteredCategoriaPadreList: LicitacionCategoria[];
  item: LicitacionCategoria;

  constructor (
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private licitacionCategoriaService: LicitacionCategoriaService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.LICITACION_CATEGORIA).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.LICITACION_CATEGORIA_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.LICITACION_CATEGORIA_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.LICITACION_CATEGORIA_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.LICITACION_CATEGORIA_DELETE);
        this.loadView();
      } 
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();        
      }  
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.buildForm();
    this.getLicitacionCategoriaPadres();

    this.loadEntity();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Codigo': [null, [Validators.required, Validators.maxLength(100)]], 
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'LicitacionCategoriaPadre': [null],
      'LicitacionCategoriaPadreId': [null]
    });
  }

  getLicitacionCategoriaPadres() {
    this.formGroup.controls.LicitacionCategoriaPadre.valueChanges
      .pipe(
        filter(res => {
          return res && res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCategoriaPadreList = [];
          this.isLicitacionCategoriaPadreSearching = true;
        }),
        switchMap(value => this.licitacionCategoriaService.search(new SearchLicitacionCategoriaRequest(20, 0, true, "Codigo", value?.toString(), null, null, true))
          .pipe(
            finalize(() => {
              this.isLicitacionCategoriaPadreSearching = false;
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data != null && data.result != null)
          this.filteredCategoriaPadreList = data.result;
        else
          this.filteredCategoriaPadreList = [];
      });
  }

  displayCategoriaPadre(item: LicitacionCategoria) {
    return item ? item?.Codigo : "";
  }

  isProcessing() {
    return (this.isSearching || this.isSaving);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        var id = Number(params['id']);

        if (id > 0) {
          if (this.canView || this.canEdit){
            this.isSearching = true;
            this.licitacionCategoriaService.get(id).subscribe(data => {
              this.isSearching = false;
              if (data != null && data.Success) {
                this.item = data.Result;

                if (this.item != null && this.item.Id > 0) {
                  if (this.canEdit) {
                    this.formGroup.patchValue(this.item);
                  }
                }
                else
                  this.msgError = "No se encontró el menú seleccionado.";
              }
              else
                this.msgError = "Ocurrió un error al intentar obtener los datos del menú.";
            },
            (err: HttpErrorResponse) => {
              this.isSearching = false;
              this.sharedFunctions.handleError(err, true);
            })
          }
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();
          }
        }
        else
          this.msgError = "Item no válido.";
      }
      else {
        if (this.canAdd) {
          this.item = new LicitacionCategoria(0, null, null, null, null, true);
          this.formGroup.patchValue(this.item);
        } 
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.onCancel(); 
        }
      }
    });
  }

  getErrorCodigo() {
    var v = this.formGroup.get('Codigo');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorNombre() {
    var v = this.formGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new SaveLicitacionCategoriaDto(0, null, null, null, true);

    r = 
    {
      ...this.formGroup.value,
      ...
      {
        Id: (this.item?.Id ?? 0),
        LicitacionCategoriaPadreId: this.formGroup.get("LicitacionCategoriaPadre")?.value?.Id,
        Activo: this.item.Activo
      } 
    };

    if (r.LicitacionCategoriaPadreId <= 0)
      r.LicitacionCategoriaPadreId = null;

    this.isSaving = true;
    this.licitacionCategoriaService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onCancel() {
    this.location.back();
  }

  navigateLicitacionCategoriaPadre(item: LicitacionCategoria) {
    if (item != null && item.Id > 0)
      this.router.navigate(['licitacion-categoria-edit', item.Id]);
  }

  onDelete() {

    if (!this.canDelete) 
        return false;

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar la categoria?", null, null)
    });

    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {

      if (result.Accepted) {
        this.isDeleting = true;
        this.licitacionCategoriaService.delete(this.item.Id).subscribe(data => {
          this.isDeleting = false;
          if (data != null && data.Success && data.Result) {
            this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
            this.onCancel();
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
            this.isDeleting = false;
            this.sharedFunctions.handleError(err, true);
        })
      }
    });
  }
}
