import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { DialogTextoLibreRequest, DialogTextoLibreResponse } from 'src/app/shared/model';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Component({
    selector: 'app-dialogTextoLibre',
    templateUrl: 'dialogTextoLibre.component.html',
    styleUrls: ['./dialogTextoLibre.component.css']
})

export class DialogTextoLibreComponent implements OnInit {

  public title: string;
  public message: string;
  public buttonAcceptText: string;
  public buttonCancelText: string;
  public result: string;
  public maxlength: number = 1000;

  constructor(
    private dialogRef: MatDialogRef<DialogTextoLibreComponent>,
    private sharedFunctions: SharedFunctions,
    @Inject(MAT_DIALOG_DATA) public data: DialogTextoLibreRequest) { 

  }

  ngOnInit() {
    this.title = this.data?.Title;
    this.message = this.data?.Message;
    this.maxlength = this.data?.Maxlength;

    this.buttonAcceptText = this.data?.ButtonTextAccept ?? 'Aceptar';
    this.buttonCancelText = this.data?.ButtonTextCancel ?? 'Cancelar';
  }

  onAccept() {
    if (this.message != null && this.message.length > this.maxlength) {
      this.sharedFunctions.showMessage("No puede ingresar más de " + this.maxlength + " caracteres" , "Error");
      return false;
    }

    this.dialogRef.close(new DialogTextoLibreResponse(this.result, false));
  }

  onDecline() {
    this.dialogRef.close(new DialogTextoLibreResponse(null, true));
  }
}