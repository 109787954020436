import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, Tenant, SearchTenantRequest, SearchTenantResponse, SaveTenantDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends CrudService<Tenant, number, SaveTenantDto, SearchTenantRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'tenant', sharedFunctions);

  }

  // search(p: SearchTenantRequest): Observable<SearchTenantResponse> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.NroDocumento != null && p.NroDocumento.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "NroDocumento=" + p.NroDocumento;

  //     if (p.VerSoloActivos != null && p.VerSoloActivos.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;
  //   }

  //   return this._http.get<SearchTenantResponse>(this.getSearchUrl()+parameters, this.httpOptions);
  // }

  // exportSearch(p: SearchTenantRequest): Observable<any> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.NroDocumento != null && p.NroDocumento.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "NroDocumento=" + p.NroDocumento;

  //     if (p.VerSoloActivos != null && p.VerSoloActivos.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;
  //   }
    
  //   return this._http.get<any>(this._serviceUrl + '/export-search'+parameters, this.httpCsvOptions);
  // }

  getAnonymousList(): Observable<GenericResponse<Tenant[]>> {
    return this._http.get<GenericResponse<Tenant[]>>(this._serviceUrl+"/anonymous", this.httpOptions);
  }
}