import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DialogConfirmRequest, DialogConfirmResponse, Estado, LicitacionCategoria, LicitacionDto, LicitacionEspecificacionTecnicaDto, ProcessLicitacionesFromWebDto, Producto, SearchEstadoRequest, SearchLicitacionCategoriaRequest, SearchProductoRequestDto } from 'src/app/shared/model';
import { EstadoService, LicitacionCategoriaService, LicitacionService, MenuFuncionService, ProductoService } from 'src/app/shared/services';
import { SharedFunctions } from 'src/app/shared/shared.functions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location, DatePipe } from '@angular/common';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { GridPageSettings, MenuFuncionCodes } from 'src/app/shared/model';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { isNumber } from 'highcharts';

@Component({
  selector: 'app-licitacion-edit',
  templateUrl: './licitacion-edit.component.html',
  styleUrls: ['./licitacion-edit.component.scss'],
  viewProviders: [MatExpansionPanel]
})
export class LicitacionEditComponent implements OnInit {

  @ViewChild('especificacionTecnicaGrid')
  public especificacionTecnicaGrid: GridComponent;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isSaving: boolean = false;
  isDeleting: boolean = false;
  isEstadoSearching: boolean = false;
  isLicitacionCategoriaSearching: boolean = false;
  isLicitacionProductoSearching: boolean = false;
  isImporting: boolean = false;

  expandEspecificacionesTecnicas: boolean = false;

  canView: boolean = false;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canImport: boolean = false;

  licitacionId: number;
  licitacion : LicitacionDto;
  itemEspecificacionTecnica: LicitacionEspecificacionTecnicaDto;
  msgError: string = "";

  isEspecificacionesTecnicasSearching: boolean = false;
  especificacionesTecnicasMsgError : string = "";
  especificacionTecnicaList: LicitacionEspecificacionTecnicaDto[];

  licitacionFormGroup: FormGroup;
  especificacionTecnicaFormGroup: FormGroup;

  estadoList: Estado[];
  filteredCategoriaList: LicitacionCategoria[];
  filteredProductoList: Producto[];

  public pageSettings: PageSettingsModel;
  public pageSizes: number[];
  
  constructor(
    private formBuilder: FormBuilder, 
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions, 
    private route: ActivatedRoute, 
    private menuService: MenuFuncionService,
    private estadoService: EstadoService,
    private productoService: ProductoService,
    private licitacionCategoriaService: LicitacionCategoriaService,
    private licitacionService : LicitacionService,
    private datePipe: DatePipe,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.LICITACION).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.LICITACION_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.LICITACION_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.LICITACION_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.LICITACION_DELETE);
        this.canImport = data.Result.some(x => x == MenuFuncionCodes.IMPORTACION_LICITACIONES);
        this.loadView();
      } else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

    this.buildLicitacionForm();

    this.getLicitacion();
    
    this.getEstados();
    this.getLicitacionCategorias();
    this.getLicitacionProductos();
  }

  getLicitacion() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        this.licitacionId = Number(params['id']);
        if (this.licitacionId > 0) {
          if(this.canView || this.canEdit) {
            this.isSearching = true;
            this.licitacionService.getLicitacionById(this.licitacionId).subscribe(data => {
              this.isSearching = false;
              if (data.Success) {
                this.licitacion = data.Result
                if(this.licitacion!= null && this.licitacion.Id > 0)
                {
                  this.licitacionFormGroup.patchValue(this.licitacion)
                  // this.licitacionFormGroup.get('Numero')?.setValue(this.licitacion.Numero);
                  // this.licitacionFormGroup.get('Descripcion')?.setValue(this.licitacion.Descripcion);
                  // this.licitacionFormGroup.get('UnidadDeCompra').setValue(this.licitacion.UnidadDeCompra);
                  // this.licitacionFormGroup.get('Dependencia').setValue(this.licitacion.Dependencia);
                  // this.licitacionFormGroup.get('Modalidad').setValue(this.licitacion.Modalidad);
                  // this.licitacionFormGroup.get('EstadoId').setValue(this.licitacion.Estado.Nombre);
                  // this.licitacionFormGroup.get('EmpresaCodigo').setValue(this.licitacion.EmpresaCodigo);
                  // this.licitacionFormGroup.get('Moneda').setValue(this.licitacion.Moneda);
                  // this.licitacionFormGroup.get('MontoReferencia').setValue(this.licitacion.MontoReferencia);
                  // this.licitacionFormGroup.get('FechaPublicacion').setValue(this.datePipe.transform(this.licitacion.FechaPublicacion, "yyyy-MM-dd"));
                  // this.licitacionFormGroup.get('FechaUltimaPublicacion').setValue(this.datePipe.transform(this.licitacion.FechaUltimaPublicacion, "yyyy-MM-dd"));

                  this.getEspecificacionesTecnicas()
                }
                else {
                  this.msgError = "No se encontró la licitacion seleccionada.";
                }
              }
              else{
                this.msgError = "Ocurrió un error al intentar obtener los datos de la licitacion.";
              }
            })
          }
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();
          }
        }
        else
          this.msgError = "Item no válido.";
      }
      else {
        // if (this.canAdd) {
        //   this.item = new Licitacion(0, null, null, null, null, null, null, null, null, false, null, null, null, null, true);
        //   this.formGroup.patchValue(this.item);
        // } 
        // else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.onCancel(); 
        // }
      }
    }, 
    (err: HttpErrorResponse) => {
      this.isSearching = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  getEspecificacionesTecnicas() {
    if (this.licitacion != null && this.licitacion.Id > 0) {
      this.isEspecificacionesTecnicasSearching = true;
      this.especificacionesTecnicasMsgError = "";
      this.licitacionService.getEspecificacionesTecnicasByLicitacionId(this.licitacionId).subscribe( data => {
        this.isEspecificacionesTecnicasSearching = false;
        if (data !=null && data.Success) {
          this.especificacionTecnicaList = data.Result.result;
        }
        else
          this.especificacionesTecnicasMsgError = "Ocurrió un error al intentar obtener las especificaciones tecnicas.";
      },
        (err: HttpErrorResponse) => {
          this.isEspecificacionesTecnicasSearching = false;
          this.sharedFunctions.handleError(err);
        })
    }
  }

  getEstados() {
    var r = new SearchEstadoRequest(null, null, true, "Nombre", null, null, true);
    this.isEstadoSearching = true;

    this.estadoService.searchLicitacionEstados(r).subscribe(data => {
      this.isEstadoSearching = false;
      if (data != null)
        this.estadoList = data?.result;
    },
      (err: HttpErrorResponse) => {
        this.isEstadoSearching = false;
        this.sharedFunctions.handleError(err);
      });
  }

  getLicitacionCategorias() {
    this.especificacionTecnicaFormGroup.controls.LicitacionCategoria.valueChanges
      .pipe(
        filter(res => {
          return res && res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCategoriaList = [];
          this.isLicitacionCategoriaSearching = true;
        }),
        switchMap(value => this.licitacionCategoriaService.search(new SearchLicitacionCategoriaRequest(20, 0, true, "Codigo", value?.toString(), null, null, true))
          .pipe(
            finalize(() => {
              this.isLicitacionCategoriaSearching = false;
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data != null && data.result != null)
          this.filteredCategoriaList = data.result;
        else
          this.filteredCategoriaList = [];
      });
  }

  displayCategoria(item: LicitacionCategoria) {
    return item ? item?.Codigo : "";
  }

  getLicitacionProductos() {
    this.especificacionTecnicaFormGroup.controls.Producto.valueChanges
      .pipe(
        filter(res => {
          return res && res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredProductoList = [];
          this.isLicitacionProductoSearching = true;
        }),
        switchMap(value => this.productoService.search(new SearchProductoRequestDto(20, 0, true, "Codigo", isNumber(parseInt(value)) ? value : null, (!isNumber(parseInt(value)) ? value : null), true))
          .pipe(
            finalize(() => {
              this.isLicitacionProductoSearching = false;
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data != null && data.result != null)
          this.filteredProductoList = data.result;
        else
          this.filteredProductoList = [];
      });
  }

  displayProducto(item: Producto) {
    return item ? item?.Codigo + " - " + item?.Nombre : "";
  }

  buildLicitacionForm() {
    this.licitacionFormGroup = this.formBuilder.group({
      'Numero': [null, [Validators.required]],
      'Descripcion': [null, [Validators.required]],
      'UnidadDeCompra': [null, [Validators.required]],
      'Dependencia': [null, [Validators.required]],
      'Modalidad': [null, [Validators.required]],
      'EstadoId': [null, [Validators.required]],
      'EmpresaCodigo': [null, [Validators.required]],
      'Moneda': [null],
      'MontoReferencia': [null, [Validators.required]],
      'FechaPublicacion': [null],
      'FechaUltimaPublicacion': [null]
    });
    
    this.especificacionTecnicaFormGroup = this.formBuilder.group({
      'Id': [0],
      'LicitacionId': [0],
      'NumeroLinea': [null, [Validators.required]],
      'LicitacionCategoriaId': [null],
      'LicitacionCategoria': [null, [Validators.required]],
      'CodigoCategoria': [null, [Validators.maxLength(100)]],
      'Descripcion': [null, [Validators.required]],
      'Clasificacion': [null, [Validators.required]],
      'CodigoProducto': [null],
      'Producto': [null],
      'UnidadMedida': [null, [Validators.required]],
      'Cantidad': [null, [Validators.required]],
      'Moneda': [null],
      'MontoUnitario': [null, [Validators.required]],
      'MontoTotal': [null, [Validators.required]],
      'SES': [null, [Validators.required]],
      'grid-index': [null]
    });
  }

  onCancel() {
    this.location.back();
  }

  clearDate(formFieldName: string) {
    if (formFieldName != null && formFieldName.length > 0) {
      if (this.licitacionFormGroup.get(formFieldName) != null && this.licitacionFormGroup.get(formFieldName)?.value)
        this.licitacionFormGroup.get(formFieldName).setValue(null);
    }
  }

  getErrorNumero() {
    var v = this.licitacionFormGroup.get('Numero');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorDescripcion() {
    var v = this.licitacionFormGroup.get('Descripcion');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorUnidadDeCompra(){
    var v = this.licitacionFormGroup.get('UnidadDeCompra');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorDependencia(){
    var v = this.licitacionFormGroup.get('Dependencia');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorModalidad(){
    var v = this.licitacionFormGroup.get('Modalidad');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorEstado(){
    var v = this.licitacionFormGroup.get('EstadoId');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEmpresaCodigo(){
    var v = this.licitacionFormGroup.get('EmpresaCodigo');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorMoneda(){
    var v = this.licitacionFormGroup.get('Moneda');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorMontoReferencia(){
    var v = this.licitacionFormGroup.get('MontoReferencia');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  editEspecificacionTecnica(data: any) {
    this.especificacionTecnicaFormGroup.reset();

    this.especificacionTecnicaFormGroup.patchValue(data);

    if (data != null) {
      this.especificacionTecnicaFormGroup.get("grid-index")?.setValue(data?.index ?? -1);

      var c = new LicitacionCategoria(data.LicitacionCategoriaId, data.LicitacionCategoria, data.LicitacionCategoria, null, null, true);

      this.filteredCategoriaList = [];
      this.filteredCategoriaList.push(c);
      this.especificacionTecnicaFormGroup.get("LicitacionCategoria")?.setValue(c);
      this.especificacionTecnicaFormGroup.get("LicitacionCategoriaId")?.setValue(c?.Id);

      if (data.ProductoId != null && data.ProductoId > 0) {
        var pSplit = data.Producto?.split('-');
        var p = new Producto(data.ProductoId, (pSplit != null && pSplit.length > 0 ? pSplit[0].trim() : ""), (pSplit != null && pSplit.length > 1 ? pSplit[1].trim() : ""), true);

        this.filteredProductoList = [];
        this.filteredProductoList.push(p);
        this.especificacionTecnicaFormGroup.get("Producto")?.setValue(p);
        this.especificacionTecnicaFormGroup.get("ProductoId")?.setValue(p?.Id);
      }
    }

    this.itemEspecificacionTecnica = data;
    this.expandEspecificacionesTecnicas = true;
  }

  saveEspecificacionTecnica() {
    if (this.especificacionTecnicaFormGroup.invalid) {  
      this.sharedFunctions.showMessage("Datos no válidos. Por favor, complete todos los campos requeridos.", "Error");
      return;
    }
    
    var nuevoItem = new LicitacionEspecificacionTecnicaDto(0, 0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    nuevoItem = { 
      ...this.especificacionTecnicaFormGroup.getRawValue(),
      LicitacionCategoriaId: this.especificacionTecnicaFormGroup.get("LicitacionCategoria")?.value?.Id, 
      LicitacionCategoria: this.especificacionTecnicaFormGroup.get("LicitacionCategoria")?.value?.Codigo, 
      ProductoId: this.especificacionTecnicaFormGroup.get("Producto")?.value?.Id, 
      Producto: (this.especificacionTecnicaFormGroup.get("Producto")?.value != null ? this.especificacionTecnicaFormGroup.get("Producto")?.value?.Codigo + " - " + this.especificacionTecnicaFormGroup.get("Producto")?.value?.Nombre : null), 
      LicitacionId: (this.licitacion?.Id ?? 0)
    };

    // console.log(nuevaCuenta);
    if (this.especificacionTecnicaList == null)
      this.especificacionTecnicaList = [];

    var exists = this.especificacionTecnicaList.some(f => f.NumeroLinea == nuevoItem.NumeroLinea && (nuevoItem.Id == 0 || nuevoItem.Id == null || f.Id != nuevoItem.Id));
    if (exists) {
      this.sharedFunctions.showMessage("El número de línea " + nuevoItem.NumeroLinea + " igresado ya existe", "Error");
      return false;
    }

    var index = this.especificacionTecnicaList.findIndex(f => f.NumeroLinea == nuevoItem.NumeroLinea);
    if (index != null && index >= 0) {
      this.especificacionTecnicaList[index] = nuevoItem;
    }
    else {
      this.especificacionTecnicaList.push(nuevoItem);
    }

    this.cleanEspecificacionTecnica();
    this.especificacionTecnicaGrid?.refresh();
    this.expandEspecificacionesTecnicas = false;
  }

  cleanEspecificacionTecnica() {
    this.itemEspecificacionTecnica = null;
    this.especificacionTecnicaFormGroup?.reset();
    this.especificacionTecnicaFormGroup?.controls['Id'].setValue(0);
    this.especificacionTecnicaFormGroup?.controls['LicitacionId'].setValue(0);

    this.filteredCategoriaList = [];
    this.filteredProductoList = [];
  }

  deleteEspecificacionTecnica(data: any) {
    if (this.especificacionTecnicaList == null)
      this.especificacionTecnicaList = [];

    if (data != null && data.NumeroLinea >= 0) {
      var index = this.especificacionTecnicaList.findIndex(f => f.NumeroLinea == data.NumeroLinea);

      if (index >= 0) {
        this.especificacionTecnicaList.splice(index, 1);
        this.especificacionTecnicaGrid?.refresh();
      }
    }
  }

  getErrorEspecificacionTecnicaNumeroLinea(){
    var v = this.especificacionTecnicaFormGroup.get('NumeroLinea');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaCodigoCategoria(){
    var v = this.especificacionTecnicaFormGroup.get('LicitacionCategoria');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaDescripcion(){
    var v = this.especificacionTecnicaFormGroup.get('Descripcion');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaClasificacion(){
    var v = this.especificacionTecnicaFormGroup.get('Clasificacion');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaCodigoProducto(){
    var v = this.especificacionTecnicaFormGroup.get('CodigoProducto');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaProducto(){
    var v = this.especificacionTecnicaFormGroup.get('Producto');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaUnidadMedida(){
    var v = this.especificacionTecnicaFormGroup.get('UnidadMedida');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaCantidad(){
    var v = this.especificacionTecnicaFormGroup.get('Cantidad');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaMoneda(){
    var v = this.especificacionTecnicaFormGroup.get('Moneda');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaMontoUnitario(){
    var v = this.especificacionTecnicaFormGroup.get('MontoUnitario');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaMontoTotal(){
    var v = this.especificacionTecnicaFormGroup.get('MontoTotal');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  getErrorEspecificacionTecnicaSES(){
    var v = this.especificacionTecnicaFormGroup.get('SES');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }

  onGuardar() {
    if (!this.licitacionFormGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new LicitacionDto(0, null, null, null, null, null, null, null, 0, null, null, null, null);

    r = 
    {
      ...this.licitacionFormGroup.value,
      ...
      {
        Id: (this.licitacion?.Id ?? 0),
        EspecificacionesTecnicas: this.especificacionTecnicaList
      } 
    };

    this.isSaving = true;
    this.licitacionService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onDelete() {
    if (!this.canDelete) 
      return false;

    this.sharedFunctions.showMessage("Función no disponible todavía", "Error");
    return false;

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar el item?", null, null)
    });
    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
        if (result != null && result.Accepted) {
          this.isDeleting = true;
          this.estadoService.delete(this.licitacion.Id).subscribe(data => {
              this.isDeleting = false;
              if (data != null && data.Success && data.Result) {
                  this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
                  this.onCancel();
              }
              else
                  this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
          },
          (err: HttpErrorResponse) => {
              this.isDeleting = false;
              this.sharedFunctions.handleError(err, true);
          })
        }
    });
  }

  onImportar() {
    var r = new ProcessLicitacionesFromWebDto(this.licitacion.Numero, null, null, null);
    this.isImporting = true;

    this.licitacionService.importarLicitaciones(r).subscribe(data => {
      this.isImporting = false;
      if (data != null && data.Success) {
        if ((data.Result.TotalNuevos + data.Result.TotalActualizados) > 0) {
          this.sharedFunctions.showMessage("Se " + (data.Result.TotalNuevos > 0 ? "importó" : "actualizó") + " éxitosamente la licitación " + r.NumeroLicitacion, "Operación Exitosa!");
          this.sharedFunctions.reloadCurrentRoute();
        }
        else
          this.sharedFunctions.showMessage("Ninguna licitación fue modificada o importada", "Error");
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isImporting = false;
      this.sharedFunctions.handleError(err, true);
    })
  }
}
