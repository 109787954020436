<mat-card>
  <mat-card-header>
    <mat-card-title>Búsqueda de Personas</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
      <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    </div>

    <ng-container *ngIf="canView">

      <mat-expansion-panel *ngIf="!isValidating">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">search</mat-icon> Filtros de Búsqueda
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="searchForm" >
          <div fxLayout="row stretch" fxLayout.xs="column">

            <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="Nombre" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Apellido</mat-label>
                <input matInput formControlName="Apellido" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Documento</mat-label>
                <input matInput formControlName="NroDocumento" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>

            <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Email Contacto</mat-label>
                <input matInput formControlName="MailContacto" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Celular</mat-label>
                <input matInput formControlName="Movil" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            
          </div>
        </form>
        <br/>

        <div fxLayout="row stretch" fxLayout.xs="column">
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="verSoloActivos" color="primary">Ver Solo Activos</mat-checkbox> 
          </div>
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="verSoloConUsuarios" color="primary">Ver Solo Personas con Usuario</mat-checkbox> 
          </div>
        </div>

      </mat-expansion-panel>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px">
        <button mat-raised-button (click)="add()" *ngIf="canAdd" [disabled]="isSearching" color="primary" class="zb-button">Agregar</button>
        <button mat-raised-button (click)="onSearch()" [disabled]="isSearching" color="primary" class="zb-button">{{isSearching ? 'Buscando...' : 'Buscar'}}</button>
        <button mat-raised-button (click)="onExport()" [disabled]="isExporting" color="primary" class="zb-button">{{isExporting ? 'Exportando...' : 'Exportar'}}</button>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
      </div>

      <div fxLayout="row stretch" fxLayout.xs="column" *ngIf="!isSearching">
        <ejs-grid #grid [dataSource]='data' [query]='query' [allowPaging]="true" [allowSorting]="true" [pageSettings]='pageSettings' [allowResizing]='true' (actionFailure)="onGridActionFailure($event)">
          <e-columns>
            <e-column field='Nombre' headerText='Nombre' width=90></e-column>
            <e-column field='Apellido' headerText='Apellido' width=90></e-column>
            <e-column field='NroDocumento' headerText='Nro. Doc.' width=90></e-column>
            <e-column field='MailContacto' headerText='Email' width=90></e-column>
            <e-column field='MovilContacto' headerText='Nro. Cel.' width=90></e-column>
            <e-column headerText='Acciones' width=90>
              <ng-template #template let-data>
                <a style="cursor: pointer; margin-right: 5px" (click)="edit(data)"><i class="fa fa-eye fa-1x"></i></a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>

    </ng-container>

  </mat-card-content>
</mat-card>

