import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query, ReturnOption  } from '@syncfusion/ej2-data';
import { Column, ExcelExportProperties, GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { DatePipe } from '@angular/common';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { UsuarioService } from 'src/app/shared/services/usuario.service';
import { RolService } from 'src/app/shared/services/rol.service';
import { EstadoService } from 'src/app/shared/services/estado.service';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

import { GridPageSettings, MenuFuncionCodes, Estado, Rol, SearchUsuariosRequestDto, SearchUsuariosItemDto, SearchRolRequest, SearchEstadoRequest } from 'src/app/shared/model';

@Component({
  selector: 'app-usuarioLista',
  templateUrl: './usuarioLista.component.html',
})

export class UsuarioListaComponent implements OnInit {
    @ViewChild('grid')
    public grid: GridComponent;

    public data: DataManager;
    total: number = 0;
    searchForm: FormGroup;
    rolList: Rol[];
    estadoList: Estado[];

    isValidating: boolean = false;
    isSearching: boolean = false;
    isExporting: boolean = false;
    isEstadoSearching: boolean = false;
    isRolSearching: boolean = false;
    canAdd: boolean = false;
    canView: boolean = false;
    verSoloBloqueados: boolean = false;
    verSoloActivos: boolean = false;

    public pageSizes: number[];
    public pageSettings: PageSettingsModel;
    public query: Query;
 
    constructor(
      private formBuilder: FormBuilder,
      private datePipe: DatePipe,
      private sharedFunctions: SharedFunctions,
      private router: Router,
      private route: ActivatedRoute,
      private menuService: MenuFuncionService,
      private rolService: RolService,
      private estadoService: EstadoService,
      private service: UsuarioService) {

    }

    ngOnInit(): void {
      this.isValidating = true;
      this.menuService.checkMenuPadre(MenuFuncionCodes.USER).subscribe(data => {
        this.isValidating = false;
        if (data != null && data.Success && data?.Result){
          this.canAdd = data.Result.some(x => x == MenuFuncionCodes.USER_ADD);
          this.canView = data.Result.some(x => x == MenuFuncionCodes.USER_LIST);
          if (this.canView){
            this.loadView();
          } else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.router.navigate(['/']);
          }
        }
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.router.navigate(['/']);
        }
      },
      (err: HttpErrorResponse) => {
        this.isValidating = false;
        this.sharedFunctions.handleError(err, true);
      });
    }

    loadView() {
      this.canView = true;
      this.pageSizes = GridPageSettings.PageSizeList;
      this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

      this.buildForm();

      this.setQueryParams();
      this.data = new DataManager({
        url: this.service.getSearchUrl(),
        adaptor: new WebApiAdaptor(),
        headers: [this.service.getAuthorizationHeader()]
      });

      this.getRoles();
      this.getEstados();
    }

    getRoles() {
      var r = new SearchRolRequest(null, null, true, "Nombre", null, null, true);
      this.isRolSearching = true;

      this.rolService.search(r).subscribe(data => {
        this.isRolSearching = false;
        if (data != null)
          this.rolList = data?.result;
      },
      (err: HttpErrorResponse) => {
        this.isRolSearching = false;
        this.sharedFunctions.handleError(err);
      });
    }
  
    getEstados() {
      var r = new SearchEstadoRequest(null, null, true, "Nombre", null, null, true);
      this.isEstadoSearching = true;

      this.estadoService.searchUsuarioEstados(r).subscribe(data => {
        this.isEstadoSearching = false;
        if (data != null)
          this.estadoList = data?.result;
      },
      (err: HttpErrorResponse) => {
        this.isEstadoSearching = false;
        this.sharedFunctions.handleError(err);
      });
    }
  
    buildForm() {
      this.searchForm = this.formBuilder.group({
        'RolId': [null],
        'Login': [null],
        'Nombre': [null],
        'Apellido': [null],
        'NroDocumento': [null],
        'Mail': [null],
        'Movil': [null],
        'EstadoId': [null]
      });
    }

    setQueryParams() {
      if (this.query == null)
        this.query = new Query();

      var index = this.query.params.findIndex(obj => obj.key === "RolId");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.RolId;
      else
        this.query.addParams("RolId",this.searchForm.value.RolId);
      
        index = this.query.params.findIndex(obj => obj.key === "Login");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.Login;
      else
        this.query.addParams("Login",this.searchForm.value.Login);

      index = this.query.params.findIndex(obj => obj.key === "Nombre");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.Nombre;
      else
        this.query.addParams("Nombre",this.searchForm.value.Nombre);

      index = this.query.params.findIndex(obj => obj.key === "Apellido");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.Apellido;
      else
        this.query.addParams("Apellido",this.searchForm.value.Apellido);

      index = this.query.params.findIndex(obj => obj.key === "Mail");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.Mail;
      else
        this.query.addParams("Mail",this.searchForm.value.Mail);

      index = this.query.params.findIndex(obj => obj.key === "Movil");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.Movil;
      else
        this.query.addParams("Movil",this.searchForm.value.Movil);

      index = this.query.params.findIndex(obj => obj.key === "EstadoId");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.EstadoId;
      else
        this.query.addParams("EstadoId",this.searchForm.value.EstadoId);

      index = this.query.params.findIndex(obj => obj.key === "NroDocumento");
      if (index >= 0)
        this.query.params[index].value = this.searchForm.value.NroDocumento;
      else
        this.query.addParams("NroDocumento",this.searchForm.value.NroDocumento);

      index = this.query.params.findIndex(obj => obj.key === "VerSoloBloqueados");
      if (index >= 0)
        this.query.params[index].value = this.verSoloBloqueados ? "true":"false";
      else
        this.query.addParams("VerSoloBloqueados", this.verSoloBloqueados? "true":"false");
  
      index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
      if (index >= 0)
        this.query.params[index].value = this.verSoloActivos ? "true":"false";
      else
        this.query.addParams("VerSoloActivos", this.verSoloActivos? "true":"false");
    }

    onGridActionFailure(event: any): void {
      this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
    }

    onSearch() {
      this.setQueryParams();
      this.grid.refresh();
    }

    showUserImage(item: SearchUsuariosItemDto) {
      return (item?.FotoPerfilUrl?.length > 0);
    }

    add() {
      if (this.canAdd)
        this.router.navigate(['usuario-edit', 0]);
      else
        this.sharedFunctions.showMessage("No puede ejecutar esta operación", "Error");
    }

    edit(item: SearchUsuariosItemDto) {
      if (!item || item.Id == null || item.Id <= 0) {
        this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
        return false;
      }
      
      this.router.navigate(['usuario-edit', item.Id]);
    }
  
    onExport() {
      var search = new SearchUsuariosRequestDto(null, null, null, "", null, null, null, null, null, null, null, null, null, this.verSoloActivos, this.verSoloBloqueados);
      search = Object.assign(search, this.searchForm.value);
  
      this.isExporting = true;
      this.service.exportSearch(search).subscribe((data: Blob) => {
        this.isExporting = false;
        var fileUrl = URL.createObjectURL(data);
  
        var anchor = document.createElement("a");
        anchor.download = "Usuarios_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
        anchor.href = fileUrl;
        anchor.click();
      },
      (err: HttpErrorResponse) => {
        this.isExporting = false;
        return this.sharedFunctions.handleError(err);
      })
    }
  }
