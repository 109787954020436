<mat-card>
  <mat-card-header>
    <mat-card-title>Gestión de Administración</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
    <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && item != null">

      <ng-container *ngIf="canEdit || canAdminEdit">

        <form [formGroup]="formGroup">

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="!tenantImageChanged()">
              <a target="_blank" href="{{item?.LogoUrl}}">
                <img src="{{item?.LogoUrl}}" style="height: 100px; width: 100px; border-radius: 100%;">
              </a>
            </div>
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="tenantImageChanged()">
              <img src="{{tenantImageBase64}}" style="height: 100px; width: 100px; border-radius: 100%; margin-bottom: 10px;">
            </div>
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center">
              <button type="button" mat-raised-button (click)="fileInput.click()">Cambiar Imagen</button>
              <input hidden type="file" (change)="convertFileToBase64($event)" #fileInput type="file" color="primary">
            </div>
          </div>

          <hr style="margin:20px 0px; width: 100%;">

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre*</mat-label>
                <input matInput formControlName="Nombre" (keyup.enter)="onGuardar()" maxlength="150">
                <mat-error *ngIf="!formGroup.controls['Nombre'].valid && formGroup.controls['Nombre'].touched">
                  {{ getErrorNombre() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre Corto</mat-label>
                <input matInput formControlName="NombreCorto" (keyup.enter)="onGuardar()" maxlength="150">
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Identificación</mat-label>
                <input matInput formControlName="NroDocumento" (keyup.enter)="onGuardar()" maxlength="100">
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Dirección</mat-label>
                <input matInput formControlName="Direccion" (keyup.enter)="onGuardar()" maxlength="300">
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Teléfono</mat-label>
                <input matInput formControlName="Telefono" (keyup.enter)="onGuardar()" maxlength="150">
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Email</mat-label>
                <input matInput formControlName="Email" type="email" (keyup.enter)="onGuardar()" maxlength="255">
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Sitio Web</mat-label>
                <input matInput formControlName="SitioWeb" (keyup.enter)="onGuardar()" maxlength="255">
              </mat-form-field>
            </div>

          </div>

        </form>

        <div fxLayout="row stretch" fxLayout.xs="column">
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="item.Activo" (keyup.enter)="onGuardar()" color="primary">Activo</mat-checkbox> 
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="!canEdit && !canAdminEdit">

        <div fxLayout="row stretch" fxLayout.xs="column">
          <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="!tenantImageChanged()">
            <a target="_blank" href="{{item?.LogoUrl}}">
              <img src="{{item?.LogoUrl}}" style="height: 100px; width: 100px; border-radius: 100%;">
            </a>
          </div>
          <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="tenantImageChanged()">
            <img src="{{tenantImageBase64}}" style="height: 100px; width: 100px; border-radius: 100%; margin-bottom: 10px;">
          </div>
        </div>

        <hr style="margin:20px 0px; width: 100%;">

        <div fxLayout="row stretch" class="zb-readonly">

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Nombre</b>: {{item.Nombre}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Nombre Corto</b>: {{item.NombreCorto}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Nro. Doc.</b>: {{item.NroDocumento}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Teléfono</b>: {{item.Telefono}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=100 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Dirección</b>: {{item.Direccion}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Email</b>: {{item?.Email}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Sitio Web</b>: <a href="{{item?.SitioWeb}}" target="_blank">{{item?.SitioWeb}}</a></mat-label>
              </div>
            </mat-expansion-panel>
          </div>

          <div fxFlex=50 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <mat-label><b>Activo</b>: {{item.Activo ? 'Sí' : 'No'}}</mat-label>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </ng-container>

    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onGuardar()" *ngIf="item != null && (canEdit || canAdminEdit)" [disabled]="isProcessing()" color="primary" class="zb-button">{{isSaving ? 'Guardando...' : 'Guardar'}}</button>
      <button mat-raised-button (click)="onCancel()" color="warn"  class="zb-button">{{canEdit ? 'Cancelar' : 'Cerrar'}}</button>
    </div>
  </mat-card-actions>

</mat-card>