import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser'
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService, ActionCompleteEventArgs, ActionBeginEventArgs } from '@syncfusion/ej2-angular-richtexteditor';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';

import { FaqService, MenuFuncionService, RolService } from 'src/app/shared/services';
import { MenuFuncionCodes, Rol, SearchRolRequest, Faq, SaveFaq, DialogConfirmRequest, DialogConfirmResponse } from 'src/app/shared/model';

@Component({
    selector: 'app-faqEdit',
    templateUrl: './faqEdit.component.html',
    providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService]
}) 

export class FaqEditComponent {
    public preguntaTools: object = {
        items: ['Undo', 'Redo', '|',
        'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', '|',
        'SubScript', 'SuperScript', '|',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', '|', 'CreateLink', '|', 
        'ClearFormat', 'SourceCode'
    ]};
    public tools: object = this.sharedFunctions.getRichTextToolbarItems();
    public insertImageSettings: object = this.sharedFunctions.getRichTextImageSettings
    (
        this.faqService.getResourceName(),
        this.faqService.uploadAttachTempUrl()
    );

    formGroup: FormGroup;

    isValidating: boolean = false;
    isSearching: boolean = false;
    isAttachmentSearching: boolean = false;
    isRolSearching: boolean = false;
    isSaving: boolean = false;
    isDeleting: boolean = false;
    canEdit: boolean = false;
    canDelete: boolean = false;
    canAdd: boolean = false;
    canView: boolean = false;
    isFileAttaching: boolean = false;

    newKeyword: string = "";
    rolList: Rol[];
    rolesString: string = "";
    preguntaHtml: any;
    respuestaHtml: any;

    msgError: string = "";
    item: Faq;

    constructor (
        private formBuilder: FormBuilder,
        private location: Location,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private sharedFunctions: SharedFunctions,
        private route: ActivatedRoute,
        private menuFuncionService: MenuFuncionService,
        private rolService: RolService,
        private faqService: FaqService
    ) {
    }

    ngOnInit(): void {
        this.isValidating = true;
        this.menuFuncionService.checkMenuPadre(MenuFuncionCodes.FAQ).subscribe(data => {
            if (data != null && data.Success && data?.Result) {
                this.isValidating = false;
                this.canEdit = data.Result.some(x => x == MenuFuncionCodes.FAQ_EDIT);
                this.canAdd = data.Result.some(x => x == MenuFuncionCodes.FAQ_ADD);
                this.canView = data.Result.some(x => x == MenuFuncionCodes.FAQ_LIST);
                this.canDelete = data.Result.some(x => x == MenuFuncionCodes.FAQ_DELETE);
                this.loadView();
            }else {
                this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                this.onCancel();        
              }      
        },
        (err: HttpErrorResponse) => {
            this.isValidating = false;
            this.sharedFunctions.handleError(err, true);
        });
    }

    loadView() {
        if (this.canEdit) {
            this.buildForm();
            this.getRoles();
        }
        
        this.loadEntity();
    }

    buildForm() {
        this.formGroup = this.formBuilder.group({
            'Respuesta': ["", Validators.required],
            'Pregunta': ["", Validators.required],
            'Orden': [1, Validators.required],
            'Roles': [null]
        });
    }

    getRoles() {
        var r = new SearchRolRequest(null, null, true, "Nombre", null, null, true);
        this.isRolSearching = true;
    
        this.rolService.search(r).subscribe(data => {
          this.isRolSearching = false;
          if (data != null)
            this.rolList = data?.result;
        },
          (err: HttpErrorResponse) => {
            this.isRolSearching = false;
            this.sharedFunctions.handleError(err);
          });
    }

    compareRoles(o1: number, o2: number): boolean {
        return o1 == o2;
    }

    isProcessing() {
        return (this.isSearching || this.isSaving || this.isDeleting);
    }

    loadEntity() {
        this.route.params.subscribe(params => {
            if (params['id'] != null && params['id'] != '0') {
                var id = Number(params['id']);
                if (id > 0) {
                    if(this.canView || this.canEdit){
                        this.isSearching = true;
                        this.faqService.get(id).subscribe(data => {
                            this.isSearching = false;
                            if (data != null && data.Success) {
                                this.item = data.Result;

                                if (this.item != null && this.item.Id > 0) {
                                    this.loadAttachments(this.item.Id);

                                    if (this.canEdit) {
                                        this.canDelete = true;
                                        this.formGroup.patchValue(this.item);
                                    }
                                    else {
                                        this.preguntaHtml = this.sanitizer.bypassSecurityTrustHtml(this.item.Pregunta);
                                        this.respuestaHtml = this.sanitizer.bypassSecurityTrustHtml(this.item.Respuesta);

                                        this.loadRoles(this.item.Id);
                                    }
                                }
                                else
                                    this.msgError = "No se encontró la FAQ seleccionada.";
                            }
                            else
                                this.msgError = "Ocurrió un error al intentar obtener los datos de la FAQ.";
                        },
                        (err: HttpErrorResponse) => {
                            this.isSearching = false;
                            this.sharedFunctions.handleError(err, true);
                        })
                    }else{
                        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                        this.onCancel();    
                      }
                }
                else
                    this.msgError = "Item no válido.";
            }
            else
            {
                if (this.canAdd){
                    this.item = new Faq(0, null, null, null, false, true, [], [], []);
                    this.formGroup.patchValue(this.item);
                } else{
                    this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                      this.onCancel(); 
                    }
            }
        });
    }

    loadAttachments(id) {
        this.isAttachmentSearching = true;
        this.faqService.getAttachments(id).subscribe(data => {
            this.isAttachmentSearching = false;
            if (data != null && data.Success)
                this.item.Adjuntos = data.Result?.filter(f => !f.IncluidoEnCuerpoMensaje);

            if (this.item.Adjuntos == null)
                this.item.Adjuntos = []
        },
        (err: HttpErrorResponse) => {
            this.isAttachmentSearching = false;
            this.sharedFunctions.handleError(err);
        })
    }

    loadRoles(id) {
        this.isRolSearching = true;
        this.faqService.getRoles(id).subscribe(data => {
            this.isRolSearching = false;
            if (data != null && data.Success)
                this.item.Roles = data.Result;

            if (this.item.Roles == null)
                this.item.Roles = []

            this.rolesString = this.item.Roles?.map(item => item?.Rol?.Nombre)?.join(", ");
        },
        (err: HttpErrorResponse) => {
            this.isRolSearching = false;
            this.sharedFunctions.handleError(err);
        })
    }

    getErrorOrden() {
        return this.formGroup.get('Orden').hasError('required') ? 'El campo es obligatorio' : '';
    }

    onGuardar() {

        if (!this.formGroup.valid) {
            this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
            return false;
        }

        var r = new SaveFaq(null, null, null, null, false, true, null, null, null);

        if (this.item.Adjuntos == null)
            this.item.Adjuntos = [];

        r = 
        {
            ...this.formGroup.value,
            ...
            {
                Id: (this.item?.Id ?? 0),
                EsPrivada: this.item?.EsPrivada,
                Activo: this.item?.Activo,
                Adjuntos: this.item?.Adjuntos,
                PalabrasClaves: this.item?.PalabrasClaves
            } 
        };

        this.isSaving = true;
        this.faqService.save(r).subscribe(data => {
            this.isSaving = false;
            if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
                this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
                this.onCancel();
            }
            else
                this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
            this.isSaving = false;
            this.sharedFunctions.handleError(err, true);
        })
    }

    onDelete() {
        if (!this.canDelete) 
            return false;
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar la FAQ?", null, null)
        });
        dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {

            if (result != null && result.Accepted) {
                this.isDeleting = true;
                this.faqService.delete(this.item.Id).subscribe(data => {
                    this.isDeleting = false;
                    if (data != null && data.Success && data.Result) {
                        this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
                        this.onCancel();
                    }
                    else
                        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
                },
                (err: HttpErrorResponse) => {
                    this.isDeleting = false;
                    this.sharedFunctions.handleError(err, true);
                })
            }
        });
    }

    onCancel() {
        this.location.back();
    }

    onFileAttachedDelete(item: any) {
        if (item.Id > 0)
            this.onExistingFileAttachedDelete(item);
        else
            this.onNewFileAttachedDelete(item);
    }

    onNewFileAttach(event: any) {
        if (event?.target?.files != null && event.target.files.length > 0) {
            var fileList = event.target.files;

            if (this.item.Adjuntos == null)
                this.item.Adjuntos = [];

            for (var i=0; i<fileList.length; i++) {
                if (this.item.Adjuntos.find(f => f.NombreDocumento == fileList[i].name)) {
                    this.sharedFunctions.showMessage("Ya existe un archivo con ese nombre. Por favor modifique el nombre del archivo o elimine el ya adjuntado", "Error");
                    return false;
                }
            }

            if (fileList != null && fileList.length > 0) {
                this.isFileAttaching = true;
                this.faqService.uploadAttachTemp(fileList).subscribe(data => {
                    this.isFileAttaching = false;
                    if (data != null && data.Success && data.Result != null && data.Result.Archivos != null && data.Result.Archivos.length > 0) {
                        for (let i=0; i<data.Result.Archivos.length; i++) {
                            if (data.Result.Archivos[i] != null) {
                                this.item.Adjuntos.push(data.Result.Archivos[i]);
                            }
                        }
                    }
                    else
                        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error intentando subir los archivos", "Error");
                },
                (err: HttpErrorResponse) => {
                    this.isFileAttaching = false;
                    this.sharedFunctions.handleError(err, true);
                })
            }
        }
    }

    onExistingFileAttachedDelete(item: any) {
        if (this.item.Adjuntos != null && this.item.Adjuntos.length > 0) {
            var index = this.item.Adjuntos.findIndex(f => f.Id == item.Id);

            if (index >= 0)
                this.item.Adjuntos.splice(index, 1);
        }
    }

    onNewFileAttachedDelete(item: any) {
        if (this.item.Adjuntos != null && this.item.Adjuntos.length > 0) {
            var index = this.item.Adjuntos.findIndex(f => f.Id == item.Id);

            if (index >= 0) {
                this.item.Adjuntos.splice(index, 1);
            }
        }
    }

    public preguntaImageUploading(args: any): void {
        if (args != null) {
            args.currentRequest.setRequestHeader(
                this.faqService.getAuthorizationHeaderParameterName(),
                this.faqService.getAuthorizationHeaderParameterValue()
            );
        }
    }

    public preguntaImageUploadSuccess(args: any): void {
        if (args != null && args.file != null) {
            let filename: any = document.querySelectorAll(".e-file-name")[0];
            
            if (filename != null) {
                filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, '');
                filename.title = args.file.name;
            }
        }
    }

    public imageUploading(args: any): void {
        if (args != null) {
            args.currentRequest.setRequestHeader(
                this.faqService.getAuthorizationHeaderParameterName(),
                this.faqService.getAuthorizationHeaderParameterValue()
            );
        }
    }

    public imageUploadSuccess(args: any): void {
        if (args != null && args.file != null) {
            let filename: any = document.querySelectorAll(".e-file-name")[0];
            
            if (filename != null) {
                filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, '');
                filename.title = args.file.name;
            }
        }
    }

    onKeywordAdd() {
        if (this.newKeyword != null && this.newKeyword.length > 0) {
            if (this.item.PalabrasClaves == null)
                this.item.PalabrasClaves = [];

            var index = this.item.PalabrasClaves.indexOf(this.newKeyword);
            if (index < 0)
                this.item.PalabrasClaves.push(this.newKeyword);
            this.newKeyword = "";
        }
    }

    onKeywordDelete(keyword: string) {
        if (keyword != null && keyword.length > 0) {
            if (this.item.PalabrasClaves == null)
                this.item.PalabrasClaves = [];

            var index = this.item.PalabrasClaves.indexOf(keyword);
            if (index >= 0)
                this.item.PalabrasClaves.splice(index, 1);
        }
    }
}