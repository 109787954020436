import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query} from '@syncfusion/ej2-data';
import { GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { EstadoService, LicitacionService, MenuFuncionService } from 'src/app/shared/services/index';

import { GridPageSettings, MenuFuncionCodes, LicitacionDto, Estado, SearchEstadoRequest, SearchLicitacionRequest } from 'src/app/shared/model';

import { DialogImportacionLicitacionComponent } from './components/dialogImportacionLicitacion.component';
import { DialogImportacionLicitacionArchivoComponent } from './components/dialogImportacionLicitacionArchivo.component';

@Component({
  selector: 'app-licitacionLista',
  templateUrl: './licitacionLista.component.html',
  styleUrls: ['./licitacionLista.component.scss']
})

export class LicitacionListaComponent implements OnInit {
  @ViewChild('grid')
  public grid: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;

  estadoList: Estado[];

  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isEstadoSearching: boolean = false;
  isLicitacionPadreSearching: boolean = false;
  
  canImport: boolean = false;
  canImportArchivo: boolean = false;
  canAdd: boolean = false;
  canView: boolean = false;
  canExport: boolean = false;

  verSoloActivos: boolean = false;

  public query: Query;

  public selectionOptions: SelectionSettingsModel;
  public pageSizes: number[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private service: LicitacionService,
    private estadoService: EstadoService,
    private menuService: MenuFuncionService,
    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.LICITACION).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result){
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.LICITACION_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.LICITACION_LIST);
        this.canImport = data.Result.some(x => x == MenuFuncionCodes.IMPORTACION_LICITACIONES);
        this.canImportArchivo = data.Result.some(x => x == MenuFuncionCodes.IMPORTACION_ARCHIVO_LICITACIONES);
        this.canExport = data.Result.some(x => x == MenuFuncionCodes.LICITACION_EXPORT);

        if (this.canView) {
          this.loadView();
        } 
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.router.navigate(['/']);
        }
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.canView = true;
    this.buildForm();

    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
    this.toolbarOptions = null; //['ExcelExport'];
    
    this.setQueryParams();
    this.data = new DataManager({
      url: this.service.getSearchUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.service.getAuthorizationHeader()]
    });

    this.getEstados();
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'FechaDesde': [new Date()],
      'FechaHasta': [new Date()],
      'Estado': [null],
      'NumeroLicitacion': [null]
    });
  }

  getEstados() {
    var r = new SearchEstadoRequest(null, null, true, "Nombre", null, null, true);
    this.isEstadoSearching = true;

    this.estadoService.searchLicitacionEstados(r).subscribe(data => {
      this.isEstadoSearching = false;
      if (data != null)
        this.estadoList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isEstadoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  clearDate(formFieldName: string) {
    if (formFieldName != null && formFieldName.length > 0) {
      if (this.searchForm.get(formFieldName) != null && this.searchForm.get(formFieldName)?.value)
        this.searchForm.get(formFieldName).setValue(null);
    }
  }
  
  onGridActionFailure(event: any): void {
    this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {
    this.setQueryParams();
    this.grid.refresh();
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "FechaDesde");
    if (index >= 0)
      this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaDesde, "yyyy-MM-dd");
    else
      this.query.addParams("FechaDesde", this.datePipe.transform(this.searchForm.value.FechaDesde, "yyyy-MM-dd"));

    index = this.query.params.findIndex(obj => obj.key === "FechaHasta");
    if (index >= 0)
      this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaHasta, "yyyy-MM-dd");
    else
      this.query.addParams("FechaHasta", this.datePipe.transform(this.searchForm.value.FechaHasta, "yyyy-MM-dd"));

    index = this.query.params.findIndex(obj => obj.key === "Estado");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Estado;
    else
      this.query.addParams("Estado",this.searchForm.value.Estado);

    index = this.query.params.findIndex(obj => obj.key === "NumeroLicitacion");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.NumeroLicitacion;
    else
      this.query.addParams("NumeroLicitacion",this.searchForm.value.NumeroLicitacion);

    index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
    if (index >= 0)
      this.query.params[index].value = this.verSoloActivos.toString();
    else
      this.query.addParams("VerSoloActivos",this.verSoloActivos.toString());
  }

  add() {
    if (this.canAdd)
      this.router.navigate(['licitacion-edit', 0]);
    else
      this.sharedFunctions.showMessage("No puede ejecutar esta operación", "Error");
  }

  edit(item: LicitacionDto) {
    if (!item || item.Id == null || item.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }
    
    this.router.navigate(['licitacion-edit', item.Id]);
  }

  onExport() {
    if (!this.canExport)
      return false;
    
    var search = new SearchLicitacionRequest(null, null, null, "", null, null, null, null);
    search = Object.assign(search, this.searchForm.value);

    this.isExporting = true;
    this.service.exportSearch(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "Licitaciones_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
      anchor.href = fileUrl;
      anchor.click();
    },
    (err: HttpErrorResponse) => {
      this.isExporting = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onImportLicitacion() {
    const dialogRef = this.dialog.open(DialogImportacionLicitacionComponent, {
      width: '60%',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  onImportArchivoLicitacion() {
    const dialogRef = this.dialog.open(DialogImportacionLicitacionArchivoComponent, {
      width: '60%',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
}
