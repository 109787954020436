import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query} from '@syncfusion/ej2-data';
import { GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { isNumber } from 'highcharts';
import 'rxjs/add/operator/debounceTime';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { LicitacionService, ProductoService, MenuFuncionService, EstadoService } from 'src/app/shared/services';
import { Estado, GridPageSettings, MenuFuncionCodes, Producto, SearchEstadoRequest, SearchProductoRequestDto } from 'src/app/shared/model';
import { SaveEspecificacionTecnicaProductoDto, SearchLicitacionDetalleLicitacionesRequest } from 'src/app/shared/model/licitacion';

@Component({
  selector: 'app-productoLicitacion',
  templateUrl: './productoLicitacion.component.html',
  styles: ["mat-datepicker-toggle {display: inline-block }"]
})

export class ProductoLicitacionComponent implements OnInit {
  @ViewChild('grid')
  public grid: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;
  filteredProductoList: Producto[];
  estadoList: Estado[];

  isSaving: boolean = false;
  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isProductoSearching: boolean = false;
  isEstadoSearching: boolean = false;

  canEdit: boolean = false;
  canView: boolean = false;
  canExport: boolean = false;
  verSoloActivos: boolean = false;

  public query: Query;

  public selectionOptions: SelectionSettingsModel;
  public pageSizes: number[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuFuncionService,
    private productoService: ProductoService,
    private estadoService: EstadoService,
    private licitacionService: LicitacionService,
    private datePipe: DatePipe,
    ) {

  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenu(MenuFuncionCodes.PRODUCTO_LICITACION_ASIGNACION).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result)
        this.loadView();
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.canView = true;
    this.canEdit = true;

    this.buildForm();

    this.getProductos();
    this.getEstados();

    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
    this.toolbarOptions = null; //['ExcelExport'];

    this.setQueryParams();
    this.data = new DataManager({
      url: this.licitacionService.getReporteDetalleLicitacionesFarmaciaUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.licitacionService.getAuthorizationHeader()]
    });

    this.menuService.checkMenu(MenuFuncionCodes.PRODUCTO_LICITACION_ASIGNACION_EXPORT).subscribe(data => {
      if (data != null && data.Success && data?.Result != null && data?.Result == true) {
        this.canExport = true;
      }
    },
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'FechaDesde': [new Date()],
      'FechaHasta': [new Date()],
      'NumeroLicitacion': [null],
      'Descripcion': [null],
      'EstadoLicitacionId': [null],
      'Producto': [null],
    });
  }

  getProductos() {
    this.searchForm.controls.Producto.valueChanges
      .pipe(
        filter(res => {
          return res && res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredProductoList = [];
          this.isProductoSearching = true;
        }),
        switchMap(value => this.productoService.search(new SearchProductoRequestDto(20, 0, true, "Codigo", isNumber(parseInt(value)) ? value : null, (!isNumber(parseInt(value)) ? value : null), true))
          .pipe(
            finalize(() => {
              this.isProductoSearching = false;
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data != null && data.result != null)
          this.filteredProductoList = data.result;
        else
          this.filteredProductoList = [];
      });
  }

  getEstados() {
    var r = new SearchEstadoRequest(null, null, true, "Nombre", null, null, true);
    this.isEstadoSearching = true;

    this.estadoService.searchLicitacionEstados(r).subscribe(data => {
      this.isEstadoSearching = false;
      this.estadoList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isEstadoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  displayProducto(producto: Producto) {
    return producto ? producto?.Codigo +  " - " + producto?.Nombre : "";
  }
  
  onGridActionFailure(event: any): void {
    this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {
    // if(!this.searchForm.controls.Fecha.valid){
    //   this.sharedFunctions.showMessage("Debe seleccionar una fecha", "Error");
    //   return;
    // }
    this.setQueryParams();
    this.grid.refresh();
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "FechaDesde");
    if (index >= 0)
      this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaDesde,"yyyy-MM-dd");
    else
      this.query.addParams("FechaDesde", this.datePipe.transform(this.searchForm.value.FechaDesde,"yyyy-MM-dd"));

    index = this.query.params.findIndex(obj => obj.key === "FechaHasta");
    if (index >= 0)
      this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaHasta,"yyyy-MM-dd");
    else
      this.query.addParams("FechaHasta", this.datePipe.transform(this.searchForm.value.FechaHasta,"yyyy-MM-dd"));
    
    index = this.query.params.findIndex(obj => obj.key === "NumeroLicitacion");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.NumeroLicitacion;
    else
      this.query.addParams("NumeroLicitacion",this.searchForm.value.NumeroLicitacion);

    index = this.query.params.findIndex(obj => obj.key === "Descripcion");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Descripcion;
    else
      this.query.addParams("Descripcion",this.searchForm.value.Descripcion);

    index = this.query.params.findIndex(obj => obj.key === "EstadoLicitacionId");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.EstadoLicitacionId;
    else
      this.query.addParams("EstadoLicitacionId",this.searchForm.value.EstadoLicitacionId);

    index = this.query.params.findIndex(obj => obj.key === "TieneProductos");
    if (index >= 0)
      this.query.params[index].value = "false";
    else
      this.query.addParams("TieneProductos", "false");
  }

  clearDate(formFieldName: string) {
    if (formFieldName != null && formFieldName.length > 0) {
        if (this.searchForm.get(formFieldName) != null && this.searchForm.get(formFieldName)?.value)
            this.searchForm.get(formFieldName).setValue(null);
    }
  }

  asignarProducto() {
    let selectedRows: any[] = this.grid.getRowsObject();
    if (selectedRows == null || selectedRows.length == 0) {
      this.sharedFunctions.showMessage("La búsqueda no trajo ningún resultado. Realice una nueva búsqueda y vuelva a intentarlo.", "Error");
      return false;
    }

    let saveLicitacionProducto: SaveEspecificacionTecnicaProductoDto = new SaveEspecificacionTecnicaProductoDto(
      selectedRows.map(x => x?.data?.EspecificacionTecnicaId)?.filter(f => f != null && f > 0),
      this.searchForm.value.Producto?.Id
    );

    this.isSaving = true;
    this.licitacionService.asignarProducto(saveLicitacionProducto).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success) {
        this.sharedFunctions.showMessage("Productos asignados correctamente!", "Operación éxitosa!");
        this.onSearch();
      }
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onExport() {
    if (!this.canExport)
      return false;

    var search = new SearchLicitacionDetalleLicitacionesRequest
    (
      null, 
      null, 
      null, 
      "", 
      this.searchForm.value.NumeroLicitacion, 
      null, 
      this.searchForm.value.Descripcion, 
      this.searchForm.value.EstadoLicitacionId, 
      this.searchForm.value.FechaDesde, 
      this.searchForm.value.FechaHasta,
      null
    );
    this.isExporting = true;
    this.licitacionService.exportReporteDetalleLicitacionesFarmacia(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "DetalleLicitacionesFarmacia_" + this.datePipe.transform(new Date(), "yyyy-MM-dd") + ".csv";
      anchor.href = fileUrl;
      anchor.click();
    },
    (err: HttpErrorResponse) => {
      this.isExporting = false;
      this.sharedFunctions.handleError(err, true);
    })
  }
}
