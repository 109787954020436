import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { FaqService } from 'src/app/shared/services';
import { ViewFaqRequestDto, ViewFaqItemDto } from 'src/app/shared/model';

@Component({
    selector: 'app-faqView',
    templateUrl: './faqView.component.html'
}) 

export class FaqViewComponent {
    searchForm: FormGroup;
    isSearching: boolean = false;
    hasSearched: boolean = false;
    msgError: string = "";
    itemList: ViewFaqItemDto[];
    pageNumber: number = -1;

    constructor (
        private formBuilder: FormBuilder,
        private location: Location,
        public dialog: MatDialog,
        private sharedFunctions: SharedFunctions,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private faqService: FaqService
    ) {
    }

    ngOnInit(): void {
        this.loadView();
    }

    loadView() {
        this.buildForm();
        this.onSearch();
    }

    buildForm() {
        this.searchForm = this.formBuilder.group({
            'Texto': [null]
        });
    }

    onSearch() {
        this.hasSearched = true;
        this.pageNumber++;
        var search = new ViewFaqRequestDto(5, this.pageNumber, true, "Orden", null, null, null, true);
        search = Object.assign(search, this.searchForm.value);

        this.isSearching = true;
        this.faqService.viewFaqs(search).subscribe(data => {
            this.isSearching = false;
            if (data != null) {
                if (data.result != null && data.result.length > 0) {
                    if (this.itemList == null)
                        this.itemList = [];

                    for (var i=0; i < data.result.length; i++) {
                        data.result[i].PreguntaHtml = this.sanitizer.bypassSecurityTrustHtml(data.result[i].Pregunta);
                        data.result[i].RespuestaHtml = this.sanitizer.bypassSecurityTrustHtml(data.result[i].Respuesta);
                        data.result[i].Adjuntos = data.result[i].Adjuntos?.filter(f => !f.IncluidoEnCuerpoMensaje);

                        this.itemList.push(data.result[i]);
                    }
                }
            }
            else
                this.msgError = "Ocurrió un error al intentar obtener los datos de las FAQs.";
        },
        (err: HttpErrorResponse) => {
            this.isSearching = false;
            this.sharedFunctions.handleError(err, true);
        })
    }

    onFilterSearch() {
        this.pageNumber = -1;
        this.itemList = [];
        
        this.onSearch();
    }

    onCancel() {
        this.location.back();
    }
}