import { NgModule,} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { GridModule, PageService, SortService, FilterService, GroupService, ExcelExportService, ToolbarService, AggregateService, DetailRowService, ResizeService } from '@syncfusion/ej2-angular-grids';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { AnnotationsService, CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AutoCompleteModule, ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { DefaultComponent} from './default.component';
import { SubmenuItemComponent } from '../../shared/components/sidebar/submenu-item.component';
import { MenuItemComponent } from '../../shared/components/sidebar/menu-item.component';
import { MenuLinkItemComponent } from '../../shared/components/sidebar/menu-link-item.component';

import { UsuarioEditComponent } from '../../pages/usuarios/usuarioEdit.component';
import { UsuarioSelfEditComponent } from '../../pages/usuarios/usuarioSelfEdit.component';
import { UsuarioListaComponent } from '../../pages/usuarios/usuarioLista.component';
import { RolListaComponent } from '../../pages/roles/rolLista.component';
import { RolEditComponent } from '../../pages/roles/rolEdit.component';
import { EstadoListaComponent } from '../../pages/estados/estadoLista.component';
import { EstadoEditComponent } from '../../pages/estados/estadoEdit.component';
import { DashboardComponent} from '../../pages/dashboard/dashboard.component';
import { MensajeUsuarioListaComponent } from '../../pages/mensaje-usuario/mensajeUsuarioLista.component';
import { MensajeUsuarioEditComponent } from '../../pages/mensaje-usuario/mensajeUsuarioEdit.component';
import { MensajeUsuarioViewComponent } from '../../pages/mensaje-usuario/mensajeUsuarioView.component';
import { ParametroSistemaListaComponent } from '../../pages/parametro-sistema/parametroSistemaLista.component';
import { ParametroSistemaEditComponent } from '../../pages/parametro-sistema/parametroSistemaEdit.component';
import { MenuListaComponent } from '../../pages/menues/menuLista.component';
import { MenuEditComponent } from '../../pages/menues/menuEdit.component';
import { MensajeUsuarioTipoListaComponent } from '../../pages/mensaje-usuario-tipo/mensajeUsuarioTipoLista.component';
import { MensajeUsuarioTipoEditComponent } from '../../pages/mensaje-usuario-tipo/mensajeUsuarioTipoEdit.component';
import { MensajeUsuarioOpcionEnvioListaComponent } from '../../pages/mensaje-usuario-opcion-envio/mensajeUsuarioOpcionEnvioLista.component';
import { MensajeUsuarioOpcionEnvioEditComponent } from '../../pages/mensaje-usuario-opcion-envio/mensajeUsuarioOpcionEnvioEdit.component';
import { MailConfiguracionListaComponent } from '../../pages/mail-configuracion/mailConfiguracionLista.component';
import { MailConfiguracionEditComponent } from '../../pages/mail-configuracion/mailConfiguracionEdit.component';
import { TenantListaComponent } from '../../pages/tenants/tenantLista.component';
import { TenantEditComponent } from '../../pages/tenants/tenantEdit.component';
import { RolTipoListaComponent } from '../../pages/rol-tipo/rolTipoLista.component';
import { RolTipoEditComponent } from '../../pages/rol-tipo/rolTipoEdit.component';
import { FaqListaComponent } from '../../pages/faq/faqLista.component';
import { FaqEditComponent } from '../../pages/faq/faqEdit.component';
import { FaqViewComponent } from '../../pages/faq/faqView.component';
import { HomeComponent } from '../../pages/home/home.component';
import { TipoDocumentoListaComponent } from '../../pages/tipo-documento/tipoDocumentoLista.component';
import { TipoDocumentoEditComponent } from '../../pages/tipo-documento/tipoDocumentoEdit.component';
import { PersonaListaComponent } from 'src/app/pages/usuarios/personaLista.component';
import { PersonaEditComponent } from 'src/app/pages/usuarios/personaEdit.component';

import { DetalleLicitacionesListaComponent } from '../../pages/licitaciones/detalleLicitacionesLista.component';
import { LicitacionListaComponent } from 'src/app/pages/licitaciones/licitacionLista.component';
import { LicitacionEditComponent } from 'src/app/pages/licitaciones/licitacion-edit.component';
import { ForecastListaComponent } from '../../pages/licitaciones/forecastLista.component';
import { ProductoLicitacionComponent } from '../../pages/producto-licitacion/productoLicitacion.component';
import { ProductoListaComponent } from '../../pages/productos/lista/productoLista.component';
import { ProductoEditarComponent } from '../../pages/productos/editar/productoEditar.component';
import { LicitacionCategoriaListaComponent } from '../../pages/licitacion-categorias/licitacionCategoriaLista.component';
import { LicitacionCategoriaEditComponent } from '../../pages/licitacion-categorias/licitacionCategoriaEdit.component';

import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';
import { DialogMensajePopUpComponent } from 'src/app/shared/components/modals/dialogMensajePopUp.component';
import { DialogTextoLibreComponent } from 'src/app/shared/components/modals/dialogTextoLibre.component';
import { DialogOlvidoClaveComponent } from 'src/app/auth/components/dialogOlvidoClave.component';
import { DialogOlvidoUsuarioComponent } from 'src/app/auth/components/dialogOlvidoUsuario.component';
import { DialogRegistroUsuarioComponent } from 'src/app/auth/components/dialogRegistroUsuario.component';
import { DialogSetearClaveComponent } from 'src/app/auth/components/dialogSetearClave.component';
import { DialogUsuarioSearchComponent } from '../../pages/usuarios/components/dialogUsuarioSearch.component';
import { DialogPersonaSearchComponent } from '../../pages/usuarios/components/dialogPersonaSearch.component';

import { DialogForecastDetalleAnioComponent } from '../../pages/licitaciones/components/dialogForecastDetalleAnio.component';
import { DialogImportacionLicitacionComponent } from '../../pages/licitaciones/components/dialogImportacionLicitacion.component';
import { DialogImportacionLicitacionArchivoComponent } from '../../pages/licitaciones/components/dialogImportacionLicitacionArchivo.component';

@NgModule({
  declarations: [
    DefaultComponent,
    MenuItemComponent,
    MenuLinkItemComponent,
    SubmenuItemComponent,

    HomeComponent,
    DashboardComponent,
    UsuarioListaComponent,
    UsuarioEditComponent,
    UsuarioSelfEditComponent,
    RolListaComponent,
    RolEditComponent,
    EstadoListaComponent,
    EstadoEditComponent,
    ParametroSistemaListaComponent,
    ParametroSistemaEditComponent,
    MensajeUsuarioListaComponent,
    MensajeUsuarioEditComponent,
    MensajeUsuarioViewComponent,
    MenuListaComponent,
    MenuEditComponent,
    MensajeUsuarioTipoEditComponent,
    MensajeUsuarioTipoListaComponent,
    MensajeUsuarioOpcionEnvioEditComponent,
    MensajeUsuarioOpcionEnvioListaComponent,
    MailConfiguracionEditComponent,
    MailConfiguracionListaComponent,
    TenantEditComponent,
    TenantListaComponent,
    RolTipoEditComponent,
    RolTipoListaComponent,
    FaqListaComponent,
    FaqEditComponent,
    FaqViewComponent,
    TipoDocumentoListaComponent,
    TipoDocumentoEditComponent,
    PersonaListaComponent,
    PersonaEditComponent,

    DetalleLicitacionesListaComponent,
    LicitacionListaComponent,
    LicitacionEditComponent,
    ForecastListaComponent,
    ProductoLicitacionComponent,
    ProductoListaComponent,
    ProductoEditarComponent,
    LicitacionCategoriaListaComponent,
    LicitacionCategoriaEditComponent,

    DialogConfirmComponent,
    DialogMensajePopUpComponent,
    DialogTextoLibreComponent,
    DialogOlvidoClaveComponent,
    DialogOlvidoUsuarioComponent,
    DialogRegistroUsuarioComponent,
    DialogSetearClaveComponent,
    DialogUsuarioSearchComponent,
    DialogPersonaSearchComponent,

    DialogForecastDetalleAnioComponent,
    DialogImportacionLicitacionComponent,
    DialogImportacionLicitacionArchivoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    MatGridListModule,
    ButtonModule,
    GridModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    UploaderModule,
    TabModule,
    DialogModule,
    MatTabsModule,
    MatStepperModule,
    AutoCompleteModule,
    ComboBoxModule,
    MatListModule,
    MatCheckboxModule,
    CircularGaugeModule,
    MatExpansionModule,
    MatSliderModule,
    MatFormFieldModule,
    RichTextEditorModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatProgressSpinnerModule,
    TreeViewModule,
    MatAutocompleteModule,
  ],
  providers:[
    DatePipe,
    PageService,
    SortService,
    ResizeService,
    FilterService,
    GroupService,
    AnnotationsService,
    ExcelExportService,
    ToolbarService,
    DetailRowService
  ]
})
export class DefaultModule { }
