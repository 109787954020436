<mat-card>
    <mat-card-header>
      <mat-card-title>Mensaje de Usuario</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
   </mat-card-header>
  
    <mat-card-content>
  
      <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
      <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
      <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>
  
      <ng-container *ngIf="!isSearching && item != null">

        <div fxLayout="row stretch" class="zb-readonly">
    
            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                    <mat-label><b>Fecha:</b> {{item.FechaEnvio | date:'dd/MM/yyyy HH:mm:ss'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                    <mat-label><b>De:</b> {{item.UsuarioCreadorNombre}} {{item.UsuarioCreadorApellido}} - {{item.UsuarioCreadorLogin}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                <mat-expansion-panel [expanded]="true" hideToggle="true">
                  <div fxLayout="row stretch" fxLayout.xs="column">
                    <mat-label><b>Para:</b> {{item.UsuarioDestinoNombre}} {{item.UsuarioDestinoApellido}} - {{item.UsuarioDestinoLogin}}</mat-label>
                  </div>
                </mat-expansion-panel>
            </div>

            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                <mat-expansion-panel [expanded]="true" hideToggle="true">
                  <div fxLayout="row stretch" fxLayout.xs="column">
                    <mat-label><b>Asunto:</b> {{item.Asunto}}</mat-label>
                  </div>
                </mat-expansion-panel>
            </div>

        </div>

        <mat-expansion-panel [expanded]="true" style="padding: 0px; margin: 10px 0px;" hideToggle="true">

            <div fxLayout="row stretch" fxLayout.xs="column">
                <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:0px 0px; padding-top: 16px;" [innerHTML]="item.MensajeHtml">
                </div>
            </div>

        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="item.Adjuntos != null && item.Adjuntos.length > 0" [expanded]="true" style="margin: 10px 0px;" hideToggle="true">

            <div fxLayout="row stretch" fxLayout.xs="column">

                <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:0px 0px; padding-top: 16px;">
                    <mat-label><b>ADJUNTOS:</b></mat-label>
                </div>
                <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;" *ngIf="isAttachmentSearching">
                    <mat-label><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
                </div>
                <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;" *ngIf="!isAttachmentSearching">
                    <mat-nav-list>
                      <mat-list-item *ngFor="let file of item.Adjuntos" style="background-color: lightgray; margin: 5px 0px;border-radius: 10px;">
                          <a matLine href="{{file.UrlDocumento}}" target="_blank">{{file.NombreDocumento}}</a>
                      </mat-list-item>
                    </mat-nav-list>
                </div>
            </div>

        </mat-expansion-panel>

      </ng-container>

    </mat-card-content>

    <mat-card-actions style="margin-bottom: 20px;">
        <div fxLayout="row stretch" fxLayout.xs="column">
            <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">Cerrar</button>
        </div>
    </mat-card-actions>

</mat-card>