<mat-card>
  <mat-card-header>
    <mat-card-title>Editar Mis Datos</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <mat-label *ngIf="isSearching"><i class="fa fa-sclavener fa-sclave"></i> Buscando...</mat-label>
    <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

    <div *ngIf="!isSearching && usuario != null">

      <mat-expansion-panel [expanded]="true">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">account_circle</mat-icon> Datos de Usuario
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="usuarioFormGroup">

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="!userImageChanged()">
              <a target="_blank" href="{{usuario?.FotoPerfilUrl}}">
                <img src="{{usuario?.FotoPerfilUrl}}" style="height: 100px; width: 100px; border-radius: 100%;">
              </a>
            </div>
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center" *ngIf="userImageChanged()">
              <img src="{{userImageBase64}}"
                style="height: 100px; width: 100px; border-radius: 100%; margin-bottom: 10px;">
            </div>
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" align="Center">
              <button type="button" mat-raised-button [disabled]="isProcessing()" (click)="fileInput.click()">Cambiar Imagen</button>
              <input hidden type="file" (change)="convertFileToBase64($event)" accept="image/*" #fileInput color="primary">
            </div>
          </div>

          <hr style="margin:20px 0px; width: 100%;">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nombre de Usuario:</b> {{usuario?.Login}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Email</b>: {{usuario?.Mail}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Últ. Inicio Sesión</b>: {{usuario?.FechaUltimoInicioSesion | date:'dd/MM/yyyy HH:mm:ss'}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Cel.</mat-label>
                <input matInput formControlName="Movil" (keyup.enter)="onGuardarUsuario()" maxlength="100">
                <mat-error *ngIf="!usuarioFormGroup.controls['Movil'].valid && usuarioFormGroup.controls['Movil'].touched">
                  {{ getErrorMovil() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Clave*</mat-label>
                <input matInput [type]="hideClave ? 'password' : 'text'" formControlName="Clave"
                  (keypress)="claveChanged=true" (keyup.enter)="onGuardarUsuario()" maxlength="100">
                <button mat-icon-button matSuffix *ngIf="claveChanged" (click)="hideClave = !hideClave" type="button">
                  <mat-icon>{{hideClave ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="!usuarioFormGroup.controls['Clave'].valid && usuarioFormGroup.controls['Clave'].touched">
                  {{ getErrorClave() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Confirma tu Clave*</mat-label>
                <input matInput [type]="hideConfirmClave ? 'password' : 'text'" formControlName="ConfirmClave"
                  (keypress)="confirmClaveChanged=true" (keyup.enter)="onGuardarUsuario()" maxlength="100">
                <button mat-icon-button matSuffix *ngIf="confirmClaveChanged"
                  (click)="hideConfirmClave = !hideConfirmClave" type="button">
                  <mat-icon>{{hideConfirmClave ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error
                  *ngIf="!usuarioFormGroup.controls['ConfirmClave'].valid && usuarioFormGroup.controls['ConfirmClave'].touched">
                  {{ getErrorConfirmClave() }}
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center">
            <button mat-raised-button (click)="onGuardarUsuario()" [disabled]="isProcessing()"
              color="primary" class="zb-button">{{isUserSaving ? 'Guardando...' : 'Guardar Usuario'}}</button>
          </div>
      
        </form>

      </mat-expansion-panel>

      <hr style="margin:20px 0px; width: 100%;">

      <mat-expansion-panel [expanded]="true">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">person</mat-icon> Datos de Persona
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="personaFormGroup">

          <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre*</mat-label>
                <input matInput formControlName="Nombre" (keyup.enter)="onGuardarPersona()" maxlength="150">
                <mat-error *ngIf="!personaFormGroup.controls['Nombre'].valid && personaFormGroup.controls['Nombre'].touched">
                  {{ getErrorNombre() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Apellido</mat-label>
                <input matInput formControlName="Apellido" (keyup.enter)="onGuardarPersona()" maxlength="150">
                <mat-error *ngIf="!personaFormGroup.controls['Apellido'].valid && personaFormGroup.controls['Apellido'].touched">
                  {{ getErrorApellido() }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Tipo Doc.</b>: {{persona.TipoDocumentoNombre}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>
            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-expansion-panel [expanded]="true" hideToggle="true">
                <div fxLayout="row stretch" fxLayout.xs="column">
                  <mat-label><b>Nro. Doc.</b>: {{persona.NroDocumento}}</mat-label>
                </div>
              </mat-expansion-panel>
            </div>

            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Fecha Nacimiento*</mat-label>
                <input matInput [matDatepicker]="fechaNacimientoPicker" formControlName="FechaNacimiento" readonly (click)="fechaNacimientoPicker.open()">
                <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaNacimientoPicker">
                </mat-datepicker-toggle>
                <mat-datepicker #fechaNacimientoPicker></mat-datepicker>
                <mat-error *ngIf="!personaFormGroup.controls['FechaNacimiento'].valid && personaFormGroup.controls['FechaNacimiento'].touched">
                  {{ getErrorFechaNacimiento() }}
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center">
            <button mat-raised-button (click)="onGuardarPersona()" [disabled]="isProcessing()"
              color="primary" class="zb-button">{{isPersonSaving ? 'Guardando...' : 'Guardar Persona'}}</button>
          </div>

        </form>

        <hr style="margin:20px 0px; width: 100%;">

        <div fxLayout="row stretch" fxLayout.xs="column" class="zb-readonly">

          <div fxFlex=100 fxFlex.xs=100 class="flex-item" fxLayoutAlign="center" style="margin-bottom: 15px;">
            <mat-label><u><b>Datos Biométricos</b></u></mat-label>
          </div>

          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true" >
              <div fxLayout="row stretch" fxLayout.xs="column">
                <a href="{{persona?.FotoDniFrenteUrl}}" target="_blank" *ngIf="hasPersonaFotoDniFrente()">Ver Foto DNI Frente</a>
                <mat-label *ngIf="!hasPersonaFotoDniFrente()">
                  <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de DNI frente</b>
                </mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <br/>
          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <a href="{{persona?.FotoDniDorsoUrl}}" target="_blank" *ngIf="hasPersonaFotoDniDorso()">Ver Foto DNI Dorso</a>
                <mat-label *ngIf="!hasPersonaFotoDniDorso()">
                  <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de DNI dorso</b>
                </mat-label>
              </div>
            </mat-expansion-panel>
          </div>
          <br/>
          <div fxFlex=33 fxFlex.xs=100 class="flex-item">
            <mat-expansion-panel [expanded]="true" hideToggle="true">
              <div fxLayout="row stretch" fxLayout.xs="column">
                <a href="{{persona?.FotoSelfieUrl}}" target="_blank" *ngIf="hasPersonaFotoSelfie()">Ver Foto Selfie</a>
                <mat-label *ngIf="!hasPersonaFotoSelfie()">
                  <b><i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i> No tiene imagen de selfie</b>
                </mat-label>
              </div>
            </mat-expansion-panel>
          </div>

        </div>

      </mat-expansion-panel>

    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">Cerrar</button>
    </div>
  </mat-card-actions>

</mat-card>