import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { SharedFunctions } from 'src/app/shared/shared.functions';

import { SeguridadService, ParametroSistemaService, TenantService } from '../../shared/services';
import { RegisterUsuarioDto, Tenant } from '../../shared/model';

@Component({
  selector: 'app-dialogRegistroUsuario',
  templateUrl: 'dialogRegistroUsuario.component.html'
})

export class DialogRegistroUsuarioComponent implements OnInit {
  @ViewChild('input') input: ElementRef;
  formGroup: FormGroup;
  private entity: RegisterUsuarioDto;
  tenantList: Tenant[] = [];
  hideClave: boolean = true;
  hideConfirmClave: boolean = true;
  buttonEnabled: boolean = true;
  allowUserRegistration: boolean = false;
  mustWaitUserConfirm: boolean = false;
  isTenantSearching: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogRegistroUsuarioComponent>,
    private formBuilder: FormBuilder, 
    private sharedFunctions: SharedFunctions,
    private seguridadService: SeguridadService,
    private tenantService: TenantService,
    private parametroSistemaService: ParametroSistemaService
  )
  {
  }

  ngOnInit(): void {
    this.buildForm();
    this.getTenants();

    this.parametroSistemaService.getAnonymousCode("PermitirAltaUsuarios").subscribe(response => {
      if (response && response.Success) {
        this.allowUserRegistration = response.Result?.Valor ? (response.Result?.Valor?.toLowerCase() == "true" || response.Result?.Valor?.toLowerCase() == "1") : false;
      }
    }, 
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });

    this.parametroSistemaService.getAnonymousCode("ConfirmarActivacionUsuariosNuevos").subscribe(response => {
      if (response && response.Success) {
        this.mustWaitUserConfirm = response.Result?.Valor ? (response.Result?.Valor?.toLowerCase() == "true" || response.Result?.Valor?.toLowerCase() == "1") : false;
      }
    }, 
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'Apellido': [null, Validators.maxLength(150)],
      'NroDocumento': [null, Validators.maxLength(100)],
      'Mail': [null, [Validators.required, Validators.maxLength(255), Validators.email]],
      'Movil': [null, Validators.maxLength(100)],
      'Login': [null, [Validators.required, Validators.maxLength(100)]],
      'Clave': [null, [Validators.required, Validators.maxLength(100)]],
      'ConfirmClave': [null, [Validators.required, Validators.maxLength(100)]],
      'TenantId': [null, Validators.required]
    });
  }

  getTenants() {
    this.isTenantSearching = true;
    this.tenantService.getAnonymousList().subscribe(data => {
      this.isTenantSearching = false;
      if (data != null)
        this.tenantList = data.Result;
    },
    (err: HttpErrorResponse) => {
      this.isTenantSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getErrorTenantId() {
    return this.formGroup.get('TenantId').hasError('required') ? 'El campo es obligatorio' : '';
  }

  getErrorNombre() {
    var v = this.formGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorApellido() {
    var v = this.formGroup.get('Apellido');
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorMail() {
    var v = this.formGroup.get('Mail');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    if (v.hasError('email'))
      return 'Debe ingresar un email con formato válido';
    return '';
  }

  getErrorLogin() {
    var v = this.formGroup.get('Login');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorClave() {
    var v = this.formGroup.get('Clave');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorConfirmClave() {
    var v = this.formGroup.get('ConfirmClave');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  onCancelar() {
    this.dialogRef.close(null);
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor completa todos los datos obligatorios marcados con asterisco" , "Error");
      return false;
    }

    if (this.tenantList != null && this.tenantList.length == 1)
      this.formGroup.get("TenantId").setValue(this.tenantList[0].Id);

    var value = { ... this.entity, ... this.formGroup.getRawValue() };

    if (value == null) {
      this.sharedFunctions.showMessage("Debe completar todos los campos para continuar con la operación", "Error");
      return false;
    }

    if (value.Clave != value.ConfirmClave) {
      this.sharedFunctions.showMessage("La clave ingresada y su confirmación son distintas", "Error");
      return false;
    }

    this.buttonEnabled = false;
    this.seguridadService.register(value).subscribe(data => {
      this.buttonEnabled = true;
      if (data.Success) {
        var msg = "Se cargaron exitosamente los datos de tu usuario. Vas a recibir un correo en la casilla de mail que ingresaste previamente y " +
          "la cual se utilizará para comunicarnos con vos en adelante.";
        if (this.mustWaitUserConfirm)
          msg += "Por el momento aguarda mientras validamos la información que ingresaste para poder utilizar el sistema. Te avisaremos por mail cuando finalicemos.";

        this.sharedFunctions.showMessage(msg, "Operación Exitosa!");
        this.dialogRef.close(true);
      } else {
        this.sharedFunctions.showMessage("Ocurrió un error generando el usuario. Vuelva a intentarlo por favor", "Error");
      }
    },
      (err: HttpErrorResponse) => {
        this.buttonEnabled = true;
        this.sharedFunctions.handleError(err, true);
      });
  }
}