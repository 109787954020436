import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { Column, ExcelExportProperties, GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { isNumber } from 'highcharts';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { MenuFuncionService, LicitacionService, EstadoService, ProductoService } from 'src/app/shared/services/index';
import { GridPageSettings, MenuFuncionCodes, SearchLicitacionDetalleLicitacionesRequest, SearchEstadoRequest, Estado, SearchProductoRequestDto, Producto, LicitacionEspecificacionTecnicaResponse } from 'src/app/shared/model/index';

import { DialogImportacionLicitacionComponent } from './components/dialogImportacionLicitacion.component';
import { DialogImportacionLicitacionArchivoComponent } from './components/dialogImportacionLicitacionArchivo.component';

@Component({
  selector: 'app-detalleLicitacionesLista',
  templateUrl: './detalleLicitacionesLista.component.html',
  styleUrls: ['./detalleLicitacionesLista.component.scss']
})

export class DetalleLicitacionesListaComponent implements OnInit {
  @ViewChild('grid')
  public grid: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;
  filteredProductoList: Producto[];

  isProductoSearching: boolean = false;
  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;

  productoList: Producto[];
  estadoList: Estado[];
  isEstadoSearching: boolean = false;

  canView: boolean = false;
  canImport: boolean = false;
  canImportArchivo: boolean = false;
  canExport: boolean = false;

  public query: Query;

  public selectionOptions: SelectionSettingsModel;
  public pageSizes: number[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private menuService: MenuFuncionService,
    private estadoService: EstadoService,
    private service: LicitacionService,
    private datePipe: DatePipe,
    private productoService: ProductoService,
  ) {

  }

  ngOnInit(): void {
    this.isValidating = true;
    
    this.menuService.checkMenu(MenuFuncionCodes.REPORTE_LICITACION_DETALLE).subscribe(data => {
      this.isValidating = false;
      if (data != null && data.Success && data?.Result) {
        this.canView = true;
        this.loadView();
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.router.navigate(['/']);
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.buildForm();
    this.getEstados();
    this.getProductos();

    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
    this.toolbarOptions = null; //['ExcelExport'];

    this.data = new DataManager();

    this.menuService.checkMenu(MenuFuncionCodes.REPORTE_LICITACION_DETALLE_EXPORT).subscribe(data => {
      this.isValidating = false;
      this.canExport = data?.Result;
        },(err: HttpErrorResponse) => {
            this.isValidating = false;
            this.sharedFunctions.handleError(err, true);
        });
    this.menuService.checkMenuPadre(MenuFuncionCodes.LICITACION).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.canImport = data.Result.some(x => x == MenuFuncionCodes.IMPORTACION_LICITACIONES);
        this.canImportArchivo = data.Result.some(x => x == MenuFuncionCodes.IMPORTACION_ARCHIVO_LICITACIONES);
      }
    },
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'NumeroLicitacion': [null],
      'EstadoLicitacionId': [null],
      'Descripcion': [null],
      'Producto': [null],
      'FechaDesde': [new Date(new Date().getFullYear(), 0, 1)],
      'FechaHasta': [new Date()]
    });
  }

  getProductos() {
    this.searchForm.controls.Producto.valueChanges
      .pipe(
        filter(res => {
          return res && res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredProductoList = [];
          this.isProductoSearching = true;
        }),
        switchMap(value => this.productoService.search(new SearchProductoRequestDto(20, 0, true, "Codigo", isNumber(parseInt(value)) ? value : null, (!isNumber(parseInt(value)) ? value : null), true))
          .pipe(
            finalize(() => {
              this.isProductoSearching = false;
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data != null && data.result != null)
          this.filteredProductoList = data.result;
        else
          this.filteredProductoList = [];
      });
  }

  displayProducto(producto: Producto) {
    return producto ? producto?.Codigo +  " - " + producto?.Nombre : "";
  }

  getEstados() {
    var r = new SearchEstadoRequest(null, null, true, "Nombre", null, null, true);
    this.isEstadoSearching = true;

    this.estadoService.searchLicitacionEstados(r).subscribe(data => {
      this.isEstadoSearching = false;
      if (data != null)
        this.estadoList = data?.result;
    },
      (err: HttpErrorResponse) => {
        this.isEstadoSearching = false;
        this.sharedFunctions.handleError(err);
      });
  }

  onGridActionFailure(event: any): void {
    this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
  }

  onSearch() {
    this.setQueryParams();
    this.data = new DataManager({
      url: this.service.getReporteDetalleLicitacionesFarmaciaUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.service.getAuthorizationHeader()],
    });
    this.grid.refresh();
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "NumeroLicitacion");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.NumeroLicitacion;
    else
      this.query.addParams("NumeroLicitacion", this.searchForm.value.NumeroLicitacion);

    index = this.query.params.findIndex(obj => obj.key === "EstadoLicitacionId");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.EstadoLicitacionId;
    else
      this.query.addParams("EstadoLicitacionId", this.searchForm.value.EstadoLicitacionId);

    index = this.query.params.findIndex(obj => obj.key === "ProductoId");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Producto?.Id;
    else
      this.query.addParams("ProductoId", this.searchForm.value.Producto?.Id);

    index = this.query.params.findIndex(obj => obj.key === "Descripcion");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Descripcion;
    else
      this.query.addParams("Descripcion", this.searchForm.value.Descripcion);

    index = this.query.params.findIndex(obj => obj.key === "FechaDesde");
    if (index >= 0)
      this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaDesde, "yyyy-MM-dd");
    else
      this.query.addParams("FechaDesde", this.datePipe.transform(this.searchForm.value.FechaDesde, "yyyy-MM-dd"));

    index = this.query.params.findIndex(obj => obj.key === "FechaHasta");
    if (index >= 0)
      this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaHasta, "yyyy-MM-dd");
    else
      this.query.addParams("FechaHasta", this.datePipe.transform(this.searchForm.value.FechaHasta, "yyyy-MM-dd"));

    index = this.query.params.findIndex(obj => obj.key === "TieneProductos");
    if (index >= 0)
      this.query.params[index].value = "true";
    else
      this.query.addParams("TieneProductos", "true");
  }

  clearDate(formFieldName: string) {
    if (formFieldName != null && formFieldName.length > 0) {
      if (this.searchForm.get(formFieldName) != null && this.searchForm.get(formFieldName)?.value)
        this.searchForm.get(formFieldName).setValue(null);
    }
  }

  onExport() {
   
    if (!this.canExport)
      return false;

    var search = new SearchLicitacionDetalleLicitacionesRequest
    (
      null, 
      null, 
      null, 
      "", 
      this.searchForm.value.NumeroLicitacion, 
      this.searchForm.value.Producto?.Id, 
      this.searchForm.value.Descripcion, 
      this.searchForm.value.EstadoLicitacionId, 
      this.searchForm.value.FechaDesde, 
      this.searchForm.value.FechaHasta,
      true
    );

    this.isExporting = true;
    this.service.exportReporteDetalleLicitacionesFarmacia(search).subscribe((data: Blob) => {
      this.isExporting = false;
      var fileUrl = URL.createObjectURL(data);

      var anchor = document.createElement("a");
      anchor.download = "DetalleLicitacionesFarmacia_" + this.datePipe.transform(new Date(), "yyyy-MM-dd") + ".csv";
      
      anchor.href = fileUrl;
      anchor.click();
    },
      (err: HttpErrorResponse) => {
        this.isExporting = false;
        this.sharedFunctions.handleError(err, true);
      })
  }

  onImportLicitacion() {
    const dialogRef = this.dialog.open(DialogImportacionLicitacionComponent, {
      width: '60%',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  onImportArchivoLicitacion() {
    const dialogRef = this.dialog.open(DialogImportacionLicitacionArchivoComponent, {
      width: '60%',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  edit(data:any ){
    if (!data.LicitacionId || data.LicitacionId  == null || data.LicitacionId  <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }
    this.router.navigate(['licitacion-edit', data.LicitacionId ]); //hacer el componente, agregar la ruta
  }
}
