<h2 mat-dialog-title>Importar Licitación</h2>

<div mat-dialog-content>

  <form [formGroup]="formGroup">

    <mat-expansion-panel [expanded]="true">

      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon style="margin-top: -3px; margin-right: 5px;">cloud_download</mat-icon> Por Número de Licitación
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        <div fxFlex=100 fxFlex.xs=100 class="flex-item">
          <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Nro. Licitación a Importar*</mat-label>
              <input matInput formControlName="NumeroLicitacion" (keyup.enter)="onImportar()" maxlength="100">
              <mat-error
                  *ngIf="!formGroup.controls['NumeroLicitacion'].valid && formGroup.controls['NumeroLicitacion'].touched">
                  {{ getErrorNumeroLicitacion() }}
              </mat-error>
          </mat-form-field>
        </div>
      </div>

    </mat-expansion-panel>

    <br/>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon style="margin-top: -3px; margin-right: 5px;">cloud_download</mat-icon> Por Rango de Fechas y Estado
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        
        <div fxFlex=33 fxFlex.xs=100 class="flex-item">
          <mat-form-field appearance="outline" style="width: 100%" >
            <mat-label>Fecha Publicación Desde*</mat-label>
            <input matInput [matDatepicker]="fechaInicioPicker" formControlName="FechaInicio" readonly (click)="fechaInicioPicker.open()">
            <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaInicioPicker">
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix (click)="clearDate('FechaInicio')">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #fechaInicioPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex=33 fxFlex.xs=100 class="flex-item">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Fecha Publicación Hasta*</mat-label>
            <input matInput [matDatepicker]="fechaFinPicker" formControlName="FechaFin" readonly (click)="fechaFinPicker.open()">
            <mat-datepicker-toggle style="display:inline-block;" matSuffix [for]="fechaFinPicker">
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix (click)="clearDate('FechaFin')">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #fechaFinPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex=34 fxFlex.xs=100 class="flex-item">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label *ngIf="!isEstadoSearching">Estado</mat-label>
            <mat-label *ngIf="isEstadoSearching">Cargando...</mat-label>
            <mat-select formControlName="Estado">
              <mat-option>Todos</mat-option>
              <mat-option *ngFor="let s of estadoList" [value]="s.Id">{{s.Nombre}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

    </mat-expansion-panel>

  </form>

</div>

<br/>

<div mat-dialog-actions>
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="primary" (click)="onImportar()" class="zb-button" [disabled]="isImporting">
      {{isImporting ? 'Importando...' : 'Importar Licitación'}}
    </button>
    <button mat-raised-button color="warn" (click)="onCancel()" class="zb-button">Cerrar</button>
  </div>
</div>