<h1 mat-dialog-title>Seteo de Clave</h1>

<div mat-dialog-content>

  <form [formGroup]="formGroup">

    <div fxLayout="row stretch" fxLayout.xs="column">
      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-label>Su usuario debe reiniciar su clave. Complete la siguiente información para llevar a cabo la operación:</mat-label>
      </div>
    </div>

    <br/>

    <div fxLayout="row stretch" fxLayout.xs="column">

      <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin: 10px 0px">
        <mat-label><b>Nombre de Usuario</b>: {{data?.Login}}</mat-label>
      </div>

      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Clave Anterior</mat-label>
          <input matInput [type]="hideClaveAnterior ? 'password' : 'text'" formControlName="Codigo" (keyup.enter)="onGuardar()">
          <button mat-icon-button matSuffix (click)="hideClaveAnterior = !hideClaveAnterior" type="button"><mat-icon>{{hideClaveAnterior ? 'visibility_off' : 'visibility'}}</mat-icon></button>
          <mat-error *ngIf="!formGroup.controls['Codigo'].valid && formGroup.controls['Codigo'].touched">
            {{ getErrorCodigo() }}
          </mat-error>
        </mat-form-field>
      </div>

      <hr style="margin:10px 0px; width: 100%;">

      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Nueva Clave</mat-label>
          <input matInput [type]="hideClave ? 'password' : 'text'" formControlName="Clave" (keyup.enter)="onGuardar()">
          <button mat-icon-button matSuffix (click)="hideClave = !hideClave" type="button"><mat-icon>{{hideClave ? 'visibility_off' : 'visibility'}}</mat-icon></button>
          <mat-error *ngIf="!formGroup.controls['Clave'].valid && formGroup.controls['Clave'].touched">
            {{ getErrorClave() }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Confirmación de Clave</mat-label>
          <input matInput [type]="hideConfirmClave ? 'password' : 'text'" formControlName="ConfirmClave" (keyup.enter)="onGuardar()">
          <button mat-icon-button matSuffix (click)="hideConfirmClave = !hideConfirmClave" type="button"><mat-icon>{{hideConfirmClave ? 'visibility_off' : 'visibility'}}</mat-icon></button>
          <mat-error *ngIf="!formGroup.controls['ConfirmClave'].valid && formGroup.controls['ConfirmClave'].touched">
            {{ getErrorConfirmClave() }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>
  </form>

</div>

<div mat-dialog-actions align="end">
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="primary" [disabled]="!buttonEnabled" (click)="onGuardar()" class="zb-button">{{buttonEnabled ? 'Guardar' : 'Guardando...'}}</button>
    <button mat-raised-button color="warn" (click)="onCancelar()" [disabled]="!buttonEnabled" class="zb-button">Cancelar</button>
  </div>
</div>