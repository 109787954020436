import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, Faq, SearchFaqRequestDto, FaqRol, SaveFaq, UploadFaqAttachResponseDto, FaqAdjunto, ViewFaqRequestDto, ViewFaqResponseDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends CrudService<Faq, number, SaveFaq, SearchFaqRequestDto> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'faq', sharedFunctions);

  }

  getAttachments(id: number): Observable<GenericResponse<FaqAdjunto[]>> {
    return this._http.get<GenericResponse<FaqAdjunto[]>>(this._serviceUrl + '/' + id + '/adjuntos', this.httpOptions);
  }

  getRoles(id: number): Observable<GenericResponse<FaqRol[]>> {
    return this._http.get<GenericResponse<FaqRol[]>>(this._serviceUrl + '/' + id + '/roles', this.httpOptions);
  }

  viewFaqs(p: ViewFaqRequestDto): Observable<ViewFaqResponseDto> {
    var queryString = this.sharedFunctions.convertToQueryString(p);
    return this._http.get<ViewFaqResponseDto>(this._serviceUrl+'/view'+queryString, this.httpOptions);
  }

  viewFaqsPublic(p: ViewFaqRequestDto): Observable<ViewFaqResponseDto> {
    var queryString = this.sharedFunctions.convertToQueryString(p);
    return this._http.get<ViewFaqResponseDto>(this._serviceUrl+'/view/public'+queryString, this.httpOptions);
  }

  uploadAttachTempUrl(): string {
    return this._serviceUrl + '/attach/temp';
  }

  uploadAttachTemp(p: any): Observable<GenericResponse<UploadFaqAttachResponseDto>> {
    const formData = new FormData();

    if (p != null) {
      for (let i = 0; i < p.length; i++) {
        const file = p[i];
        if (file != null)
          formData.append('uploadFiles', file);
      }
    }

    return this._http.post<GenericResponse<UploadFaqAttachResponseDto>>(this.uploadAttachTempUrl(), formData, this.httpFormDataOptions);
  }
}