import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as signalR from '@microsoft/signalr';

import { SignalRConstants } from '../model/index';
import { SendMensajeUsuarioNotificationDto } from '../model/index';

@Injectable({
  providedIn: 'root',
})
export class SignalrMensajeUsuarioService {
  _connection: signalR.HubConnection;
  _resource: string;

  receiveMessage: BehaviorSubject<SendMensajeUsuarioNotificationDto>;

  constructor() {
    this.receiveMessage = new BehaviorSubject<SendMensajeUsuarioNotificationDto>(null);
  }

  // Establish a connection to the SignalR server hub
  public initiateSignalrConnection(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._connection = new signalR.HubConnectionBuilder()
        .withUrl(environment.signalrHubUrl + "mensajeUsuarioHub") // the SignalR server url as set in the .NET Project properties and Startup class
        .withAutomaticReconnect(SignalRConstants.RetriesArray)
        .build();

      this.setSignalrClientMethods();

      this._connection
        .start()
        .then(() => {
          // console.log(`SignalR connection success! connectionId: ${this._connection.connectionId} `);
          resolve();
        })
        .catch((error) => {
          console.log(`SignalR connection error: ${error}`);
          reject();
        });
    });
  }

  // This method will implement the methods defined in the ISignalrDemoHub interface in the SignalrDemo.Server .NET solution
  private setSignalrClientMethods(): void {
    this._connection.on('ReceiveMessage', (model: SendMensajeUsuarioNotificationDto) => {
      this.receiveMessage.next(model);
    });
  }
}