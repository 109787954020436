<mat-card>

  <mat-card-header>
    <mat-card-title>Preguntas Frecuentes</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <form [formGroup]="searchForm">

      <div fxLayout="row stretch" class="zb-readonly" fxLayoutAlign="center center">
        <div fxFlex=40 fxFlex.xs=100 class="flex-item" *ngIf="tenantList != null && tenantList.length != 1">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label *ngIf="!isTenantSearching">Empresa</mat-label>
            <mat-label *ngIf="isTenantSearching">Cargando...</mat-label>
            <mat-select formControlName="TenantId" (selectionChange)="onTenantSelect()">
              <mat-option *ngFor="let s of tenantList" [value]="s.Id">{{s.Nombre}}</mat-option>
            </mat-select>
          </mat-form-field>

          <hr style="margin: 10px 0px; width: 100%;">
        </div>
      </div>

      <div fxLayout="row stretch" *ngIf="hasTenantSelected()">
        
        <div fxFlex=100 fxFlex.xs=100 class="flex-item">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label style="text-align: center;">Buscar por texto de pregunta o palabras clave</mat-label>
            <input matInput formControlName="Texto" (keyup.enter)="onFilterSearch()">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>

      </div>

    </form>

    <div fxLayout="row stretch" class="zb-readonly" *ngIf="!isSearching && hasSearched && (itemList == null || itemList.length == 0)" style="text-align: center; color: indianred;">
      <mat-label>
        <i class="fa fa-exclamation-triangle fa-2x"></i>
        <br/>
        No se encontraron resultados para su búsqueda. Intente nuevamente por favor
      </mat-label>
    </div>

    <div fxLayout="row stretch" class="zb-readonly" *ngIf="itemList != null && itemList.length > 0">

      <div fxFlex=100 fxFlex.xs=100 class="flex-item" *ngFor="let item of itemList">
        <mat-expansion-panel [expanded]="false">

          <mat-expansion-panel-header style="background-color: rgb(207, 207, 207);">
            <mat-panel-title>
              <div style="margin-top: 12px;" [innerHTML]="item.PreguntaHtml"></div>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:0px 0px; padding-top: 16px;" [innerHTML]="item.RespuestaHtml">
            </div>
          </div>

          <ng-container *ngIf="item.Adjuntos != null && item.Adjuntos.length > 0">

            <hr style="margin: 15px 0px; width: 100%;">

            <div fxLayout="row stretch" fxLayout.xs="column">
              <mat-nav-list>
                <mat-list-item *ngFor="let file of item.Adjuntos" style="background-color: lightgray; margin: 5px;border-radius: 10px; padding: 10px;">
                  <a matLine style="font: 400 14px/20px Roboto, 'Helvetica Neue', sans-serif; letter-spacing: normal; color: black; text-decoration: none;" href="{{file.UrlDocumento}}" target="_blank">{{file.NombreDocumento}}</a>
                </mat-list-item>
              </mat-nav-list>
            </div>

          </ng-container>

        </mat-expansion-panel>
      </div>

      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutAlign="center center" style="text-align: center;">
          <button mat-raised-button (click)="onSearch()" [ngStyle]="{'width': (isMobile ? '100%' : 'auto')}" class="zb-button" [disabled]="isSearching">{{isSearching ? 'Cargando...' : 'Ver mas'}}</button>
        </div>
      </div>

    </div>

    <div fxLayout="row stretch" fxLayout.xs="column" style="text-align: center;">
      <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
      <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>
    </div>

  </mat-card-content>

  <mat-card-actions style="margin-bottom: 20px;">
    <div fxLayout="row stretch" fxLayout.xs="column">
      <button mat-raised-button (click)="onCancel()" color="warn" [ngStyle]="{'width': (isMobile ? '100%' : 'auto')}" class="zb-button">Cerrar</button>
    </div>
  </mat-card-actions>

</mat-card>