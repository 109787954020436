import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';

import { SharedFunctions } from 'src/app/shared/shared.functions';
import { RolService, RolTipoService, MenuFuncionService, MensajeUsuarioOpcionEnvioService, MensajeUsuarioTipoService } from 'src/app/shared/services';

import { MenuFuncionCodes, SaveRolDto, Rol, MenuFuncion, SearchMenuFuncionRequestDto, SearchMenuFuncionItemDto, SearchRolTipoRequest, RolTipo, DialogConfirmResponse, DialogConfirmRequest } from 'src/app/shared/model';
import { SearchMensajeUsuarioTipoRequest, MensajeUsuarioTipo, SearchMensajeUsuarioOpcionEnvioRequest, MensajeUsuarioOpcionEnvio } from 'src/app/shared/model';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';

@Component({
    selector: 'app-rolEdit',
    templateUrl: './rolEdit.component.html',
  })

export class RolEditComponent implements OnInit {
  formGroup: FormGroup;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isDeleting: boolean = false;
  isMenuRolSearching: boolean = false;
  isMenuSearching: boolean = false;
  isMensajeOpcionEnvioSearching: boolean = false;
  isMensajeTipoSearching: boolean = false;
  isRolTipoSearching: boolean = false;
  isSaving: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canAdd: boolean = false;
  canView: boolean = false
  menuLoaded: boolean = false;
  rolMenuLoaded: boolean = false;
  puedeVerMensajeOpcionEnvio: boolean = false;
  puedeVerMensajeTipo: boolean = false;

  msgError: string = "";
  item: Rol;

  rolTipoList: RolTipo[];
  public menuList: any[] = [];
  mensajeUsuarioOpcionEnvioList: MensajeUsuarioOpcionEnvio[];
  mensajeUsuarioTipoList: MensajeUsuarioTipo[];

  rolOpcionesEnvioMensajeList: MensajeUsuarioOpcionEnvio[];
  rolMensajeUsuarioTipoList: MensajeUsuarioTipo[];
  public rolMenuList: MenuFuncion[];
  rolMensajeOpcionEnvioList: SearchMenuFuncionItemDto[];
  rolMensajeTipoList: SearchMenuFuncionItemDto[];

  @ViewChild('treeViewMenu')
  public treeObj: TreeViewComponent;
  @ViewChild('check')
  public checkboxObj: CheckBoxComponent;
  public treeViewField: Object = null;

  constructor (
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private menuFuncionService: MenuFuncionService,
    private rolTipoService: RolTipoService,
    private mensajeUsuarioOpcionEnvioService: MensajeUsuarioOpcionEnvioService,
    private mensajeUsuarioTipoService: MensajeUsuarioTipoService,
    private rolService: RolService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuFuncionService.checkMenuPadre(MenuFuncionCodes.ROL).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.ROL_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.ROL_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.ROL_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.ROL_DELETE);
        this.loadView();
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.menuFuncionService.checkMenuPadre(MenuFuncionCodes.MENSAJE_USUARIO_OPC_ENVIO).subscribe(data => {
      if (data != null && data.Success)
        this.puedeVerMensajeOpcionEnvio = (data?.Result != null && data?.Result.length > 0);
    },
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });

    this.menuFuncionService.checkMenuPadre(MenuFuncionCodes.MENSAJE_USUARIO_TIPO).subscribe(data => {
      if (data != null && data.Success)
        this.puedeVerMensajeTipo = (data?.Result != null && data?.Result.length > 0);
    },
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });
    
    this.buildForm();
    this.loadEntity();
    this.getMenus();
    this.getRolTipos();
    this.getOpcionesEnvioMensaje();
    this.getTiposMensaje();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Codigo': [null, [Validators.required, Validators.maxLength(100)]],
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'RolTipoId': [null, Validators.required],
      'Menues': [null],
      'OpcionesEnvioMensaje': [null],
      'TiposMensaje': [null]
    });
  }

  checkRolTipoLevel() {
    if (this.item != null && this.item.RolTipoId > 0 && this.rolTipoList != null && this.rolTipoList.length > 0) {
      var userMaxRolTipo = Math.min( ...this.rolTipoList.map(f => f.Id) ); 
      if (userMaxRolTipo <= this.item.RolTipoId)
        this.canEdit = true;
        this.canDelete = true;
    }
  }

  getRolTipos() {
    this.isRolTipoSearching = true;
    this.rolTipoService.getAll().subscribe(data => {
      this.isRolTipoSearching = false;
      if (data != null)
        this.rolTipoList = data?.Result;
      this.checkRolTipoLevel();
    },
    (err: HttpErrorResponse) => {
      this.isRolTipoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getMenus() {
    var r = new SearchMenuFuncionRequestDto(null, null, true, "Orden", null, null, null, null, true);
    this.isMenuSearching = true;
    this.menuFuncionService.search(r).subscribe(data => {
      this.isMenuSearching = false;
      if (data != null)
        this.menuList = data?.result;
      
      this.menuLoaded = true;
      this.setTreeviewFieldsData();
    },
    (err: HttpErrorResponse) => {
      this.isMenuSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getOpcionesEnvioMensaje() {
    var r = new SearchMensajeUsuarioOpcionEnvioRequest(null, null, true, "Nombre", null, null, null, true);
    this.isMensajeOpcionEnvioSearching = true;

    this.mensajeUsuarioOpcionEnvioService.search(r).subscribe(data => {
      this.isMensajeOpcionEnvioSearching = false;
      if (data != null)
        this.mensajeUsuarioOpcionEnvioList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isMensajeOpcionEnvioSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getTiposMensaje() {
    var r = new SearchMensajeUsuarioTipoRequest(null, null, true, "Nombre", null, null, null, true);
    this.isMensajeTipoSearching = true;

    this.mensajeUsuarioTipoService.search(r).subscribe(data => {
      this.isMensajeTipoSearching = false;
      if (data != null)
        this.mensajeUsuarioTipoList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isMensajeTipoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getMenuesByRol(rolId: number) {
    var r = new SearchMenuFuncionRequestDto(null, null, true, "Orden", null, null, rolId, null, true)
    this.isMenuRolSearching = true;

    this.menuFuncionService.search(r).subscribe(data => {
      this.isMenuRolSearching = false;
      if (data != null)
        this.rolMenuList = data?.result;

      this.rolMenuLoaded = true;
      this.setTreeviewFieldsData();
    },
    (err: HttpErrorResponse) => {
      this.isMenuRolSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getOpcionesEnvioMensajeByRol(rolId: number) {
    var r = new SearchMensajeUsuarioOpcionEnvioRequest(null, null, true, "Nombre", null, null, rolId, true);
    this.isMensajeOpcionEnvioSearching = true;

    this.mensajeUsuarioOpcionEnvioService.search(r).subscribe(data => {
      this.isMensajeOpcionEnvioSearching = false;
      if (data != null)
        this.rolOpcionesEnvioMensajeList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isMensajeOpcionEnvioSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getTiposMensajeByRol(rolId: number) {
    var r = new SearchMensajeUsuarioTipoRequest(null, null, true, "Nombre", null, null, rolId, true);
    this.isMensajeTipoSearching = true;

    this.mensajeUsuarioTipoService.search(r).subscribe(data => {
      this.isMensajeTipoSearching = false;
      if (data != null)
        this.rolMensajeUsuarioTipoList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isMensajeTipoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  setTreeviewFieldsData() {
    if (this.menuLoaded && this.rolMenuLoaded) {
      if (this.rolMenuList != null && this.rolMenuList.length > 0) {
        this.rolMenuList.forEach(m => {
          var index = this.menuList.findIndex(f => f.Id == m.Id);
          if (index >= 0)
            this.menuList[index].isChecked = true;
        });
      }

      if (this.treeObj != null)
        this.treeObj.autoCheck = false;

      this.treeViewField = 
      { 
        dataSource: this.menuList,
        id: 'Id',
        parentID: 'MenuPadreId',
        text: 'Nombre',
        hasChildren: 'TieneHijos'
      };
    }
  }

  compareMenues(o1: number, o2: number): boolean {
    return o1 === o2;
  }

  compareOpcionesEnvioMensaje(o1: number, o2: number): boolean {
    return o1 === o2;
  }

  compareTiposMensaje(o1: number, o2: number): boolean {
    return o1 === o2;
  }

  isProcessing() {
    return (this.isSearching || this.isSaving);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        var id = Number(params['id']);
        if (id > 0) {
          if(this.canView || this.canEdit){

            this.isSearching = true;
            this.rolService.get(id).subscribe(data => {
              this.isSearching = false;
              if (data != null && data.Success) {
                this.item = data.Result;
                
                if (this.item != null && this.item.Id > 0) {
                  this.checkRolTipoLevel();
                  this.formGroup.patchValue(this.item);
                  
                  if (this.item.Funciones != null)
                  this.formGroup.get('Menues').setValue(this.item.Funciones.map(item => item.MenuFuncionId));
                  
                  if (this.item.OpcionesEnvioMensaje != null)
                  this.formGroup.get('OpcionesEnvioMensaje').setValue(this.item.OpcionesEnvioMensaje.map(item => item.MensajeUsuarioOpcionEnvioId));
                  
                  if (this.item.TiposMensaje != null)
                  this.formGroup.get('TiposMensaje').setValue(this.item.TiposMensaje.map(item => item.MensajeUsuarioTipoId));
                  
                  this.getOpcionesEnvioMensajeByRol(this.item.Id);
                  this.getTiposMensajeByRol(this.item.Id);
                  this.getMenuesByRol(this.item.Id);
                }
                else
                this.msgError = "No se encontró el rol seleccionado.";
              }
              else
              this.msgError = "Ocurrió un error al intentar obtener los datos del rol.";
            },
            (err: HttpErrorResponse) => {
              this.isSearching = false;
              this.sharedFunctions.handleError(err, true);
            })
          }else{
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();    
            }
        }
        else
          this.msgError = "Item no válido.";
      } 
      else {
          if(this.canAdd) {
            this.canEdit = true;
            this.item = new Rol(0, null, null, true, false, null, null, null, null, null);
            this.formGroup.patchValue(this.item);
            this.rolMenuLoaded = true;
            this.setTreeviewFieldsData();
          }
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel(); 
          }
        }
    });
  }

  getErrorCodigo() {
    var v = this.formGroup.get('Codigo');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorNombre() {
    var v = this.formGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorRolTipoId() {
    return this.formGroup.get('RolTipoId').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getErrorMenues() {
    return this.formGroup.get('Menues').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getErrorOpcionesEnvioMensaje() {
    return this.formGroup.get('OpcionesEnvioMensaje').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getErrorTiposMensaje() {
    return this.formGroup.get('TiposMensaje').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getTotalSelectedMenues() {
    // return (this.formGroup?.controls['Menues']?.value?.length ?? 0);
    return (this.treeObj?.checkedNodes?.length ?? 0);
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new SaveRolDto(null, null, null, null, false, true, null, null, null);

    r = 
    {
      ...this.formGroup.value,
      ...
      {
        Id: (this.item?.Id ?? 0),
        Activo: this.item.Activo,
        EsDefaultParaAltas: this.item.EsDefaultParaAltas,
        Menues: this.treeObj?.checkedNodes
      } 
    };

    this.isSaving = true;
    this.rolService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onCancel() {
    this.location.back();
  }

  concatMensajeOpcionesEnvio() {
    return this.rolOpcionesEnvioMensajeList?.map(m => m.Nombre)?.join(", ");
  }

  concatMensajeTipos() {
    return this.rolMensajeUsuarioTipoList?.map(m => m.Nombre)?.join(", ");
  }

  onDelete() {
    if (!this.canDelete) 
        return false;
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar el rol?", null, null)
    });
    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
        if (result != null && result.Accepted) {
            this.isDeleting = true;
            this.rolService.delete(this.item.Id).subscribe(data => {
                this.isDeleting = false;
                if (data != null && data.Success && data.Result) {
                    this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
                    this.onCancel();
                }
                else
                    this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
            },
            (err: HttpErrorResponse) => {
                this.isDeleting = false;
                this.sharedFunctions.handleError(err, true);
            })
        }
    });
  }
}
