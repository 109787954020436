import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { EstadoService, LicitacionService } from 'src/app/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { Estado, ProcessLicitacionesFromWebDto, SearchEstadoRequest } from 'src/app/shared/model';

@Component({
    selector: 'app-dialogImportacionLicitacion',
    templateUrl: 'dialogImportacionLicitacion.component.html',
    styleUrls: ['./dialogImportacionLicitacion.component.css']
})

export class DialogImportacionLicitacionComponent implements OnInit {

  formGroup: FormGroup;

  estadoList: Estado[];

  isImporting: boolean = false;
  isEstadoSearching: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DialogImportacionLicitacionComponent>,
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private estadoService: EstadoService,
    private licitacionService: LicitacionService
  ) {

  }

  ngOnInit(): void {
    this.buildForm();
    this.getEstados();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'NumeroLicitacion': [null, [Validators.maxLength(100)]],
      'Estado': [null],
      'FechaInicio': [new Date()],
      'FechaFin': [new Date()]
    });
  }

  getEstados() {
    var r = new SearchEstadoRequest(null, null, true, "Nombre", null, null, true);
    this.isEstadoSearching = true;

    this.estadoService.searchLicitacionEstados(r).subscribe(data => {
      this.isEstadoSearching = false;
      if (data != null)
        this.estadoList = data?.result;
    },
      (err: HttpErrorResponse) => {
        this.isEstadoSearching = false;
        this.sharedFunctions.handleError(err);
      });
  }

  getErrorNumeroLicitacion() {
    var v = this.formGroup.get('NumeroLicitacion');
    if (v.hasError('required'))
        return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
        return 'Límite de 100 caracteres alcanzado';
    return '';
}

  onImportar() {
    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Complete los datos obligatorios para continuar", "Error");
      return;
    }

    var r = new ProcessLicitacionesFromWebDto(null, null, null, null);
    r = Object.assign(r, this.formGroup.value);

    this.isImporting = true;
    this.licitacionService.importarLicitaciones(r).subscribe(data => {
      this.isImporting = false;
      if (data != null && data.Success && data.Result != null) {
        var importacionPorNumero = (r.NumeroLicitacion != null && r.NumeroLicitacion.length > 0);
        if (importacionPorNumero) {
          if ((data.Result.TotalNuevos + data.Result.TotalActualizados) > 0)
            this.sharedFunctions.showMessage("Se " + (data.Result.TotalNuevos > 0 ? "importó" : "actualizó") + " éxitosamente la licitación " + r.NumeroLicitacion, "Operación Exitosa!");
          else
            this.sharedFunctions.showMessage("Ninguna licitación fue modificada o importada", "Error");
        }
        else {
          this.sharedFunctions.showMessage(
            "Se importaron " + data.Result.TotalNuevos + " licitaciones nuevas y se actualizaron " + data.Result.TotalActualizados + " existentes." + 
            (data.Result.Errores != null && data.Result.Errores.length > 0 ? (" Ocurrieron algunos errores intentando llevar a cabo la operación" + 
              (data.Result.Errores.length <= 5 ? ": " + data.Result.Errores.join(", ") : "")) : ""), 
            "Operación Exitosa!");
        }

        if (data.Result.Errores == null || data.Result.Errores.length == 0)
          this.formGroup.reset();
      }
      else
        this.sharedFunctions.showMessage("Ocurrió un error realizando la importación", "Importación de Licitaciones");
    },
    (err: HttpErrorResponse) => {
      this.isImporting = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  clearDate(formFieldName: string) {
    if (formFieldName != null && formFieldName.length > 0) {
      if (this.formGroup.get(formFieldName) != null && this.formGroup.get(formFieldName)?.value)
        this.formGroup.get(formFieldName).setValue(null);
    }
  }
}