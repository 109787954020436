import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { SharedFunctions } from 'src/app/shared/shared.functions';

import { SeguridadService } from '../../shared/services';

@Component({
  selector: 'app-dialogSetearClave',
  templateUrl: 'dialogSetearClave.component.html'
})

export class DialogSetearClaveComponent implements OnInit {

  formGroup: FormGroup;
  hideClave: boolean = true;
  hideConfirmClave: boolean = true;
  hideClaveAnterior: boolean = true;
  buttonEnabled: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DialogSetearClaveComponent>,
    private service: SeguridadService, 
    private sharedFunctions: SharedFunctions,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
     'Login': [this.data?.Login],
     'UsuarioId': [this.data?.UsuarioId],
     'Clave': [null, Validators.required],
     'ConfirmClave': [null, Validators.required],
     'Codigo': [null, Validators.required]
    });
  }

  onGuardar() {
    if(!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Complete los campos obligatorios para continuar con la operación", "Error");
      return false;
    }

    if (this.formGroup.get('Clave')?.value?.toString() != this.formGroup.get('ConfirmClave')?.value?.toString()) {
      this.sharedFunctions.showMessage("Revise la clave seteada y su confirmación ya que son diferentes", "Error");
      return false;
    }

    var value = { ... this.formGroup.getRawValue() };
    this.buttonEnabled = false;

    this.service.saveResetPassword(value).subscribe(data => {
      this.buttonEnabled = true;
      if(data.Success) {
        let result = data.Result;

        if (result) {
          this.sharedFunctions.showMessage("Se modificó exitosamente la clave. Por favor inicie sesión con la clave recién generada", "Seteo de Clave");
          this.dialogRef.close(result);
        }
      }
      else
        this.sharedFunctions.showMessage("Ocurrió un error recuperando la clave del usuario", "Error");
    },
    (err: HttpErrorResponse) => {
      this.buttonEnabled = true;
      this.sharedFunctions.handleError(err, true);
    })
  }
  
  onCancelar() {
    this.dialogRef.close(null);
  }

  getErrorClave() {
    return this.formGroup.get('Clave').hasError('required') ? 'Este campo es obligatorio' : '';
  }

  getErrorConfirmClave() {
    return this.formGroup.get('ConfirmClave').hasError('required') ? 'Este campo es obligatorio' : '';
  }

  getErrorCodigo() {
    return this.formGroup.get('Codigo').hasError('required') ? 'Este campo es obligatorio' : '';
  }
}