import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, RolTipo, SearchRolTipoRequest, SearchRolTipoResponse, SaveRolTipoDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class RolTipoService extends CrudService<RolTipo, number, SaveRolTipoDto, SearchRolTipoRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'rolTipo', sharedFunctions);

  }

  getAll(): Observable<GenericResponse<RolTipo[]>> {
    return this._http.get<GenericResponse<RolTipo[]>>(this._serviceUrl + '', this.httpOptions);
  }
}