import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrudService } from '.';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class SeguridadService extends CrudService<any, number, any, any> { 

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'seguridad', sharedFunctions);
  }

  login(p: any): Observable<any> {
    return this._http.post<any>(this._serviceUrl + '/login', p, this.httpOptions);
  }

  register(p: any): Observable<any> {
    return this._http.post<any>(this._serviceUrl + '/register', p, this.httpOptions);
  }

  forgotUser(p: any): Observable<any> {
    return this._http.post<any>(this._serviceUrl + '/forgot-user', p, this.httpOptions);
  }

  forgotPassword(p: any): Observable<any> {
    return this._http.post<any>(this._serviceUrl + '/reset-password', p, this.httpOptions);
  }

  saveResetPassword(p: any): Observable<any> {
    return this._http.post<any>(this._serviceUrl + '/save-reset-password', p, this.httpOptions);
  }

  checkUsuarioPermiso(funcion:string): Observable<boolean> {
    return this._http.get<boolean>(this._serviceUrl + '/check-usuario-permiso/' + funcion, this.httpOptions);
  }
}