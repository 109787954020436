import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { GridPageSettings, MenuFuncionCodes, EstadoConstants, SaveUsuarioDto, Usuario, Rol, TipoDocumento, SearchTipoDocumentoRequest, SavePersonVerificationImageRequestDto, SearchPersonaRequestDto, DialogConfirmRequest, DialogConfirmResponse } from 'src/app/shared/model';
import { SearchUsuarioHistorialRequestDto, SearchUsuarioHistorialItemDto, SearchRolRequest, Persona, SavePersonaDto } from 'src/app/shared/model';
import { ActivateUserRequestDto, BlockUserRequestDto, DialogTextoLibreRequest, DialogTextoLibreResponse, ChangeUserStateRequestDto } from 'src/app/shared/model';
import { DialogPersonaSearchComponentRequestDto, DialogPersonaSearchComponentResponseDto, Tenant, SearchTenantRequest } from 'src/app/shared/model';
import { UsuarioService, RolService, MenuFuncionService, TipoDocumentoService, PersonaService, TenantService } from 'src/app/shared/services';

import { DialogTextoLibreComponent } from 'src/app/shared/components/modals/dialogTextoLibre.component';
import { DialogPersonaSearchComponent } from '../usuarios/components/dialogPersonaSearch.component';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';

@Component({
  selector: 'app-usuarioEdit',
  templateUrl: './usuarioEdit.component.html',
  styleUrls: ['./usuarioEdit.component.scss']
})

export class UsuarioEditComponent implements OnInit {
  usuarioFormGroup: FormGroup;
  personaFormGroup: FormGroup;
  hideClave: boolean = true;
  hideConfirmClave: boolean = true;
  hidePin: boolean = true;
  hideConfirmPin: boolean = true;
  claveChanged: boolean = false;
  confirmClaveChanged: boolean = false;
  pinChanged: boolean = false;
  confirmPinChanged: boolean = false;
  usuarioId: number;

  public pageSettings: PageSettingsModel;
  public pageSizes: number[];

  isValidating: boolean = false;
  isSearching: boolean = false;
  isHistorialSearching: boolean = false;
  isUserSaving: boolean = false;
  isPersonSaving: boolean = false;
  isActivating: boolean = false;
  isApproving: boolean = false;
  isRejecting: boolean = false;
  isBlocking: boolean = false;
  isResetting: boolean = false;
  isRolSearching: boolean = false;
  isTipoDocumentoSearching: boolean = false;
  isPersonaSearching: boolean = false;
  isTenantSearching: boolean = false;
  isImageLoading: boolean = false;
  isPersonaDataSearching: boolean = true;

  canPersonEdit: boolean = false;
  canPersonAdd: boolean = false;
  isNewUser: boolean = true;
  
  canAdd: boolean = false;
  canView: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  canUserActions: boolean = false;

  bloqueado: boolean = false;
  activo: boolean = true;
  debeCambiarClave: boolean = false;
  msgError: string = "";

  personaSearchResult: Persona;
  usuario: Usuario;
  persona: Persona = new Persona(null, null, null, null, null, null, null, true, null, null, null);
  userImageBase64: string | ArrayBuffer = null;
  userImageExtension: string = "";
  rolList: Rol[];
  tipoDocumentoList: TipoDocumento[];
  historialList: SearchUsuarioHistorialItemDto[];
  historialMsgError: string = "";
  personaMsgError: string = "";
  rolesString: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public tipoDocumentoService: TipoDocumentoService,
    private usuarioService: UsuarioService,
    private personaService: PersonaService,
    private menuService: MenuFuncionService,
    public tenantService: TenantService,
    private rolService: RolService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuService.checkMenuPadre(MenuFuncionCodes.USER).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;

        this.canEdit = data.Result.some(x => x == MenuFuncionCodes.USER_EDIT);
        this.canAdd = data.Result.some(x => x == MenuFuncionCodes.USER_ADD);
        this.canView = data.Result.some(x => x == MenuFuncionCodes.USER_LIST);
        this.canDelete = data.Result.some(x => x == MenuFuncionCodes.USER_DELETE);
        this.canUserActions = data.Result.some(x => x == MenuFuncionCodes.USER_ACTIONS);

        this.loadView();
      }
      else {
        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
        this.onCancel();
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

    this.menuService.checkMenuPadre(MenuFuncionCodes.PERSONA).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canPersonEdit = data.Result.some(x => x == MenuFuncionCodes.PERSONA_EDIT);
        this.canPersonAdd = data.Result.some(x => x == MenuFuncionCodes.PERSONA_ADD);

        if (this.canPersonAdd || this.canPersonEdit) {
          this.buildPersonForm();
          this.getTiposDocumento();
          this.loadPersonData();
        }
      }
    },
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });

    this.buildUserForm();
    this.getRoles();

    this.loadEntity();
  }

  buildUserForm() {
    this.usuarioFormGroup = this.formBuilder.group({
      'Mail': [null, [Validators.required, Validators.maxLength(255), Validators.email]],
      'ConfirmMail': [null, [Validators.required, Validators.maxLength(255), Validators.email]],
      'Movil': [null, Validators.maxLength(100)],
      'Login': [null, [Validators.required, Validators.maxLength(100)]],
      'Clave': [null, [Validators.required, Validators.maxLength(100)]],
      'ConfirmClave': [null, [Validators.required, Validators.maxLength(100)]],
      'MotivoBloqueo': [null, Validators.maxLength(300)],
      'Roles': [null, Validators.required]
    });
  }

  buildPersonForm() {
    this.personaFormGroup = this.formBuilder.group({
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'Apellido': [null, [Validators.required, Validators.maxLength(150)]],
      'TipoDocumentoId': [null],
      'NroDocumento': [null, Validators.maxLength(100)],
      'FechaNacimiento': [null],
      'MailContacto': [null, [Validators.maxLength(255), Validators.email]]
    });
  }

  getTiposDocumento() {
    var r = new SearchTipoDocumentoRequest(null, null, true, "Nombre", null, null, true);
    this.isTipoDocumentoSearching = true;

    this.tipoDocumentoService.search(r).subscribe(data => {
      this.isTipoDocumentoSearching = false;
      if (data != null) {
        this.tipoDocumentoList = data?.result;
      }
    },
    (err: HttpErrorResponse) => {
      this.isTipoDocumentoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getRoles() {
    var r = new SearchRolRequest(null, null, true, "Nombre", null, null, true);
    this.isRolSearching = true;

    this.rolService.search(r).subscribe(data => {
      this.isRolSearching = false;
      if (data != null)
        this.rolList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isRolSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  compareRoles(o1: number, o2: number): boolean {
    return o1 == o2;
  }

  convertFileToBase64(event, fieldName) {
    this.userImageBase64 = null;
    const file = event.target.files[0];

    if (file != null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.backofficeImageBase64 = reader.result;
      };
      reader.onloadend = () => {

        var imageBase64 = reader.result;
        var imageExtension = (file?.name?.split('.')?.pop() ?? "");

        switch (fieldName) {
          case "ImagenPerfil":
            this.userImageBase64 = imageBase64;
            this.userImageExtension = imageExtension;
            break;
          case "DniFrente":
          case "DniDorso":
          case "Selfie":
            this.cargarImagenVerificacion(fieldName, imageBase64, imageExtension);
            break;
        }
        // console.log(reader.result);
      };
    }
  }

  cargarImagenVerificacion(fieldName, imageBase64, imageExtension) {
    if (fieldName != null && fieldName.length > 0 && imageBase64 != null && imageBase64.length > 0 && imageExtension != null && imageExtension.length > 0) {

      var index = imageBase64?.toString()?.indexOf(",");
      imageBase64 = (index != null && index >= 0 ? imageBase64?.toString()?.substring(index + 1) : null);

      var r = new SavePersonVerificationImageRequestDto(this.persona.Id, fieldName, imageBase64, imageExtension);
      this.isImageLoading = true;

      this.personaService.saveVerificationImagen(r).subscribe(data => {
        this.isImageLoading = false;
        if (data != null && data.Success && data.Result != null) {
          switch (fieldName) {
            case "DniFrente":
              this.persona.FotoDniFrenteUrl = data.Result;
              break;
            case "DniDorso":
              this.persona.FotoDniDorsoUrl = data.Result;
              break;
            case "Selfie":
              this.persona.FotoSelfieUrl = data.Result;
              break;
          }

          this.sharedFunctions.showMessage("Se cargó la imagen exitosamente!", "Imagen cargada");
        }
        else
          this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
      },
      (err: HttpErrorResponse) => {
        this.isImageLoading = false;
        this.sharedFunctions.handleError(err, true);
      })
    }
  }

  isProcessing() {
    return (this.isSearching || this.isUserSaving || this.isPersonSaving || this.isActivating || this.isApproving || this.isRejecting || this.isBlocking);
  }

  showStateFlowButtons() {
    return (this.usuario != null && this.usuario.Id > 0 && this.usuario.UsuarioEstadoId == EstadoConstants.ESTADO_USUARIO_PENDIENTE && this.canEdit);
  }

  showActionButtons() {
    return (this.usuario != null && this.usuario.Id > 0 && this.usuario.UsuarioEstadoId == EstadoConstants.ESTADO_USUARIO_APROBADO && this.canUserActions);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        this.usuarioId = Number(params['id']);

        if (this.usuarioId > 0) {
          if (this.canView || this.canEdit) {
            this.isNewUser = false;
            this.isSearching = true;
            this.usuarioService.get(this.usuarioId).subscribe(data => {
              this.isSearching = false;
              if (data != null && data.Success) {
                this.usuario = data.Result;

                if (this.usuario != null && this.usuario.Id > 0) {
                  if (this.canEdit) {
                    this.usuarioFormGroup.patchValue(this.usuario);

                    //Se ponen valores para que no falle el usuarioForm por sus validaciones pero no se usan para editar
                    this.usuarioFormGroup.get('Clave').setValue("123");
                    this.usuarioFormGroup.get('ConfirmClave').setValue("123");
                    this.usuarioFormGroup.get('ConfirmMail').setValue(this.usuario.Mail);

                    if (this.usuario.Roles != null)
                      this.usuarioFormGroup.get('Roles').setValue(this.usuario.Roles?.map(item => item?.Id));
                  }
                  else {
                    this.rolesString = this.usuario.Roles?.map(item => item?.Nombre)?.join(", ");
                  }

                  this.getPersona();
                  this.getHistorial();
                }
                else
                  this.msgError = "No se encontró el usuario seleccionado.";
              }
              else
                this.msgError = "Ocurrió un error al intentar obtener los datos del usuario.";
            },
            (err: HttpErrorResponse) => {
              this.isSearching = false;
              this.sharedFunctions.handleError(err, true);
            })
          }
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel(); 
          }
        }
        else
          this.msgError = "Item no válido.";
      }
      else {
        if (this.canAdd) {
          this.usuario = Usuario.getNewInstance();
          this.usuarioFormGroup.patchValue(this.usuario);

          this.persona = new Persona(null, null, null, null, null, null, null, true, null, null, null);
          if (this.canPersonAdd || this.canPersonEdit)
            this.personaFormGroup.patchValue(this.persona);
        }
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.onCancel(); 
        }
      }
    });
  }

  getPersona() {
    if (this.usuario != null && this.usuario.PersonaId > 0) {
      this.isPersonaSearching = true;
      this.personaMsgError = "";

      this.personaService.get(this.usuario.PersonaId).subscribe(data => {
        this.isPersonaSearching = false;
        if (data != null) {
          this.persona = data.Result;
          this.loadPersonData();
        }
        else
          this.personaMsgError = "Ocurrió un error al intentar obtener los datos de la persona.";
      },
        (err: HttpErrorResponse) => {
          this.isPersonaSearching = false;
          this.sharedFunctions.handleError(err);
        })
    }
  }

  loadPersonData() {
    if (this.persona != null) {
      if (this.canPersonEdit) {
        this.personaFormGroup.patchValue(this.persona);

        if (this.persona.FechaNacimiento != null)
          this.personaFormGroup.get("FechaNacimiento").setValue(this.datePipe.transform(this.persona.FechaNacimiento, "yyyy-MM-dd"));
      }
    }
  }

  getHistorial() {
    if (this.usuario != null && this.usuario.Id > 0) {
      var r = new SearchUsuarioHistorialRequestDto(null, null, false, "FechaCreacion", null, null, this.usuario.Id, null);
      this.isHistorialSearching = true;
      this.historialMsgError = "";

      this.usuarioService.searchHistory(r).subscribe(data => {
        this.isHistorialSearching = false;
        if (data != null) {
          this.historialList = data.result;
        }
        else
          this.historialMsgError = "Ocurrió un error al intentar obtener los datos del usuario.";
      },
        (err: HttpErrorResponse) => {
          this.isHistorialSearching = false;
          this.sharedFunctions.handleError(err);
        })
    }
  }

  onNroDocumentoChange() {
    this.checkPersonaExistance(true, false);
  }

  checkPersonaExistance(searchByDoc: boolean, searchByCuit: boolean) {
    var tipoDocId = this.personaFormGroup?.get("TipoDocumentoId")?.value;
    var nroDoc = this.personaFormGroup?.get("NroDocumento")?.value;
    var cuit = this.personaFormGroup?.get("Cuit")?.value;

    if ((tipoDocId > 0 && nroDoc?.length > 0) || cuit?.length > 0) {
      var r = new SearchPersonaRequestDto(null, null, true, "Apellido", null, null, tipoDocId, nroDoc, true, false);
      
      this.isPersonaDataSearching = true;

      this.personaService.search(r).subscribe(data => {
        this.isPersonaDataSearching = false;
        if (data != null && data.result != null && data.result.length > 0) {
          this.personaSearchResult = data?.result[0];

          if (this.personaSearchResult.Id == this.persona?.Id) {
            this.personaSearchResult = null;
            return;
          }

          var msg = "Se encontró a la persona " + this.personaSearchResult.Nombre + " " + this.personaSearchResult.Apellido;
          if (searchByDoc) msg +=" en base al tipo y nro. de documento ingresado. ";
          if (searchByCuit) msg +=" en base al CUIT/CUIL ingresado. ";
          msg += "\n\n¿Desea cargar los datos encontrados?"

          const dialogRef = this.dialog.open(DialogConfirmComponent, {
            data: new DialogConfirmRequest("Persona encontrada", msg, "Sí, carga los datos", "No cargues nada")
          });

          dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
            if (result != null && result.Accepted)
              this.cargarPersonaEncontrada();
            else
              this.noCargarPersonaEncontrada();
          });
        }
      },
      (err: HttpErrorResponse) => {
        this.isPersonaDataSearching = false;
        this.sharedFunctions.handleError(err);
      });
    }
  }

  cargarPersonaEncontrada() {
    if (this.personaSearchResult != null && this.personaSearchResult.Id > 0) {
      if (this.usuario != null)
        this.usuario.PersonaId =this.personaSearchResult.Id;
      this.persona = this.personaSearchResult;
      this.loadPersonData();

      this.personaSearchResult = null;
    }
  }

  noCargarPersonaEncontrada() {
    this.personaSearchResult = null;
  }

  onPersonSearchClick() {
    if (this.isProcessing())
      return;

    const dialogRef = this.dialog.open(DialogPersonaSearchComponent, {
      width: '90%',
      data: new DialogPersonaSearchComponentRequestDto(false, 0)
    });

    dialogRef.afterClosed().subscribe((result: DialogPersonaSearchComponentResponseDto) => {
      if (result != null && !result.Cancelled) {
        var u = (result.Personas != null && result.Personas.length > 0 ? result.Personas[0] : null);

        this.persona = new Persona(null, null, null, null, null, null, null, true, null, null, null);
        if (u != null && u.Id > 0)
          this.persona = new Persona(u.Id, u.Nombre, u.Apellido, u.NroDocumento, u.TipoDocumentoId, u.FechaNacimiento, null, u.Activo,
            u.FotoDniFrenteUrl, u.FotoDniDorsoUrl, u.FotoSelfieUrl);

        this.loadPersonData();
      }
    });
  }

  onPersonDeassociateClick() {
    this.persona = new Persona(null, null, null, null, null, null, null, true, null, null, null);
    if (this.usuario != null)
      this.usuario.PersonaId = 0;
    this.loadPersonData();
  }

  canDeassociatePerson() {
    return this.canEdit && this.canPersonAdd && this.persona != null && this.persona.Id > 0;
  }

  clearDate(formFieldName: string) {
    if (formFieldName != null && formFieldName.length > 0) {
      if (this.personaFormGroup.get(formFieldName) != null && this.personaFormGroup.get(formFieldName)?.value)
        this.personaFormGroup.get(formFieldName).setValue(null);
    }
  }

  getErrorNombre() {
    var v = this.personaFormGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorApellido() {
    var v = this.personaFormGroup.get('Apellido');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorFechaNacimiento() {
    return this.personaFormGroup.get('FechaNacimiento').hasError('required') ? 'El campo es obligatorio' : '';
  }

  getErrorTipoDocumentoId() {
    return this.personaFormGroup.get('TipoDocumentoId').hasError('required') ? 'El campo es obligatorio' : '';
  }

  getErrorNroDocumento() {
    var v = this.personaFormGroup.get('NroDocumento');
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorMailContacto() {
    var v = this.usuarioFormGroup.get('MailContacto');
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    if (v.hasError('email'))
      return 'Debe ingresar un email con formato válido';
    return '';
  }

  getErrorLogin() {
    var v = this.usuarioFormGroup.get('Login');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorClave() {
    var v = this.usuarioFormGroup.get('Clave');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorConfirmClave() {
    var v = this.usuarioFormGroup.get('ConfirmClave');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    if (v.hasError('email'))
      return 'Debe ingresar un email con formato válido';
    return '';
  }

  getErrorMovil() {
    var v = this.usuarioFormGroup.get('Movil');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorMail() {
    var v = this.usuarioFormGroup.get('Mail');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    if (v.hasError('email'))
      return 'Debe ingresar un email con formato válido';
    return '';
  }

  getErrorConfirmMail() {
    var v = this.usuarioFormGroup.get('ConfirmMail');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 255 caracteres alcanzado';
    if (v.hasError('email'))
      return 'Debe ingresar un email con formato válido';
    return '';
  }

  getErrorRoles() {
    return this.usuarioFormGroup.get('Roles').hasError('required') ? 'El campo es obligatorio' : '';
  }

  hasPersonaFotoDniFrente() {
    return (this.persona?.FotoDniFrenteUrl != null && this.persona?.FotoDniFrenteUrl.length > 0);
  }

  hasPersonaFotoDniDorso() {
    return (this.persona?.FotoDniDorsoUrl != null && this.persona?.FotoDniDorsoUrl.length > 0);
  }

  hasPersonaFotoSelfie() {
    return (this.persona?.FotoSelfieUrl != null && this.persona?.FotoSelfieUrl.length > 0);
  }

  userImageChanged() {
    return (this.userImageBase64 != null);
  }

  onGuardarPersona() {

    if (!this.personaFormGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new SavePersonaDto(null, null, null, null, null, null, true, null, null, null, null, null, null);

    r =
    {
      ...this.personaFormGroup.value,
      ...
      {
        Id: (this.persona?.Id ?? 0),
        Activo: this.persona.Activo
      }
    };

    this.isPersonSaving = true;
    this.personaService.save(r).subscribe(data => {
      this.isPersonSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
      (err: HttpErrorResponse) => {
        this.isPersonSaving = false;
        this.sharedFunctions.handleError(err, true);
      })
  }

  onGuardarUsuario() {

    if (!this.usuarioFormGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    if (this.usuarioFormGroup.get('Clave')?.value?.toString() != this.usuarioFormGroup.get('ConfirmClave')?.value?.toString()) {
      this.sharedFunctions.showMessage("Revise la clave seteada y su confirmación ya que son diferentes", "Error");
      return false;
    }

    if (this.usuarioFormGroup.get('Mail')?.value?.toString() != this.usuarioFormGroup.get('ConfirmMail')?.value?.toString()) {
      this.sharedFunctions.showMessage("Revise el mail escrito y su confirmación ya que son diferentes", "Error");
      return false;
    }

    if (this.isNewUser) {
      if (!this.personaFormGroup.valid) {
        this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios de la persona para continuar", "Error");
        return false;
      }
    }

    var m = this.usuarioFormGroup.get('Movil')?.value;
    if (m != null && m.length > 0 && !m.startsWith("+"))
      this.usuarioFormGroup.get('Movil').setValue("+" + m);

    var index = this.userImageBase64?.toString()?.indexOf(",");
    var r = new SaveUsuarioDto(null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, true, null);

    if (this.isNewUser)
      r =
      {
        ...this.usuarioFormGroup.value,
        ...this.personaFormGroup.value,
        ...
        {
          Id: (this.usuario?.Id ?? 0),
          PersonaId: (this.persona?.Id ?? 0),
          FotoPerfilBase64: (index != null && index >= 0 ? this.userImageBase64?.toString()?.substring(index + 1) : null),
          FotoPerfilExtension: this.userImageExtension,
          DebeCambiarClave: this.debeCambiarClave,
          Bloqueado: this.bloqueado,
          Activo: this.activo
        },
      };
    else
      r =
      {
        ...this.usuarioFormGroup.value,
        ...this.persona,
        ...
        {
          Id: (this.usuario?.Id ?? 0),
          PersonaId: (this.usuario?.PersonaId ?? 0),
          FotoPerfilBase64: (index != null && index >= 0 ? this.userImageBase64?.toString()?.substring(index + 1) : null),
          FotoPerfilExtension: this.userImageExtension,
          DebeCambiarClave: this.debeCambiarClave,
          Bloqueado: this.bloqueado,
          Activo: this.activo
        },
      };


    this.isUserSaving = true;
    this.usuarioService.save(r).subscribe(data => {
      this.isUserSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.getHistorial();
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");

        if (this.isNewUser)
          this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
      (err: HttpErrorResponse) => {
        this.isUserSaving = false;
        this.sharedFunctions.handleError(err, true);
      })
  }

  onActivar() {
    if (this.isProcessing()) return false;

    const dialogRef = this.dialog.open(DialogTextoLibreComponent, {
      width: '500px',
      data: new DialogTextoLibreRequest(
        (this.usuario?.Activo ? 'Desactivación de Usuario' : 'Activación de Usuario'),
        'Ingrese un comentario (opcional)',
        'Aceptar',
        'Cancelar Operación',
        300
      )
    });

    dialogRef.afterClosed().subscribe((result: DialogTextoLibreResponse) => {
      if (result != null && !result.Cancelled) {
        var r = new ActivateUserRequestDto(this.usuario?.Id, !this.usuario?.Activo, result.Result);

        this.isActivating = true;
        this.usuarioService.activateUser(r).subscribe(data => {
          this.isActivating = false;
          if (data != null && data.Success && data.Result != null && data.Result) {
            this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
            this.loadView();
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
          (err: HttpErrorResponse) => {
            this.isActivating = false;
            this.sharedFunctions.handleError(err, true);
          })
      }
    });
  }

  onAprobar() {
    if (this.isProcessing()) return false;

    const dialogRef = this.dialog.open(DialogTextoLibreComponent, {
      width: '500px',
      data: new DialogTextoLibreRequest(
        'Aprobación de Usuario',
        'Ingrese un comentario (opcional)',
        'Aprobar',
        'Cancelar Operación',
        300
      )
    });

    dialogRef.afterClosed().subscribe((result: DialogTextoLibreResponse) => {
      if (result != null && !result.Cancelled) {

        var r = new ChangeUserStateRequestDto(this.usuario?.Id, 0, result.Result);
        this.isApproving = true;

        this.usuarioService.approveUser(r).subscribe(data => {
          this.isApproving = false;
          if (data != null && data.Success && data.Result != null && data.Result) {
            this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
            this.loadView();
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
          (err: HttpErrorResponse) => {
            this.isApproving = false;
            this.sharedFunctions.handleError(err, true);
          })
      }
    });
  }

  onRechazar() {
    if (this.isProcessing()) return false;

    const dialogRef = this.dialog.open(DialogTextoLibreComponent, {
      width: '500px',
      data: new DialogTextoLibreRequest(
        'Rechazo de Usuario',
        'Ingrese el motivo del rechazo del usuario',
        'Rechazar',
        'Cancelar Operación',
        300
      )
    });

    dialogRef.afterClosed().subscribe((result: DialogTextoLibreResponse) => {
      if (result != null && !result.Cancelled) {
        if (result.Result == null || result.Result.length == 0) {
          this.sharedFunctions.showMessage("Debe ingresar un motivo de rechazo para continuar", "Error");
          return false;
        }

        var r = new ChangeUserStateRequestDto(this.usuario?.Id, 0, result.Result);
        this.isRejecting = true;

        this.usuarioService.rejectUser(r).subscribe(data => {
          this.isRejecting = false;
          if (data != null && data.Success && data.Result != null && data.Result) {
            this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
            this.loadView();
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
          (err: HttpErrorResponse) => {
            this.isRejecting = false;
            this.sharedFunctions.handleError(err, true);
          })
      }
    });
  }

  onBloquear() {
    if (this.isProcessing()) return false;

    const dialogRef = this.dialog.open(DialogTextoLibreComponent, {
      width: '500px',
      data: new DialogTextoLibreRequest(
        (this.usuario?.Bloqueado ? 'Desbloqueo de Usuario' : 'Bloqueo de Usuario'),
        'Ingrese un comentario (opcional)',
        'Aceptar',
        'Cancelar Operación',
        300
      )
    });

    dialogRef.afterClosed().subscribe((result: DialogTextoLibreResponse) => {
      if (result != null && !result.Cancelled) {
        var r = new BlockUserRequestDto(this.usuario?.Id, !this.usuario?.Bloqueado, result.Result);

        this.isBlocking = true;
        this.usuarioService.blockUser(r).subscribe(data => {
          this.isBlocking = false;
          if (data != null && data.Success && data.Result != null && data.Result) {
            this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
            this.loadView();
          }
          else
            this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
          (err: HttpErrorResponse) => {
            this.isBlocking = false;
            this.sharedFunctions.handleError(err, true);
          })
      }
    });
  }

  onReinicioClave() {
    if (this.isProcessing()) return false;

    this.isResetting = true;
    this.usuarioService.resetPassword(this.usuario?.Id).subscribe(data => {
      this.isResetting = false;
      if (data != null && data.Success && data.Result != null) {
        this.sharedFunctions.showMessage("Se envió un correo electrónico a la casilla de mail asociado al usuario con las instrucciones para el reinicio de clave", "Reinicio de Clave");
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
      (err: HttpErrorResponse) => {
        this.isResetting = false;
        this.sharedFunctions.handleError(err, true);
      })
  }

  onCancel() {
    this.location.back();
  }
}
