export class MensajeUsuario {
    constructor (
        public readonly Id: number,
        public readonly FechaCreacion: Date,
        public readonly FechaEnvio: Date,
        public readonly FechaEnvioHora: number,
        public readonly FechaEnvioMinuto: number,
        public readonly UsuarioCreadorId: number,
        public readonly UsuarioCreadorNombre: string,
        public readonly UsuarioCreadorApellido: string,
        public readonly UsuarioCreadorLogin: string,
        public readonly UsuarioDestinoNombre: string,
        public readonly UsuarioDestinoApellido: string,
        public readonly UsuarioDestinoLogin: string,
        public readonly EnvioId: string,
        public readonly Asunto: string,
        public readonly Mensaje: string,
        public readonly Leido: boolean,
        public readonly Editable: boolean,
        public readonly OpcionEnvioId: number,
        public readonly MensajeUsuarioTipoId: number,
        public readonly RolDestinoId: number | null,
        public readonly UsuarioDestinoId: number | null,
        public readonly TotalEnviados: number,
        public Adjuntos: MensajeUsuarioAdjunto[],
        public MensajeHtml: any
    ) {}
}

export class MensajeUsuarioAdjunto {
    constructor (
        public readonly Id: number,
        public readonly NombreDocumento: string,
        public readonly UrlDocumento: string,
        public readonly MensajeUsuarioId: number,
        public readonly IncluidoEnCuerpoMensaje: boolean
    ) {}
}

export class CantidadNoLeidosUsuarioDto {
    constructor (
        public readonly Total: number
    ) {}
}

export class SearchMensajeUsuarioRequestDto {
    constructor( 
        public readonly PageSize: number | null,
        public readonly PageNumber: number | null,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly VerEnviados: boolean | null,
        public readonly FechaCreacionDesde: Date | null,
        public readonly FechaCreacionHasta: Date | null,
        public readonly FechaEnvioDesde: Date | null,
        public readonly FechaEnvioHasta: Date | null,
        public readonly MensajeUsuarioTipoId: number | null,
        public readonly UsuarioCreador: string,
        public readonly UsuarioDestino: string,
        public readonly Asunto: string,
        public readonly VerSoloNoLeidos: boolean | null
    ){}
}

export class SearchMensajeUsuarioResponseDto {
    constructor( 
        public readonly count: number, 
        public readonly result: SearchMensajeUsuarioItemDto[]
    ){}
}

export class SearchMensajeUsuarioItemDto {
    constructor( 
        public readonly Id: number,
        public readonly UsuarioCreadorId: number,
        public readonly UsuarioCreadorNombre: string,
        public readonly UsuarioCreadorLogin: string,
        public readonly UsuarioDestinoNombre: string,
        public readonly UsuarioDestinoLogin: string,
        public readonly UsuarioNombre: string,
        public readonly UsuarioLogin: string,
        public readonly Asunto: string,
        public readonly MensajeUsuarioTipoCodigo: string,
        public readonly MensajeUsuarioTipoNombre: string,
        public readonly FechaCreacion: Date,
        public readonly FechaEnvio: Date,
        public readonly Leido: boolean,
        public readonly Enviado: boolean,
        public readonly FechaLeido: Date | null,
        public readonly EsPopUp: boolean
    ){}
}

export class MensajeUsuarioResumenDto {
    constructor( 
        public readonly Id: number,
        public readonly UsuarioCreadorNombre: string,
        public readonly UsuarioCreadorImagenUrl: string,
        public readonly Asunto: string,
        public readonly FechaEnvio: Date,
        public readonly Leido: boolean,
        public readonly EsPopUp: boolean
    ){}
}

export class SaveMensajeUsuario {
    constructor (
        public readonly Id: number,
        public readonly FechaEnvio: Date,
        public readonly HoraEnvioString: string,
        public readonly RolDestinoId: number | null,
        public readonly UsuarioDestinoId: number | null,
        public readonly MensajeUsuarioTipoId: number,
        public readonly Asunto: string,
        public readonly Mensaje: string,
        public readonly OpcionEnvioId: number,
        public Adjuntos: MensajeUsuarioAdjunto[],
        public NuevosAdjuntosBorrados: string[]
    ) {}
}

export class UploadMensajeUsuarioAttachResponseDto {
    constructor (
        public readonly Archivos: MensajeUsuarioAdjunto[]
    ) {}
}

export class SendMensajeUsuarioNotificationDto {
    constructor (
        public readonly mensajeUsuarioId: number,
        public readonly usuarioDestinoId: number,
        public readonly usuarioOrigen: string,
        public readonly asunto: string
    ) {}
}