<h2 mat-dialog-title>Búsqueda de Usuarios</h2>

<div mat-dialog-content>

    <mat-expansion-panel>

        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-top: -3px;">search</mat-icon> Filtros de Búsqueda
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <form [formGroup]="searchForm" >
          <div fxLayout="row stretch" fxLayout.xs="column">
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isRolSearching">Roles</mat-label>
                <mat-label *ngIf="isRolSearching">Cargando...</mat-label>
                <mat-select formControlName="RolId">
                  <mat-option>Todos</mat-option>
                  <mat-option *ngFor="let s of rolList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Usuario</mat-label>
                <input matInput formControlName="Login" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="Nombre" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
  
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Apellido</mat-label>
                <input matInput formControlName="Apellido" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Documento</mat-label>
                <input matInput formControlName="NroDocumento" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Email</mat-label>
                <input matInput formControlName="Mail" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Nro. Celular</mat-label>
                <input matInput formControlName="Movil" (keyup.enter)="onSearch()">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex=25 fxFlex.xs=100 class="flex-item">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label *ngIf="!isEstadoSearching">Estados</mat-label>
                <mat-label *ngIf="isEstadoSearching">Cargando...</mat-label>
                <mat-select formControlName="EstadoId">
                  <mat-option>Todos</mat-option>
                  <mat-option *ngFor="let s of estadoList" [value]="s.Id">{{s.Nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>

        <br/>
  
        <div fxLayout="row stretch" fxLayout.xs="column">
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="verSoloBloqueados" color="primary">Ver Solo Bloqueados</mat-checkbox> 
          </div>
          <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
            <mat-checkbox [(ngModel)]="verSoloActivos" color="primary">Ver Solo Activos</mat-checkbox> 
          </div>
        </div>
  
    </mat-expansion-panel>
  
    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px">
      <button mat-raised-button (click)="onSearch()" [disabled]="isSearching" color="primary" class="zb-button">{{isSearching ? 'Buscando...' : 'Buscar'}}</button>
    </div>
  
    <br/>
  
    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
    </div>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;" *ngIf="!isSearching">
      <ejs-grid #grid [dataSource]='data' [query]='query' [allowPaging]="true" [allowSorting]="true" [pageSettings]='pageSettings' [allowResizing]='true' (rowSelected)='onRowSelected($event)'>
        <e-columns>
          <e-column textAlign='Center' headerText='' width=40 *ngIf="allowMultipleSelection">
            <ng-template #template let-data>
              <button mat-raised-button (click)="addItem(data)" color="primary" style="width: 40px; min-width: 40px; margin-left: -16px;">
                <i class="fa fa-plus"></i>
              </button>
            </ng-template>
          </e-column>
          <e-column textAlign='Center' headerText='Imagen' width=90>
            <ng-template #template let-data> 
              <img *ngIf="showUserImage(data)" src="{{data?.FotoPerfilUrl}}" style="height: 25px; width: 25px; border-radius: 100%; margin-top: 4px;">
              <mat-icon *ngIf="!showUserImage(data)">person_outline</mat-icon>
            </ng-template> 
          </e-column>
          <e-column field='Login' headerText='Usuario' width=90></e-column>
          <e-column field='Nombre' headerText='Nombre' width=90></e-column>
          <e-column field='Apellido' headerText='Apellido' width=90></e-column>
          <e-column field='NroDocumento' headerText='Nro. Doc.' width=90></e-column>
          <e-column field='Mail' headerText='Email' width=90></e-column>
          <e-column field='Movil' headerText='Nro. Cel.' width=90></e-column>
          <e-column field='Estado' headerText='Estado' width=90></e-column>
          <e-column field='Activo' headerText='Activo' width=90>
            <ng-template #template let-data>
              {{data.Activo ? 'Sí' : 'No'}}
            </ng-template>
          </e-column>
          <e-column field='Bloqueado' headerText='Bloqueado' width=90>
            <ng-template #template let-data>
              {{data.Bloqueado ? 'Sí' : 'No'}}
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </div>

    <hr style="margin:20px 0px; width: 100%;">

    <div fxLayout="row stretch" fxLayout.xs="column" style="text-align: center; margin: 10px 0px;">
      <mat-label style="font-weight: bold; text-align: center; font-size: 14px;">Total Seleccionados: {{itemsSeleccionados?.length}} {{getTotalSelectionText()}}</mat-label>
    </div>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;" *ngIf="allowMultipleSelection">
      <ejs-grid #gridSelected [dataSource]='itemsSeleccionados' [allowPaging]="true" [allowSorting]="true" [pageSettings]='pageSettings' [allowResizing]='true'>
        <e-columns>
          <e-column textAlign='Center' headerText='' width=40>
            <ng-template #template let-data>
              <button mat-raised-button (click)="removeItem(data)" color="primary" style="width: 40px; min-width: 40px; margin-left: -16px;">
                <i class="fa fa-times"></i>
              </button>
            </ng-template>
          </e-column>
          <e-column textAlign='Center' headerText='Imagen' width=90>
            <ng-template #template let-data> 
              <img *ngIf="showUserImage(data)" src="{{data?.FotoPerfilUrl}}" style="height: 25px; width: 25px; border-radius: 100%; margin-top: 4px;">
              <mat-icon *ngIf="!showUserImage(data)">person_outline</mat-icon>
            </ng-template> 
          </e-column>
          <e-column field='Login' headerText='Usuario' width=90></e-column>
          <e-column field='Nombre' headerText='Nombre' width=90></e-column>
          <e-column field='Apellido' headerText='Apellido' width=90></e-column>
          <e-column field='NroDocumento' headerText='Nro. Doc.' width=90></e-column>
          <e-column field='Mail' headerText='Email' width=90></e-column>
          <e-column field='Movil' headerText='Nro. Cel.' width=90></e-column>
          <e-column field='Estado' headerText='Estado' width=90></e-column>
          <e-column field='Activo' headerText='Activo' width=90>
            <ng-template #template let-data>
              {{data.Activo ? 'Sí' : 'No'}}
            </ng-template>
          </e-column>
          <e-column field='Bloqueado' headerText='Bloqueado' width=90>
            <ng-template #template let-data>
              {{data.Bloqueado ? 'Sí' : 'No'}}
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </div>

</div>

<br/>

<div mat-dialog-actions>
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="primary" (click)="onSelect()" class="zb-button">Seleccionar</button>
    <button mat-raised-button color="warn" (click)="onCancel()" class="zb-button">Cancelar</button>
    <button mat-raised-button color="accent" (click)="onClear()" class="zb-button">Limpiar Selección</button>
  </div>
</div>