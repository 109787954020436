import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth-guard.service';

import { HomeComponent } from './pages/home/home.component';
import { UsuarioListaComponent } from './pages/usuarios/usuarioLista.component';
import { UsuarioEditComponent } from './pages/usuarios/usuarioEdit.component';
import { UsuarioSelfEditComponent } from './pages/usuarios/usuarioSelfEdit.component';
import { RolListaComponent } from './pages/roles/rolLista.component';
import { RolEditComponent } from './pages/roles/rolEdit.component';
import { MensajeUsuarioListaComponent} from './pages/mensaje-usuario/mensajeUsuarioLista.component';
import { MensajeUsuarioEditComponent} from './pages/mensaje-usuario/mensajeUsuarioEdit.component';
import { MensajeUsuarioViewComponent} from './pages/mensaje-usuario/mensajeUsuarioView.component';
import { EstadoListaComponent } from './pages/estados/estadoLista.component';
import { EstadoEditComponent } from './pages/estados/estadoEdit.component';
import { ParametroSistemaListaComponent } from './pages/parametro-sistema/parametroSistemaLista.component';
import { ParametroSistemaEditComponent } from './pages/parametro-sistema/parametroSistemaEdit.component';
import { MenuListaComponent } from './pages/menues/menuLista.component';
import { MenuEditComponent } from './pages/menues/menuEdit.component';
import { MensajeUsuarioTipoListaComponent } from './pages/mensaje-usuario-tipo/mensajeUsuarioTipoLista.component';
import { MensajeUsuarioTipoEditComponent } from './pages/mensaje-usuario-tipo/mensajeUsuarioTipoEdit.component';
import { MensajeUsuarioOpcionEnvioListaComponent } from './pages/mensaje-usuario-opcion-envio/mensajeUsuarioOpcionEnvioLista.component';
import { MensajeUsuarioOpcionEnvioEditComponent } from './pages/mensaje-usuario-opcion-envio/mensajeUsuarioOpcionEnvioEdit.component';
import { MailConfiguracionListaComponent } from './pages/mail-configuracion/mailConfiguracionLista.component';
import { MailConfiguracionEditComponent } from './pages/mail-configuracion/mailConfiguracionEdit.component';
import { TenantListaComponent } from './pages/tenants/tenantLista.component';
import { TenantEditComponent } from './pages/tenants/tenantEdit.component';
import { RolTipoListaComponent } from './pages/rol-tipo/rolTipoLista.component';
import { RolTipoEditComponent } from './pages/rol-tipo/rolTipoEdit.component';
import { FaqListaComponent } from './pages/faq/faqLista.component';
import { FaqEditComponent } from './pages/faq/faqEdit.component';
import { FaqViewComponent } from './pages/faq/faqView.component';
import { TipoDocumentoListaComponent } from './pages/tipo-documento/tipoDocumentoLista.component';
import { TipoDocumentoEditComponent } from './pages/tipo-documento/tipoDocumentoEdit.component';

import { DetalleLicitacionesListaComponent } from './pages/licitaciones/detalleLicitacionesLista.component';
import { ForecastListaComponent } from './pages/licitaciones/forecastLista.component';
import { ProductoLicitacionComponent } from './pages/producto-licitacion/productoLicitacion.component';
import { ProductoListaComponent } from './pages/productos/lista/productoLista.component';
import { ProductoEditarComponent } from './pages/productos/editar/productoEditar.component';
import { LicitacionEditComponent } from './pages/licitaciones/licitacion-edit.component';
import { LicitacionListaComponent } from './pages/licitaciones/licitacionLista.component';
import { LicitacionCategoriaEditComponent } from './pages/licitacion-categorias/licitacionCategoriaEdit.component';
import { LicitacionCategoriaListaComponent } from './pages/licitacion-categorias/licitacionCategoriaLista.component';

const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  canActivate: [AuthGuard],
  children: [{
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },{
    path:'home',
    component: HomeComponent
  },{
    path:'usuario-list',
    component: UsuarioListaComponent
  },{
    path:'usuario-edit/:id',
    component: UsuarioEditComponent
  }, {
    path:'usuario-self-edit',
    component: UsuarioSelfEditComponent
  }, {
    path:"rol-list",
    component: RolListaComponent
  }, {
    path: "rol-edit/:id",
    component: RolEditComponent
  }, {
    path: "mensaje-usuario-list",
    component: MensajeUsuarioListaComponent
  }, {
    path: "mensaje-usuario-edit/:id",
    component: MensajeUsuarioEditComponent
  }, {
    path: "mensaje-usuario-view/:id",
    component: MensajeUsuarioViewComponent
  }, {
    path:"estado-list",
    component: EstadoListaComponent
  }, {
    path: "estado-edit/:id",
    component: EstadoEditComponent
  }, {
    path:"parametro-sistema-list",
    component: ParametroSistemaListaComponent
  }, {
    path: "parametro-sistema-edit/:id",
    component: ParametroSistemaEditComponent
  }, {
    path:"menu-list",
    component: MenuListaComponent
  }, {
    path: "menu-edit/:id",
    component: MenuEditComponent
  }, {
    path: "mensaje-usuario-tipo-list",
    component: MensajeUsuarioTipoListaComponent
  }, {
    path: "mensaje-usuario-tipo-edit/:id",
    component: MensajeUsuarioTipoEditComponent
  }, {
    path: "mensaje-usuario-opcion-envio-list",
    component: MensajeUsuarioOpcionEnvioListaComponent
  }, {
    path: "mensaje-usuario-opcion-envio-edit/:id",
    component: MensajeUsuarioOpcionEnvioEditComponent
  }, {
    path: "mail-configuracion-list",
    component: MailConfiguracionListaComponent
  }, {
    path: "mail-configuracion-edit/:id",
    component: MailConfiguracionEditComponent
  }, {
    path: "tenant-list",
    component: TenantListaComponent
  }, {
    path: "tenant-edit/:id",
    component: TenantEditComponent
  }, {
    path: "rol-tipo-list",
    component: RolTipoListaComponent
  }, {
    path: "rol-tipo-edit/:id",
    component: RolTipoEditComponent
  }, {
    path: "faq-list",
    component: FaqListaComponent
  }, {
    path: "faq-edit/:id",
    component: FaqEditComponent
  }, {
    path: "faq-view",
    component: FaqViewComponent
  }, {
    path: "tipo-documento-list",
    component: TipoDocumentoListaComponent
  }, {
    path: "tipo-documento-edit/:id",
    component: TipoDocumentoEditComponent
  }, {
    path: "reporte-licitacion-detalle",
    component: DetalleLicitacionesListaComponent
  }, {
    path: "licitacion-edit/:id",
    component: LicitacionEditComponent
  }, {
    path: "licitacion-list",
    component: LicitacionListaComponent 
  }, {
    path: "reporte-forecast",
    component: ForecastListaComponent
  }, {
    path: "producto-licitacion",
    component: ProductoLicitacionComponent
  }, {
    path: "producto-list",
    component: ProductoListaComponent
  }, {
    path: "producto-edit/:id",
    component: ProductoEditarComponent
  }, {
    path: "licitacion-categoria-list",
    component: LicitacionCategoriaListaComponent
  }, {
    path: "licitacion-categoria-edit/:id",
    component: LicitacionCategoriaEditComponent
  },
]
}, {
  path: 'auth',
  loadChildren: './auth/auth.module#AuthModule',
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
