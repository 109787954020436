import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, Producto, SaveProductoDto, SearchProductoRequestDto, SearchProductoResponseDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
    providedIn: 'root'
})
export class ProductoService extends CrudService<Producto, number, SaveProductoDto, SearchProductoRequestDto> {

    constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
        super(_http, 'producto', sharedFunctions);
    }

    getProductos(p: SearchProductoRequestDto): Observable<SearchProductoResponseDto>{
        var parameters = this.sharedFunctions.convertToQueryString(p);
        return this._http.get<SearchProductoResponseDto>(this._serviceUrl + '/search' + parameters, this.httpOptions);
    }
}