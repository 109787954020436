<mat-card>
    <mat-card-header>
        <mat-card-title>Gestión de Producto</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
        <mat-label *ngIf="!isSearching && msgError.length > 0">{{msgError}}</mat-label>

        <div *ngIf="!isSearching && item != null">

            <ng-container *ngIf="canEdit">

                <form [formGroup]="formGroup">

                    <div fxLayout="row stretch" fxLayout.xs="column">
                        <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label>Código*</mat-label>
                                <input matInput formControlName="Codigo" (keyup.enter)="onSearch()" maxlength="100">
                                <mat-icon matSuffix>search</mat-icon>
                                <mat-error
                                    *ngIf="!formGroup.controls['Codigo'].valid && formGroup.controls['Codigo'].touched">
                                    {{ getErrorCodigo() }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label>Nombre*</mat-label>
                                <input matInput formControlName="Nombre" (keyup.enter)="onGuardar()" maxlength="500">
                                <mat-error
                                    *ngIf="!formGroup.controls['Nombre'].valid && formGroup.controls['Nombre'].touched">
                                    {{ getErrorNombre() }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </form>

                <div fxLayout="row stretch" fxLayout.xs="column">
                    <div fxFlex=33.33 fxFlex.xs=100 class="flex-item">
                        <mat-checkbox [(ngModel)]="item.Activo" (keyup.enter)="onGuardar()" color="primary">Activo
                        </mat-checkbox>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="!canEdit">

                <div fxLayout="row stretch" class="zb-readonly">

                    <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                        <mat-expansion-panel [expanded]="true" hideToggle="true">
                            <div fxLayout="row stretch" fxLayout.xs="column">
                                <mat-label><b>Código</b>: {{item.Codigo}}</mat-label>
                            </div>
                        </mat-expansion-panel>
                    </div>
                    <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                        <mat-expansion-panel [expanded]="true" hideToggle="true">
                            <div fxLayout="row stretch" fxLayout.xs="column">
                                <mat-label><b>Nombre</b>: {{item.Nombre}}</mat-label>
                            </div>
                        </mat-expansion-panel>
                    </div>

                    <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                        <mat-expansion-panel [expanded]="true" hideToggle="true">
                            <div fxLayout="row stretch" fxLayout.xs="column">
                                <mat-label><b>Activo</b>: {{item.Activo ? 'Sí' : 'No'}}</mat-label>
                            </div>
                        </mat-expansion-panel>
                    </div>

                </div>

            </ng-container>

        </div>

    </mat-card-content>

    <mat-card-actions style="margin-top: -25px; margin-left: 12px;">
        <div fxLayout="row stretch" fxLayout.xs="column">
            <button mat-raised-button (click)="onGuardar()" *ngIf="canEdit" [disabled]="isProcessing()" color="primary"
                class="zb-button">{{isSaving ? 'Guardando...' : 'Guardar'}}</button>
            <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canEdit ? 'Cancelar' : 'Cerrar'}}</button>
            <button mat-raised-button (click)="onDelete()" [disabled]="isProcessing()" *ngIf="canDelete" color="accent" class="zb-button">{{isDeleting ? 'Eliminando...' : 'Eliminar'}}</button>
        </div>
    </mat-card-actions>

</mat-card>