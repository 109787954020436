import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrudService } from '.';
import { GenericResponse, MenuFuncion, SearchMenuFuncionRequestDto, SearchMenuFuncionResponseDto, SaveMenuFuncionDto } from '../model/index';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})

export class MenuFuncionService extends CrudService<MenuFuncion, number, SaveMenuFuncionDto, SearchMenuFuncionRequestDto> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http, 'menuFuncion', sharedFunctions);
  }

  checkMenu(p: string): Observable<GenericResponse<boolean>> {
    return this._http.get<GenericResponse<boolean>>(this._serviceUrl + '/check-menu/'+p, this.httpOptions);
  }

  checkMenuPadre(p: string): Observable<GenericResponse<string[]>> {
    return this._http.get<GenericResponse<string[]>>(this._serviceUrl + '/check-menu/padre/'+p, this.httpOptions);
  }

  getAllOrdered(): Observable<GenericResponse<MenuFuncion[]>> {
    return this._http.get<GenericResponse<MenuFuncion[]>>(this._serviceUrl + '/ordered', this.httpOptions);
  }

  getOrderedByRol(id: number): Observable<GenericResponse<MenuFuncion[]>> {
    return this._http.get<GenericResponse<MenuFuncion[]>>(this._serviceUrl + '/ordered/rol/'+id, this.httpOptions);
  }

  // search(p: SearchMenuFuncionRequestDto): Observable<SearchMenuFuncionResponseDto> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Codigo != null && p.Codigo.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Codigo=" + p.Codigo;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.RolId != null && p.RolId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "RolId=" + p.RolId;

  //     if (p.MenuPadreId != null && p.MenuPadreId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "MenuPadreId=" + p.MenuPadreId;

  //     if (p.VerSoloActivos != null && p.VerSoloActivos.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;
  //   }

  //   return this._http.get<SearchMenuFuncionResponseDto>(this.getSearchUrl()+parameters, this.httpOptions);
  // }

  // exportSearch(p: SearchMenuFuncionRequestDto): Observable<any> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Codigo != null && p.Codigo.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Codigo=" + p.Codigo;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.RolId != null && p.RolId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "RolId=" + p.RolId;

  //     if (p.MenuPadreId != null && p.MenuPadreId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "MenuPadreId=" + p.MenuPadreId;

  //     if (p.VerSoloActivos != null && p.VerSoloActivos.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;
  //   }

  //   return this._http.get<any>(this._serviceUrl + '/export-search'+parameters, this.httpCsvOptions);
  // }
}
