<h2 mat-dialog-title>{{title}}</h2>

<div mat-dialog-content>

    <div fxLayout="row stretch" fxLayout.xs="column">
        <mat-label>{{message}}</mat-label>
    </div>

</div>

<br/>

<div mat-dialog-actions>
    <div fxLayout="row stretch" fxLayout.xs="column">
        <button mat-raised-button color="primary" (click)="onAccept()" class="zb-button">{{buttonAcceptText}}</button>
        <button mat-raised-button color="warn" (click)="onDecline()" class="zb-button">{{buttonCancelText}}</button>
    </div>
</div>