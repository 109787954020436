import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { MenuFuncionCodes, ProcessLicitacionesFromWebDto } from 'src/app/shared/model';
import { LicitacionService, MenuFuncionService } from 'src/app/shared/services';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public canImport: boolean = false;
  public isImporting: boolean = false;
  importWebForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private licitacionService: LicitacionService,
    private menuService: MenuFuncionService
  ) { 

  }

  ngOnInit() {
    this.menuService.checkMenu(MenuFuncionCodes.IMPORTACION_LICITACIONES).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.canImport = true;
        this.buildImportForm();
      }
    },
    (err: HttpErrorResponse) => {
      this.sharedFunctions.handleError(err);
    });
  }

  buildImportForm() {
    this.importWebForm = this.formBuilder.group({
      'FechaInicio': [new Date(), Validators.required],
      'FechaFin': [new Date()]
    });
  }

  importLicitaciones() {
    if (!this.importWebForm.valid) {
      this.sharedFunctions.showMessage("Debe seleccionar una fecha para realizar la importación", "Error");
      return false;
    }

    var fechaInicio = this.importWebForm.get("FechaInicio")?.value;
    
    var fechaFin = new Date(fechaInicio);
    fechaFin.setDate(fechaInicio.getDate() + 1);
    this.importWebForm.get("FechaFin")?.setValue(fechaFin);

    var r = new ProcessLicitacionesFromWebDto(null, null, null, null);
    r = Object.assign(r, this.importWebForm.value);

    this.isImporting = true;
    this.licitacionService.importarLicitaciones(r).subscribe(data => {
      this.isImporting = false;
      if (data != null && data.Success && data.Result != null) {
        this.sharedFunctions.showMessage(
          "Se importaron " + data.Result.TotalNuevos + " licitaciones nuevas y se actualizaron " + data.Result.TotalActualizados + " existentes." + 
          (data.Result.Errores != null && data.Result.Errores.length > 0 ? (" Ocurrieron algunos errores intentando llevar a cabo la operación" + 
            (data.Result.Errores.length <= 5 ? ": " + data.Result.Errores.join(", ") : "")) : ""), 
          "Importación de Licitaciones");
      }
      else
        this.sharedFunctions.showMessage("Ocurrió un error realizando la importación", "Importación de Licitaciones");
    },
    (err: HttpErrorResponse) => {
      this.isImporting = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  getErrorFechaInicio() {
    var v = this.importWebForm.get('FechaInicio');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    return '';
  }
}
