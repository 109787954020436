import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { ProductoService } from 'src/app/shared/services';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

import { DialogConfirmRequest, DialogConfirmResponse, MenuFuncionCodes, Producto, RolTipo, SaveProductoDto, SaveRolTipoDto } from 'src/app/shared/model';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-productoEditar',
    templateUrl: './productosEditar.component.html',
    styles: [
    ]
})

export class ProductoEditarComponent implements OnInit {
    formGroup: FormGroup;

    isValidating: boolean = false;
    isSearching: boolean = false;
    isSaving: boolean = false;
    isDeleting: boolean = false;
    isTipoSearching: boolean = false;

    canView: boolean = false;
    canAdd: boolean = false;
    canEdit: boolean = false;
    canDelete: boolean = false;

    msgError: string = "";
    item: Producto;

    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        public dialog: MatDialog,
        private sharedFunctions: SharedFunctions,
        private route: ActivatedRoute,
        private menuService: MenuFuncionService,
        private productoService: ProductoService) {
    }

    ngOnInit(): void {
        this.isValidating = true;
        this.menuService.checkMenuPadre(MenuFuncionCodes.PRODUCTO).subscribe(data => {
        if (data != null && data.Success && data?.Result) {
            this.isValidating = false;
            this.canEdit = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_EDIT);
            this.canAdd = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_ADD);
            this.canView = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_LIST);
            this.canDelete = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_DELETE);
            this.loadView();
        } else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();        
        }      
        },
        (err: HttpErrorResponse) => {
        this.isValidating = false;
        this.sharedFunctions.handleError(err, true);
        });
    }

    loadView() {
        this.buildForm();
        this.loadEntity();
    }

    buildForm() {
        this.formGroup = this.formBuilder.group({
            'Nombre': [null, [Validators.required, Validators.maxLength(500)]],
            'Codigo': [null, [Validators.required, Validators.maxLength(100)]],
            /* 'Activo': [true, Validators.required] */
        });
    }

    isProcessing() {
        return (this.isSearching || this.isSaving || this.isDeleting);
    }

    loadEntity() {
        this.route.params.subscribe(params => {
            if (params['id'] != null && params['id'] != '0') {
                var id = Number(params['id']);

                if (id > 0) {
                    if(this.canView || this.canEdit) {
                        this.isSearching = true;
                        this.productoService.get(id).subscribe(data => {
                            this.isSearching = false;
                            if (data != null && data.Success) {
                                this.item = data.Result;

                                if (this.item != null && this.item.Id > 0) {
                                    this.formGroup.patchValue(this.item);
                                }
                                else
                                    this.msgError = "No se encontró el tipo seleccionado.";
                            }
                            else
                                this.msgError = "Ocurrió un error al intentar obtener los datos del tipo.";
                        },
                        (err: HttpErrorResponse) => {
                            this.isSearching = false;
                            this.sharedFunctions.handleError(err, true);
                        })
                    }
                    else {
                        this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                        this.onCancel();    
                    }
                }
                else
                    this.msgError = "Item no válido.";
            }
            else {
                if (this.canAdd) {
                    this.item = new Producto(0, null, null, true);
                    this.canDelete = false;
                    this.formGroup.patchValue(this.item);
                }
                else {
                    this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                    this.onCancel(); 
                }
            }
        });
    }

    getErrorCodigo() {
        var v = this.formGroup.get('Codigo');
        if (v.hasError('required'))
            return 'El campo es obligatorio';
        if (v.hasError('maxlength'))
            return 'Límite de 100 caracteres alcanzado';
        return '';
    }

    getErrorNombre() {
        var v = this.formGroup.get('Nombre');
        if (v.hasError('required'))
            return 'El campo es obligatorio';
        if (v.hasError('maxlength'))
            return 'Límite de 150 caracteres alcanzado';
        return '';
    }

    onGuardar() {

        if (!this.formGroup.valid) {
            this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
            return false;
        }

        var r = new SaveProductoDto(null, null, null, true);

        r =
        {
            ...this.formGroup.value,
            ...
            {
                Id: (this.item?.Id ?? 0),
                Activo: this.item?.Activo ?? true
            }
        };

        this.isSaving = true;
        this.productoService.save(r).subscribe(data => {
            this.isSaving = false;
            if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
                this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
                this.onCancel();
            }
            else
                this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
        },
        (err: HttpErrorResponse) => {
            this.isSaving = false;
            this.sharedFunctions.handleError(err, true);
        })
    }

    onCancel() {
        this.location.back();
    }

    onDelete() {

        if (!this.canDelete) 
            return false;
    
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar el producto?", null, null)
        });
    
        dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {
    
            if (result.Accepted) {
                this.isDeleting = true;
                this.productoService.delete(this.item.Id).subscribe(data => {
                    this.isDeleting = false;
                    if (data != null && data.Success && data.Result) {
                        this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
                        this.onCancel();
                    }
                    else
                        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
                },
                (err: HttpErrorResponse) => {
                    this.isDeleting = false;
                    this.sharedFunctions.handleError(err, true);
                })
            }
        });
    }
}