import { Rol } from "./rol";

export class Faq {
    constructor (
        public readonly Id: number,
        public readonly Pregunta: string,
        public readonly Respuesta: string,
        public readonly Orden: number,
        public readonly EsPrivada: boolean,
        public readonly Activo: boolean,
        public Adjuntos: FaqAdjunto[],
        public PalabrasClaves: string[],
        public Roles: FaqRol[],
    ) {}
}

export class FaqRol {
    constructor (
        public readonly Id: number,
        public readonly RolId: string,
        public readonly FaqId: number,
        public readonly Rol: Rol
    ) {}
}

export class FaqAdjunto {
    constructor (
        public readonly Id: number,
        public readonly NombreDocumento: string,
        public readonly UrlDocumento: string,
        public readonly FaqId: number,
        public readonly IncluidoEnCuerpoMensaje: boolean
    ) {}
}

export class SearchFaqRequestDto {
    constructor( 
        public readonly PageSize: number | null,
        public readonly PageNumber: number | null,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly VerSoloPublicas: boolean | null,
        public readonly Texto: string,
        public readonly VerSoloActivos: boolean | null
    ){}
}

export class SearchFaqResponseDto {
    constructor( 
        public readonly count: number, 
        public readonly result: SearchFaqItemDto[]
    ){}
}

export class SearchFaqItemDto {
    constructor( 
        public readonly Id: number,
        public readonly Pregunta: string,
        public readonly Orden: number,
        public readonly EsPrivada: boolean,
        public readonly Activo: boolean
    ){}
}

export class SaveFaq {
    constructor (
        public readonly Id: number,
        public readonly Pregunta: string,
        public readonly Respuesta: string,
        public readonly Orden: number,
        public readonly EsPrivada: boolean,
        public readonly Activo: boolean,
        public PalabrasClaves: string[],
        public Adjuntos: FaqAdjunto[],
        public NuevosAdjuntosBorrados: string[]
    ) {}
}

export class UploadFaqAttachResponseDto {
    constructor (
        public readonly Archivos: FaqAdjunto[]
    ) {}
}

export class ViewFaqRequestDto {
    constructor( 
        public readonly PageSize: number | null,
        public readonly PageNumber: number | null,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly VerSoloPublicas: boolean | null,
        public readonly Texto: string,
        public readonly TenantId: number | null,
        public readonly VerSoloActivos: boolean | null
    ){}
}

export class ViewFaqResponseDto {
    constructor( 
        public readonly count: number, 
        public readonly result: ViewFaqItemDto[]
    ){}
}

export class ViewFaqItemDto {
    constructor( 
        public readonly Pregunta: string,
        public readonly Respuesta: string,
        public PreguntaHtml: any,
        public RespuestaHtml: any,
        public Adjuntos: FaqAdjunto[]
    ){}
}