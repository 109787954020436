import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query} from '@syncfusion/ej2-data';
import { Column, ExcelExportProperties, GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';GridComponent
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { FaqService } from 'src/app/shared/services/faq.service';
import { MenuFuncionService } from 'src/app/shared/services/menuFuncion.service';

import { GridPageSettings, MenuFuncionCodes, SearchFaqItemDto, SearchFaqRequestDto } from 'src/app/shared/model';

@Component({
    selector: 'app-faqLista',
    templateUrl: './faqLista.component.html'
}) 

export class FaqListaComponent {

    @ViewChild('grid')
    public grid: GridComponent;

    public data: DataManager;
    total: number = 0;
    searchForm: FormGroup;

    isValidating: boolean = false;
    isSearching: boolean = false;
    isExporting: boolean = false;
    canEdit: boolean = false;
    canView: boolean = false;
    canAdd: boolean = false;
    verSoloPublicas: boolean = false;
    verSoloActivos: boolean = false;
    public query: Query;

    public selectionOptions: SelectionSettingsModel;
    public pageSizes: number[];
    public toolbarOptions: ToolbarItems[];
    public pageSettings: PageSettingsModel;

    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private sharedFunctions: SharedFunctions,
        private router: Router,
        private route: ActivatedRoute,
        private menuService: MenuFuncionService,
        private service: FaqService,
        private datePipe: DatePipe) {

    }

    ngOnInit(): void {
        this.isValidating = true;
        this.menuService.checkMenuPadre(MenuFuncionCodes.FAQ).subscribe(data => {
            this.isValidating = false;
            if (data != null && data.Success && data?.Result){
                this.canAdd = data.Result.some(x => x == MenuFuncionCodes.FAQ_ADD);
                this.canView = data.Result.some(x => x == MenuFuncionCodes.FAQ_LIST);
                  if (this.canView){
                    this.loadView();
                  } else {
                    this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                    this.router.navigate(['/']);
                  }
            }
            else {
                this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                this.router.navigate(['/']);
            }
        },
        (err: HttpErrorResponse) => {
            this.isValidating = false;
            this.sharedFunctions.handleError(err, true);
        });
    }

    loadView() {
        this.buildForm();
        this.pageSizes = GridPageSettings.PageSizeList;
        this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
        this.toolbarOptions = null; //['ExcelExport'];
        this.setQueryParams();
        this.data = new DataManager({
            url: this.service.getSearchUrl(),
            adaptor: new WebApiAdaptor(),
            headers: [this.service.getAuthorizationHeader()]
        });

    }

    buildForm() {
        this.searchForm = this.formBuilder.group({
            'Texto': [null]
        });
    }

    onGridActionFailure(event: any): void {
        this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
    }

    onSearch() {
        this.setQueryParams();
        this.grid.refresh();
    }

    onSentSearch() {
        this.verSoloPublicas = !this.verSoloPublicas;
        this.verSoloActivos = false;
        this.onSearch();
    }

    setQueryParams() {
        if (this.query == null)
            this.query = new Query();

        var index = this.query.params.findIndex(obj => obj.key === "VerSoloPublicas");
        if (index >= 0)
            this.query.params[index].value = this.verSoloPublicas.toString();
        else
            this.query.addParams("VerSoloPublicas",this.verSoloPublicas.toString());
        
        index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
        if (index >= 0)
            this.query.params[index].value = this.verSoloActivos.toString();
        else
            this.query.addParams("VerSoloActivos",this.verSoloActivos.toString());

        index = this.query.params.findIndex(obj => obj.key === "Texto");
        if (index >= 0)
            this.query.params[index].value = this.searchForm.value.Texto;
        else
            this.query.addParams("Texto",this.searchForm.value.Texto);
    }

    add() {
        if (this.canAdd)
            this.router.navigate(['faq-edit', 0]);
        else
            this.sharedFunctions.showMessage("No puede ejecutar esta operación", "Error");
    }

    view(item: SearchFaqItemDto) {
        if (!item || item.Id == null || item.Id <= 0) {
            this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
            return false;
        }

        this.router.navigate(['faq-edit', item.Id]);
    }

    onExport() {
        var search = new SearchFaqRequestDto(null, null, null, "", this.verSoloPublicas, null, this.verSoloActivos);
        search = Object.assign(search, this.searchForm.value);

        this.isExporting = true;
        this.service.exportSearch(search).subscribe((data: Blob) => {
            this.isExporting = false;
            var fileUrl = URL.createObjectURL(data);

            var anchor = document.createElement("a");
            anchor.download = "FAQs_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
            anchor.href = fileUrl;
            anchor.click();
        },
        (err: HttpErrorResponse) => {
            this.isExporting = false;
            this.sharedFunctions.handleError(err, true);
        })
    }
}