
<app-widget-area [data]="bigChart"></app-widget-area>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-cards">
  <mat-card fxFlex="25">

  </mat-card>
  <mat-card fxFlex="25">

  </mat-card>
  <mat-card fxFlex="25">

  </mat-card>
  <mat-card fxFlex="25">

  </mat-card>
</div>

