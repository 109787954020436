import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, Subject, Observer } from 'rxjs';
import { CrudService } from '.';
import { GenericResponse, MensajeUsuario, SaveMensajeUsuario, SearchMensajeUsuarioRequestDto, SearchMensajeUsuarioResponseDto } from '../model';
import { CantidadNoLeidosUsuarioDto, MensajeUsuarioResumenDto, MensajeUsuarioAdjunto, SearchMensajeUsuarioItemDto, UploadMensajeUsuarioAttachResponseDto } from '../model';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})

export class MensajeUsuarioService extends CrudService<MensajeUsuario, number, SaveMensajeUsuario, SearchMensajeUsuarioRequestDto> { 
  constructor(protected _http: HttpClient, private datePipe: DatePipe, protected sharedFunctions: SharedFunctions) {
    super(_http,'mensajeUsuario', sharedFunctions);
  }

  public messageReadSubject: Subject<number> = new Subject<number>();

  getAttachments(id: number): Observable<GenericResponse<MensajeUsuarioAdjunto[]>> {
    return this._http.get<GenericResponse<MensajeUsuarioAdjunto[]>>(this._serviceUrl + '/' + id + '/adjuntos', this.httpOptions);
  }

  getTotalUnread(): Observable<GenericResponse<CantidadNoLeidosUsuarioDto>> {
    return this._http.get<GenericResponse<CantidadNoLeidosUsuarioDto>>(this._serviceUrl + '/total-no-leidos', this.httpOptions);
  }

  getResume(): Observable<GenericResponse<MensajeUsuarioResumenDto[]>> {
    return this._http.get<GenericResponse<MensajeUsuarioResumenDto[]>>(this._serviceUrl + '/resumen', this.httpOptions);
  }

  getPendingPopUp(): Observable<GenericResponse<SearchMensajeUsuarioItemDto[]>> {
    return this._http.get<GenericResponse<SearchMensajeUsuarioItemDto[]>>(this._serviceUrl + '/pending-popup', this.httpOptions);
  }

  // search(p: SearchMensajeUsuarioRequestDto): Observable<GenericResponse<SearchMensajeUsuarioResponseDto>> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.VerEnviados != null && p.VerEnviados.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerEnviados=" + p.VerEnviados;

  //     if (p.FechaCreacionDesde != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaCreacionDesde=" + this.datePipe.transform(p.FechaCreacionDesde,"yyyy-MM-dd");
  
  //     if (p.FechaCreacionHasta != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaCreacionHasta=" + this.datePipe.transform(p.FechaCreacionHasta,"yyyy-MM-dd");

  //     if (p.FechaEnvioDesde != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaEnvioDesde=" + this.datePipe.transform(p.FechaEnvioDesde,"yyyy-MM-dd");

  //     if (p.FechaEnvioHasta != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaEnvioHasta=" + this.datePipe.transform(p.FechaEnvioHasta,"yyyy-MM-dd");

  //     if (p.MensajeUsuarioTipoId != null && p.MensajeUsuarioTipoId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "MensajeUsuarioTipoId=" + p.MensajeUsuarioTipoId;

  //     if (p.UsuarioCreador != null && p.UsuarioCreador.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "UsuarioCreador=" + p.UsuarioCreador;

  //     if (p.UsuarioDestino != null && p.UsuarioDestino.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "UsuarioDestino=" + p.UsuarioDestino;

  //     if (p.Asunto != null && p.Asunto.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Asunto=" + p.Asunto;

  //     if (p.VerSoloNoLeidos != null && p.VerSoloNoLeidos.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloNoLeidos=" + p.VerSoloNoLeidos;
  //   }

  //   return this._http.get<GenericResponse<SearchMensajeUsuarioResponseDto>>(this.getSearchUrl()+parameters, this.httpOptions);
  // }

  // exportSearch(p: SearchMensajeUsuarioRequestDto): Observable<any> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.VerEnviados != null && p.VerEnviados.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerEnviados=" + p.VerEnviados;

  //     if (p.FechaCreacionDesde != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaCreacionDesde=" + this.datePipe.transform(p.FechaCreacionDesde,"yyyy-MM-dd");
  
  //     if (p.FechaCreacionHasta != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaCreacionHasta=" + this.datePipe.transform(p.FechaCreacionHasta,"yyyy-MM-dd");

  //     if (p.FechaEnvioDesde != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaEnvioDesde=" + this.datePipe.transform(p.FechaEnvioDesde,"yyyy-MM-dd");

  //     if (p.FechaEnvioHasta != null)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "FechaEnvioHasta=" + this.datePipe.transform(p.FechaEnvioHasta,"yyyy-MM-dd");

  //     if (p.MensajeUsuarioTipoId != null && p.MensajeUsuarioTipoId > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "MensajeUsuarioTipoId=" + p.MensajeUsuarioTipoId;

  //     if (p.UsuarioCreador != null && p.UsuarioCreador.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "UsuarioCreador=" + p.UsuarioCreador;

  //     if (p.UsuarioDestino != null && p.UsuarioDestino.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "UsuarioDestino=" + p.UsuarioDestino;

  //     if (p.Asunto != null && p.Asunto.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Asunto=" + p.Asunto;

  //     if (p.VerSoloNoLeidos != null && p.VerSoloNoLeidos.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloNoLeidos=" + p.VerSoloNoLeidos;
  //   }

  //   return this._http.get<any>(this._serviceUrl + '/export-search'+parameters, this.httpCsvOptions);
  // }

  save2(p: SaveMensajeUsuario): Observable<GenericResponse<number[]>> {
    return this._http.post<GenericResponse<number[]>>(this._serviceUrl + '/save', p, this.httpOptions);
  }

  uploadAttachTempUrl(): string {
    return this._serviceUrl + '/attach/temp';
  }

  uploadAttachTemp(p: any): Observable<GenericResponse<UploadMensajeUsuarioAttachResponseDto>> {
    const formData = new FormData();

    if (p != null) {
      for (let i = 0; i < p.length; i++) {
        const file = p[i];
        if (file != null)
          formData.append('uploadFiles', file);
      }
    }

    return this._http.post<GenericResponse<UploadMensajeUsuarioAttachResponseDto>>(this.uploadAttachTempUrl(), formData, this.httpFormDataOptions);
  }

  markAsRead(id: number): Observable<GenericResponse<boolean>> {
    return this._http.post<GenericResponse<boolean>>(this._serviceUrl + '/leido/'+id, null, this.httpOptions);
  }

  informMessageRead(messageId: number): void {
    this.messageReadSubject.next(messageId);
  }

  onMessageRead(): Observable<number> {
    return this.messageReadSubject;
  }

  deleteByEnvio(envioId: string): Observable<GenericResponse<boolean>> {
    return this._http.delete<GenericResponse<boolean>>(this._serviceUrl + '/envio/'+envioId, this.httpOptions);
  }
}