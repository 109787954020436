import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { GridComponent, PageSettingsModel, RowSelectEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { PersonaService, TipoDocumentoService } from 'src/app/shared/services';
import { GridPageSettings, Estado, TipoDocumento, DialogPersonaSearchComponentRequestDto, DialogPersonaSearchComponentResponseDto } from 'src/app/shared/model';
import { Persona, SearchTipoDocumentoRequest } from 'src/app/shared/model';

@Component({
  selector: 'app-dialogPersonaSearch',
  templateUrl: 'dialogPersonaSearch.component.html',
  styleUrls: ['./dialogPersonaSearch.component.css']
})

export class DialogPersonaSearchComponent implements OnInit {

  @ViewChild('grid')
  public grid: GridComponent;
  @ViewChild('gridSelected')
  public gridSelected: GridComponent;

  public data: DataManager;
  total: number = 0;
  searchForm: FormGroup;
  tipoDocumentoList: TipoDocumento[];
  estadoList: Estado[];
  isValidating: boolean = false;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isEstadoSearching: boolean = false;
  isTipoDocumentoSearching: boolean = false;
  canEdit: boolean = false;
  verSoloBloqueados: boolean = false;
  verSoloActivos: boolean = false;
  allowMultipleSelection: boolean = false;
  maxItemSelection: number = 0;
  itemsSeleccionados: Persona[] = [];

  public pageSizes: number[];
  public pageSettings: PageSettingsModel;
  public query: Query;

  constructor(
    private dialogRef: MatDialogRef<DialogPersonaSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public input: DialogPersonaSearchComponentRequestDto,
    private formBuilder: FormBuilder,
    private sharedFunctions: SharedFunctions,
    private tipoDocumentoService: TipoDocumentoService,
    private service: PersonaService
    ) {

  }

  ngOnInit(): void {
    this.allowMultipleSelection = (this.input?.AllowMultipleSelection ?? false);
    this.maxItemSelection = (this.input?.MaxItemSelection ?? 0);

    this.loadView();
  }

  loadView() {
    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

    this.buildForm();
    this.setQueryParams();
    
    this.data = new DataManager({
      url: this.service.getSearchUrl(),
      adaptor: new WebApiAdaptor(),
      headers: [this.service.getAuthorizationHeader()]
    });

    this.getTipoDocumentos();
  }

  getTipoDocumentos() {
    var r = new SearchTipoDocumentoRequest(null, null, true, "Nombre", null, null, true);
    this.isTipoDocumentoSearching = true;

    this.tipoDocumentoService.search(r).subscribe(data => {
      this.isTipoDocumentoSearching = false;
      if (data != null)
        this.tipoDocumentoList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isTipoDocumentoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getTotalSelectionText() {
    return (this.allowMultipleSelection && this.maxItemSelection > 0 ? "de " + this.maxItemSelection : "");
  }

  buildForm() {
    this.searchForm = this.formBuilder.group({
      'TipoDocumentoId': [null],
      'Nombre': [null],
      'Apellido': [null],
      'NroDocumento': [null],
      'VerSoloActivos': [true]
    });
  }

  setQueryParams() {
    if (this.query == null)
      this.query = new Query();

    var index = this.query.params.findIndex(obj => obj.key === "TipoDocumentoId");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.TipoDocumentoId;
    else
      this.query.addParams("TipoDocumentoId",this.searchForm.value.TipoDocumentoId);
    
    index = this.query.params.findIndex(obj => obj.key === "Login");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Login;
    else
      this.query.addParams("Login",this.searchForm.value.Login);

    index = this.query.params.findIndex(obj => obj.key === "Nombre");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Nombre;
    else
      this.query.addParams("Nombre",this.searchForm.value.Nombre);

    index = this.query.params.findIndex(obj => obj.key === "Apellido");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.Apellido;
    else
      this.query.addParams("Apellido",this.searchForm.value.Apellido);

    index = this.query.params.findIndex(obj => obj.key === "NroDocumento");
    if (index >= 0)
      this.query.params[index].value = this.searchForm.value.NroDocumento;
    else
      this.query.addParams("NroDocumento",this.searchForm.value.NroDocumento);

    index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
    if (index >= 0)
      this.query.params[index].value = this.verSoloActivos ? "true" : "false";
    else
      this.query.addParams("VerSoloActivos", this.verSoloActivos? "true" : "false");
  }

  onSearch() {
    this.setQueryParams();
    this.grid.refresh();
  }

  onRowSelected(args: RowSelectEventArgs) {
    if (!this.allowMultipleSelection) {
      const selectedrecords: any[] = this.grid.getSelectedRecords();

      if (selectedrecords != null && selectedrecords.length > 0)
        this.addItem(selectedrecords[0]);
    }
  }

  addItem(item: Persona) {
    if (this.itemsSeleccionados == null)
      this.itemsSeleccionados = [];

    if (item != null && item.Id  > 0) {
      var index = this.itemsSeleccionados.findIndex(f => f.Id == item.Id);

      if (index < 0) {
        if (!this.allowMultipleSelection)
          this.itemsSeleccionados = [];
        else {
          if (this.maxItemSelection > 0 && this.itemsSeleccionados.length >= this.maxItemSelection) {
            this.sharedFunctions.showMessage("Llegaste al límite de personas que podes seleccionar", "Error");
            return false;
          }
        }

        this.itemsSeleccionados.push(item);
        if (this.gridSelected != null)
          this.gridSelected.refresh();
      }
    }
  }

  removeItem(item: Persona) {
    if (this.itemsSeleccionados == null)
      this.itemsSeleccionados = [];

    if (item != null && item.Id  > 0) {
      var index = this.itemsSeleccionados.findIndex(f => f.Id == item.Id);
      if (index >= 0) {
        this.itemsSeleccionados.splice(index, 1);
        if (this.gridSelected != null)
          this.gridSelected.refresh();
      }
    }
  }

  onSelect() {
    if (this.itemsSeleccionados == null || this.itemsSeleccionados.length == 0) {
      this.sharedFunctions.showMessage("Debe seleccionar " + (this.allowMultipleSelection ? "al menos" : "") + " una persona para continuar", "Error");
      return false;
    }

    this.dialogRef.close(new DialogPersonaSearchComponentResponseDto(false, this.itemsSeleccionados));
  }

  onClear() {
    this.itemsSeleccionados = [];
    this.onSearch();
  }

  onCancel() {
    this.dialogRef.close(new DialogPersonaSearchComponentResponseDto(true, null));
  }
}