import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { MenuFuncionService, RolTipoService } from 'src/app/shared/services/index';
import { MenuFuncionCodes, RolTipo, SaveMenuFuncionDto, SearchMenuFuncionItemDto, MenuFuncion, SearchMenuFuncionRequestDto, DialogConfirmRequest, DialogConfirmResponse } from 'src/app/shared/model';
import { DialogConfirmComponent } from 'src/app/shared/components/modals/dialogConfirm.component';

@Component({
    selector: 'app-menuEdit',
    templateUrl: './menuEdit.component.html',
  })

export class MenuEditComponent implements OnInit {
  formGroup: FormGroup;

  isValidating: boolean = false;
  isSearching: boolean = false;
  isMenuSearching: boolean = false;
  isSaving: boolean = false;
  isMenuPadreSearching: boolean = false;
  isRolTipoSearching: boolean = false;
  isDeleting: boolean = false;

  canView: boolean = false;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  msgError: string = "";
  menuPadreList: SearchMenuFuncionItemDto[];
  rolTipoList: RolTipo[];
  item: MenuFuncion;

  constructor (
    private formBuilder: FormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    private sharedFunctions: SharedFunctions,
    private route: ActivatedRoute,
    private rolTipoService: RolTipoService,
    private menuFuncionService: MenuFuncionService) {
  }

  ngOnInit(): void {
    this.isValidating = true;
    this.menuFuncionService.checkMenuPadre(MenuFuncionCodes.MENU).subscribe(data => {
      if (data != null && data.Success && data?.Result) {
        this.isValidating = false;
        this.canEdit = true;
        this.canDelete = true;

        this.loadView();
      }
      else {
        this.menuFuncionService.checkMenu(MenuFuncionCodes.MENU_LIST).subscribe(data => {
          this.isValidating = false;
          if (data != null && data.Success && data?.Result)
            this.loadView();
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();
          }
        },
        (err: HttpErrorResponse) => {
          this.isValidating = false;
          this.sharedFunctions.handleError(err, true);
        });
      }
    },
    (err: HttpErrorResponse) => {
      this.isValidating = false;
      this.sharedFunctions.handleError(err, true);
    });
  }

  loadView() {
    if (this.canEdit) {
      this.buildForm();
      this.getMenuPadres();
      this.getRolTipos();
    }

    this.loadEntity();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      'Codigo': [null, [Validators.required, Validators.maxLength(100)]], 
      'Nombre': [null, [Validators.required, Validators.maxLength(150)]],
      'Orden': [null, Validators.required],
      'Link': [null, Validators.maxLength(100)],
      'Icono': [null, Validators.maxLength(100)],
      'MenuPadreId': [null],
      'MaxRolTipoId': [null, Validators.required]
    });
  }

  getMenuPadres() {
    var r = new SearchMenuFuncionRequestDto(null, null, true, "Nombre", null, null, null, null, true);
    this.isMenuPadreSearching = true;

    this.menuFuncionService.search(r).subscribe(data => {
      this.isMenuPadreSearching = false;
      if (data != null)
        this.menuPadreList = data?.result;
    },
    (err: HttpErrorResponse) => {
      this.isMenuPadreSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  getRolTipos() {
    this.isRolTipoSearching = true;
    this.rolTipoService.getAll().subscribe(data => {
      this.isRolTipoSearching = false;
      this.rolTipoList = data?.Result;
    },
    (err: HttpErrorResponse) => {
      this.isRolTipoSearching = false;
      this.sharedFunctions.handleError(err);
    });
  }

  isProcessing() {
    return (this.isSearching || this.isSaving);
  }

  loadEntity() {
    this.route.params.subscribe(params => {
      if (params['id'] != null && params['id'] != '0') {
        var id = Number(params['id']);

        if (id > 0) {
          if (this.canView || this.canEdit ){
            this.isSearching = true;
            this.menuFuncionService.get(id).subscribe(data => {
              this.isSearching = false;
              if (data != null && data.Success) {
                this.item = data.Result;

                if (this.item != null && this.item.Id > 0) {
                  if (this.canEdit) {
                    this.formGroup.patchValue(this.item);
                  }
                }
                else
                  this.msgError = "No se encontró el menú seleccionado.";
              }
              else
                this.msgError = "Ocurrió un error al intentar obtener los datos del menú.";
            },
            (err: HttpErrorResponse) => {
              this.isSearching = false;
              this.sharedFunctions.handleError(err, true);
            })
          }
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.onCancel();
          }
        }
        else
          this.msgError = "Item no válido.";
      }
      else {
        if (this.canAdd) {
          this.item = new MenuFuncion(null, null, null, true, null, null, null, null, null, true, 0, null, null);
          this.formGroup.patchValue(this.item);
        } 
        else {
          this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
          this.onCancel(); 
        }
      }
    });
  }

  getErrorCodigo() {
    var v = this.formGroup.get('Codigo');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorNombre() {
    var v = this.formGroup.get('Nombre');
    if (v.hasError('required'))
      return 'El campo es obligatorio';
    if (v.hasError('maxlength'))
      return 'Límite de 150 caracteres alcanzado';
    return '';
  }

  getErrorLink() {
    var v = this.formGroup.get('Link');
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorIcono() {
    var v = this.formGroup.get('Icono');
    if (v.hasError('maxlength'))
      return 'Límite de 100 caracteres alcanzado';
    return '';
  }

  getErrorOrden() {
    return this.formGroup.get('Orden').hasError('required') ? 'El campo es obligatorio' :'';
  }

  getErrorMaxRolTipoId() {
    return this.formGroup.get('MaxRolTipoId').hasError('required') ? 'El campo es obligatorio' :'';
  }

  onGuardar() {

    if (!this.formGroup.valid) {
      this.sharedFunctions.showMessage("Por favor complete todos los datos obligatorios para continuar", "Error");
      return false;
    }

    var r = new SaveMenuFuncionDto(null, null, null, null, null, null, null, null, null, null);

    r = 
    {
      ...this.formGroup.value,
      ...
      {
        Id: (this.item?.Id ?? 0),
        Visible: this.item.Visible,
        Activo: this.item.Activo
      } 
    };

    if (r.MenuPadreId <= 0)
      r.MenuPadreId = null;

    this.isSaving = true;
    this.menuFuncionService.save(r).subscribe(data => {
      this.isSaving = false;
      if (data != null && data.Success && data.Result != null && data.Result.Id > 0) {
        this.sharedFunctions.showMessage("Se guardaron correctamente los datos!", "Operación Exitosa!");
        this.onCancel();
      }
      else
        this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
    },
    (err: HttpErrorResponse) => {
      this.isSaving = false;
      this.sharedFunctions.handleError(err, true);
    })
  }

  onCancel() {
    this.location.back();
  }

  navigateMenuPadre(item: MenuFuncion) {
    if (item != null && item.Id > 0)
      this.router.navigate(['menu-edit', item.Id]);
  }

  onDelete() {

    if (!this.canDelete) 
        return false;

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: new DialogConfirmRequest(null, "¿Está seguro que desea eliminar el menú?", null, null)
    });

    dialogRef.afterClosed().subscribe((result: DialogConfirmResponse) => {

        if (result.Accepted) {
            this.isDeleting = true;
            this.menuFuncionService.delete(this.item.Id).subscribe(data => {
              this.isDeleting = false;
              if (data != null && data.Success && data.Result) {
                this.sharedFunctions.showMessage("Se eliminaron correctamente los datos!", "Operación Exitosa!");
                this.onCancel();
              }
              else
                this.sharedFunctions.showMessage(data?.Message ?? "Ocurrió un error ejecutando la operación", "Error");
            },
            (err: HttpErrorResponse) => {
                this.isDeleting = false;
                this.sharedFunctions.handleError(err, true);
            })
        }
    });
  }
}
