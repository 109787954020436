import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { DialogConfirmRequest, DialogConfirmResponse } from 'src/app/shared/model';

@Component({
    selector: 'app-dialogConfirm',
    templateUrl: 'dialogConfirm.component.html',
    styleUrls: ['./dialogConfirm.component.css']
})

export class DialogConfirmComponent implements OnInit {

  public title: string;
  public message: string;
  public buttonAcceptText: string;
  public buttonCancelText: string;

  constructor(
    private dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmRequest) { 

  }

  ngOnInit() {
    this.title = this.data?.Title ?? 'Confirmación de la Operación';
    this.message = this.data?.Message ?? '¿Está seguro que desea continuar?';

    this.buttonAcceptText = this.data?.ButtonTextAccept ?? 'Aceptar';
    this.buttonCancelText = this.data?.ButtonTextCancel ?? 'Cancelar';
  }

  onAccept() {
    this.dialogRef.close(new DialogConfirmResponse(true));
  }

  onDecline() {
    this.dialogRef.close(new DialogConfirmResponse(false));
  }
}