export class Producto{
    constructor(
        public readonly Id: number,
        public readonly Codigo: string,
        public readonly Nombre: string,
        public Activo: boolean
    ){}
}

export class SaveProductoDto{
    constructor(
        public Id: number,
        public Codigo: string,
        public Nombre: string,
        public Activo: boolean
    ){}
}

export class SearchProductoRequestDto{
    constructor(
        public readonly PageSize: number | null,
        public readonly PageNumber: number | null,
        public readonly OrderAscending: boolean | null,
        public readonly OrderFieldName: string,
        public readonly Codigo: string,
        public readonly Nombre: string,
        public readonly VerSoloActivos: boolean | null
    ){}
}

export class SearchProductoResponseDto{
    constructor(
        public readonly count: number,
        public readonly result: Producto[]
    ){}
}