import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';

import { SharedFunctions } from 'src/app/shared/shared.functions';

import { GridPageSettings, SearchLicitacionForecastAnioItemDto } from 'src/app/shared/model';

@Component({
    selector: 'app-dialogForecastDetalleAnio',
    templateUrl: 'dialogForecastDetalleAnio.component.html',
    styleUrls: ['./dialogForecastDetalleAnio.component.css']
})

export class DialogForecastDetalleAnioComponent implements OnInit {

  @ViewChild('grid')
  public grid: GridComponent;

  public pageSizes: number[];
  public pageSettings: PageSettingsModel;

  constructor(
    private dialogRef: MatDialogRef<DialogForecastDetalleAnioComponent>,
    @Inject(MAT_DIALOG_DATA) public input: SearchLicitacionForecastAnioItemDto,
    private sharedFunctions: SharedFunctions
  ) {

  }

  ngOnInit(): void {
    console.log(this.input);

    this.pageSizes = GridPageSettings.PageSizeList;
    this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}