import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrudService } from '.';
import { GenericResponse, Usuario, SaveUsuarioDto, SearchUsuariosRequestDto, SearchUsuariosResponseDto, ChangeUserStateRequestDto } from "../model/index";
import { SearchUsuarioHistorialRequestDto, SearchUsuarioHistorialResponseDto, ActivateUserRequestDto, BlockUserRequestDto } from "../model/index";

import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})

export class UsuarioService extends CrudService<Usuario, number, SaveUsuarioDto, SearchUsuariosRequestDto> {

  constructor(protected _http: HttpClient, private datePipe: DatePipe, protected sharedFunctions: SharedFunctions) {
    super(_http,'usuario', sharedFunctions);

  }

  // search(p: SearchUsuariosRequestDto): Observable<GenericResponse<SearchUsuariosResponseDto>> {
  //   var querystring = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.Login != null && p.Login.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Login=" + p.Login;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.Apellido != null && p.Apellido.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Apellido=" + p.Apellido;

  //     if (p.NroDocumento != null && p.NroDocumento.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "NroDocumento=" + p.NroDocumento;

  //     if (p.Movil != null && p.Movil.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Movil=" + p.Movil;

  //     if (p.Mail != null && p.Mail.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Mail=" + p.Mail;

  //     if (p.RolId != null && p.RolId > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "RolId=" + p.RolId;

  //     if (p.EstadoId != null && p.EstadoId > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "EstadoId=" + p.EstadoId;
  
  //     if (p.VerSoloActivos != null)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;
  
  //     if (p.VerSoloBloqueados != null)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "VerSoloBloqueados=" + p.VerSoloBloqueados;
  //   }

  //   return this._http.get<GenericResponse<SearchUsuariosResponseDto>>(this.getSearchUrl()+querystring, this.httpOptions);
  // }

  // exportSearch(p: SearchUsuariosRequestDto): Observable<any> {
  //   var querystring = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.Login != null && p.Login.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Login=" + p.Login;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.Apellido != null && p.Apellido.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Apellido=" + p.Apellido;

  //     if (p.NroDocumento != null && p.NroDocumento.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "NroDocumento=" + p.NroDocumento;

  //     if (p.Movil != null && p.Movil.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Movil=" + p.Movil;

  //     if (p.Mail != null && p.Mail.length > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "Mail=" + p.Mail;

  //     if (p.RolId != null && p.RolId > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "RolId=" + p.RolId;

  //     if (p.EstadoId != null && p.EstadoId > 0)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "EstadoId=" + p.EstadoId;

  //     if (p.VerSoloActivos != null)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "VerSoloActivos=" + p.VerSoloActivos;

  //     if (p.VerSoloBloqueados != null)
  //       querystring += (querystring.length == 0 ? "?" : "&") + "VerSoloBloqueados=" + p.VerSoloBloqueados;
  //   }

  //   return this._http.get<any>(this._serviceUrl + '/export-search'+querystring, this.httpCsvOptions);
  // }

  searchHistory(p: SearchUsuarioHistorialRequestDto): Observable<SearchUsuarioHistorialResponseDto> {
    // var querystring = "";
    var queryString = this.sharedFunctions.convertToQueryString(p);
    // if (p != null) {
    //   if (p.PageSize != null && p.PageSize > 0)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

    //   if (p.PageNumber != null && p.PageNumber >= 0)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

    //   if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

    //   if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

    //   if (p.FechaDesde != null)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "FechaDesde=" + this.datePipe.transform(p.FechaDesde,"yyyy-MM-dd");
  
    //   if (p.FechaHasta != null)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "FechaHasta=" + this.datePipe.transform(p.FechaHasta,"yyyy-MM-dd");

    //   if (p.UsuarioId != null && p.UsuarioId > 0)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "UsuarioId=" + p.UsuarioId;

    //   if (p.Descripcion != null && p.Descripcion.length > 0)
    //     querystring += (querystring.length == 0 ? "?" : "&") + "Descripcion=" + p.Descripcion;
    // }

    return this._http.get<SearchUsuarioHistorialResponseDto>(this._serviceUrl + '/history/search'+queryString, this.httpOptions);
  }

  getSelf(): Observable<GenericResponse<Usuario>> {
    return this._http.get<GenericResponse<Usuario>>(this._serviceUrl + '/self', this.httpOptions);
  }

  resetPassword(id: number): Observable<GenericResponse<boolean>> {
    return this._http.post<GenericResponse<boolean>>(this._serviceUrl + '/reset-password/'+id, this.httpOptions);
  }

  saveSelf(p: SaveUsuarioDto): Observable<GenericResponse<SaveUsuarioDto>> {
    return this._http.post<GenericResponse<SaveUsuarioDto>>(this._serviceUrl + '/self', p, this.httpOptions);
  }

  deleteSelf(): Observable<GenericResponse<boolean>> {
    return this._http.delete<GenericResponse<boolean>>(this._serviceUrl + '/self', this.httpOptions);
  }

  activateUser(p: ActivateUserRequestDto): Observable<GenericResponse<boolean>> {
    return this._http.post<GenericResponse<boolean>>(this._serviceUrl + '/activate', p, this.httpOptions);
  }

  blockUser(p: BlockUserRequestDto): Observable<GenericResponse<boolean>> {
    return this._http.post<GenericResponse<boolean>>(this._serviceUrl + '/block', p, this.httpOptions);
  }

  approveUser(p: ChangeUserStateRequestDto): Observable<GenericResponse<boolean>> {
    return this._http.post<GenericResponse<boolean>>(this._serviceUrl + '/approve', p, this.httpOptions);
  }

  rejectUser(p: ChangeUserStateRequestDto): Observable<GenericResponse<boolean>> {
    return this._http.post<GenericResponse<boolean>>(this._serviceUrl + '/reject', p, this.httpOptions);
  }
}