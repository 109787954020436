import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { Router } from '@angular/router';
import { GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { GridPageSettings, MenuFuncionCodes, Producto, SearchProductoRequestDto } from 'src/app/shared/model';
import { MenuFuncionService, ProductoService } from 'src/app/shared/services';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Component({
    selector: 'app-productoLista',
    templateUrl: './productosLista.component.html',
    styles: [
    ]
})

export class ProductoListaComponent implements OnInit {
    @ViewChild('grid')
    public grid: GridComponent;
    
    data: DataManager;
    searchForm: FormGroup;

    isExporting: boolean = false;
    isValidating: boolean = false;
    canView: boolean = false;
    canAdd: boolean = false;
    verSoloActivos: boolean = false;
    canExport: boolean = false;

    public query: Query;
    public selectionOptions: SelectionSettingsModel;
    public pageSizes: number[];
    public toolbarOptions: ToolbarItems[];
    public pageSettings: PageSettingsModel;

    constructor(
        private menuService: MenuFuncionService,
        private sharedFunctions: SharedFunctions,
        private productoService: ProductoService,
        private router: Router,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe
    ) { }

    ngOnInit(): void {
        this.isValidating = true;
        this.menuService.checkMenu(MenuFuncionCodes.PRODUCTO_EXPORT).subscribe(data => {
            this.isValidating = false;
            this.canExport = data?.Result;
        },(err: HttpErrorResponse) => {
            this.isValidating = false;
            this.sharedFunctions.handleError(err, true);
        });

        this.menuService.checkMenuPadre(MenuFuncionCodes.PRODUCTO).subscribe(data => {
          this.isValidating = false;
          if (data != null && data.Success && data?.Result){
            this.canAdd = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_ADD);
            this.canView = data.Result.some(x => x == MenuFuncionCodes.PRODUCTO_LIST);
              if (this.canView){
                this.loadView();
              } else {
                this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                this.router.navigate(['/']);
              }
          }
          else {
            this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
            this.router.navigate(['/']);
          }
        },
        (err: HttpErrorResponse) => {
          this.isValidating = false;
          this.sharedFunctions.handleError(err, true);
        });
    }

    loadView() {
        this.canView = true;

        this.buildForm();

        this.pageSizes = GridPageSettings.PageSizeList;
        this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
        this.toolbarOptions = null; //['ExcelExport'];

        this.setQueryParams();
        this.data = new DataManager({
            url: this.productoService.getSearchUrl(),
            adaptor: new WebApiAdaptor(),
            headers: [this.productoService.getAuthorizationHeader()]
        });
    }

    buildForm() {
        this.searchForm = this.formBuilder.group({
            'Codigo': [null],
            'Nombre': [null]
        });
    }

    onGridActionFailure(event: any): void {
        this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
    }

    onSearch() {
        this.setQueryParams();
        this.grid.refresh();
    }

    setQueryParams() {
        if (this.query == null)
            this.query = new Query();

        var index = this.query.params.findIndex(obj => obj.key === "Codigo");
        if (index >= 0)
            this.query.params[index].value = this.searchForm.value.Codigo;
        else
            this.query.addParams("Codigo", this.searchForm.value.Tipo);

        index = this.query.params.findIndex(obj => obj.key === "Nombre");
        if (index >= 0)
            this.query.params[index].value = this.searchForm.value.Nombre;
        else
            this.query.addParams("Nombre", this.searchForm.value.Nombre);

        index = this.query.params.findIndex(obj => obj.key === "VerSoloActivos");
        if (index >= 0)
            this.query.params[index].value = this.verSoloActivos.toString();
        else
            this.query.addParams("VerSoloActivos",this.verSoloActivos.toString());
    }

    add() {
        if (this.canAdd){
            this.router.navigate(['producto-edit', 0]);
        }
        else {
            this.sharedFunctions.showMessage("No tiene permisos para crear un nuevo Banco", "Error");
        }
    }

    edit(item: Producto) {
        if (!item || item.Id == null || item.Id <= 0) {
            this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
            return false;
        }
        this.router.navigate(['producto-edit', item.Id]);
    }

    onExport() {
        var search = new SearchProductoRequestDto(null, null, null, "Codigo", null, null, this.verSoloActivos);
        search = Object.assign(search, this.searchForm.value);

        this.isExporting = true;
        this.productoService.exportSearch(search).subscribe((data: Blob) => {
            this.isExporting = false;
            var fileUrl = URL.createObjectURL(data);

            var anchor = document.createElement("a");
            anchor.download = "Productos_" + this.datePipe.transform(new Date(), "yyyy-MM-dd") + ".csv";
            anchor.href = fileUrl;
            anchor.click();
        },
        (err: HttpErrorResponse) => {
            this.isExporting = false;
            this.sharedFunctions.handleError(err, true);
        })
    }
}