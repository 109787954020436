export class Estado {
  constructor(
    public readonly Id: number,
    public readonly Nombre: string,
    public readonly Tipo: number,
    public readonly TipoNombre: string,
    public readonly Activo: boolean
  ) {}
}

export class SearchEstadoRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly Nombre: string,
    public readonly Tipo: number,
    public readonly VerSoloActivos: boolean | null
  ) {}
}
export class SaveEstadoDto {
  constructor(
    public Nombre: string,
    public Tipo: number,
    public Activo: boolean
  ) {}
}

export class SearchEstadoResponse {
  constructor(
    public readonly count: number,
    public readonly result: Estado[]
  ) {}
}

export class TipoEstado {
  constructor(
    public readonly Id: number,
    public readonly Nombre: string
  ) {}
}
