import { Estado } from "./estado";
//TODO: redefinir estas entidades cuando se necesiten
export class LicitacionDto {
  constructor(
    public readonly Id: number,
    public  Numero : string,
    public  Descripcion : string,
    public  UnidadDeCompra : string,
    public  Dependencia : string,
    public  FechaPublicacion : Date,
    public  FechaUltimaPublicacion : Date,
    public  Modalidad : string,
    public  EstadoId : number,
    public  Estado: Estado,
    public  EmpresaCodigo  : string,
    public  Moneda : string,
    public  MontoReferencia : number,
  ) {}
}

export class SearchLicitacionRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly NumeroLicitacion: string,
    public readonly Estado: number,
    public readonly FechaDesde: Date,
    public readonly FechaHasta: Date,
  ) {}
}

export class SaveLicitacionDto {
  constructor(
  ) {}
}

export class SearchLicitacionResponse {
  constructor(
    public readonly count: number,
    public readonly result: LicitacionDto[]
  ) {}
}

export class SearchLicitacionDetalleLicitacionesRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly NumeroLicitacion: string,
    public readonly ProductoId: string,
    public readonly Descripcion: string,
    public readonly EstadoLicitacionId: number,
    public readonly FechaDesde: Date,
    public readonly FechaHasta: Date,
    public readonly TieneProductos :boolean | null, 
  ) {}
}

export class SearchLicitacionDetalleLicitacionesResponse {
  constructor(
    public readonly count: number,
    public readonly result: SearchLicitacionDetalleLicitacionesItemDto[]
  ) {}
}

export class SearchLicitacionDetalleLicitacionesItemDto {
  constructor(
    public readonly LicitacionId: number,
    public readonly EspecificacionTecnicaId: number,
    public readonly NumeroLicitacion: string,
    public readonly EstadoLicitacion: string,
    public readonly Clasificacion: string,
    public readonly Descripcion: string,
    public readonly Codigo: string,
    public readonly Cantidad: number,
    public readonly MontoReferencia: number,
    public readonly UnidadMedida: string
  ) {}
}

export class LicitacionEspecificacionTecnicaDto
{
  constructor(  
    public readonly Id : number,
    public readonly LicitacionId : number,
    public readonly NumeroLinea : number,
    public readonly LicitacionCategoriaId : number,
    public readonly LicitacionCategoria: string,
    public readonly CodigoCategoria: string ,
    public readonly Descripcion: string, 
    public readonly Clasificacion: string, 
    public readonly CodigoProducto: string, 
    public readonly ProductoId: number, 
    public readonly Producto: string ,
    public readonly UnidadMedida: string ,
    public readonly Cantidad: number ,
    public readonly Moneda: string ,
    public readonly MontoUnitario: number ,
    public readonly MontoTotal: number ,
    public readonly SES: string
  ) {}
}

export class LicitacionEspecificacionTecnicaResponse {
  constructor(
    public readonly count: number,
    public readonly result: LicitacionEspecificacionTecnicaDto[]
  ) {}
}

export class SearchLicitacionForecastRequest {
  constructor(
    public readonly PageSize: number | null,
    public readonly PageNumber: number | null,
    public readonly OrderAscending: boolean | null,
    public readonly OrderFieldName: string,
    public readonly ProductoId: number,
    public readonly Descripcion: string,
    public readonly EstadoLicitacionId: number,
    public readonly AnioDesde: number,
    public readonly AnioHasta: number
  ) {}
}

export class SearchLicitacionForecastResponse {
  constructor(
    public readonly count: number,
    public readonly result: SearchLicitacionForecastItemDto[]
  ) {}
}

export class SearchLicitacionForecastItemDto {
  constructor(
    public readonly CodigoProducto: string,
    public readonly Descripcion: string,
    public readonly UnidadMedida: string,
    public readonly Moneda: string,
    public readonly MinimoPrecioUnitario: number,
    public readonly TotalCantidad: number,
    public readonly TotalMonto: number
  ) {}
}

export class SearchLicitacionForecastAnioItemDto {
  constructor(
    public readonly Anio: string,
    public readonly Mes: string,
    public readonly MesNombre: string,
    public readonly TotalCantidad: number,
    public readonly MinimoPrecioUnitario: number,
    public readonly TotalMonto: number,
    public readonly Meses: SearchLicitacionForecastAnioItemDto[],
    public VerDetalles: boolean
  ) {}
}

export class SaveEspecificacionTecnicaProductoDto {
  constructor(
    public EspecificacionTecnicas: number[],
    public ProductoId: number,
  ) {}
}

export class ProcessLicitacionesFromWebDto {
  constructor(
    public NumeroLicitacion: string,
    public FechaInicio: Date,
    public FechaFin: Date,
    public Estado: number
  ) {}
}

export class ProcessLicitacionesFromWebResultDto {
  constructor(
    public TotalNuevos: number,
    public TotalActualizados: number,
    public Errores: string[]
  ) {}
}

export class ImportArchivoEspecificacionesTecnicasRequestDto {
  constructor(
    public NombreArchivo: string,
    public Archivo: any,
    public ActualizarAsignados: boolean,
    public SaltearPrimeraLinea: boolean,
    public CaracterSeparador: string
  ) {}
}

export class ImportArchivoEspecificacionesTecnicasResponseDto {
  constructor(
    public TotalLineasLeidas: number,
    public TotalLineasConProducto: number,
    public TotalProductosAgregados: number,
    public TotalLicitacionesActualizadas: number
  ) {}
}