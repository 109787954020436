import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CrudService } from '.';
import { GenericResponse, ParametroSistema, SearchParametroSistemaRequestDto, SearchParametroSistemaResponseDto } from '../model';
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})

export class ParametroSistemaService extends CrudService<ParametroSistema, number, ParametroSistema, SearchParametroSistemaRequestDto> { 
  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'parametroSistema', sharedFunctions);
  }

  getCode(codigo: string): Observable<GenericResponse<ParametroSistema>> {
    return this._http.get<GenericResponse<ParametroSistema>>(this._serviceUrl + '/code/' + codigo, this.httpOptions);
  }

  getAnonymousCode(codigo: string): Observable<GenericResponse<ParametroSistema>> {
    return this._http.get<GenericResponse<ParametroSistema>>(this._serviceUrl + '/anonymous-code/' + codigo, this.httpOptions);
  }

  // search(p: SearchParametroSistemaRequestDto): Observable<GenericResponse<SearchParametroSistemaResponseDto>> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Codigo != null && p.Codigo.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Codigo=" + p.Codigo;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.VerSoloPermitirAccederAnonimamente != null && p.VerSoloPermitirAccederAnonimamente.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloPermitirAccederAnonimamente=" + p.VerSoloPermitirAccederAnonimamente;
  //   }

  //   return this._http.get<GenericResponse<SearchParametroSistemaResponseDto>>(this.getSearchUrl()+parameters, this.httpOptions);
  // }

  // exportSearch(p: SearchParametroSistemaRequestDto): Observable<any> {
  //   var parameters = "";

  //   if (p != null) {
  //     if (p.PageSize != null && p.PageSize > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageSize=" + p.PageSize;

  //     if (p.PageNumber != null && p.PageNumber >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "PageNumber=" + p.PageNumber;

  //     if (p.OrderAscending != null && p.OrderAscending.toString().length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderAscending=" + p.OrderAscending;

  //     if (p.OrderFieldName != null && p.OrderFieldName.length >= 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "OrderFieldName=" + p.OrderFieldName;

  //     if (p.Codigo != null && p.Codigo.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Codigo=" + p.Codigo;

  //     if (p.Nombre != null && p.Nombre.length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "Nombre=" + p.Nombre;

  //     if (p.VerSoloPermitirAccederAnonimamente != null && p.VerSoloPermitirAccederAnonimamente.toString().length > 0)
  //       parameters += (parameters.length == 0 ? "?" : "&") + "VerSoloPermitirAccederAnonimamente=" + p.VerSoloPermitirAccederAnonimamente;
  //   }

  //   return this._http.get<any>(this._serviceUrl + '/export-search'+parameters, this.httpCsvOptions);
  // }
}