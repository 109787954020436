import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManager, WebApiAdaptor, Query} from '@syncfusion/ej2-data';
import { Column, ExcelExportProperties, GridComponent, PageSettingsModel, SelectionSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';GridComponent
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { SharedFunctions } from 'src/app/shared/shared.functions';
import { MensajeUsuarioService, MenuFuncionService } from 'src/app/shared/services';
import { DialogMensajePopUpComponent } from 'src/app/shared/components/modals/dialogMensajePopUp.component';
import { GridPageSettings, MenuFuncionCodes, SearchMensajeUsuarioItemDto, SearchMensajeUsuarioRequestDto } from 'src/app/shared/model';

@Component({
    selector: 'app-mensajeUsuarioLista',
    templateUrl: './mensajeUsuarioLista.component.html',
    styleUrls: ['./mensajeUsuarioLista.component.scss']
}) 

export class MensajeUsuarioListaComponent {

    @ViewChild('grid')
    public grid: GridComponent;

    public data: DataManager;
    total: number = 0;
    searchForm: FormGroup;

    isValidating: boolean = false;
    isSearching: boolean = false;
    isExporting: boolean = false;
    canEdit: boolean = false;
    canView: boolean = false;
    verEnviados: boolean = false;
    verSoloNoLeidos: boolean = false;
    public query: Query;

    public selectionOptions: SelectionSettingsModel;
    public pageSizes: number[];
    public toolbarOptions: ToolbarItems[];
    public pageSettings: PageSettingsModel;

    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private sharedFunctions: SharedFunctions,
        private router: Router,
        private route: ActivatedRoute,
        private menuService: MenuFuncionService,
        private service: MensajeUsuarioService,
        private datePipe: DatePipe) {

    }

    ngOnInit(): void {
        this.isValidating = true;
        this.menuService.checkMenu(MenuFuncionCodes.MENSAJE_USUARIO_LIST).subscribe(data => {
            this.isValidating = false;
            if (data != null && data.Success && data?.Result)
                this.loadView();
            else {
                this.sharedFunctions.showMessage("No tiene permisos para acceder a esta vista", "Error");
                this.router.navigate(['/']);
            }
        },
        (err: HttpErrorResponse) => {
            this.isValidating = false;
            this.sharedFunctions.handleError(err, true);
        });
    }

    loadView() {
        this.canView = true;
        this.buildForm();

        this.pageSizes = GridPageSettings.PageSizeList;
        this.pageSettings = { pageCount: GridPageSettings.PageCount, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };
        this.toolbarOptions = null; //['ExcelExport'];

        this.setQueryParams();
        this.data = new DataManager({
            url: this.service.getSearchUrl(),
            adaptor: new WebApiAdaptor(),
            headers: [this.service.getAuthorizationHeader()]
        });

        this.menuService.checkMenu(MenuFuncionCodes.MENSAJE_USUARIO_EDIT).subscribe(data => {
            this.isValidating = false;
            if (data != null && data.Success && data?.Result)
                this.canEdit = true;
        },
        (err: HttpErrorResponse) => {
            this.sharedFunctions.handleError(err);
        });
    }

    buildForm() {
        this.searchForm = this.formBuilder.group({
            'UsuarioCreador': [null],
            'UsuarioDestino': [null],
            'FechaEnvioDesde': [null],
            'FechaEnvioHasta': [null],
            'Asunto': [null]
        });
    }

    onGridActionFailure(event: any): void {
        this.sharedFunctions.showMessage("Ocurrió un error intentando realizar la búsqueda", "Error");
      }

    onSearch() {
        this.setQueryParams();
        this.grid.refresh();
    }

    onSentSearch() {
        this.verEnviados = !this.verEnviados;
        this.verSoloNoLeidos = false;
        this.onSearch();
    }

    setQueryParams() {
        if (this.query == null)
            this.query = new Query();

        var index = this.query.params.findIndex(obj => obj.key === "VerEnviados");
        if (index >= 0)
            this.query.params[index].value = this.verEnviados.toString();
        else
            this.query.addParams("VerEnviados",this.verEnviados.toString());
        
        index = this.query.params.findIndex(obj => obj.key === "VerSoloNoLeidos");
        if (index >= 0)
            this.query.params[index].value = this.verSoloNoLeidos.toString();
        else
            this.query.addParams("VerSoloNoLeidos",this.verSoloNoLeidos.toString());

        index = this.query.params.findIndex(obj => obj.key === "FechaCreacionDesde");
        if (index >= 0)
            this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaCreacionDesde,"yyyy-MM-dd");
        else
            this.query.addParams("FechaCreacionDesde", this.datePipe.transform(this.searchForm.value.FechaCreacionDesde,"yyyy-MM-dd"));

        index = this.query.params.findIndex(obj => obj.key === "FechaCreacionHasta");
        if (index >= 0)
            this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaCreacionHasta,"yyyy-MM-dd");
        else
            this.query.addParams("FechaCreacionHasta", this.datePipe.transform(this.searchForm.value.FechaCreacionHasta,"yyyy-MM-dd"));

        index = this.query.params.findIndex(obj => obj.key === "FechaEnvioDesde");
        if (index >= 0)
            this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaEnvioDesde,"yyyy-MM-dd");
        else
            this.query.addParams("FechaEnvioDesde", this.datePipe.transform(this.searchForm.value.FechaEnvioDesde,"yyyy-MM-dd"));

        index = this.query.params.findIndex(obj => obj.key === "FechaEnvioHasta");
        if (index >= 0)
            this.query.params[index].value = this.datePipe.transform(this.searchForm.value.FechaEnvioHasta,"yyyy-MM-dd");
        else
            this.query.addParams("FechaEnvioHasta", this.datePipe.transform(this.searchForm.value.FechaEnvioHasta,"yyyy-MM-dd"));
        
        index = this.query.params.findIndex(obj => obj.key === "MensajeUsuarioTipoId");
        if (index >= 0)
            this.query.params[index].value = this.searchForm.value.MensajeUsuarioTipoId;
        else
            this.query.addParams("MensajeUsuarioTipoId",this.searchForm.value.MensajeUsuarioTipoId);

        index = this.query.params.findIndex(obj => obj.key === "UsuarioCreador");
        if (index >= 0)
            this.query.params[index].value = this.searchForm.value.UsuarioCreador;
        else
            this.query.addParams("UsuarioCreador",this.searchForm.value.UsuarioCreador);

        index = this.query.params.findIndex(obj => obj.key === "UsuarioDestino");
        if (index >= 0)
            this.query.params[index].value = this.searchForm.value.UsuarioDestino;
        else
            this.query.addParams("UsuarioDestino",this.searchForm.value.UsuarioDestino);

        index = this.query.params.findIndex(obj => obj.key === "Asunto");
        if (index >= 0)
            this.query.params[index].value = this.searchForm.value.Asunto;
        else
            this.query.addParams("Asunto",this.searchForm.value.Asunto);
    }

    clearDate(formFieldName: string) {
        if (formFieldName != null && formFieldName.length > 0) {
            if (this.searchForm.get(formFieldName) != null && this.searchForm.get(formFieldName)?.value)
                this.searchForm.get(formFieldName).setValue(null);
        }
    }

    add() {
        if (this.canEdit)
            this.router.navigate(['mensaje-usuario-edit', 0]);
        else
            this.sharedFunctions.showMessage("No puede ejecutar esta operación", "Error");
    }

    view(item: SearchMensajeUsuarioItemDto) {
        if (!item || item.Id == null || item.Id <= 0) {
            this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
            return false;
        }

        if (this.verEnviados) {
            var loggedUser = this.sharedFunctions.getLoggedUser();

            if (loggedUser != null && loggedUser.Id > 0) {
                if (loggedUser.Id == item.UsuarioCreadorId && !item.Enviado)
                    this.router.navigate(['mensaje-usuario-edit', item.Id]);
                else
                    this.openMessage(item);
            }
        }
        else
            this.openMessage(item);
    }

    openMessage(item: SearchMensajeUsuarioItemDto) {
        if (item.EsPopUp)
            this.openMessagePopUp(item);
        else
            this.router.navigate(['mensaje-usuario-view', item.Id]);
    }

    openMessagePopUp(item: SearchMensajeUsuarioItemDto) {
        const dialogRef = this.dialog.open(DialogMensajePopUpComponent, {
            width: '90%',
            data: item.Id
        });
    
        dialogRef.afterClosed().subscribe((result: boolean) => {
            this.grid.refresh();
        });
    }

    onExport() {
        var search = new SearchMensajeUsuarioRequestDto(null, null, null, "", this.verEnviados, null, null, null, null, null, null, null, null, null);
        search = Object.assign(search, this.searchForm.value);

        this.isExporting = true;
        this.service.exportSearch(search).subscribe((data: Blob) => {
            this.isExporting = false;
            var fileUrl = URL.createObjectURL(data);

            var anchor = document.createElement("a");
            anchor.download = "MensajeUsuarios_" + this.datePipe.transform(new Date(),"yyyy-MM-dd") + ".csv";
            anchor.href = fileUrl;
            anchor.click();
        },
        (err: HttpErrorResponse) => {
            this.isExporting = false;
            this.sharedFunctions.handleError(err, true);
        })
    }
}