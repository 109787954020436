import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ToastComponent , ToastAnimationSettingsModel, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";

import { SharedFunctions } from 'src/app/shared/shared.functions';
import { DialogMensajePopUpComponent } from 'src/app/shared/components/modals/dialogMensajePopUp.component';
import { SendMensajeUsuarioNotificationDto } from '../../model/index';

import { MenuFuncionService, MensajeUsuarioService, SignalrMensajeUsuarioService } from '../../services';
import { MenuFuncionCodes, MensajeUsuarioResumenDto } from 'src/app/shared/model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {
  panelOpenState = false;

  @ViewChild('notificationtoaster') notificationElement: ToastComponent;
  public notification: any = { Title: "", Message:"" };
  public notificationPosition: ToastPositionModel = { X: 'Right', Y: 'Top' };
  public notificationShowAnimation: ToastAnimationSettingsModel = { show: { effect: 'FadeIn', duration: 600, easing: 'linear'}, hide: { effect: 'FadeOut', duration: 600, easing: 'linear' }};

  usuario: any;
  showUserMessageIcon: boolean = false;
  isMensajesLoading: boolean = false;
  totalUnreadMessages: number = 0;
  messageList: MensajeUsuarioResumenDto[];

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor(
    private sharedFunctions: SharedFunctions,
    private router: Router, 
    public dialog: MatDialog,
    private menuService: MenuFuncionService,
    private mensajeUsuarioService: MensajeUsuarioService,
    private signalrService: SignalrMensajeUsuarioService
  ) {
  }

  ngOnInit(): void {
    this.usuario = this.sharedFunctions.getLoggedUser();
    
    this.setShowUserMessageIcon();
    this.refreshMessages();
    this.getPendingPopUpMessages();
    this.loadSignalREvent();
    this.loadMessageReadEvent();
  }

  loadSignalREvent() {
    this.signalrService.initiateSignalrConnection();
    this.signalrService.receiveMessage.subscribe((model: SendMensajeUsuarioNotificationDto) => {
      if (model != null && model.usuarioDestinoId > 0) { 
        if (this.usuario != null && this.usuario.Id == model.usuarioDestinoId) {
          this.showNotification(model);
          this.getTotalUnreadMessages();
          this.getMessageResume();
        }
      }
    });
  }

  loadMessageReadEvent() {
    this.mensajeUsuarioService.onMessageRead().subscribe((messageId) => {
        if (messageId > 0)
          this.refreshMessages();
      }
    );
  }

  showNotification(model: SendMensajeUsuarioNotificationDto) {
    var origen = (model?.usuarioOrigen != null && model?.usuarioOrigen.length > 0 ? " de " + model?.usuarioOrigen + " " : "");
    var title = "¡Recibiste un nuevo mensaje" + origen + "!";
    var msg = "Asunto: " + (model?.asunto != null && model?.asunto.length > 0 ? model.asunto : "(sin asunto)");

    this.notification = { Title: title, Message: msg };
    this.notificationElement.show({ title: title, content: msg, cssClass: 'e-toast-info' });
  }

  refreshMessages() {
    this.getTotalUnreadMessages();
    this.getMessageResume();
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  showUserImage(imageUrl: string) {
    return (imageUrl != null && imageUrl?.length > 0);
  }

  setShowUserMessageIcon() {
    this.menuService.checkMenu(MenuFuncionCodes.MENSAJE_USUARIO_LIST).subscribe(data => {
      if (data != null && data.Success && data?.Result)
        this.showUserMessageIcon = true;
    },
    (err: HttpErrorResponse) => {
    });
  }

  getTotalUnreadMessages() {
    this.mensajeUsuarioService.getTotalUnread().subscribe(data => {
      if (data != null && data.Success && data.Result != null)
        this.totalUnreadMessages = (data.Result.Total ?? 0);
    },
    (err: HttpErrorResponse) => {
    });
  }

  getMessageResume() {
    this.isMensajesLoading = true;
    this.mensajeUsuarioService.getResume().subscribe(data => {
      this.isMensajesLoading = false;
      if (data != null && data.Success)
        this.messageList = data.Result;
    },
    (err: HttpErrorResponse) => {
      this.isMensajesLoading = false;
    });
  }

  getPendingPopUpMessages() {
    this.mensajeUsuarioService.getPendingPopUp().subscribe(data => {
      if (data != null && data.Success && data?.Result != null && data.Result.length > 0) {
        data.Result.forEach(item => this.openMessagePopUp(item.Id));
      }
    },
    (err: HttpErrorResponse) => {
    });
  }

  logout() {
    this.sharedFunctions.clearLocalStorage();
    this.router.navigate(['auth/login']);
  }

  selfEdition() {
    this.router.navigate(['usuario-self-edit']);
  }

  openUserMessages() {
    this.router.navigate(['mensaje-usuario-list']);
  }

  openMessage(item: MensajeUsuarioResumenDto) {
    if (!item || item.Id == null || item.Id <= 0) {
      this.sharedFunctions.showMessage("Debe seleccionar un item", "Error");
      return false;
    }

    if (item.EsPopUp)
      this.openMessagePopUp(item.Id);
    else
      this.router.navigate(['mensaje-usuario-view', item.Id]);
  }

  openMessagePopUp(itemId: number) {
    const dialogRef = this.dialog.open(DialogMensajePopUpComponent, {
        width: '90%',
        data: itemId
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
    });
}

  openUserHelp() {
    this.router.navigate(['faq-view']);
  }
}
