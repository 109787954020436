<mat-card>
    <mat-card-header>
        <mat-card-title>Gestión de FAQ</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
   </mat-card-header>

    <mat-card-content>
  
        <mat-label *ngIf="isValidating"><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
        <mat-label *ngIf="isSearching"><i class="fa fa-spinner fa-spin"></i> Buscando...</mat-label>
        <mat-label *ngIf="!isSearching && msgError?.length > 0">{{msgError}}</mat-label>

        <div *ngIf="!isSearching && item != null">

            <ng-container *ngIf="canEdit">

                <form [formGroup]="formGroup">

                    <mat-expansion-panel [expanded]="true">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon style="margin-top: -3px; margin-right: 5px;">settings</mat-icon> Configuraciones de FAQ
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row stretch" fxLayout.xs="column">

                            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label>Orden</mat-label>
                                    <input type="number" matInput formControlName="Orden" (keyup.enter)="onGuardar()">
                                    <mat-error *ngIf="!formGroup.controls['Orden'].valid && formGroup.controls['Orden'].touched">
                                        {{ getErrorOrden() }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                                <mat-form-field appearance="outline" style="width: 100%">
                                  <mat-label *ngIf="!isRolSearching">Roles</mat-label>
                                  <mat-label *ngIf="isRolSearching">Cargando...</mat-label>
                                  <mat-select formControlName="Roles" multiple [compareWith]="compareRoles">
                                    <mat-option *ngFor="let s of rolList" [value]="s.Id">{{s.Nombre}}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                                <div fxLayout="row stretch" fxLayout.xs="column">
                                    <div fxFlex=25 fxFlex.xs=100 class="flex-item" style="margin: 20px 0px;">
                                        <mat-checkbox [(ngModel)]="item.EsPrivada" [ngModelOptions]="{standalone: true}" color="primary">Solo Acceso Privado</mat-checkbox> 
                                    </div>
                                    <div fxFlex=25 fxFlex.xs=100 class="flex-item" style="margin: 20px 0px;">
                                        <mat-checkbox [(ngModel)]="item.Activo" [ngModelOptions]="{standalone: true}" color="primary">Activo</mat-checkbox> 
                                    </div>
                                </div>
                            </div>

                        </div>

                    </mat-expansion-panel>

                    <br/>

                    <mat-expansion-panel [expanded]="true" hideToggle="true">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon style="margin-right: 5px;">help_outline</mat-icon> <p style="margin-top: 3px;">Pregunta</p>
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row stretch" fxLayout.xs="column">

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                                <ejs-richtexteditor #preguntaRte formControlName="Pregunta" [toolbarSettings]='preguntaTools'
                                    (imageUploading)="preguntaImageUploading($event)" (imageUploadSuccess)="preguntaImageUploadSuccess($event)">
                                </ejs-richtexteditor>
                            </div>

                        </div>

                    </mat-expansion-panel>

                    <br/>

                    <mat-expansion-panel [expanded]="true" hideToggle="true">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon style="margin-right: 5px;">question_answer</mat-icon> Respuesta
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row stretch" fxLayout.xs="column">

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                                <ejs-richtexteditor #respuestaRte formControlName="Respuesta" [toolbarSettings]='tools' [insertImageSettings]='insertImageSettings' 
                                    (imageUploading)="imageUploading($event)" (imageUploadSuccess)="imageUploadSuccess($event)">
                                </ejs-richtexteditor>
                            </div>

                        </div>

                    </mat-expansion-panel>

                    <br/>

                    <mat-expansion-panel [expanded]="true" hideToggle="true">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon style="margin-right: 5px;">attach_file</mat-icon> Adjuntos
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row stretch" fxLayout.xs="column">

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;" *ngIf="item.Adjuntos != null && item.Adjuntos.length > 0">
                                <mat-nav-list>
                                    <mat-list-item *ngFor="let file of item.Adjuntos" style="background-color: lightgray; margin: 5px 0px;border-radius: 10px;">
                                        <a matLine href="{{file.UrlDocumento}}" target="_blank">{{file.NombreDocumento}}</a>
                                        <button mat-icon-button (click)="onFileAttachedDelete(file)" title="Eliminar adjunto">
                                            <i class="fa fa-trash fa-1x"></i>
                                        </button>
                                    </mat-list-item>
                                </mat-nav-list>
                            </div>

                            <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;">
                                <button mat-icon-button matSuffix (click)="fileInput.click()" [disabled]="isFileAttaching"><mat-icon style="margin-right: 5px;">attach_file</mat-icon> Agregar adjunto</button>
                                <input hidden (change)="onNewFileAttach($event)" #fileInput type="file" multiple>
                                <mat-label *ngIf="isFileAttaching"><br/><i class="fa fa-spinner fa-spin"></i> Subiendo archivo...</mat-label>
                            </div>
                        </div>

                    </mat-expansion-panel>

                </form>

                <br/>

                <mat-expansion-panel [expanded]="true" hideToggle="true">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon style="margin-right: 5px;">search</mat-icon> Palabras Claves
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div fxLayout="row stretch" fxLayout.xs="column">

                        <div fxFlex=90 fxFlex.xs=100 class="flex-item" *ngIf="canEdit">
                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label>Nueva Palabra Clave</mat-label>
                                <input matInput [(ngModel)]="newKeyword" [ngModelOptions]="{standalone: true}" (keyup.enter)="onKeywordAdd()">
                            </mat-form-field>
                        </div>
                        <div fxFlex=10 fxFlex.xs=100 class="flex-item" style="margin-top:10px;" *ngIf="canEdit" align="end">
                            <div fxLayout="row stretch" fxLayout.xs="column">
                                <button mat-raised-button (click)="onKeywordAdd()" [disabled]="isProcessing()" color="primary" class="zb-button">Agregar</button>
                            </div>
                        </div>

                        <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;">
                            <ng-container *ngFor="let word of item.PalabrasClaves">
                                <mat-label style="background-color: lightgray; padding: 7px; margin: 8px 5px; border-radius: 10px;">
                                    {{word}} 
                                    <button mat-icon-button style="margin-left: 3px;" (click)="onKeywordDelete(word)" title="Eliminar palabra clave">
                                        <i class="fa fa-trash fa-1x"></i>
                                    </button>
                                </mat-label>
                                
                            </ng-container>
                        </div>

                    </div>

                </mat-expansion-panel>

            </ng-container>

            <ng-container *ngIf="!canEdit">

                <mat-expansion-panel [expanded]="true">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon style="margin-top: -3px; margin-right: 5px;">settings</mat-icon> Configuraciones de FAQ
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div fxLayout="row stretch" class="zb-readonly">
                
                        <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                            <mat-expansion-panel [expanded]="true" hideToggle="true">
                                <div fxLayout="row stretch" fxLayout.xs="column">
                                    <mat-label><b>Orden</b>: {{item.Orden}}</mat-label>
                                </div>
                            </mat-expansion-panel>
                        </div>
                        <div fxFlex=50 fxFlex.xs=100 class="flex-item">
                            <mat-expansion-panel [expanded]="true" hideToggle="true">
                            <div fxLayout="row stretch" fxLayout.xs="column">
                                <mat-label><b>Activo</b>: {{item.Activo ? 'Sí' : 'No'}}</mat-label>
                            </div>
                            </mat-expansion-panel>
                        </div>

                        <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                            <mat-expansion-panel [expanded]="true" hideToggle="true">
                            <div fxLayout="row stretch" fxLayout.xs="column">
                                <mat-label><b>Roles</b>: {{rolesString}}</mat-label>
                            </div>
                            </mat-expansion-panel>
                        </div>

                        <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                            <mat-expansion-panel [expanded]="true" hideToggle="true">
                                <div fxLayout="row stretch" fxLayout.xs="column">
                                    <mat-label><b>Pregunta</b>:</mat-label>
                                </div>
                                <br/>
                                <div fxLayout="row stretch" fxLayout.xs="column" [innerHTML]="preguntaHtml">
                                </div>
                            </mat-expansion-panel>
                        </div>

                        <div fxFlex=100 fxFlex.xs=100 class="flex-item">
                            <mat-expansion-panel [expanded]="true" hideToggle="true">
                                <div fxLayout="row stretch" fxLayout.xs="column">
                                    <mat-label><b>Respuesta</b>:</mat-label>
                                </div>
                                <br/>
                                <div fxLayout="row stretch" fxLayout.xs="column" [innerHTML]="respuestaHtml">
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </div>

                </mat-expansion-panel>

                <br/>

                <mat-expansion-panel [expanded]="true">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon style="margin-top: -3px;">attach_file</mat-icon> Adjuntos
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div fxLayout="row stretch" fxLayout.xs="column">

                        <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;" *ngIf="isAttachmentSearching">
                        <mat-label><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
                        </div>
                        <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;" *ngIf="!isAttachmentSearching">
                        <mat-nav-list>
                        <mat-list-item *ngFor="let file of item.Adjuntos" style="background-color: lightgray; margin: 5px 0px;border-radius: 10px;">
                            <a matLine href="{{file.UrlDocumento}}" target="_blank">{{file.NombreDocumento}}</a>
                        </mat-list-item>
                        </mat-nav-list>
                        </div>
                    </div>

                </mat-expansion-panel>

                <br/>

                <mat-expansion-panel [expanded]="true">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon style="margin-right: 5px;">search</mat-icon> Palabras Claves
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div fxLayout="row stretch" fxLayout.xs="column">

                        <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;">
                            <ng-container *ngFor="let word of item.PalabrasClaves">
                                <mat-label style="background-color: lightgray; padding: 7px; margin: 8px 5px; border-radius: 10px;">
                                    {{word}} 
                                </mat-label>
                                
                            </ng-container>
                        </div>

                    </div>

                </mat-expansion-panel>
        
              </ng-container>

        </div>

    </mat-card-content>

    <mat-card-actions style="margin-bottom: 20px;">
        <div fxLayout="row stretch" fxLayout.xs="column">
            <button mat-raised-button (click)="onGuardar()" *ngIf="canEdit" [disabled]="isProcessing()" color="primary" class="zb-button">{{isSaving ? 'Guardando...' : 'Guardar'}}</button>
            <button mat-raised-button (click)="onCancel()" color="warn" class="zb-button">{{canEdit ? 'Cancelar' : 'Cerrar'}}</button>
            <button mat-raised-button (click)="onDelete()" [disabled]="isProcessing()" *ngIf="canDelete" color="accent" class="zb-button">{{isDeleting ? 'Eliminando...' : 'Eliminar'}}</button>
        </div>
    </mat-card-actions>

</mat-card>