<h2 mat-dialog-title>Detalle por Mes para el Año {{input.Anio}}</h2>

<div mat-dialog-content>

    <div fxLayout="row stretch" fxLayout.xs="column" style="margin: 10px 0px;">
      <ejs-grid #grid [dataSource]='input.Meses' [allowSorting]="true" [allowResizing]='true'>
        <e-columns>
          <e-column field='MesNombre' headerText='Mes' width=90></e-column>
          <e-column field='TotalCantidad' headerText='Total Cantidad' textAlign='Right' format="N2" width=90></e-column>
          <e-column field='MinimoPrecioUnitario' headerText='Min. Precio Unitario' textAlign='Right' format="N2" width=90></e-column>
          <e-column field='TotalMonto' headerText='Total Monto' textAlign='Right' format="N2" width=90></e-column>
        </e-columns>
      </ejs-grid>
    </div>

</div>

<br/>

<div mat-dialog-actions>
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="warn" (click)="onCancel()" class="zb-button">Cerrar</button>
  </div>
</div>