import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, MailConfiguracion, SearchMailConfiguracionRequest, MailProveedorEnvio, SaveMailConfiguracionDto, SendTestEmailDto, UploadMailCertificadoDto } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
  providedIn: 'root'
})
export class MailConfiguracionService extends CrudService<MailConfiguracion, number, SaveMailConfiguracionDto, SearchMailConfiguracionRequest> {

  constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
    super(_http,'mailConfiguracion', sharedFunctions);

  }

  getProveedoresEnvio(): Observable<GenericResponse<MailProveedorEnvio[]>> {
    return this._http.get<GenericResponse<MailProveedorEnvio[]>>(this._serviceUrl + '/proveedores-envio', this.httpOptions);
  }

  sendTestEmail(p: SendTestEmailDto): Observable<GenericResponse<any>> {
    return this._http.post<GenericResponse<any>>(this._serviceUrl + '/send-test-email', p, this.httpOptions);
  }

  uploadAttachTempUrl(): string {
    return this._serviceUrl + '/attach-certificado/temp';
  }

  uploadAttachTemp(p: any): Observable<GenericResponse<UploadMailCertificadoDto>> {
    const formData = new FormData();

    if (p != null) {
      for (let i = 0; i < p.length; i++) {
        const file = p[i];
        if (file != null)
          formData.append('uploadFiles', file);
      }
    }

    return this._http.post<GenericResponse<UploadMailCertificadoDto>>(this.uploadAttachTempUrl(), formData, this.httpFormDataOptions);
  }
}