<h1 mat-dialog-title>Recuperación de Clave</h1>

<div mat-dialog-content>

  <form [formGroup]="formGroup">

    <div fxLayout="row stretch" fxLayout.xs="column">
      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-label>Ingresa tu nombre de usuario y te enviaremos los pasos para recuperar tu clave:</mat-label>
      </div>
    </div>
    <br/>
    <div fxLayout="row stretch" fxLayout.xs="column" fxLayoutGap="20px">
      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Nombre de Usuario</mat-label>
          <input matInput formControlName="Login" (keyup.enter)="onGuardar()">
          <mat-error *ngIf="!formGroup.controls['Login'].valid && formGroup.controls['Login'].touched">
            {{ getErrorLogin() }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

</div>

<div mat-dialog-actions align="end">
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="primary" [disabled]="!buttonEnabled" (click)="onGuardar()" class="zb-button">{{buttonEnabled ? 'Recuperar' : 'Procesando...'}}</button>
    <button mat-raised-button color="warn" (click)="onCancelar()" [disabled]="!buttonEnabled" class="zb-button">Cancelar</button>
  </div>
</div>