import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { CrudService } from "./crud.service";
import { GenericResponse, LicitacionCategoria, SaveLicitacionCategoriaDto, SearchLicitacionCategoriaRequest, SearchLicitacionCategoriaResponse } from "../model/index";
import { SharedFunctions } from 'src/app/shared/shared.functions';

@Injectable({
    providedIn: 'root'
})
export class LicitacionCategoriaService extends CrudService<LicitacionCategoria, number, SaveLicitacionCategoriaDto, SearchLicitacionCategoriaRequest> {

    constructor(protected _http: HttpClient, protected sharedFunctions: SharedFunctions) {
        super(_http, 'licitacioncategoria', sharedFunctions);
    }
}