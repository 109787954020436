<h2 mat-dialog-title>{{item?.Asunto}}</h2>

<div mat-dialog-content>

    <div fxLayout="row stretch" fxLayout.xs="column" *ngIf="item == null">
        <mat-label>{{msgError}}</mat-label>
    </div>

    <div fxLayout="row stretch" fxLayout.xs="column" *ngIf="item != null">
        <div fxFlex=100 fxFlex.xs=100 class="flex-item" [innerHTML]="item?.Mensaje">
        </div>
    </div>

    <div fxLayout="row stretch" fxLayout.xs="column" *ngIf="item != null && item.Adjuntos != null && item.Adjuntos.length > 0">

        <hr style="margin: 0px; width: 100%;">

        <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:0px 0px; padding-top: 16px;">
            <mat-label><b>ADJUNTOS:</b></mat-label>
        </div>
        <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;" *ngIf="isAttachmentSearching">
            <mat-label><i class="fa fa-spinner fa-spin"></i> Cargando...</mat-label>
        </div>
        <div fxFlex=100 fxFlex.xs=100 class="flex-item" style="margin:10px;" *ngIf="!isAttachmentSearching">
            <mat-nav-list>
                <mat-list-item *ngFor="let file of item.Adjuntos" style="background-color: lightgray; margin: 5px 0px;border-radius: 10px;">
                    <a matLine href="{{file.UrlDocumento}}" target="_blank">{{file.NombreDocumento}}</a>
                </mat-list-item>
                </mat-nav-list>
        </div>
    </div>

</div>

<br/>

<div mat-dialog-actions>
    <div fxLayout="row stretch" fxLayout.xs="column">
        <button mat-raised-button color="warn" (click)="onClose()" class="zb-button">Cerrar</button>
    </div>
</div>