<h1 mat-dialog-title>Creación de Usuario</h1>

<div mat-dialog-content>
  <form [formGroup]="formGroup">

    <div fxLayout="row stretch" fxLayout.xs="column">

      <div fxFlex=100 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Mail*</mat-label>
          <input matInput formControlName="Mail" type="email" (keyup.enter)="onGuardar()" maxlength="255">
          <mat-error *ngIf="!formGroup.controls['Mail'].valid && formGroup.controls['Mail'].touched">
            {{ getErrorMail() }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex=50 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Nombre*</mat-label>
          <input matInput formControlName="Nombre" type="text" (keyup.enter)="onGuardar()" maxlength="150">
          <mat-error *ngIf="!formGroup.controls['Nombre'].valid && formGroup.controls['Nombre'].touched">
            {{ getErrorNombre() }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex=50 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Apellido</mat-label>
          <input matInput formControlName="Apellido" type="text" (keyup.enter)="onGuardar()" maxlength="150">
          <mat-error *ngIf="!formGroup.controls['Apellido'].valid && formGroup.controls['Apellido'].touched">
            {{ getErrorApellido() }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex=50 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Nro. Documento</mat-label>
          <input matInput formControlName="NroDocumento" type="text" (keyup.enter)="onGuardar()" maxlength="100">
          <mat-error *ngIf="!formGroup.controls['NroDocumento'].valid && formGroup.controls['NroDocumento'].touched">
            {{ getErrorNroDocumento() }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex=50 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Móvil</mat-label>
          <input matInput formControlName="Movil" type="text" (keyup.enter)="onGuardar()" maxlength="100">
          <mat-error *ngIf="!formGroup.controls['Movil'].valid && formGroup.controls['Movil'].touched">
            {{ getErrorMovil() }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <hr style="margin-top: 20px; margin-bottom: 20px; width: 100%;">

    <div fxLayout="row stretch" fxLayout.xs="column">

      <div fxFlex=50 fxFlex.xs=100 class="flex-item" *ngIf="tenantList?.length != 1">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label *ngIf="!isTenantSearching">Empresa</mat-label>
          <mat-label *ngIf="isTenantSearching">Cargando...</mat-label>
          <mat-select formControlName="TenantId">
            <mat-option *ngFor="let s of tenantList" [value]="s.Id">{{s.Nombre}}</mat-option>
          </mat-select>
          <mat-error *ngIf="!formGroup.controls['TenantId'].valid && formGroup.controls['TenantId'].touched">
            {{ getErrorTenantId() }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex=50 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Nombre de Usuario*</mat-label>
          <input matInput formControlName="Login" type="text" (keyup.enter)="onGuardar()" maxlength="100">
          <mat-error *ngIf="!formGroup.controls['Login'].valid && formGroup.controls['Login'].touched">
            {{ getErrorLogin() }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row stretch" fxLayout.xs="column">

      <div fxFlex=50 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Clave*</mat-label>
          <input matInput [type]="hideClave ? 'password' : 'text'" formControlName="Clave" (keyup.enter)="onGuardar()">
          <button mat-icon-button matSuffix (click)="hideClave = !hideClave" type="button"><mat-icon>{{hideClave ? 'visibility_off' : 'visibility'}}</mat-icon></button>
          <mat-error *ngIf="!formGroup.controls['Clave'].valid && formGroup.controls['Clave'].touched">
            {{ getErrorClave() }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex=50 fxFlex.xs=100 class="flex-item">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Confirma tu Clave*</mat-label>
          <input matInput [type]="hideConfirmClave ? 'password' : 'text'" formControlName="ConfirmClave" (keyup.enter)="onGuardar()">
          <button mat-icon-button matSuffix (click)="hideConfirmClave = !hideConfirmClave" type="button"><mat-icon>{{hideConfirmClave ? 'visibility_off' : 'visibility'}}</mat-icon></button>
          <mat-error *ngIf="!formGroup.controls['ConfirmClave'].valid && formGroup.controls['ConfirmClave'].touched">
            {{ getErrorConfirmClave() }}
          </mat-error>
        </mat-form-field>
      </div>
      
    </div>

  </form>
</div>

<div mat-dialog-actions align="end">
  <div fxLayout="row stretch" fxLayout.xs="column">
    <button mat-raised-button color="primary" (click)="onGuardar()" [disabled]="!buttonEnabled" class="zb-button"><mat-icon>input</mat-icon> {{buttonEnabled ? 'Crear' : 'Creando...'}}</button>
    <button mat-raised-button color="warn" (click)="onCancelar()" [disabled]="!buttonEnabled" class="zb-button"><mat-icon>cancel</mat-icon> Cancelar</button>  
  </div>
</div>